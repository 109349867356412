import { Spinner } from ':components/shadcn';
import { useTranslation } from 'react-i18next';

type LoadingProps = Readonly<{
    translationId?: string;
}>;

export default function Loading({ translationId }: LoadingProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'loading' });

    return (
        <div className='absolute w-full h-full flex flex-col justify-center'>
            <div className='flex justify-center'>
                <div style={{ marginBottom: '100px' }}>
                    <div className='m-4 ml-8 text-2xl text-secondary'>
                        {t([ translationId ?? '',  'default-text' ])}
                    </div>
                    <div className='flex justify-center'>
                        <Spinner />
                    </div>
                </div>
            </div>
        </div>
    );
}

import { useEffect, useRef, useState } from 'react';
import { Button, Modal } from ':components/shadcn';
import { Link } from 'react-router-dom';
import { ErrorType, type PlanExceededError, type SubscriptionInvalidError, type SubscriptionError, isPlanExceededError } from ':frontend/types/Subscription';
import { Trans, useTranslation } from 'react-i18next';
import { emptyFunction } from ':frontend/utils/common';
import { routesFE } from ':utils/routes';
import { useCached } from ':components/hooks';
import SpinnerButton from '../common/SpinnerButton';
import { toMaster, useUser } from ':frontend/context/UserProvider';
import { trpc } from ':frontend/context/TrpcProvider';
import { RiAlertFill } from ':components/icons/old';
import { useMaster } from ':frontend/context/UserProvider';
import { SubscriptionTierCode, SubscriptionTierPaymentPeriod } from ':utils/entity/subscription';
import { useSubscriptionUpsert } from '../../pages/Subscriptions';


export function SubscriptionUpsellModal({ show, onHide }: {show: boolean, onHide: () => void}) {
    const { team } = useMaster();
    const { buySubscription, isFetching } = useSubscriptionUpsert();

    // FIXME the behavior of the modal needs to be checked.
    // FIXME translations.
    return (
        <Modal.Root
            open={show}
            // backdrop='static'
            // keyboard={false}
            // centered
        >
            <Modal.Content className='text-center' closeButton={null}>
                <Modal.Header>
                    <h2>Upsell</h2>
                    <p>Upsell text</p>
                </Modal.Header>
                <Modal.Footer>

                    <Button onClick={onHide}>Close</Button>
                    <SpinnerButton
                        isFetching={isFetching}
                        onClick={() => buySubscription(SubscriptionTierCode.paid, SubscriptionTierPaymentPeriod.monthly)}
                    >
                        {team.dateTrialStarted ? 'Buy' : 'Try'}
                    </SpinnerButton>
                </Modal.Footer>
            </Modal.Content>
        </Modal.Root>
    );
}

type PlanExceededErrorModalProps = Readonly<{
    error: PlanExceededError | undefined;
    onHide: () => void;
}>;

export function PlanExceededErrorModal({ error, onHide }: PlanExceededErrorModalProps) {
    return (
        <CommonModal error={error} onHide={onHide} />
    );
}

type UnselectablePlanErrorModalProps = Readonly<{
    error: SubscriptionInvalidError | undefined;
    onHide: () => void;
}>
export function UnselectablePlanErrorModal({ error, onHide }: UnselectablePlanErrorModalProps) {
    return (
        <CommonModal error={error} onHide={onHide}/>
    );
}

type SubscriptionEndedErrorModalProps = Readonly<{
    error: SubscriptionInvalidError | undefined;
}>;

export function SubscriptionEndedErrorModal({ error }: SubscriptionEndedErrorModalProps) {
    return (
        <CommonModal error={error} onHide={emptyFunction} />
    );
}

type CommonModalProps = Readonly<{
    error: SubscriptionError | undefined;
    onHide: () => void;
}>;

function CommonModal({ error, onHide }: CommonModalProps) {
    const cachedError = useCached(error);
    if (!cachedError)
        return null;

    return (
        <Modal.Root open={!!error}>
            <Modal.Content closeButton={null}>
                <ModalBody error={cachedError} onHide={onHide} />
            </Modal.Content>
        </Modal.Root>
    );
}

function ModalBody({ error, onHide }: CommonModalProps) {
    const isMasterOrFreelancer = !!toMaster(useUser());
    const { t } = useTranslation('common', { keyPrefix: isMasterOrFreelancer ? error?.type : 'subscription.schedulerError' });
    const [ isFetching, setIsFetching ] = useState(false);
    const subscriptionSessionPromiseRef = useRef<Promise<string>>();

    const createSubscriptionSessionMutation = trpc.$subscription.createSubscriptionSession.useMutation();

    useEffect(() => {
        if (error?.type !== ErrorType.PlanPending)
            return;

        subscriptionSessionPromiseRef.current = (async () => {
            try {
                const response = await createSubscriptionSessionMutation.mutateAsync();
                return response.continueUrl;
            }
            catch {
                return '';
            }
        })();
    }, [ error, createSubscriptionSessionMutation ]);

    async function openSubscriptionSession() {
        if (!subscriptionSessionPromiseRef.current)
            return;
        setIsFetching(true);
        window.location.href = await subscriptionSessionPromiseRef.current;
    }

    return (
        <div className='text-center'>
            <RiAlertFill size={60} className='text-warning' />
            <Modal.Header>
                <Modal.Title>{t('title')}</Modal.Title>
            </Modal.Header>

            {!isMasterOrFreelancer ? (
                <div className='whitespace-pre-wrap mb-4'>
                    {t('text')}
                </div>
            ) : isPlanExceededError(error) ? (<>
                <div>
                    <Trans>
                        {t('cart-payments-text', { count: error.cart })}
                        {t('available-payments-text', { count: error.available })}
                    </Trans>
                </div>
                <Modal.Footer>
                    <div className='mt-8 w-full'>
                        <div>
                            <Button onClick={onHide} className='fl-button-inverse float-right'>
                                {t('hide-button')}
                            </Button>
                        </div>
                        <div>
                            <Link to={routesFE.subscription.path} className='float-left'>
                                <Button>{t('new-plan-button')}</Button>
                            </Link>
                        </div>
                    </div>
                </Modal.Footer>
            </>) : error?.type === ErrorType.PlanPending ? (<>
                <div className='whitespace-pre-wrap mb-4'>
                    {t('text')}
                </div>
                <Modal.Footer>
                    <SpinnerButton isFetching={isFetching} onClick={openSubscriptionSession}>
                        {t('checkButton')}
                    </SpinnerButton>
                </Modal.Footer>
            </>) : (<>
                <div className='whitespace-pre-wrap mb-4'>
                    {t('text')}
                </div>
                <Modal.Footer>
                    <Button onClick={onHide}>
                        {t('hide-button')}
                    </Button>
                </Modal.Footer>
            </>)}
        </div>
    );
}

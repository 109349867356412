import { BankingMobileIcon, XmarkIcon } from ':components/icons/basic';
import StripeLogo from ':components/icons/StripeLogo';
import { Button, Form } from ':components/shadcn';
import { PaymentMethod } from ':utils/entity/order';
import { useTranslation } from 'react-i18next';

export type PaymentMethodRadioOption = {
    method: PaymentMethod;
    isDisabled?: boolean;
    onEnableClick?: () => void;
};

type PaymentMethodRadioProps = Readonly<{
    value?: PaymentMethod;
    options: PaymentMethodRadioOption[];
    onChange: (value: PaymentMethod) => void;
}>;

export function PaymentMethodRadio({ value, options, onChange }: PaymentMethodRadioProps) {
    const { t } = useTranslation('components', { keyPrefix: 'paymentMethodSelect' });

    return (
        <Form.RadioGroup
            className='grid grid-flow-col auto-cols-fr gap-4'
            value={value}
            onValueChange={onChange}
        >
            {options.map(option => {
                const { method, isDisabled } = option;
                // FIXME Tooltip

                // const label = (
                //     <span>
                //         {t(`${method}-title`)}
                //         {/* {method === PaymentMethod.noInvoice && <InfoTooltip text={t(`${method}-tooltip`)} className='ml-2' />} */}
                //     </span>
                // );

                if (isDisabled) {
                    return (
                        <div key={option.method} className='flex flex-col items-center gap-2'>
                            <div className='self-stretch h-28 rounded-md flex flex-col items-center justify-center gap-2 outline outline-2 outline-secondary-200'>
                                {paymentMethodIcons[option.method]()}

                                {option.onEnableClick && (<>
                                    <Button variant='white' size='tiny' onClick={option.onEnableClick}>
                                        {t(`${method}-enable-button`)}
                                    </Button>
                                </>)}
                            </div>
                            <span className='leading-5 text-secondary-300'>{t(`${method}-title`)}</span>
                        </div>
                    );
                }

                return (
                    <Form.CustomRadioItem
                        key={option.method}
                        value={option.method}
                        direction='col'
                        label={t(`${method}-title`)}
                    >
                        <div className='h-28 rounded-md flex flex-col items-center justify-center [&:not(:hover)]:outline-secondary-200'>
                            {paymentMethodIcons[option.method]()}
                        </div>
                    </Form.CustomRadioItem>
                );
            })}
        </Form.RadioGroup>
    );
}

// TODO Enable size on stripe logo.

const paymentMethodIcons = {
    [PaymentMethod.stripe]: () => <StripeLogo className='h-9' />,
    [PaymentMethod.bankTransfer]: () => <BankingMobileIcon size={36} />,
    [PaymentMethod.noInvoice]: () => <XmarkIcon size={36} />,
} as const;

import { useCallback, useEffect } from 'react';
import { Form } from ':components/shadcn';
import { useForm } from 'react-hook-form';
import { type InvoicingProfileFE } from ':frontend/types/Invoicing';
import { addressToUpsert, defaultEditableAddress, type EditableAddress } from ':frontend/types/Address';
import { optionalStringToPut } from ':frontend/utils/common';
import { NewInvoicingProfileFormInner, type NewInvoicingProfileFormData } from './NewInvoicingProfileForm';
import { useTranslation } from 'react-i18next';
import { useMaster } from ':frontend/context/UserProvider';
import { useAres } from './useAres';
import { MapPinIcon, UsersIcon } from ':components/icons/old';
import { SpinnerButton } from '../common';
import { ControlledCountrySelect } from '../forms';
import { canonizeEmail } from ':utils/forms';
import { InfoTooltip } from '../forms/buttons';
import type { AresInfo } from ':utils/lib/ares';
import type { InvoicingProfileGeneralUpsert } from ':utils/entity/invoicing';

type InputType = InvoicingProfileFE;
type FormDataType = NewInvoicingProfileFormData & EditableAddress & {
    cin: string;
    legalName: string;
    tin: string;
};

export type GeneralSupplierFormOutput = {
    general: InvoicingProfileGeneralUpsert;
};

function inputToForm(input: InputType): FormDataType {
    return {
        ...(input.address?.toEditable() ?? defaultEditableAddress),
        cin: input.cin ?? '',
        tin: input.tin ?? '',
        legalName: input.legalName ?? '',
        title: input.title,
        locale: input.locale,
        dueDays: input.dueDays,
        invoicingEmail: input.email ?? '',
        hideEmailOnInvoice: input.hideEmailOnInvoice,
    };
}

function formToOutput(data: FormDataType): GeneralSupplierFormOutput {
    return {
        general: {
            address: addressToUpsert(data),
            cin: optionalStringToPut(data.cin),
            tin: optionalStringToPut(data.tin),
            legalName: optionalStringToPut(data.legalName),
            email: optionalStringToPut(canonizeEmail(data.invoicingEmail)),
            hideEmailOnInvoice: data.hideEmailOnInvoice,
            title: data.title,
            locale: data.locale,
            dueDays: data.dueDays,
        },
    };
}

type GeneralSupplierFormProps = Readonly<{
    input: InputType;
    onSubmit: (output: GeneralSupplierFormOutput) => void;
    saveButton?: React.FC<{ onClick: () => void }>;
    onChange?: (isDirty: boolean) => void;
}>;

export function GeneralSupplierForm({ input, onSubmit, saveButton, onChange }: GeneralSupplierFormProps) {
    const { t } = useTranslation('components', { keyPrefix: 'invoicingForm' });
    const { teamSettings, profiles } = useMaster();

    const form = useForm<FormDataType>({
        defaultValues: inputToForm(input),
    });
    const { register, control, formState: { errors, isDirty }, setValue, reset, handleSubmit } = form;

    function onValid(data: FormDataType) {
        onSubmit(formToOutput(data));
    }

    useEffect(() => onChange?.(isDirty), [ isDirty ]);
    useEffect(() => {
        reset(inputToForm(input));
    }, [ input ]);

    const fillFromAres = useCallback((ares: AresInfo) => {
        ares.legalName && setValue('legalName', ares.legalName, { shouldDirty: true });
        // We don't use tin here. You wouldn't know it, it's going to another form.
        setValue('cin', ares.cin, { shouldDirty: true });
        ares.address?.city && setValue('city', ares.address.city, { shouldDirty: true });
        ares.address?.line1 && setValue('line1', ares.address.line1, { shouldDirty: true });
        ares.address?.postalCode && setValue('postalCode', ares.address.postalCode, { shouldDirty: true });
        ares.address?.country && setValue('country', ares.address.country, { shouldDirty: true });
    }, [ setValue ]);

    const { fetchAres, isFetchingAres } = useAres(fillFromAres);

    // If there is only one profile, we show only that profile. Therefore, there's no need to show the name field.
    const isOnlyOneProfile = profiles.length === 1;

    return (
        <Form.Root onSubmit={handleSubmit(onValid)}>
            <NewInvoicingProfileFormInner
                control={control}
                errors={errors}
                setValue={setValue}
                watch={form.watch}
                hideName={isOnlyOneProfile}
            />

            <h5 className='mt-8 font-semibold text-lg'>{t('invoicing-company-title')}</h5>

            <div className='mt-4 flex items-end gap-4'>
                <div className='flex-1'>
                    <Form.Input
                        label={t('cin-label')}
                        {...register('cin')}
                        placeholder={t('cin-placeholder')}
                    />
                </div>

                {teamSettings.country === 'CZ' && (
                    <div className='pb-2'>
                        <SpinnerButton variant='outline' size='small' isFetching={isFetchingAres} onClick={() => fetchAres(form.getValues('cin'))}>{t('prefill-from-cin')}</SpinnerButton>
                    </div>
                )}
            </div>

            <div className='mt-4 grid grid-cols-3 gap-4'>
                <div>
                    <Form.Input
                        label={t('legal-name-label')}
                        {...register('legalName')}
                        placeholder={t('legal-name-placeholder')}
                    />
                </div>

                <div>
                    <Form.Input
                        label={<>{t('tin-label')}<InfoTooltip text={t('tin-tooltip')} className='ml-2' /></>}
                        {...register('tin')}
                    />
                </div>
            </div>

            <h5 className='mt-8 font-semibold text-lg'>{t('invoicing-address-title')}</h5>

            <div className='mt-4'>
                <Form.Input
                    label={t('city-label')}
                    {...register('city')}
                    placeholder={t('city-placeholder')}
                />
            </div>

            <div className='mt-4'>
                <Form.Input
                    label={t('line1-label')}
                    {...register('line1')}
                    placeholder={t('line1-placeholder')}
                />
            </div>

            <div className='mt-4 items-start grid grid-cols-2 gap-4'>
                <div>
                    <Form.Input
                        label={t('postal-code-label')}
                        {...register('postalCode')}
                        placeholder={t('postal-code-placeholder')}
                    />
                </div>

                <div>
                    <Form.Label>{t('country-label')}</Form.Label>
                    <ControlledCountrySelect
                        control={control}
                        name='country'
                    />
                </div>
            </div>

            <>{saveButton?.({ onClick: handleSubmit(onValid) })}</>
        </Form.Root>
    );
}

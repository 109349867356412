import { localizer } from '.';
import { type DateTime } from 'luxon';
import { capitalize } from ':frontend/utils/common';

type HeaderType = 'month' | 'week';

type HeaderProps = Readonly<{
    date: DateTime;
    type: HeaderType;
}>;

export default function Header({ date, type }: HeaderProps) {
    return (
        <div className='flex items-baseline justify-center gap-4 select-none'>
            <div className='font-normal'>{capitalize(localizer.format(date, 'cccc'))}</div>
            {type === 'week' && <div className='fl-header-daynumber p-[6px] rounded-full h-[29px] w-[29px] leading-4 text-center'>{localizer.format(date, 'd')}</div>}
        </div>
    );
}

import { useCallback, useMemo, useState } from 'react';
import NewLocationModal from './NewLocationModal';
import { type Id } from ':utils/id';
import { useTranslation } from 'react-i18next';
import { CreatableSelect, type SelectConfig, type SingleValue } from ':components/shadcn';
import { trpc } from ':frontend/context/TrpcProvider';
import type { LocationOutput } from ':utils/entity/location';

type Option = {
    value: string;
    label: string;
};

function locationToOption(location: LocationOutput): Option {
    return {
        value: location.id,
        label: location.title,
    };
}

type LocationSelectProps = Readonly<{
    value: Id | undefined;
    readOnly?: boolean;
    onChange?: (value: Id | undefined) => void;
    id?: string;
    className?: string;
    immutableProps?: SelectConfig<Option>;
}>;

export function LocationSelect({ value, readOnly, onChange, ...rest }: LocationSelectProps) {
    const { t } = useTranslation('components', { keyPrefix: 'locationSelect' });
    const [ showNewLocationModal, setShowNewLocationModal ] = useState(false);
    const [ newLocationTitle, setNewLocationTitle ] = useState('');

    const trpcUtils = trpc.useUtils();
    const locations = trpc.location.getLocations.useQuery().data;

    const options = useMemo(() => (locations ?? []).map(locationToOption), [ locations ]);
    const option = useMemo(() => options.find(option => value && value === option.value), [ options, value ]);

    const handleOnChange = useCallback((value: SingleValue<Option>) => {
        const selectedId = (locations ?? [])
            .map(location => location.id)
            .find(id => id === value?.value);

        onChange?.(selectedId);
    }, [ locations, onChange ]);

    const handleBeforeCreated = useCallback((value: string) => {
        setNewLocationTitle(value);
        setShowNewLocationModal(true);
        onChange?.(undefined);
    }, [ onChange ]);

    const handleLocationCreated = useCallback((location: LocationOutput) => {
        trpcUtils.location.getLocations.setData(undefined, prev => [ ...(prev ?? []), location ]);
        onChange?.(location.id);
    }, [ trpcUtils, onChange ]);

    const { t: tf } = useTranslation('common', { keyPrefix: 'select' });
    const newOptionLabel = useCallback((input: string) => (<>
        {tf('create-option-label') + ' '}<span>{input}</span>
    </>), [ tf ]);

    return (<>
        <NewLocationModal
            defaultTitle={newLocationTitle}
            show={showNewLocationModal}
            onClose={() => setShowNewLocationModal(false)}
            onLocationCreated={handleLocationCreated}
        />
        <CreatableSelect
            placeholder={t('placeholder')}
            noOptionsMessage={() => t('no-options-placeholder')}
            options={options}
            value={option}
            menuIsOpen={readOnly ? false : undefined}
            onChange={handleOnChange}
            isDisabled={readOnly ? true : undefined}
            onCreateOption={handleBeforeCreated}
            formatCreateLabel={newOptionLabel}
            isClearable
            {...rest}
        />
    </>);
}

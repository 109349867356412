import { linkify } from ':utils/common';
import { zId } from ':utils/id';
import { z } from 'zod';

/**
 * There are some special predefined platforms which are handled differently (custom icon, link, etc.).
 */
export enum PlatformType {
    googleMeet = 'googleMeet',
    microsoftTeams = 'microsoftTeams',
    zoom = 'zoom',
    /** All other types are considered video. */
    physical = 'physical',
    custom = 'custom',
}

export function getPlatformType(platform: string): PlatformType {
    return platform in PlatformType ? PlatformType[platform as keyof typeof PlatformType] : PlatformType.custom;
}

/** @returns Linkified link, Google Maps search link in case of a physical location. */
export function getLocationLink({ value, platform }: { value: string, platform: string }) {
    const type = getPlatformType(platform);

    if (type === PlatformType.physical)
        return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(value)}`;

    return linkify(value);
}

export type LocationOutput = z.infer<typeof zLocationOutput>;
export const zLocationOutput = z.object({
    id: zId,
    title: z.string(),
    platform: z.string(),
    value: z.string(),
});

export type LocationUpsert = z.infer<typeof zLocationUpsert>;
export const zLocationUpsert = z.object({
    title: z.string(),
    platform: z.string(),
    value: z.string(),
});

import clsx from 'clsx';
import { CloseIcon, MdModeEdit, PlusIcon } from ':components/icons/old';
import { Button, Tooltip } from ':components/shadcn';
import { Trash2Icon, CircleInfoIcon } from ':components/icons/basic';

type BaseButtonProps = Readonly<{
    aria: string;
    className?: string;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    id?: string;
    disabled?: boolean;
}>;

/** @deprecated Use Button instead. */
export function CloseButton({ aria, className, ...rest }: BaseButtonProps) {
    return (
        <Button
            type='button'
            aria-label={aria}
            className={clsx('fl-icon-button', className)}
            {...rest}
        >
            <CloseIcon size={18} />
        </Button>
    );
}

/** @deprecated Use Button instead. */
export function DeleteButton({ aria, className, ...rest }: BaseButtonProps) {
    return (
        <Button
            type='button'
            aria-label={aria}
            className={clsx('fl-icon-button', className)}
            {...rest}
        >
            <Trash2Icon size='sm' />
        </Button>
    );
}

/** @deprecated Use Button instead. */
export function AddButton({ aria, className, ...rest }: BaseButtonProps) {
    return (
        <Button
            type='button'
            aria-label={aria}
            className={clsx('fl-icon-button', className)}
            {...rest}
        >
            <PlusIcon size={16} />
        </Button>
    );
}

/** @deprecated Use Button instead. */
export function EditButton({ aria, className, ...rest }: BaseButtonProps) {
    return (
        <Button
            type='button'
            aria-label={aria}
            className={clsx('fl-icon-button', className)}
            {...rest}
        >
            <MdModeEdit size={16} />
        </Button>
    );
}

type InfoTooltipProps = Readonly<{
    text: string;
    className?: string;
}>;

export function InfoTooltip({ text, className }: InfoTooltipProps) {
    return (
        <div className={clsx('inline-block relative w-0 h-3', className)}>
            <div className='absolute top-[-3px]'>
                <Tooltip
                    tooltipText={text}
                    className='whitespace-pre-line bg-white'
                >
                    <span className='inline-block' style={{ width: '18px', height: '18px' }}>
                        <CircleInfoIcon size={18} className='text-primary select-none cursor-pointer' />
                    </span>
                </Tooltip>
            </div>
        </div>
    );
}

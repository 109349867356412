import { useEffect, useMemo } from 'react';
import { Form } from ':components/shadcn';
import { useForm } from 'react-hook-form';
import { type ClientFE } from ':frontend/types/Client';
import { RHFErrorMessage } from ':frontend/components/forms/ErrorMessage';
import { useTranslation } from 'react-i18next';
import { EMAIL_SOFT_VALIDATION_PATTERN, canonizeEmail } from ':utils/forms';
import { optionalStringToPatch, stringToPatch } from ':frontend/utils/common';
import { EditNotesIcon, UsersIcon } from ':components/icons/old';
import { ClientStatsView } from './ClientStats';
import { toMaster, useUser } from ':frontend/context/UserProvider';
import { ClientAccessType, type ClientUpdate } from ':utils/entity/client';
import type { OmitId } from ':utils/id';
import { OnlyToYouLabel } from '../common/OnlyToYouLabel';

type ClientGeneralFormProps = Readonly<{
    onSubmit: (output: OmitId<ClientUpdate>) => void;
    defaultValue: ClientFE;
    onChange?: (isDirty: boolean) => void;
    saveButton?: React.FC<{ onClick: () => void }>;
}>;

export default function ClientGeneralForm({ onSubmit, defaultValue, onChange, saveButton }: ClientGeneralFormProps) {
    const { t } = useTranslation('components', { keyPrefix: 'clientForm' });
    const { t: tf } = useTranslation('common', { keyPrefix: 'form' });

    const { form, handleSubmit, isEditable } = useClientGeneralForm(onSubmit, defaultValue, onChange);
    const { register, formState: { errors } } = form;

    return (<>
        <ClientStatsView client={defaultValue} />
        <Form.Root onSubmit={handleSubmit}>
            <h5 className='m-0 flex items-center'><UsersIcon size={24} className='mr-4' />{t('general-title')}</h5>
            <div className='mt-4'>
                <div>
                    <Form.Input
                        label={t('email-label')}
                        type='email'
                        {...register('email', {
                            required: tf('email-required'),
                            pattern: {
                                value: EMAIL_SOFT_VALIDATION_PATTERN,
                                message: tf('email-invalid'),
                            },
                        })}
                        placeholder={t('email-placeholder')}
                        disabled={!isEditable}
                    />
                    <RHFErrorMessage errors={errors} name='email' />
                </div>
                <div>
                    <Form.Input
                        label={t('phone-number-label')}
                        {...register('phoneNumber', { required: false })}
                        placeholder={t('phone-number-placeholder')}
                        disabled={!isEditable}
                    />
                </div>
            </div>
            <div className='mt-8'>
                <div className='flex items-center justify-between'>
                    <h5 className='m-0 flex items-center'><EditNotesIcon size={24} className='mr-4' />{t('note-label')}</h5>
                    <OnlyToYouLabel className='my-2' />
                </div>
                <Form.Textarea
                    {...register('note', { required: false, maxLength: { value: 1000, message: tf('text-too-long', { count: 1000 }) } })}
                    placeholder={t('note-placeholder')}
                    minRows={4}
                    className='mt-2'
                    disabled={!isEditable}
                />
                <RHFErrorMessage errors={errors} name='note' />
            </div>
            <>{saveButton?.({ onClick: handleSubmit })}</>
        </Form.Root>
    </>);
}

export function useClientGeneralForm(
    onSubmit: (output: OmitId<ClientUpdate>) => void,
    defaultValue: ClientFE,
    onChange?: (isDirty: boolean) => void,
) {
    const form = useForm<ClientGeneralFormData>();
    const isDirty = form.formState.isDirty;
    const userContext = useUser();
    const isMasterOrFreelancer = !!toMaster(useUser());

    const isEditable = useMemo(() => {
        if (isMasterOrFreelancer)
            return true;
        return defaultValue.access === ClientAccessType.owner;
    }, [ defaultValue, userContext, isMasterOrFreelancer ]);

    useEffect(() => onChange?.(isDirty), [ isDirty, onChange ]);

    function onValidSubmit(data: ClientGeneralFormData) {
        onSubmit(formToOutput(defaultValue, { ...data, email: canonizeEmail(data.email) }));
    }

    useEffect(() => {
        form.reset(inputToForm(defaultValue));
    }, [ defaultValue ]);

    return {
        form,
        handleSubmit: form.handleSubmit(onValidSubmit),
        isEditable,
    };
}

type ClientGeneralFormData = {
    email: string;
    phoneNumber: string;
    note: string;
};

function inputToForm(client: ClientFE): ClientGeneralFormData {
    return {
        email: client.email,
        phoneNumber: client.phoneNumber ?? '',
        note: client.note ?? '',
    };
}

function formToOutput(client: ClientFE, data: ClientGeneralFormData): OmitId<ClientUpdate> {
    return {
        email: stringToPatch(client.email, data.email),
        phoneNumber: optionalStringToPatch(client.phoneNumber, data.phoneNumber),
        note: optionalStringToPatch(client.note, data.note),
    };
}

import { useTranslation } from 'react-i18next';
import { Button } from ':components/shadcn';
import { useState } from 'react';
import { api } from ':frontend/utils/api';
import { getAppUserLocalization } from ':frontend/types/i18n';

type GoogleOauthButtonProps = Readonly<{
    title?: string;
    type: 'register' | 'login';
}>;

export function GoogleOauthButton({ title, type }: GoogleOauthButtonProps) {
    const { t } = useTranslation('pages', { keyPrefix: type });
    const [ isFetching, setIsFetching ] = useState(false);

    async function register() {
        const localization = getAppUserLocalization();

        setIsFetching(true);
        const response = await api.backend.googleOauth(localization);
        setIsFetching(false);
        if (!response.status || !response.data.redirectUrl)
            return;

        window.location.href = response.data.redirectUrl;
    }

    return (
        <GoogleButton
            title={title ?? t(`${type}-google-button`)}
            onClick={register}
            disabled={isFetching}
        />
    );
}

type GoogleButtonProps = Readonly<{
    title: string;
    onClick?: () => void;
    disabled?: boolean;
}>;

function GoogleButton({ title, onClick, disabled }: GoogleButtonProps) {
    return (
        <Button
            variant='white'
            type='submit'
            className='w-full relative'
            onClick={onClick}
            disabled={disabled}
        >
            {title}
            <img src='/static/integration-icons/google-logo.svg' width={30} className='absolute select-none drag-none left-[15%]' />
        </Button>
    );
}

import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import type { History, Transition, To } from 'history';
import { UNSAFE_NavigationContext, useNavigate, type NavigateFunction, type NavigateOptions } from 'react-router-dom';

// Currently, we have to use react-router-dom ~6.3, because the next version removes the block function from the history object.
// The solution might be to use the history object directly (instead of the interface provided by react-router-dom).
// https://stackoverflow.com/questions/74106591/getting-navigator-block-is-not-a-function-while-navigating-to-other-page
// or
// https://gist.github.com/MarksCode/64e438c82b0b2a1161e01c88ca0d0355
// We can also find ways how to avoid blocking navigation (e.g. saving the state in the local storage and restoring it after the user returns to the page).
// https://github.com/remix-run/react-router/issues/8139#issuecomment-1262630360
// Like it's not a bad idea, but it shouldn't be anyone's opinion if we use blocking or not ...
// So, after some reading ... the newest versions of react router 6 should support it again, but it will require some changes in the code.

/** This is like Transition, but it with 'this: void' so it's unbound-method-safe. */
type BlockerInput = {
    retry: (this: void) => void;
};

type Blocker = (input: BlockerInput) => void;

type UnblockWrapper = {
    unblock: () => void;
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
const defaultUnblock = () => {};

type UseBlockerReturn = {
    navigate: NavigateFunction;
    navigateUnblocked: NavigateFunction;
};

export function useBlocker(blocker: Blocker, when: boolean): UseBlockerReturn {
    const navigator = useContext(UNSAFE_NavigationContext).navigator as History;
    const unblockWrapper = useRef<UnblockWrapper>({ unblock: defaultUnblock });

    useEffect(() => {
        if (!when)
            return;

        const unblock = navigator.block((transition: Transition) => {
            const autoUnblockingTransition = {
                ...transition,
                retry() {
                    unblock();
                    transition.retry();
                },
            };

            blocker(autoUnblockingTransition);
        });
        unblockWrapper.current.unblock = unblock;

        return unblock;
    }, [ navigator, blocker, when ]);

    const navigate = useNavigate();

    const navigateUnblocked = useCallback((to: To | number, options?: NavigateOptions) => {
        unblockWrapper.current.unblock();

        if (typeof to === 'number')
            navigate(to);
        else
            navigate(to, options);
    }, [ navigate, unblockWrapper ]);

    return {
        navigate,
        navigateUnblocked,
    };
}

// This wrapper is needed because function itself can't be passed to the setState (it would be called and its result would be used as the new state instead).
type RetryObject = {
    callback: () => void;
};

export type BlockerModalControl = {
    show: boolean;
    exit: () => void;
    stay: () => void;
};

type UseBlockerModalReturn = UseBlockerReturn & {
    control: BlockerModalControl;
};

export function useBlockerModal(when: boolean): UseBlockerModalReturn {
    const [ show, setShow ] = useState(false);
    const [ retry, setRetry ] = useState<RetryObject>();

    const exit = useCallback(() => {
        setShow(false);
        if (retry)
            retry.callback();
    }, [ retry ]);

    const stay = useCallback(() => {
        setShow(false);
        setRetry(undefined);
    }, []);

    const blocker = useCallback((input: BlockerInput) => {
        setShow(true);
        setRetry({ callback: input.retry });
    }, []);
    const { navigate, navigateUnblocked } = useBlocker(blocker, when);

    const control = useMemo(() => ({ show, exit, stay }), [ show, exit, stay ]);

    return {
        navigate,
        navigateUnblocked,
        control,
    };
}

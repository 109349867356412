import { useEffect, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link,  useNavigate,  useSearchParams } from 'react-router-dom';
import { routesFE } from ':utils/routes';
import useAuth from ':frontend/context/AuthProvider';
import { GoogleOauthButton } from ':frontend/components/auth/googleButton';
import { FlowlanceBanner } from ':components/icons/old';
import { ACCESS_TOKENS_COOKIE_NAME, zAccessTokens } from ':utils/auth';
import Cookies from 'js-cookie';

type ErrorData = {
    error: string;
    email: string;
};

export default function RegisterGoogleCallback() {
    const { t } = useTranslation('pages', { keyPrefix: 'registerGoogle' });
    const [ searchParams ] = useSearchParams();
    const { auth } = useAuth();

    const navigate = useNavigate();

    useEffect(() => {
        const cookieTokens = Cookies.get(ACCESS_TOKENS_COOKIE_NAME);
        if (!cookieTokens)
            return;

        const tokens = zAccessTokens.parse(JSON.parse(cookieTokens));
        Cookies.remove(ACCESS_TOKENS_COOKIE_NAME);

        auth.loginWithTokens(tokens);
        navigate(routesFE.root);
    }, []);

    const errorData = useMemo(() => {
        const error = searchParams.get('error');
        const email = searchParams.get('email');

        return error ? { error, email } as ErrorData : undefined;
    }, [ searchParams ]);

    return (
        <div className='fl-unauthorized-page'>
            <div className='fl-centering-wrapper'>
                <div className='fl-unauthorized-card'>
                    <div className='fl-unauthorized-content flex flex-col items-center'>
                        <FlowlanceBanner />
                        {errorData?.error === 'app.google.missing_permissions' ? (<>
                            <h1>{t('missing-access')}</h1>
                            <p>{t('grant-access')}</p>
                            <GoogleOauthButton title={t('grant-access-button')} type='register' />
                        </>) : (<>
                            <h1>{t('something-went-wrong')}</h1>
                            <p className='text-lg mb-0'>
                                <Trans
                                    i18nKey='try-again'
                                    t={t}
                                    components={{
                                        a: <Link to={routesFE.register.index} />,
                                    }}
                                />
                            </p>
                        </>)}
                    </div>

                </div>
            </div>
        </div>
    );
}

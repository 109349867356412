import { useCallback, useMemo } from 'react';
import { Button, DropdownMenu, Sheet, SheetContent, Spinner } from ':components/shadcn';
import { EventForm } from ':frontend/components/event/EventForm';
import { useClients, type PreselectBackpay, createActionState, type BlockerModalControl } from ':frontend/hooks';
import { useCached } from ':components/hooks';
import type { Selected } from ':frontend/pages/CalendarDetail';
import { getChange, SyncModal, useEvent, type UpdateEventsAction, type UseEventDispatch, type UseEventState } from '../event/useEvent';
import { type ClientInfoFE } from ':frontend/types/Client';
import EventUpdateModal from '../event/EventUpdateModal';
import { EventPayment } from '../event/EventPayment';
import EventStateBadge, { EventDraftBadge, StateTransitionButtons } from '../event/EventStateBadge';
import { CloseButton } from '../forms/buttons';
import { useTranslation } from 'react-i18next';
import { CheckIcon } from ':components/icons/old';
import { SpinnerButton } from '../common';
import type { TFunction } from 'i18next';
import EventNotes from '../event/EventNotes';
import { participantIsBillable } from ':frontend/types/EventParticipant';
import { Link } from 'react-router-dom';
import { routesFE } from ':utils/routes';
import { useUser } from ':frontend/context/UserProvider';
import { TeamMemberBadge } from '../team/TeamMemberBadge';
import { TeamMemberRole } from ':utils/entity/team';
import { Trash2Icon } from ':components/icons/basic';
import { BlockNavigationModal } from '../BlockNavigationModal';

type EventSidebarProps = Readonly<{
    input?: Selected;
    onClose: (action?: UpdateEventsAction) => void;
}>;

export default function EventSidebar({ input, onClose }: EventSidebarProps) {
    const { clients, addClients } = useClients();

    const cachedInput = useCached(input);
    if (!clients || !cachedInput)
        return null;

    return (
        <EventSidebarInner
            show={!!input}
            input={cachedInput}
            onClose={onClose}
            clients={clients}
            addClients={addClients}
        />
    );
}

type EventSidebarInnerProps = Readonly<{
    show: boolean;
    input: Selected;
    onClose: (action?: UpdateEventsAction) => void;
    clients: ClientInfoFE[];
    addClients: (clients: ClientInfoFE[]) => void;
}>;

function EventSidebarInner({ show, input, onClose, clients, addClients }: EventSidebarInnerProps) {
    const { state, dispatch } = useEvent(input, onClose, addClients);
    const tryClose = useCallback(() => dispatch({ type: 'confirmClose', value: 'start' }), [ dispatch ]);
    const confirmCloseModalControl: BlockerModalControl = {
        show: !!state.isConfirmClose,
        stay: () => dispatch({ type: 'confirmClose', value: 'back' }),
        exit: () => dispatch({ type: 'confirmClose', value: 'discard' }),
    };

    return (<>
        {state.sync && <EventUpdateModal state={state.sync} dispatch={dispatch} />}
        <BlockNavigationModal control={confirmCloseModalControl} />
        <Sheet open={show} onOpenChange={newOpen => !newOpen && tryClose()}>
            <SheetContent style={{ width: 588 }}>
                <EventDisplayInner
                    state={state}
                    dispatch={dispatch}
                    clients={clients}
                />
            </SheetContent>
        </Sheet>
    </>);
}

type StateDispatchProps = Readonly<{
    state: UseEventState;
    dispatch: UseEventDispatch;
}>;

type EventDisplayInner = StateDispatchProps & Readonly<{
    clients: ClientInfoFE[];
}>;

export function EventDisplayInner({ state, dispatch, clients }: EventDisplayInner) {
    const { t } = useTranslation('components', { keyPrefix: 'eventSidebar' });
    const isMaster = useUser().role === TeamMemberRole.master;

    return (
        <div className='overflow-auto h-full flex flex-col'>
            <div style={{ padding: '24px' }}>
                <div className='flex items-center gap-2 pb-4 mb-2'>
                    <div style={{ backgroundColor: 'white', padding: '6px 12px' }} className='rounded border'>
                        {state.event ? (
                            <EventStateBadge event={state.event} />
                        ) : (
                            <EventDraftBadge />
                        )}
                    </div>
                    {state.event?.isInPackage ? (
                        <div style={{ backgroundColor: 'white', padding: '6px 12px' }} className='rounded border'>
                            <span>{state.event?.packageProgress}</span>
                            {' '}
                            <span>{t('package-progress-label')}</span>
                        </div>
                    ) : state.event?.recurrenceIndex !== undefined && (
                        <div style={{ backgroundColor: 'white', padding: '6px 12px' }} className='rounded border'>
                            <span>#{state.event.recurrenceIndex + 1}</span>
                            {' '}
                            <span>{t('recurrence-index-label')}</span>
                        </div>
                    )}
                    {isMaster && state.event && (
                        <TeamMemberBadge appUserId={state.event.ownerId} size='md' />
                    )}
                    <div className='grow' />
                    {autosaveLabel(state, t)}
                    <CloseButton aria={t('close-button-aria')} onClick={() => dispatch({ type: 'confirmClose', value: 'start' })} />
                </div>
                <EventForm
                    state={state}
                    dispatch={dispatch}
                    clients={clients}
                />
            </div>
            <div className='fl-event-sidebar-mid grow flex flex-col items-stretch gap-2'>
                {!state.event?.isInPackage && (
                    <EventPayment
                        state={state}
                        dispatch={dispatch}
                        clients={clients}
                    />
                )}
                <EventNotes
                    state={state}
                    dispatch={dispatch}
                />
            </div>
            <div className='fl-event-sidebar-bot flex items-center border-t'>
                <OptionsMenu state={state} dispatch={dispatch} />
                <div className='grow' />
                <SaveButtons state={state} dispatch={dispatch} />
            </div>
        </div>
    );
}

function autosaveLabel(state: UseEventState, t: TFunction) {
    const isSaving = !!state.sync?.fetching;
    const isSaved = !!state.close?.result;

    return (
        <div className='flex items-center text-success' style={{ width: '120px' }}>
            {isSaving ? (<>
                <Spinner
                    size='sm'
                    style={{ marginRight: '9px', marginTop: '-2px' }}
                />
                {t('autosave-saving-label')}
            </>) : isSaved && (<>
                <CheckIcon size={18} className='mr-2' />
                {t('autosave-saved-label')}
            </>)}
        </div>
    );
}

function OptionsMenu({ state, dispatch }: StateDispatchProps) {
    const { t } = useTranslation('components', { keyPrefix: 'eventSidebar' });

    if (!state.event)
        return null;

    return (
        <DropdownMenu.Root>
            <DropdownMenu.Trigger className='px-4'>
                {t('options-button')}
            </DropdownMenu.Trigger>
            <DropdownMenu.Content className='fl-dropdown-menu'>
                <div className='fl-dropdown-menu-inner'>
                    <StateTransitionButtons
                        event={state.event}
                        onSubmit={(transition, fid) => dispatch({ type: 'sync', operation: { type: 'transition', transition }, fid })}
                        buttonType='compact'
                        buttonClassName='w-full'
                        fetching={state.sync?.fetching}
                        isOverlay={state.sync?.phase !== SyncModal.None}
                    />
                    <SpinnerButton
                        // TODO aria
                        variant='outline-danger'
                        size='tiny' className='w-full'
                        onClick={() => {
                            dispatch({ type: 'sync', operation: { type: 'delete' }, fid: FID_DELETE });
                        }}
                        fetching={state.sync?.fetching}
                        fid={FID_DELETE}
                        isOverlay={state.sync?.phase !== SyncModal.None}
                    >
                        <Trash2Icon className='mr-2' />{t('delete-button')}
                    </SpinnerButton>
                </div>
            </DropdownMenu.Content>
        </DropdownMenu.Root>
    );
}

const FID_DELETE = 'delete';

function SaveButtons({ state, dispatch }: StateDispatchProps) {
    const { t } = useTranslation('components', { keyPrefix: 'eventSidebar' });
    const change = getChange(state);
    // We want to enable the button if there is anything to save. Note this still doesn't include things like notes and payment state, which are saved separately.
    const isChange = change.type !== 'none';
    const { payment } = state;
    const existingPaymentState = useMemo(() => {
        if (state.event?.clients.some(c => c.isInOrder))
            return 'order';

        return undefined;
    }, [ state.event ]);

    // The payment hasn't even started - we show just the save button.
    if (!payment)
        return saveButton(true, isChange, state, dispatch, t);

    // There is already a payment. If it's complete (all clients are invoiced), we show, again, just the save button.
    // If it's, however, only partial, we enable the Save&Bill button.
    if (existingPaymentState) {
        const billableClients = payment.clients.filter(c => !c.original || participantIsBillable(c.original));
        const isBillable = billableClients.length > 0;

        return (
            <div className='flex gap-1'>
                {isBillable && saveAndBillButton(isChange, state, dispatch, t)}
                {saveButton(!isBillable, isChange, state, dispatch, t)}
            </div>
        );
    }

    // The event exists, but it isn't invoiced yet. This means the Save&Bill button.
    const isCheckoutEnabled = payment.clients.length > 0;
    return (
        <div className='flex gap-1'>
            {isCheckoutEnabled && saveAndBillButton(isChange, state, dispatch, t)}
            {saveButton(!isCheckoutEnabled, isChange, state, dispatch, t)}
        </div>
    );
}

function saveButton(isPrimary: boolean, isChange: boolean, state: UseEventState, dispatch: UseEventDispatch, t: TFunction) {
    return (
        <SpinnerButton
            variant={isPrimary ? 'primary' : 'outline'}
            onClick={() => dispatch({ type: 'sync', operation: { type: 'start', isBillLater: false }, fid: FID_SAVE })}
            fetching={state.sync?.fetching}
            fid={FID_SAVE}
            isOverlay={state.sync?.phase !== SyncModal.None}
            disabled={!isChange}
            className='px-8'
        >
            {t('save-button')}
        </SpinnerButton>
    );
}

const FID_SAVE = 'save';

function saveAndBillButton(isChange: boolean, state: UseEventState, dispatch: UseEventDispatch, t: TFunction) {
    if (isChange) {
        return (
            <SpinnerButton
                variant='primary'
                onClick={() => dispatch({ type: 'sync', operation: { type: 'start', isBillLater: true }, fid: FID_SAVE_AND_BILL })}
                fetching={state.sync?.fetching}
                fid={FID_SAVE_AND_BILL}
                isOverlay={state.sync?.phase !== SyncModal.None}
            >
                {t('save-and-bill-button')}
            </SpinnerButton>
        );
    }

    if (!state.event)
        return null;

    return (
        <Link to={routesFE.orders.newBackpay} state={createActionState<PreselectBackpay>('preselectBackpay', {
            eventIds: [ state.event.id ],
        })}>
            <Button>
                {t('bill-button')}
            </Button>
        </Link>
    );
}

const FID_SAVE_AND_BILL = 'save-and-bill';

import { useMemo, type ReactNode } from 'react';
import { Skeleton } from '../common';
import { MoneyDisplay } from ':components/custom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { type TFunction } from 'i18next';
import { CalendarPlusIcon } from ':components/icons/old';
import { Button } from ':components/shadcn';
import { ProductOrderItemFE, type GenericProductItem } from ':frontend/types/orders/ProductOrderItem';
import { type Money } from ':utils/money';
import DateTimeDisplay from '../common/DateTimeDisplay';
import { getRandomSkeletonArray } from ':utils/math';
import ClientIconLink from '../client/ClientIconLink';
import { type Id } from ':utils/id';

export const PRODUCT_ICON = '📦';

type ProductItemTileProps = Readonly<{
    productItem: GenericProductItem<true>;
    onSchedule?: (productItem: GenericProductItem) => void;
    isActive?: boolean;
    hideClientId?: Id;
}>;

export function ProductItemTile({ productItem, onSchedule, isActive, hideClientId }: ProductItemTileProps) {
    const { t } = useTranslation('components', { keyPrefix: 'productDisplay' });
    const toSchedule = productItem.sessionsCount - productItem.scheduledCount;

    return (
        <div
            className={clsx(
                'fl-product-item-tile flex flex-col rounded-2xl fl-hover-trigger',
                isActive && 'fl-hover-trigger-triggered',
                ProductOrderItemFE.isCompleted(productItem) ? 'bg-secondary-100' : 'bg-white',
            )}
        >
            <span className='text-lg' style={{ height: '22px' }}>{PRODUCT_ICON}</span>
            <h3 className='mt-4 mb-3 truncate'>{productItem.title}</h3>
            {productStats(productItem, t, 'flex flex-col items-start gap-2')}
            {!ProductOrderItemFE.isCompleted(productItem) && (<>
                <div className='fl-divider-light shrink-0' style={{ margin: '12px 0px' }} />
                <div className='flex flex-col'>
                    {toSchedule > 0 ? (
                        <span>
                            <span className='mr-1'>{toSchedule}</span>
                            <span className='text-sm text-secondary-600'>{t('to-schedule-count-label', { count: toSchedule })}</span>
                        </span>
                    ) : (
                        <span className='text-sm text-secondary-600'>{t('all-scheduled-label')}</span>
                    )}
                    <span className='mt-2'>
                        <span className='text-sm text-secondary-600'>{t('created-at-label')}</span>{' '}<DateTimeDisplay dateTime={productItem.createdAt} date />
                    </span>
                </div>
            </>)}
            {productItem.guest.id !== hideClientId && (
                <div className='mt-2'>
                    <ClientIconLink client={productItem.guest} />
                </div>
            )}
            <div className='grow' />
            <div className='flex'>
                {productItem.price && (
                    <ProductPriceDisplay price={productItem.price} />
                )}
                <div className='grow' />
                {toSchedule > 0 && (
                    <Button size='tiny' className='px-2 bg-black text-white fl-hover-target' onClick={() => onSchedule?.(productItem)}>
                        <CalendarPlusIcon size={18} className='mr-2' />{t('schedule-item-button')}
                    </Button>
                )}
            </div>
        </div>
    );
}

type ProductPriceDisplayProps = Readonly<{
    price: Money;
}>;

export function ProductPriceDisplay({ price }: ProductPriceDisplayProps) {
    return (
        <span className='py-2 px-4 rounded' style={{ height: '32px', backgroundColor: '#F5F5F5' }}>
            <MoneyDisplay money={price} />
        </span>
    );
}

type ProductForStats = {
    sessionsDuration: number;
    sessionsCount: number;
};

function productStats(product: ProductForStats, t: TFunction, className: string): ReactNode {
    return (
        <div className={className}>
            <span>
                <span className='mr-1'>{product.sessionsDuration}</span>
                <span className='text-sm text-secondary-600'>{t('minutes-count-label', { count: product.sessionsDuration })}</span>
            </span>
            <span>
                <span className='mr-1'>{product.sessionsCount}</span>
                <span className='text-sm text-secondary-600'>{t('sessions-count-label', { count: product.sessionsCount })}</span>
            </span>
        </div>
    );
}

type ProductSkeletonsProps = Readonly<{
    variant: 'product' | 'product-item';
}>;

export function ProductTileSkeletons({ variant }: ProductSkeletonsProps) {
    const skeletonArray = useMemo(() => getRandomSkeletonArray(1, 4), []);

    return (<>
        {skeletonArray.map(key => (
            <ProductTileSkeleton key={key} variant={variant} />
        ))}
    </>);
}

function ProductTileSkeleton({ variant }: ProductSkeletonsProps) {
    const height = variant === 'product' ? 240 : 285;

    return (
        <div className={clsx(variant === 'product' ? 'fl-product-tile' : 'fl-product-item-tile', 'p-0')}>
            <Skeleton className='rounded-2xl' height={height} />
        </div>
    );
}

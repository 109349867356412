import { ArrowLeftIcon, ArrowRightIcon, Check1Icon, SpaceshipIcon } from ':components/icons/basic';
import { Button, ScrollArea } from ':components/shadcn';
import { ProductPhonePreviewDisplay, productStyles } from ':components/store/product/ProductCard';
import { StoreBioDisplay } from ':components/store/StoreBioDisplay';
import { getStoreStyles } from ':components/store/utils';
import { BlockNavigationModal } from ':frontend/components/BlockNavigationModal';
import { SpinnerButton } from ':frontend/components/common';
import { Content, Topbar } from ':frontend/components/Layout';
import { ProductDetailsForm, ProductPublishForm } from ':frontend/components/product/productForms';
import { createProductPreview, useProductForms, type ProductFormsDispatch, type ProductFormsState } from ':frontend/components/product/useProductForms';
import { trpc } from ':frontend/context/TrpcProvider';
import { useBlockerModal } from ':frontend/hooks';
import { type ProductType } from ':utils/entity/product';
import { routesFE } from ':utils/routes';
import clsx from 'clsx';
import type { TFunction } from 'i18next';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

export function NewProduct() {
    const { type } = useParams<{ type: ProductType }>();
    if (!type)
        throw new Error('Missing product type');

    const { t } = useTranslation('pages', { keyPrefix: 'newProduct' });
    // Let's disable the navigation in all cases because the user already did something (he selected the product type).
    const { navigateUnblocked, control } = useBlockerModal(true);
    const [ state, dispatch, isFetching ] = useProductForms({ type }, navigateUnblocked);

    return (<>
        <Topbar>
            <NewProductTopbar state={state} dispatch={dispatch} isFetching={isFetching} />
        </Topbar>
        <Content>
            <div className='max-w-[920px] mx-auto py-12 flex justify-between'>
                <div className='flex flex-col gap-6 w-[480px]'>
                    <div className='flex items-center gap-4'>
                        <div className={clsx('w-10 h-10 rounded-full flex items-center justify-center', productStyles[type].bg)}>
                            {productStyles[type].icon({ size: 22 })}
                        </div>
                        <div>
                            <h1 className='text-md leading-4 font-semibold text-secondary-900'>{t(`${state.phase}.page-title`, { type: state.type })}</h1>
                            <p className='leading-5 mt-1'>{t(`${state.phase}.page-description`)}</p>
                        </div>
                    </div>
                    {state.phase === 'details' ? (
                        <ProductDetailsForm state={state} dispatch={dispatch} />
                    ) : (
                        <ProductPublishForm state={state} dispatch={dispatch} />
                    )}
                </div>
                <ProductPhonePreview state={state} />
            </div>
        </Content>
        <BlockNavigationModal control={control} />
    </>);
}

type NewProductTopbarProps = Readonly<{
    state: ProductFormsState;
    dispatch: ProductFormsDispatch;
    isFetching: boolean;
}>;

function NewProductTopbar({ state, dispatch, isFetching }: NewProductTopbarProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'newProduct' });

    const navigate = useNavigate();

    function handleBack() {
        if (state.phase === 'details') {
            // In theory, the user should be able to navigate back if he didn't fill in any details, but it's not needed now.
            navigate(routesFE.products.list);
            return;
        }

        dispatch({ type: 'phase', value: 'back' });
    }

    return (<>
        <div className='w-1/4'>
            <Button variant='transparent' size='small' onClick={handleBack}>
                <ArrowLeftIcon size='sm' />
                {t(`${state.phase}.back-button`)}
            </Button>
        </div>
        <div className='grow' />
        <div className='flex items-center gap-2'>
            {phaseLabel('type', state.phase, t)}
            <div className='h-[1.5px] w-10 bg-primary' />
            {phaseLabel('details', state.phase, t)}
            <div className={clsx('h-[1.5px] w-10 bg-primary', state.phase === 'publish' ? 'bg-primary' : 'bg-secondary-400')} />
            {phaseLabel('publish', state.phase, t)}
        </div>
        <div className='grow' />
        <div className='w-1/4 text-end'>
            <SpinnerButton
                variant='dark'
                size='small'
                onClick={() => dispatch({ type: 'phase', value: 'continue' })}
                isFetching={isFetching}
            >
                {state.phase === 'publish' && <SpaceshipIcon />}
                {t(`${state.phase}.continue-button`)}
                {state.phase === 'details' && <ArrowRightIcon />}
            </SpinnerButton>
        </div>
    </>);
}

const newProductPhases = [ 'type', 'details', 'publish' ];
type NewProductPhase = typeof newProductPhases[number];

export function phaseLabel(phase: NewProductPhase, currentPhase: NewProductPhase, t: TFunction) {
    const index = newProductPhases.indexOf(phase);
    const isChecked = index < newProductPhases.indexOf(currentPhase);
    const isCurrent = phase === currentPhase;

    return (<>
        <div className={clsx(
            'h-7 w-7 rounded-full border flex items-center justify-center',
            isChecked || isCurrent ? 'text-primary border-primary bg-primary-50' : 'border-secondary-100 bg-white',
        )}>
            {isChecked ? (
                <Check1Icon size='xs' />
            ) : (
                <span>{index + 1}</span>
            )}
        </div>
        <span className={clsx(isCurrent && 'text-primary')}>
            {t(`${phase}.phase-title`)}
        </span>
    </>);
}

type ProductPhonePreviewProps = Readonly<{
    state: ProductFormsState;
    className?: string;
}>;

export function ProductPhonePreview({ state, className }: ProductPhonePreviewProps) {
    const store = trpc.store.getStore.useQuery().data;
    const locations = trpc.location.getLocations.useQuery().data;

    const product = useMemo(() => createProductPreview(state.type, state.details.form, locations ?? []), [ state.type, state.details.form, locations ]);

    if (!store)
        return null;

    const storeStyles = getStoreStyles(store.design, 'fl-store-bg');

    return (
        // Based on iPhone 11 + one pixel on each side for the border.
        <ScrollArea
            className={clsx(`
                fl-override-screen-sm
                w-[377px] h-[814px] rounded-2xl border border-[#e6e6e6]
                shadow-[1.3px_1.3px_4px_0px_rgba(80,88,113,0.25),5.3px_5.3px_13.2px_0px_rgba(80,88,113,0.08),4px_4px_7.9px_0px_rgba(121,135,176,0.08)_inset,0px_4px_4px_0px_rgba(0,0,0,0.25)]
                `, className)}
        >
            <div className={clsx('w-[375px] min-h-[812px] px-5 py-6 flex flex-col items-center gap-6', storeStyles.className)} style={storeStyles.style}>
                <StoreBioDisplay bio={store.bio} image={store.image} />
                <ProductPhonePreviewDisplay product={product} />
            </div>
        </ScrollArea>
    );
}

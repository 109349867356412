import useNotifications, { type AddAlertFunction } from ':frontend/context/NotificationProvider';
import { trpc } from ':frontend/context/TrpcProvider';
import { useMaster, type MasterContext } from ':frontend/context/UserProvider';
import { getCurrency, getTaxRate, type CurrencyFE, type TaxRateFE } from ':utils/money';
import { priceFromServer, priceToServer, toNumber, transformToPositiveIntegerOrEmpty, transformToPrice } from ':utils/math';
import { Updator, Validator, zodRule, type FormErrors, type FormPath, type RulesDefinition } from ':frontend/utils/updator';
import { type ProductUpsert, type BaseProductUpsert, ProductType, type ProductPricing, type BundleProductUpsert, type SessionProductUpsert, type DigitalProductUpsert, type CustomProductUpsert, zBaseProductUpsert, type ProductOutput } from ':utils/entity/product';
import { useEffect, useMemo, useReducer, type Dispatch } from 'react';
import { createErrorAlert, createTranslatedSuccessAlert } from '../notifications';
import { routesFE } from ':utils/routes';
import { fileDataToServer, type FileData } from ':utils/entity/file';
import { createSlug, minutesToSeconds } from ':utils/common';
import type { Id } from ':utils/id';
import type { NavigateFunction } from 'react-router-dom';
import { optionalStringToPut } from ':frontend/utils/common';
import { useTranslation } from 'react-i18next';
import type { TFunction } from 'i18next';
import { createTypedTFunction } from ':utils/i18n';
import type { ProductPreview } from ':components/store/product/ProductCard';
import type { LocationOutput } from ':utils/entity/location';
import { secondsToMinutes } from 'date-fns';

/** Use ProductOutput if editing existing product. Otherwise ProductType. */
type FormInput = ProductOutput | { type: ProductType };

export function useProductForms(input: FormInput, navigateUnblocked: NavigateFunction) {
    const context = useMaster();
    const { t } = useTranslation();

    const [ state, dispatch ] = useReducer(productFormsReducer, { input, context, t }, createInitialState);

    const { addAlert } = useNotifications();

    const createMutation = trpc.product.createProduct.useMutation();
    const updateMutation = trpc.product.updateProduct.useMutation();

    const syncFunction = useMemo(
        () => createProductSync(createMutation, updateMutation, addAlert, navigateUnblocked),
        [ createMutation, updateMutation, addAlert, navigateUnblocked ],
    );

    useEffect(() => {
        if (!state.sync)
            return;

        syncFunction(state);
    }, [ !!state.sync ]);

    return [
        state,
        dispatch,
        createMutation.isPending,
    ] as const;
}

function createProductSync(
    createMutation: ReturnType<typeof trpc.product.createProduct.useMutation>,
    updateMutation: ReturnType<typeof trpc.product.updateProduct.useMutation>,
    addAlert: AddAlertFunction,
    navigateUnblocked: NavigateFunction,
) {
    function create(upsert: ProductUpsert) {
        createMutation.mutate(upsert, {
            onError: error => {
                addAlert(createErrorAlert(error.data));
            },
            onSuccess: () => {
                addAlert(createTranslatedSuccessAlert('pages:newProduct:create-success-alert'));
                navigateUnblocked(routesFE.products.list);
            },
        });
    }

    function update(upsert: ProductUpsert, id: Id) {
        updateMutation.mutate({ ...upsert, id }, {
            onError: error => {
                addAlert(createErrorAlert(error.data));
            },
            onSuccess: () => {
                addAlert(createTranslatedSuccessAlert('pages:newProduct:update-success-alert'));
                navigateUnblocked(routesFE.products.list);
            },
        });
    }

    return (state: ProductFormsState) => {
        if (!state.sync)
            return;

        const sync = state.sync;

        if (state.original)
            update(sync.upsert, state.original.id);
        else
            create(sync.upsert);
    };
}

export type ProductFormsState = {
    /** The original product. If it's defined, we are editing it. Otherwise, a new product is going to be created. */
    original: ProductOutput | undefined;
    type: ProductType;
    isCustomDomainAllowed: boolean;
    phase: 'details' | 'publish';
    details: {
        form: DetailsFormState;
        formErrors?: FormErrors;
        wasSubmitted?: boolean;
    };
    publish: {
        form: PublishFormState;
        formErrors?: FormErrors;
        wasSubmitted?: boolean;
        suggestedSlug: string;
        isSlugTouched?: boolean;
    };
    sync?: SyncState;
};

function createInitialState({ input, context, t }: { input: FormInput, context: MasterContext, t: TFunction }): ProductFormsState {
    return {
        original: 'id' in input ? input : undefined,
        type: input.type,
        isCustomDomainAllowed: context.subscription.restrictions.store.customDomain,
        phase: 'details',
        details: { form: createInitialDetailsFormState(input, context, t) },
        publish: { form: createInitialPublishFormState(input), suggestedSlug: '' },
    };
}

type ProductFormsAction = PhaseAction | DetailsAction | PublishAction;

export type ProductFormsDispatch = Dispatch<ProductFormsAction>;

function productFormsReducer(state: ProductFormsState, action: ProductFormsAction): ProductFormsState {
    switch (action.type) {
    case 'phase': return phase(state, action);
    case 'details': return details(state, action);
    case 'publish': return publish(state, action);
    }
}

type PhaseAction = {
    type: 'phase';
    value: 'back' | 'continue';
};

function phase(state: ProductFormsState, action: PhaseAction): ProductFormsState {
    if (state.phase === 'details') {
        // There can't be 'back' because we are at the beginning. So it must be 'continue'.
        // We try to validate the form and then go to the next phase.
        const formErrors = Validator.validate(state.details.form, detailsRules);
        if (formErrors)
            return { ...state, details: { ...state.details, formErrors, wasSubmitted: true } };

        const suggestedSlug = createSlug(state.details.form.title);
        // If the slug can't be customized, we force the slug to be the same as the title.
        // If the user didn't touch the slug, we also update it.
        // Otherwise, we don't want to overwrite the user's input.
        const updateSlug = !state.isCustomDomainAllowed || !state.publish.isSlugTouched;

        return { ...state,
            phase: 'publish',
            details: { ...state.details, formErrors, wasSubmitted: true },
            publish: {
                ...state.publish,
                suggestedSlug,
                form: updateSlug ? { ...state.publish.form, slug: suggestedSlug } : state.publish.form,
            },
        };
    }

    if (action.value === 'back')
        // We can only go back from 'publish' to 'details'.
        return { ...state, phase: 'details' };

    // We can't go back from 'details' since that's handled by navigate. We can only continue from 'publish', which means sync.
    if (state.sync)
        return state;

    const formErrors = Validator.validate(state.publish.form, publishRules);
    const sync = formErrors ? undefined : { upsert: createProductUpsert(state) };
    return { ...state, sync, publish: { ...state.publish, formErrors, wasSubmitted: true } };
}

type DetailsFormState = {
    // Needed for validation.
    type: ProductType;
    // Common properties

    title: string;
    /** Optional. */
    description: string;
    /** Optional. */
    thumbnail: FileData | undefined;

    // Pricing properties

    currency: CurrencyFE;
    /**
     * If undefined, the product is free.
     * The `basePrice` and `discountedPrice` are named differently from backend to avoid confusion with `price` and `originalPrice`.
     */
    basePrice: undefined | '' | number;
    /** The new price after discount. If undefined, there is no discount. */
    discountedPrice: undefined | '' | number;
    /** Same for both price and discount. */
    vat: TaxRateFE;
    pricingPeriod: 'monthly' | 'weekly';

    // Specific properties

    /** Optional. */
    successMessage: string;
    buttonText: string;
    isShowButton: boolean;
    sessions: '' | number;
    /** In minutes. */
    duration: '' | number;
    /** Optional. */
    locationId: Id | undefined;
    scheduling: 'enabled' | 'disabled' | 'custom';
    /** Optional unless scheduling is 'custom'. */
    schedulingUrl: string;
    /** Optional. */
    url: string;
    isShowUrl: boolean;
    isLimitedOffer: boolean;
};

function createInitialDetailsFormState(input: FormInput, context: MasterContext, t: TFunction): DetailsFormState {
    const { type } = input;
    const product = 'id' in input ? input : undefined;
    const tt = createTypedTFunction(t, type);

    const data: DetailsFormState = {
        // Common fields.
        type,
        title: product?.title ?? '',
        description: product?.description ?? '',
        // FIXME thumbnail
        // thumbnail: product?.thumbnail,
        thumbnail: undefined,
        successMessage: product?.successMessage ?? tt('components:productDetailsForm.successMessage-placeholder', { firstName: context.appUser.firstName }),
        buttonText: product?.buttonText ?? tt('components:productDetailsForm.buttonText-placeholder'),

        // Pricing fields - handled separately.
        currency: context.teamSettings.currency,
        basePrice: undefined,
        discountedPrice: undefined,
        vat: context.teamSettings.taxRate,

        // Specific fields - need to be set based on the product type.
        pricingPeriod: 'monthly',
        isShowButton: false,
        sessions: '',
        duration: '',
        locationId: undefined,
        scheduling: 'disabled',
        schedulingUrl: '',
        url: '',
        isShowUrl: true,
        isLimitedOffer: true,
    };

    if (product) {
        if (product.pricing)
            loadProductPricing(data, product.pricing);

        loadSpecificProductFields(data, product);
    }

    return data;
}

type DetailsAction = {
    type: 'details';
    field: FormPath<DetailsFormState>;
    value: unknown;
};

function details(state: ProductFormsState, action: DetailsAction): ProductFormsState {
    const { form, formErrors } = Updator.update(state.details, action.field, action.value, state.details.wasSubmitted ? detailsRules : undefined);
    return { ...state, details: {
        form,
        formErrors,
        wasSubmitted: state.details.wasSubmitted,
    } };
}

const detailsRules: RulesDefinition<DetailsFormState> = {
    title: zodRule('common:form-error', zBaseProductUpsert.shape.title),
    description: zodRule('common:form-error', zBaseProductUpsert.shape.description),
    // TODO use zod for this
    basePrice: value => value === undefined || transformToPrice(value) !== '' || 'common:form-error.required',
    discountedPrice: (value, form) => form.basePrice === undefined || value === undefined || transformToPrice(value) !== '' || 'common:form-error.required',
    successMessage: zodRule('common:form-error', zBaseProductUpsert.shape.successMessage),
    buttonText: zodRule('common:form-error', zBaseProductUpsert.shape.buttonText),
    // TODO use zod for this
    sessions: (value, form) => form.type !== ProductType.Bundle
        || transformToPositiveIntegerOrEmpty(value) !== ''
        || 'common:form-error.required',
    duration: (value, form) => ![ ProductType.Session, ProductType.Bundle ].includes(form.type)
        || transformToPositiveIntegerOrEmpty(value) !== ''
        || 'common:form-error.required',
    // TODO include more product types in this (Link a Lead Magnet)
    url: (value, form) => form.type !== ProductType.Digital || value !== '' || 'common:form-error.required',
    schedulingUrl: (value, form) => form.scheduling !== 'custom' || value !== '' || 'common:form-error.required',
};

type PublishFormState = {
    visibility: 'public' | 'private';
    /**
     * Doesn't have to be unique now. We will make it unique on backend.
     * This is *not* a valid slug. It's just the user input that needs to be transformed into a slug.
     */
    slug: string;
    // TODO collect info
};

function createInitialPublishFormState(input: FormInput): PublishFormState {
    const product = 'id' in input ? input : undefined;
    return {
        visibility: (product && !product.isPublic) ? 'private' : 'public',
        slug: product?.slug ?? '',
    };
}

type PublishAction = {
    type: 'publish';
    field: FormPath<PublishFormState>;
    value: unknown;
};

function publish(state: ProductFormsState, action: PublishAction): ProductFormsState {
    const { form, formErrors } = Updator.update(state.publish, action.field, action.value, state.publish.wasSubmitted ? publishRules : undefined);

    const publish = {
        ...state.publish,
        form,
        formErrors,
    };
    if (action.field === 'slug')
        publish.isSlugTouched = true;

    return { ...state, publish };
}

const publishRules: RulesDefinition<PublishFormState> = {
    slug: zodRule('common:form-error', zBaseProductUpsert.shape.slug),
};

type SyncState = {
    upsert: ProductUpsert;
};

function createProductUpsert(state: ProductFormsState): ProductUpsert {
    const details = state.details.form;
    const publish = state.publish.form;

    const base: BaseProductUpsert = {
        isPublic: publish.visibility === 'public',
        title: details.title,
        description: optionalStringToPut(details.description),
        thumbnail: details.thumbnail && fileDataToServer(details.thumbnail),
        slug: publish.slug,
        // TODO iteration2
        limit: undefined,
        successMessage: optionalStringToPut(details.successMessage),
        buttonText: details.buttonText,
    };

    const pricing = createProductPricing(details);

    switch (state.type) {
    case ProductType.Session:
        return {
            type: ProductType.Session,
            ...base,
            pricing,
            sessionsDuration: minutesToSeconds(toNumber(details.duration)),
            locationId: details.locationId,
            schedulingUrl: details.schedulingUrl,
        } satisfies SessionProductUpsert;
    case ProductType.Bundle:
        return {
            type: ProductType.Bundle,
            ...base,
            pricing,
            sessionsCount: toNumber(details.sessions),
            sessionsDuration: minutesToSeconds(toNumber(details.duration)),
            locationId: details.locationId,
            schedulingUrl: details.schedulingUrl,
        } satisfies BundleProductUpsert;
    case ProductType.Digital:
        return {
            type: ProductType.Digital,
            ...base,
            pricing,
            url: details.url,
        } satisfies DigitalProductUpsert;
    // TODO Not supported yet
    // case ProductType.Lead:
    // case ProductType.Membership:
    // case ProductType.Link:
    case ProductType.Custom:
        return {
            type: ProductType.Custom,
            ...base,
            pricing,
        } satisfies CustomProductUpsert;
    }

    throw new Error(`Unsupported product type "${state.type}"`);
}

function createProductPricing(details: DetailsFormState): ProductPricing | undefined {
    if (details.basePrice === undefined)
        return undefined;

    return {
        currency: details.currency.id,
        price: priceToServer(details.discountedPrice === undefined ? toNumber(details.basePrice) : toNumber(details.discountedPrice)),
        originalPrice: details.discountedPrice === undefined ? undefined : priceToServer(toNumber(details.basePrice)),
        vat: details.vat.id,
    };
}

function loadProductPricing(details: DetailsFormState, pricing: ProductPricing): void {
    details.currency = getCurrency(pricing.currency);
    details.basePrice = priceFromServer(pricing.originalPrice ?? pricing.price);
    details.discountedPrice = pricing.originalPrice ? priceFromServer(pricing.price) : undefined;
    details.vat = getTaxRate(pricing.vat);
}

function loadSpecificProductFields(details: DetailsFormState, product: ProductOutput): void {
    switch (product.type) {
    case ProductType.Session:
        details.duration = secondsToMinutes(product.sessionsDuration);
        details.locationId = product.location?.id;
        if (product.schedulingUrl) {
            details.scheduling = 'custom';
            details.schedulingUrl = product.schedulingUrl;
        }
        // TODO Separate disabled / enabled scheduling.
        break;
    case ProductType.Bundle:
        details.sessions = product.sessionsCount;
        details.duration = secondsToMinutes(product.sessionsDuration);
        details.locationId = product.location?.id;
        if (product.schedulingUrl) {
            details.scheduling = 'custom';
            details.schedulingUrl = product.schedulingUrl;
        }
        // TODO Separate disabled / enabled scheduling.
        break;
    case ProductType.Digital:
        details.url = product.url;
        break;
        // TODO Not supported yet.
        // case ProductType.Lead:
        // case ProductType.Membership:
        // case ProductType.Link:

        // Nothing to be done for other products.
    }

    // TODO Not supported yet.
    // pricingPeriod
    // isShowButton
    // isShowUrl
    // isLimitedOffer
}

// This function creates only a subset of the product data. It also don't transform all data to the server format (e.g., locations).
export function createProductPreview(type: ProductType, details: DetailsFormState, locations: LocationOutput[]): ProductPreview {
    const base: ProductPreview = {
        id: 'new',
        type,
        title: details.title,
        thumbnail: details.thumbnail,
        description: details.description,
        buttonText: details.buttonText,
    };

    const pricing = createProductPricing(details);

    switch (type) {
    case ProductType.Session:
        return {
            ...base,
            pricing,
            sessionsDuration: toNumber(details.duration),
            location: locations.find(l => l.id === details.locationId),
        };
    case ProductType.Bundle:
        return {
            ...base,
            pricing,
            sessionsCount: toNumber(details.sessions),
            sessionsDuration: toNumber(details.duration),
            location: locations.find(l => l.id === details.locationId),
        };
    case ProductType.Digital:
        return {
            ...base,
            pricing,
        };
    // TODO Not supported yet
    // case ProductType.Lead:
    // case ProductType.Membership:
    // case ProductType.Link:
    case ProductType.Custom:
        return {
            ...base,
            pricing,
        };
    }

    throw new Error(`Unsupported product type "${type}"`);
}

import { useCallback, useEffect, useMemo, useReducer } from 'react';
import { getEnumValues } from ':utils/common';
import { useMaster, type MasterContext } from ':frontend/context/UserProvider';
import { isCurrencySupported } from ':frontend/types/BankAccount';
import { NoBankAccountModal } from './NoBankAccountModal';
import { NoStripeModal } from './NoStripeModal';
import { type Id } from ':utils/id';
import { PaymentMethod } from ':utils/entity/order';
import { PaymentMethodRadio, type PaymentMethodRadioOption } from ':components/custom';

type PaymentMethodSelectProps = Readonly<{
    value?: PaymentMethod;
    onChange: (value: PaymentMethod) => void;
    currencyIds?: Id[];
    options?: PaymentMethod[];
}>;

export function PaymentMethodSelect({ value, onChange, currencyIds, options }: PaymentMethodSelectProps) {
    const [ state, dispatch ] = usePaymentMethods(options, currencyIds);
    const optionComponents = useMemo(() => state.options.map(pm => paymentMethodToTabOption(pm, state, dispatch)), [ state, dispatch ]);
    const closeStripeModal = useCallback(() => dispatch({ type: 'stripeModal', isOpen: false }), [ dispatch ]);

    return (<>
        <PaymentMethodRadio
            value={value}
            onChange={onChange}
            options={optionComponents}
        />
        {state.isBankModalNeeded && (
            <NoBankAccountModal
                show={state.isBankModalOpen}
                onClose={() => dispatch({ type: 'bankModal', isOpen: false })}
                currencyIds={state.unsupportedIds}
            />
        )}
        {state.isStripeModalNeeded && (
            <NoStripeModal
                show={state.isStripeModalOpen}
                onClose={closeStripeModal}
            />
        )}
    </>);
}

function paymentMethodToTabOption(method: PaymentMethod, state: PaymentMethodsState, dispatch: PaymentMethodsDispatch): PaymentMethodRadioOption {
    const output: PaymentMethodRadioOption = {
        method,
        isDisabled: !state.enabledOptions.includes(method),
        onEnableClick: undefined,
    };

    if (method === PaymentMethod.bankTransfer)
        output.onEnableClick = () => dispatch({ type: 'bankModal', isOpen: true });
    if (method === PaymentMethod.stripe)
        output.onEnableClick = () => dispatch({ type: 'stripeModal', isOpen: true });

    return output;
}

function usePaymentMethods(
    options?: PaymentMethod[],
    currencyIds?: Id[],
) {
    const masterContext = useMaster();
    const initialState = useMemo(() => computeInitialState({ masterContext, currencyIds, options }), [ masterContext, currencyIds, options ]);
    const [ state, dispatch ] = useReducer(paymentMethodsReducer, initialState);

    useEffect(() => {
        dispatch({ type: 'input', input: { masterContext, currencyIds, options } });
    }, [ masterContext, currencyIds, options ]);

    return [ state, dispatch ] as const;
}

type PaymentMethodsInput = {
    options?: PaymentMethod[];
    currencyIds?: Id[];
    masterContext: MasterContext;
};

type PaymentMethodsState = {
    input: PaymentMethodsInput;
    unsupportedIds: Id[];
    options: PaymentMethod[];
    enabledOptions: PaymentMethod[];
    isBankModalOpen: boolean;
    isBankModalNeeded: boolean;
    isStripeModalOpen: boolean;
    isStripeModalNeeded: boolean;
};

function computeInitialState(input: PaymentMethodsInput): PaymentMethodsState {
    return {
        input,
        ...computeOptions(input),
        isBankModalOpen: false,
        isStripeModalOpen: false,
    };
}

function computeOptions(input: PaymentMethodsInput, previousState?: PaymentMethodsState) {
    const unsupportedIds = input.currencyIds?.filter(id => !isCurrencySupported(input.masterContext.bankAccounts, id)) ?? [];
    const options = input.options ?? getEnumValues(PaymentMethod);
    const enabledOptions = options.filter(pm => isPaymentMethodSupported(pm, input.currencyIds ?? [], input.masterContext));

    return {
        unsupportedIds,
        options,
        enabledOptions,
        // If the modal was needed in the previous state, we include it as well so that it doesn't just disappear.
        isBankModalNeeded: unsupportedIds.length > 0 || !!previousState?.isBankModalNeeded,
        isStripeModalNeeded: !input.masterContext.team.isStripeConnected || !!previousState?.isStripeModalNeeded,
    };
}

type PaymentMethodAction = InputAction | ModalAction;
type PaymentMethodsDispatch = (action: PaymentMethodAction) => void;

type InputAction = {
    type: 'input';
    input: PaymentMethodsInput;
};

type ModalAction = {
    type: 'bankModal' | 'stripeModal';
    isOpen: boolean;
};

function paymentMethodsReducer(state: PaymentMethodsState, action: PaymentMethodAction) {
    console.log('Reduce:', state, action);
    switch (action.type) {
    case 'input': return {
        ...state,
        input: action.input,
        ...computeOptions(action.input, state),
    };
    case 'bankModal': return { ...state, isBankModalOpen: action.isOpen };
    case 'stripeModal': return { ...state, isStripeModalOpen: action.isOpen };
    }
}

/**
 * Returns the first supported payment method from the available ones.
 * @param available The available payment methods.
 * @param currencyIds The currency Ids for which the payment method should be supported.
 */
export function getSupportedPaymentMethod(available: PaymentMethod[], currencyIds: Id[], masterContext: MasterContext): PaymentMethod | undefined {
    for (const method of available) {
        if (isPaymentMethodSupported(method, currencyIds, masterContext))
            return method;
    }
}

function isPaymentMethodSupported(method: PaymentMethod, currencyIds: Id[], masterContext: MasterContext): boolean {
    switch (method) {
    case PaymentMethod.bankTransfer:
        return currencyIds.every(id => isCurrencySupported(masterContext.bankAccounts, id));
    case PaymentMethod.stripe:
        return masterContext.team.isStripeConnected;
    default:
        return true;
    }
}

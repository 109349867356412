import { useMemo } from 'react';
import { type ClientInfoFE } from ':frontend/types/Client';
import { useTranslation } from 'react-i18next';
import { type PaymentState, type UseEventDispatch, type UseEventState } from './useEvent';
import { Button, Form, Tooltip } from ':components/shadcn';
import { HiLockClosed, InvoiceIcon, UsersIcon, WalletIcon } from ':components/icons/old';
import { ChevronDownIcon, ChevronUpIcon } from ':components/icons/basic';
import { transformToPrice } from ':utils/math';
import { ContinuousParticipantSelect } from '../client/ContinuousParticipantSelect';
import ClientIconLink, { ClientIconBadge } from '../client/ClientIconLink';
import { getParticipantName, getClientOrContact, type EditablePayingParticipant } from ':frontend/types/EventParticipant';
import { CurrencySelect } from '../forms/CurrencySelect';
import { MoneyDisplay, CurrencyDisplay } from ':components/custom';
import i18next from ':frontend/types/i18n';
import { CloseButton, DeleteButton, EditButton } from '../forms/buttons';
import { VatSelect } from '../forms/VatSelect';
import { masterComponent, useMaster } from ':frontend/context/UserProvider';
import type { TFunction } from 'i18next';
import { computeRecurrenceCount, type Recurrence } from ':utils/recurrence';
import { Table } from '../common';
import { computePriceSummary, OrderSummary } from './priceSummary';
import { getI18nLocale } from ':utils/i18n';
import { routesBE } from ':utils/routes';
import type { DateTime } from 'luxon';

type EventPaymentProps = Readonly<{
    state: UseEventState;
    dispatch: UseEventDispatch;
    clients: ClientInfoFE[];
}>;

export const EventPayment = masterComponent(({ state, dispatch, clients }: EventPaymentProps) => {
    const { t } = useTranslation('components', { keyPrefix: 'eventPayment' });

    // If the event isn't new, we show data about only one item.
    const totalEvents = useMemo(() => state.event ? 1 : computeEventsCount(state.form), [ state.event, state.form ]);
    const existingPaymentState = useMemo(() => {
        if (state.event?.clients.some(c => c.isInOrder))
            return 'order';

        return undefined;
    }, [ state.event ]);

    if (!state.payment) {
        return (
            <Button onClick={() => dispatch({ type: 'payment', operation: 'create' })}>
                <WalletIcon size={20} className='mr-2' />
                {t('setup-payment-button')}
            </Button>
        );
    }

    if (existingPaymentState) {
        return (
            <div className='flex flex-col gap-2 pb-4'>
                <div className='flex items-center gap-4' style={{ height: 42 }}>
                    <WalletIcon size={20} />
                    <span>{t('payment-label')}</span>
                    <div className='grow' />
                    {!state.payment?.isEditing ? (
                        <EditButton aria={t('start-edit-button-aria')} onClick={() => dispatch({ type: 'payment', operation: 'startEdit' })} />
                    ) : (
                        <CloseButton aria={t('stop-edit-button-aria')} onClick={() => dispatch({ type: 'payment', operation: 'stopEdit' })} />
                    )}
                </div>
                {existingPaymentState === 'order' && (
                    <ExistingOrder state={state} dispatch={dispatch} />
                )}
            </div>
        );
    }

    return (
        <div className='flex flex-col gap-2 pb-4'>
            <div className='flex items-center gap-4' style={{ height: 42 }}>
                <WalletIcon size={20} />
                <span>{t('payment-label')}</span>
                <div className='grow' />
                <DeleteButton aria={t('delete-button-aria')} onClick={() => dispatch({ type: 'payment', operation: 'delete' })} />
            </div>
            <ClientsTable
                state={state}
                dispatch={dispatch}
                clients={clients}
                totalEvents={totalEvents}
            />
        </div>
    );
});

type ClientsTableProps = Readonly<{
    state: UseEventState;
    dispatch: UseEventDispatch;
    clients: ClientInfoFE[];
    totalEvents: number;
}>;

function ClientsTable({ state, dispatch, clients, totalEvents }: ClientsTableProps) {
    const { t } = useTranslation('components', { keyPrefix: 'eventPayment' });
    const payment = state.payment;

    const summary = useMemo(() => payment?.clients && computePriceSummary(payment.clients, totalEvents), [ payment?.clients, totalEvents ]);

    const { teamSettings } = useMaster();

    if (!payment)
        return null;

    return (
        <div className='flex flex-col gap-2' style={{ paddingLeft: '36px' }}>
            <div className='py-2 flex gap-4'>
                <Form.Switch
                    checked={payment.isLinked}
                    onCheckedChange={value => dispatch({ type: 'payment', operation: 'link', value })}
                    className='fl-icon-chain'
                />
                <div>
                    <div className='mb-1'>{t(payment.isLinked ? 'link-label-linked' : 'link-label-unlinked')}</div>
                    <div className='text-secondary-600' style={{ fontSize: '12px' }}>{t('link-description')}</div>
                </div>
            </div>
            <div className='fl-divider-light' />
            {payment.isLinked
                ? clientsPriceRow(payment, dispatch, t)
                : addClientRow(payment, dispatch, clients, t)
            }
            {payment.clients.length > 0 && (<>
                {(payment.isShowClients || !payment.isLinked) && (<>
                    <div className='fl-divider-light' />
                    {payment.clients.map(client => (
                        <ClientRow key={client.identifier} client={client} dispatch={dispatch} isLinked={payment.isLinked} />
                    ))}
                </>)}
                {summary && (<>
                    <div className='fl-divider-light' />
                    <div>
                        <div className='flex items-center justify-end gap-2' style={{ height: '42px' }}>
                            {teamSettings.isTaxesEnabled && (
                                <div className='flex items-baseline'>
                                    <Form.Label>{t('vat-label')}</Form.Label>
                                    <VatSelect
                                        immutableProps={{ variant: 'transparent', menuAlignment: 'top' }}
                                        value={payment.vat}
                                        onChange={value => value && dispatch({ type: 'payment', operation: 'vat', value })}
                                        className='rs-condensed'
                                    />
                                </div>
                            )}
                            <div className='text-lg'>
                                {t('total-price-label')}{' '}
                                <MoneyDisplay money={summary.vatBreakdown.withVat} />
                            </div>
                        </div>
                        {!summary.isOnlyZeroVat && (
                            <div style={{ fontSize: '12px' }} className='text-right text-secondary-600 pb-2'>
                                {t('without-vat-label')}{' '}
                                <MoneyDisplay money={summary.vatBreakdown.withoutVat} />
                            </div>
                        )}
                    </div>
                </>)}
            </>)}
        </div>
    );
}

function computeEventsCount({ start, recurrence }: { start: DateTime, recurrence?: Recurrence}): number {
    return recurrence
        ? computeRecurrenceCount(recurrence, start)
        : 1;
}

function clientsPriceRow(payment: PaymentState, dispatch: UseEventDispatch, t: TFunction) {
    const isSymbolBefore = payment.currency.isSymbolBefore(getI18nLocale(i18next));

    return (
        <div className='flex items-center gap-2 py-1'>
            <div className='shrink-0'>{t('price-per-client-label')}</div>
            <div className='grow' />
            {isSymbolBefore && (
                <CurrencySelect
                    value={payment.currency}
                    onChange={value => value && dispatch({ type: 'payment', operation: 'currency', value })}
                    className='rs-condensed'
                />
            )}
            <Form.Input
                style={{ maxWidth: '90px' }}
                className='text-right'
                type='number'
                value={payment.price}
                onChange={e => dispatch({ type: 'payment', operation: 'price', value: transformToPrice(e.target.value) })}
            />
            {!isSymbolBefore && (
                <CurrencySelect
                    value={payment.currency}
                    onChange={value => value && dispatch({ type: 'payment', operation: 'currency', value })}
                    className='rs-condensed'
                />
            )}
            <Button variant='outline' onClick={() => dispatch({ type: 'payment', operation: 'showClients', value: !payment.isShowClients })}>
                {payment.isShowClients ? (<>
                    <ChevronUpIcon size={18} className='mr-2' />
                    <span>{t('hide-clients-button')}</span>
                </>) : (<>
                    <ChevronDownIcon size={18} className='mr-2' />
                    <span>{t('show-clients-button')}</span>
                </>)}
            </Button>
        </div>
    );
}

function addClientRow(payment: PaymentState, dispatch: UseEventDispatch, clients: ClientInfoFE[], t: TFunction) {
    return (
        <div className='flex items-center gap-4 px-1 py-1'>
            <UsersIcon size={20} />
            <div className='grow'>
                <ContinuousParticipantSelect
                    immutableProps={{ variant: 'transparent', removeIndicator: true }}
                    hideValue
                    clients={clients}
                    value={payment.clients}
                    onChange={value => dispatch({ type: 'payment', operation: 'clients', value })}
                    placeholder={t('clients-placeholder')}
                />
            </div>
            <CurrencySelect
                immutableProps={{ variant: 'transparent', menuAlignment: 'left' }}
                value={payment.currency}
                onChange={value => value && dispatch({ type: 'payment', operation: 'currency', value })}
                className='rs-condensed'
            />
        </div>
    );
}

type ClientRowProps = Readonly<{
    client: EditablePayingParticipant;
    dispatch: UseEventDispatch;
    isLinked: boolean;
}>;

function ClientRow({ client, dispatch, isLinked }: ClientRowProps) {
    const { t } = useTranslation('components', { keyPrefix: 'continuousParticipantSelect' });

    return (
        <div className='flex items-center' style={{ height: '42px' }}>
            <div className='grow overflow-hidden pr-4'>
                <ClientIconBadge client={getClientOrContact(client)} />
            </div>
            {isLinked ? (
                <MoneyDisplay money={{ amount: client.price || 0, currency: client.currency }} />
            ) : (
                <CurrencyDisplay currency={client.currency} className='shrink-0 gap-1'>
                    <Form.Input
                        style={{ maxWidth: '90px' }}
                        className='text-right'
                        type='number'
                        value={client.price}
                        onChange={e => dispatch({ type: 'payment', operation: 'clientPrice', client, value: transformToPrice(e.target.value) })}
                    />
                </CurrencyDisplay>
            )}
            <DeleteButton
                aria={t('delete-button-aria', { name: getParticipantName(client) })}
                onClick={() => dispatch({ type: 'payment', operation: 'clientRemove', client })}
                className='shrink-0'
            />
        </div>
    );
}

type ExistingOrderProps = Readonly<{
    state: UseEventState;
    dispatch: UseEventDispatch;
}>;

function ExistingOrder({ state, dispatch }: ExistingOrderProps) {
    const { t } = useTranslation('components', { keyPrefix: 'eventPayment' });
    const event = state.event;
    if (!event)
        return null;

    const isSomeoneInStripeOrder = event.clients.some(client => client.isInStripeOrder);

    return (<>
        <Table compact noLines>
            <Table.Header>
                <Table.HeaderCol />
                <Table.HeaderCol xs='auto' className='text-right'>
                    {t('price-col')}
                    {state.payment?.isEditing && isSomeoneInStripeOrder && (
                        <Tooltip
                            side='top'
                            tooltipText={t('stripe-disabled-price-tooltip')}
                        >
                            <span>
                                <HiLockClosed size={16} className='text-primary select-none cursor-pointer ml-1 relative top-[-2px]' />
                            </span>
                        </Tooltip>
                    )}
                </Table.HeaderCol>
                <Table.HeaderCol xs='auto' className='text-center whitespace-nowrap'>
                    {t('paid-col')}
                    {isSomeoneInStripeOrder && (
                        <Tooltip
                            side='top'
                            tooltipText={t('stripe-disabled-paid-tooltip')}
                        >
                            <span>
                                <HiLockClosed size={16} className='text-primary select-none cursor-pointer ml-1 relative top-[-2px]' />
                            </span>
                        </Tooltip>
                    )}
                </Table.HeaderCol>
                <Table.HeaderCol xs='auto' />
            </Table.Header>
            <Table.Body>
                {state.payment?.clients.map(client => (
                    <ExistingOrderRow key={client.identifier} state={state} dispatch={dispatch} client={client} />
                ))}
            </Table.Body>
        </Table>
        <OrderSummary state={state} />
    </>);
}

type ExistingOrderRowProps = Readonly<{
    state: UseEventState;
    dispatch: UseEventDispatch;
    client: EditablePayingParticipant;
}>;

function ExistingOrderRow({ state, dispatch, client }: ExistingOrderRowProps) {
    const original = client.original!;

    return (
        <Table.Row>
            <Table.Col truncate><ClientIconLink client={original.client} /></Table.Col>
            {state.payment?.isEditing && !original.isInStripeOrder ? (
                <Table.Col xs='auto' className='py-0'>
                    <CurrencyDisplay currency={client.currency} className='gap-1'>
                        <Form.Input
                            style={{ maxWidth: '90px', minWidth: '90px' }}
                            className='text-right min-h-0 py-0'
                            type='number'
                            value={client.price}
                            onChange={e => dispatch({ type: 'payment', operation: 'clientPrice', client, value: transformToPrice(e.target.value) })}
                        />
                    </CurrencyDisplay>
                </Table.Col>
            ) : (
                <Table.Col xs='auto' className='text-right'>
                    <MoneyDisplay money={original.payment.price} />
                </Table.Col>
            )}
            <Table.Col xs='auto'>
                <div className='flex justify-center'>
                    <Form.Switch
                        checked={original.isPaid}
                        disabled
                    />
                </div>
            </Table.Col>
            <Table.Col xs='auto'>
                {original.invoiceId && (
                    <a href={routesBE.public.invoice.resolve({ id: original.invoiceId })} target='_blank' rel='noreferrer'>
                        <InvoiceIcon size={18} />
                    </a>
                )}
            </Table.Col>
        </Table.Row>
    );
}

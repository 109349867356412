import clsx from 'clsx';
import { type CalendarEvent, isGoogleEvent, isDraftEvent, isFullEvent } from ':frontend/types/calendar/Calendar';
import { isGoogleEventTransparent } from ':frontend/types/calendar/Google';
import { isEventTransparent } from ':frontend/types/Event';
import { DateTime } from 'luxon';
import type { CSSProperties } from 'react';
import { isColorDark, MILLISECONDS_IN_SECOND, secondsToMinutes } from ':utils/common';

export function getEventStyle(event: CalendarEvent, showMembers: boolean, selected?: boolean): { className?: string, style?: CSSProperties } {
    if (isDraftEvent(event)) {
        return {
            className: clsx('rbc-event-draft', getEventSizeClass(event)),
        };
    }

    if (isGoogleEvent(event)) {
        return {
            className: clsx('rbc-event-google', getEventSizeClass(event), {
                'rbc-event-transparent': isGoogleEventTransparent(event.resource.event),
                'rbc-event-finished': event.end <= DateTime.now(),
                'select-none cursor-default': event.allDay,
            }),
            style: {
                '--fl-event-base-color': event.resource.calendar.color,
            },
        };
    }

    if (isFullEvent(event)) {
        const className = clsx(getEventSizeClass(event), {
            'rbc-event-transparent': isEventTransparent(event.resource.event),
            'rbc-event-finished': event.end <= DateTime.now(),
            'rbc-event-too-bright': showMembers && !isColorDark(event.resource.teamMember.color),
        });

        const baseColor = showMembers
            ? '#' + event.resource.teamMember.color
            : event.resource.calendar?.color;

        return {
            className,
            style: baseColor ? {
                '--fl-event-base-color': baseColor,
            } : {},
        };
    }

    return {};
}

function getEventSizeClass(event: CalendarEvent): string {
    return `fl-event-lines_${getEventSize(event)}`;
}

function getEventSize(event: CalendarEvent): 'all' | number {
    if (event.allDay)
        return 'all';

    const durationInMinutes = secondsToMinutes((event.end.toMillis() - event.start.toMillis()) / MILLISECONDS_IN_SECOND);
    return Math.floor(durationInMinutes / MINUTES_PER_LINE);
}

const MINUTES_PER_LINE = 20;

import { useState } from 'react';
import { Modal } from ':components/shadcn';
import useNotifications from ':frontend/context/NotificationProvider';
import { trpc } from ':frontend/context/TrpcProvider';
import { createTranslatedErrorAlert, createTranslatedSuccessAlert } from '../notifications';
import BankingForm from './BankingForm';
import type { BankAccountUpsert } from ':utils/entity/money';
import { useMaster } from ':frontend/context/UserProvider';
import { BankAccountFE } from ':frontend/types/BankAccount';
import { type Id } from ':utils/id';

type UpsertBankAccountModalProps = Readonly<{
    bankAccount: BankAccountFE | undefined;
    show: boolean;
    onClose: () => void;
    /** Only the currencies that are currently unsupported. */
    currencyIds: Id[];
}>;

export default function UpsertBankAccountModal({ bankAccount, show, onClose, currencyIds }: UpsertBankAccountModalProps) {
    const [ isFetching, setIsFetching ] = useState(false);

    return (
        <Modal.Root open={show} onOpenChange={() => onClose()}>
            <Modal.Content closeButton='Close' className='max-w-2xl'>
                {bankAccount ? (
                    <EditAccount
                        bankAccount={bankAccount}
                        onClose={onClose}
                        currencyIds={currencyIds}
                        isFetching={isFetching}
                        setIsFetching={setIsFetching}
                    />
                ) : (
                    <CreateAccount
                        onClose={onClose}
                        currencyIds={currencyIds}
                        isFetching={isFetching}
                        setIsFetching={setIsFetching}
                    />
                )}
            </Modal.Content>
        </Modal.Root>
    );
}

type EditAccountProps = Readonly<{
    bankAccount: BankAccountFE;
    onClose: () => void;
    currencyIds: Id[];
    isFetching: boolean;
    setIsFetching: (isFetching: boolean) => void;
}>;

function EditAccount({ bankAccount, onClose, currencyIds, isFetching, setIsFetching }: EditAccountProps) {
    const { addAlert } = useNotifications();
    const { setBankAccounts } = useMaster();

    const updateBankAccountMutation = trpc.money.updateBankAccount.useMutation();

    function updateAccount(edit: BankAccountUpsert) {
        if (isFetching)
            return;

        setIsFetching(true);

        updateBankAccountMutation.mutate({
            raw: edit.raw,
            currencies: edit.currencies,
            id: bankAccount.id,
        }, {
            onError: () => {
                addAlert(createTranslatedErrorAlert());
            },
            onSuccess: response => {
                const updatedAccount = BankAccountFE.fromServer(response);
                setBankAccounts((oldAccounts: BankAccountFE[]) => oldAccounts.map(a => a.id !== updatedAccount.id ? a : updatedAccount));

                addAlert(createTranslatedSuccessAlert('common:bankAccount.edited-alert'));
            },
            onSettled: () => {
                setIsFetching(false);
                onClose();
            },
        });
    }

    return (
        <BankingForm
            input={bankAccount}
            defaultCurrencyIds={currencyIds}
            onSubmit={updateAccount}
            isFetching={isFetching}
        />
    );
}

type CreateAccountProps = Readonly<{
    onClose: () => void;
    currencyIds: Id[];
    isFetching: boolean;
    setIsFetching: (isFetching: boolean) => void;
}>;

function CreateAccount({ onClose, currencyIds, isFetching, setIsFetching }: CreateAccountProps) {
    const { addAlert } = useNotifications();
    const { setBankAccounts } = useMaster();

    const createBankAccountMutation = trpc.money.createBankAccount.useMutation();

    function createAccount(init: BankAccountUpsert) {
        setIsFetching(true);
        createBankAccountMutation.mutate(init, {
            onError: () => {
                addAlert(createTranslatedErrorAlert());
            },
            onSuccess: response => {
                const newAccount = BankAccountFE.fromServer(response);
                setBankAccounts((oldAccounts: BankAccountFE[]) => [ ...oldAccounts, newAccount ]);

                addAlert(createTranslatedSuccessAlert('common:bankAccount.created-alert'));
            },
            onSettled: () => {
                setIsFetching(false);
                onClose();
            },
        });
    }

    return (
        <BankingForm
            defaultCurrencyIds={currencyIds}
            onSubmit={createAccount}
            isFetching={isFetching}
            isAddForm
        />
    );
}

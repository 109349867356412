import { type Dispatch, useCallback, useMemo } from 'react';
import { Button, Form, Modal } from ':components/shadcn';
import { Skeleton, Table } from ':frontend/components/common';
import { OpenCloseChevronButton } from ':frontend/components/common/OpenCloseChevronButton';
import { MoneyDisplay } from ':components/custom';
import { getSkeletonArray } from ':utils/math';
import { useTranslation } from 'react-i18next';
import { type InvoicingClient, useToggle, type EventItem, type UseBackpayDispatch, useInvoiceBackpay } from ':frontend/hooks';
import { useCached } from ':components/hooks';
import DateTimeDisplay from ':frontend/components/common/DateTimeDisplay';
import { ShoppingBagIcon } from ':components/icons/old';
import { type Id } from ':utils/id';
import EventStateBadge from ':frontend/components/event/EventStateBadge';
import { emptyFunction } from ':frontend/utils/common';
import ClientIconLink from ':frontend/components/client/ClientIconLink';
import FilterRow, { useFilters, useFiltersApply, type FilterFunction } from ':frontend/components/common/filters/FilterRow';
import EventStateFilter, { filterName as eventFiltersName } from ':frontend/components/common/filters/EventStateFilter';
import createEventClientFilter, { filterName as clientFilterName } from ':frontend/components/common/filters/ClientFilter';
import clsx from 'clsx';
import { type EventFE } from ':frontend/types/Event';
import { InfoCard, useInfoCardState } from ':frontend/components/settings/InfoCard';
import { type EventCheckoutInput } from ':frontend/components/orders/checkout/useCheckout';
import { CheckoutModalInner, type CheckoutOutput } from ':frontend/components/orders/checkout/CheckoutModalInner';
import { routesFE } from ':utils/routes';
import { useNavigate } from 'react-router-dom';

export default function NewBackpayOrder() {
    const { t } = useTranslation('pages', { keyPrefix: 'invoiceBackpay' });
    const navigate = useNavigate();

    const { state: { invoicingClients, clients, checkoutInput }, selectedItems, preselected, dispatch } = useInvoiceBackpay();
    const checkoutOutput = useCallback((action: CheckoutOutput) => {
        switch (action.type) {
        case 'back':
            dispatch({ type: 'checkoutEnd' });
            break;
        case 'finish':
            navigate(routesFE.orders.list);
            break;
        }
    }, [ dispatch, navigate ]);

    const filters = useMemo(() => [
        EventStateFilter,
        createEventClientFilter(clients ?? []),
    ], [ clients ]);

    const filtersControl = useFilters(filters);
    const applyEvents = useFiltersApply(filtersControl, eventFiltersName);
    const applyClients = useFiltersApply(filtersControl, clientFilterName);
    const filteredInvoicingClients = useMemo(() => invoicingClients?.filter(client => applyClients(client.client)), [ invoicingClients, applyClients ]);

    const isInvoicing = selectedItems !== 0;
    const isInfoCardClosed = useInfoCardState('backpay').cardState.closed;

    return (<>
        <CheckoutModal
            input={checkoutInput}
            output={checkoutOutput}
        />
        <div className='flex flex-col h-full'>
            <div className='max-w-[1200px] w-full mx-auto'>
                <div className={clsx(!isInvoicing && 'my-4')} style={isInvoicing ? { height: '0px', opacity: '0' } : { height: '42px' }}>
                    <div>
                        <h1 className='m-0'>{t('page-title')}</h1>
                    </div>
                    <div className='flex items-center justify-center w-fit text-lg'>
                        {isInfoCardClosed && t('start-invoicing-text')}
                    </div>
                    <div />
                </div>
                {isInvoicing && (
                    <div className='my-4 pl-4 pr-2 flex items-center justify-between rounded rounded-md border border-primary bg-white' style={{ height: '42px' }}>
                        <span className='tabular-nums'>
                            {t('selected-items-label', { count: selectedItems })}
                        </span>
                        <div className='flex items-center gap-2'>
                            <Button onClick={() => dispatch({ type: 'checkoutStart' })} size='tiny' className='mr-2'>
                                <ShoppingBagIcon size={18} className='mr-2' />{t('open-cart-button')}
                            </Button>
                            <Button onClick={() => dispatch({ type: 'reset' })} size='tiny' variant='outline-danger'>
                                {t('cancel-button')}
                            </Button>
                        </div>
                    </div>
                )}
            </div>
            <InfoCard infoKey='backpay' className='mb-8' />
            {/* The marginTop value is very carefully chosen so that the filter row and the top of the table is in the same height as on other pages. */}
            <div className='max-w-[1200px] w-full mx-auto mb-4' style={{ marginTop: '16.8px' }}>
                <FilterRow control={filtersControl} />
            </div>
            <div className='fl-main-scroller pb-12'>
                <Table className='max-w-[1200px] w-full mx-auto'>
                    <Table.Header>
                        <Table.HeaderCol xs='auto' />
                        <Table.HeaderCol>{t('client-label')}</Table.HeaderCol>
                        <Table.HeaderCol className='text-right'>{t('events-label')}</Table.HeaderCol>
                        <Table.HeaderCol>{t('email-label')}</Table.HeaderCol>
                        <Table.HeaderCol xs='auto' />
                    </Table.Header>
                    <Table.Body>
                        <InvoicingClientsList
                            invoicingClients={filteredInvoicingClients}
                            dispatch={dispatch}
                            preselectedClientId={preselected?.clientId}
                            applyEvents={applyEvents}
                        />
                    </Table.Body>
                </Table>
            </div>
        </div>
    </>);
}

type InvoicingClientsListProps = Readonly<{
    invoicingClients?: InvoicingClient[];
    preselectedClientId?: Id;
    dispatch: UseBackpayDispatch;
    applyEvents: FilterFunction<EventFE>;
}>;

function InvoicingClientsList({ invoicingClients, dispatch, preselectedClientId, applyEvents }: InvoicingClientsListProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'invoiceBackpay' });
    if (!invoicingClients)
        return (<>{getSkeletonArray().map(id => <InvoicingClientSkeleton key={id} />)}</>);

    if (invoicingClients.length === 0) {
        return (
            <Table.Row>
                <Table.Col colSpan={5} className='text-center text-xl py-12'>
                    {t('no-invoicing-clients-yet-text')}
                </Table.Col>
            </Table.Row>
        );
    }

    return (<>
        {invoicingClients.map(invoicingClient => (
            <InvoicingClientRow
                key={invoicingClient.client.id}
                invoicingClient={invoicingClient}
                dispatch={dispatch}
                isPreselected={invoicingClient.client.id === preselectedClientId}
                applyEvents={applyEvents}
            />
        ))}
        <Table.Row className='fl-hide-if-not-first-row'>
            <Table.Col colSpan={5} className='text-center text-xl py-12'>
                {t('no-invoicing-clients-text')}
            </Table.Col>
        </Table.Row>
    </>);
}

type InvoicingClientRowProps = Readonly<{
    invoicingClient: InvoicingClient;
    dispatch: UseBackpayDispatch;
    isPreselected: boolean;
    applyEvents: FilterFunction<EventFE>;
}>;

function InvoicingClientRow({ invoicingClient: { client, items }, dispatch, isPreselected, applyEvents }: InvoicingClientRowProps) {
    const { visibleItems, selectedItems, selectedVisibleItems } = useMemo(() => {
        const visibleItems = items.filter(item => applyEvents(item.event));
        return {
            visibleItems,
            selectedItems: items.filter(i => i.isSelected),
            selectedVisibleItems: visibleItems.filter(i => i.isSelected),
        };
    }, [ items, applyEvents ]);

    const [ isExpanded, setIsExpanded ] = useToggle(isPreselected || selectedVisibleItems.length > 0);
    const isNotAllSelected = selectedVisibleItems.length !== visibleItems.length;

    if (visibleItems.length === 0)
        return null;

    return (<>
        {/* The doulble toggle is intentional - the second one negates the first one so there is an 'unclickable zone' around the checkbox. */}
        <Table.Row className='select-none cursor-pointer fl-hoverable' onClick={setIsExpanded.toggle}>
            <Table.Col className='select-none cursor-default fl-hoverable-exception' onClick={setIsExpanded.toggle}>
                <Form.Checkbox
                    checked={selectedVisibleItems.length > 0}
                    onCheckedChange={value => dispatch({ type: 'select', items: visibleItems, isSelected: value })}
                    className={isNotAllSelected ? 'fl-checkbox-dash' : ''}
                />
            </Table.Col>
            <Table.Col truncate>
                <ClientIconLink client={client} className='fl-hoverable-exception' />
            </Table.Col>
            <Table.Col xs='auto' className='tabular-nums text-right whitespace-nowrap'>
                <span className='text-white bg-primary rounded' style={{ padding: '2px 4px' }}>{selectedItems.length}</span>
                <span>{` / ${items.length}`}</span>
            </Table.Col>
            <Table.Col truncate>{client.email}</Table.Col>
            <Table.Col xs='auto'>
                <OpenCloseChevronButton opened={isExpanded} onClick={emptyFunction} />
            </Table.Col>
        </Table.Row>
        {isExpanded && (
            <Table.Row>
                <Table.Col colSpan={5} className='p-0'>
                    {visibleItems.map(item => (
                        <ParticipantRow key={item.event.id} item={item} dispatch={dispatch} />
                    ))}
                </Table.Col>
            </Table.Row>
        )}
    </>);
}

type ParticipantRowProps = Readonly<{
    item: EventItem;
    dispatch: UseBackpayDispatch;
}>;

function ParticipantRow({ item, dispatch }: ParticipantRowProps) {
    const { event, participant, isSelected } = item;

    return (
        <div className='items-center'>
            <div className='pr-4 w-fit'>
                <div className='pl-4'>
                    <Form.Checkbox checked={isSelected} onCheckedChange={value => dispatch({ type: 'select', item, isSelected: value })} />
                </div>
            </div>
            <div className='py-4 w-fit'>
                <DateTimeDisplay dateTime={event.start} date />
            </div>
            <div className='w-fit'>
                <DateTimeDisplay dateTime={event.start} time />
                {' - '}
                <DateTimeDisplay dateTime={event.end} time />
            </div>
            {/* <Col className='flex items-center truncate'>
                <CalendarIcon calendar={calendar} className='mr-2' />
                <div className='truncate'>{event.displayTitle}</div>
            </Col> */}
            <div className='truncate'>
                {event.displayTitle}
            </div>
            <div>
                <EventStateBadge event={item.event}/>
            </div>
            <div>
                <MoneyDisplay money={participant.payment.price} />
            </div>
        </div>
    );
}

function InvoicingClientSkeleton() {
    return (
        <Table.Row>
            <Table.Col>
                <Form.Checkbox disabled />
            </Table.Col>
            <Table.Col><Skeleton height={20} /></Table.Col>
            <Table.Col><Skeleton height={20} /></Table.Col>
            <Table.Col><Skeleton height={20} /></Table.Col>
            <Table.Col>
                <OpenCloseChevronButton opened={false} onClick={emptyFunction} />
            </Table.Col>
        </Table.Row>
    );
}

type CheckoutModalProps = Readonly<{
    input?: EventCheckoutInput;
    output: Dispatch<CheckoutOutput>;
}>;

function CheckoutModal({ input, output }: CheckoutModalProps) {
    const cachedInput = useCached(input);
    if (!cachedInput)
        return null;

    return (
        <Modal.Root open={!!input}>
            <Modal.Content className='fl-checkout-modal p-0' closeButton={null}>
                <CheckoutModalInner
                    input={cachedInput}
                    output={output}
                />
            </Modal.Content>
        </Modal.Root>
    );
}

import { useCallback } from 'react';
import { Select } from ':components/shadcn';
import { localeToOption, type LocaleOption } from ':frontend/types/i18n';
import { Controller, type Control, type FieldPath, type FieldValues } from 'react-hook-form';
import { SUPPORTED_LOCALES, type LocaleCode, type LocaleType } from ':utils/i18n';

type LocaleSelectProps<TType extends LocaleType> = Readonly<{
    value?: LocaleCode<TType>;
    onChange: (value?: LocaleCode<TType>) => void;
    type: TType;
    placeholder?: string;
    disabled?: boolean;
}>;

export function LocaleSelect<TType extends LocaleType>({ value, onChange, type, placeholder, disabled }: LocaleSelectProps<TType>) {
    const options = SUPPORTED_LOCALES[type].map(localeToOption) as LocaleOption<TType>[];

    return (
        <Select
            options={options}
            value={value ? localeToOption(value) : undefined}
            onChange={option => onChange(option?.value)}
            placeholder={placeholder}
            isDisabled={disabled}
        />
    );
}

type ControlledLocaleSelectProps<TType extends LocaleType, TFieldValues extends FieldValues> = {
    control: Control<TFieldValues>;
    name: FieldPath<TFieldValues>;
    type: TType;
    placeholder?: string;
    disabled?: boolean;
};

export function ControlledLocaleSelect<TType extends LocaleType, TFieldValues extends FieldValues>({ control, name, type, placeholder, disabled }: ControlledLocaleSelectProps<TType, TFieldValues>) {
    const InnerSelect = useCallback(({ field }: { field: { value?: LocaleCode<TType>, onChange: (value?: LocaleCode<TType> ) => void } }) => {
        return (
            <LocaleSelect
                value={field.value}
                onChange={field.onChange}
                type={type}
                placeholder={placeholder}
                disabled={disabled}
            />
        );
    }, [ type ]);

    return (
        <Controller
            control={control}
            name={name}
            render={InnerSelect}
        />
    );
}

import { zId } from ':utils/id';
import { zEventParticipantOutput } from './eventParticipant';
import { zClientInfoOutput } from './client';
import { zEventInfoOutput } from './event';
import { z } from 'zod';
import { zCurrencyId } from './money';
import { zDateTime } from ':utils/common';
import { zLocationOutput } from './location';
import { ProductType } from './product';

// Internal types

const zBaseOrderItemOutput = z.object({
    id: zId,
    title: z.string(),
    quantity: z.number(),
    index: z.number(),
    unitPrice: z.number(),
    vat: zId,
});

export type OrderDataOutput = z.infer<typeof zOrderDataOutput>;
const zOrderDataOutput = z.object({
    currencyId: zCurrencyId,
    createdAt: zDateTime,
});

export type GeneralOrderItemOutput = z.infer<typeof zGeneralOrderItemOutput>;
const zGeneralOrderItemOutput = zBaseOrderItemOutput.and(zOrderDataOutput);

// Items
export enum OrderItemType {
    Event = 'event',
    Product = 'product',
    Custom = 'custom',
}

export type EventOrderItemOutput = z.infer<typeof zEventOrderItemOutput>;
const zEventOrderItemOutput = z.object({
    eventParticipant: zEventParticipantOutput.and(z.object({
        event: zEventInfoOutput,
    })),
});

export type SessionProductOrderItemOutput = z.infer<typeof zSessionProductOrderItemOutput>;
const zSessionProductOrderItemOutput = z.object({
    type: z.literal(ProductType.Session),
    successMessage: z.string().optional(),
    sessionsDuration: z.number(),
    location: zLocationOutput.optional(),
    schedulingUrl: z.string().optional(),
    guest: zClientInfoOutput,
    scheduledCount: z.number(),
    completedCount: z.number(),
});

export type BundleProductOrderItemOutput = z.infer<typeof zBundleProductOrderItemOutput>;
const zBundleProductOrderItemOutput = zSessionProductOrderItemOutput.extend({
    type: z.literal(ProductType.Bundle),
    successMessage: z.string().optional(),
    sessionsCount: z.number(),
});

export type LeadProductOrderItemOutput = z.infer<typeof zLeadProductOrderItemOutput>;
const zLeadProductOrderItemOutput = z.object({
    type: z.literal(ProductType.Lead),
    successMessage: z.string().optional(),
});

export type DigitalProductOrderItemOutput = z.infer<typeof zDigitalProductOrderItemOutput>;
const zDigitalProductOrderItemOutput = z.object({
    type: z.literal(ProductType.Digital),
    successMessage: z.string().optional(),
    url: z.string(),
});

export type CustomProductOrderItemOutput = z.infer<typeof zCustomProductOrderItemOutput>;
const zCustomProductOrderItemOutput = z.object({
    type: z.literal(ProductType.Custom),
    successMessage: z.string().optional(),
});

export type ProductOrderItemOutput = z.infer<typeof zProductOrderItemOutput>;
const zProductOrderItemOutput = z.discriminatedUnion('type', [
    zSessionProductOrderItemOutput,
    zBundleProductOrderItemOutput,
    zLeadProductOrderItemOutput,
    zDigitalProductOrderItemOutput,
    zCustomProductOrderItemOutput,
]);

export type SpecificOrderItemOutput = z.infer<typeof zSpecificOrderItemOutput>;
const zSpecificOrderItemOutput = z.object({
    eventOrderItem: zEventOrderItemOutput.nullable(),
    productOrderItem: zProductOrderItemOutput.nullable(),
});

export type OrderItemOutput = z.infer<typeof zOrderItemOutput>;
export const zOrderItemOutput = zBaseOrderItemOutput.and(zSpecificOrderItemOutput);

export type ProductItemOutput = z.infer<typeof zProductItemOutput>;
export const zProductItemOutput = zGeneralOrderItemOutput.and(z.object({
    productOrderItem: zProductOrderItemOutput,
}));

export const zEventItemInit = z.object({
    // TODO remove and compute on BE
    title: z.string(),
    participantIds: z.array(zId),
});

export type CustomItemInit = z.infer<typeof zCustomItemInit>;
export const zCustomItemInit = z.object({
    title: z.string(),
    quantity: z.number(),
    unitPrice: z.number(),
    vat: zId,
});

export const zDiscountItemInit = zCustomItemInit.and(z.object({
    currencyId: zCurrencyId,
}));

export type OrderItemEdit = z.infer<typeof zOrderItemEdit>;
export const zOrderItemEdit = z.object({
    id: zId,
    title: z.string(),
    quantity: z.number(),
    unitPrice: z.number(),
    vat: zId,
    isDeleted: z.boolean().optional(),
});

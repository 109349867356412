import { priceFromServer } from ':utils/money';
import { Query, capitalize, getNameFromEmail, getShortcutFromString } from ':frontend/utils/common';
import { getPersonName } from './Person';
import { InvoicingOverrideFE, SubscriberSettingsFE } from './Invoicing';
import { canonizeEmail } from ':utils/forms';
import { type Entity, type Id, type Unique, createExampleId } from ':utils/id';
import type { LocaleCode, CountryCode, TimezoneCode } from ':utils/i18n';
import { type ClientAccessType, ClientState, type ClientOutput, type ClientInfoOutput, type ClientStatsOutput } from ':utils/entity/client';

export class ClientInfoFE implements Entity {
    readonly query: Query;
    readonly shortcut: string;

    protected constructor(
        readonly id: Id,
        readonly email: string,
        readonly name: string,
        readonly phoneNumber: string | undefined,
        readonly state: ClientState,
        readonly isDeleted: boolean,
        readonly invoicingProfileId: Id,
        readonly locale: LocaleCode,
        /** If defined, this value overrides the value from the invoicing profile. */
        readonly dueDays: number | undefined,
        readonly tagIds: Id[],
    ) {
        this.query = new Query(...name.split(' '), getNameFromEmail(email));
        this.shortcut = computeClientShortcut(name, email);
    }

    static fromServer(input: ClientInfoOutput): ClientInfoFE {
        return new ClientInfoFE(
            input.id,
            input.email,
            input.name,
            input.phoneNumber,
            input.state,
            !!input.isDeleted,
            input.invoicingProfile,
            input.locale,
            input.dueDays,
            input.tags,
        );
    }

    static createExample(): ClientInfoFE {
        return new ClientInfoFE(createExampleId('clients'), '', '', undefined, ClientState.active, false, createExampleId('invoicing-profiles'), 'en-US', 14, []);
    }
}

export function computeClientShortcut(name: string, email: string): string {
    if (name.length < 2)
        return capitalize(email.slice(0, 2));

    return getShortcutFromString(name);
}

export class ClientFE extends ClientInfoFE {
    private constructor(
        id: Id,
        email: string,
        name: string,
        phoneNumber: string | undefined,
        state: ClientState,
        deleted: boolean,
        invoicingProfileId: Id,
        locale: LocaleCode,
        dueDays: number | undefined,
        tagIds: Id[],
        readonly timezone: TimezoneCode,
        readonly country: CountryCode,
        readonly note: string | undefined,
        readonly subscriberSettings: SubscriberSettingsFE,
        readonly invoicingOverride: InvoicingOverrideFE,
        readonly access?: ClientAccessType,
    ) {
        super(id, email, name, phoneNumber, state, deleted, invoicingProfileId, locale, dueDays, tagIds);
    }

    static fromServer(input: ClientOutput): ClientFE {
        return new ClientFE(
            input.id,
            input.email,
            input.name,
            input.phoneNumber,
            input.state,
            !!input.isDeleted,
            input.invoicingProfile,
            input.locale,
            input.dueDays,
            input.tags,
            input.timezone,
            input.country,
            input.note,
            SubscriberSettingsFE.fromServer(input.subscriberSettings, input.name),
            InvoicingOverrideFE.fromServer(input.invoicingOverride),
            input.access,
        );
    }

    updateFromInfo(info: ClientInfoFE): ClientFE {
        return new ClientFE(
            info.id,
            info.email,
            info.name,
            info.phoneNumber,
            info.state,
            info.isDeleted,
            info.invoicingProfileId,
            info.locale,
            info.dueDays,
            info.tagIds,
            this.timezone,
            this.country,
            this.note,
            this.subscriberSettings,
            this.invoicingOverride,
            this.access,
        );
    }
}

export type ClientContactFromServer = {
    names?: {
        displayName?: string;
        familyName?: string;
        givenName?: string;
    }[];
    emailAddresses?: {
        value?: string;
    }[];
};

export class ClientContact implements Unique {
    readonly query: Query;
    readonly shortcut: string;
    readonly canonicalEmail: string;

    private constructor(
        /** Display email. For identifying purposes use the canonicalEmail instead! */
        readonly email: string,
        readonly name: string,
    ) {
        this.query = new Query(name, getNameFromEmail(email));
        this.shortcut = computeClientShortcut(name, email);
        this.canonicalEmail = canonizeEmail(email);
    }

    static fromServer(input: ClientContactFromServer): ClientContact | undefined {
        const email = !!input.emailAddresses?.length && input.emailAddresses[0].value;
        if (!email)
            return undefined;

        const name = !!input.names?.length && input.names[0] || undefined;

        return new ClientContact(
            email,
            name?.displayName ?? getPersonName({ email, firstName: name?.givenName, lastName: name?.familyName }),
        );
    }

    static fromEmail(email: string): ClientContact {
        return new ClientContact(email, email);
    }

    /** The client contacts are identified by their canonical emails. */
    get unique(): string {
        return this.canonicalEmail;
    }
}


export class ClientStatsFE {
    private constructor(
        readonly ordersTotal: number,
        readonly ordersUnpaid: number,
        readonly orderCount: number,
        readonly eventsInvoiced: number,
    ) {
    }

    static fromServer(input: ClientStatsOutput): ClientStatsFE {
        return new ClientStatsFE(
            priceFromServer(input.ordersTotal),
            priceFromServer(input.ordersUnpaid),
            input.orderCount,
            input.eventsInvoiced,
        );
    }
}

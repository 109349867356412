import { forwardRef, useCallback, useMemo, useState } from 'react';
import { ClientInfoFE } from ':frontend/types/Client';
import { ClientTagFE, sortClientTags } from ':frontend/types/ClientTag';
import { AddButton } from '../forms/buttons';
import { Button, DropdownMenu, Form } from ':components/shadcn';
import { type Id } from ':utils/id';
import { useMaster } from ':frontend/context/UserProvider';
import { useTranslation } from 'react-i18next';
import { useToggle } from ':frontend/hooks';
import { CheckIcon, CloseIcon, DotsHorizontalIcon } from ':components/icons/old';
import { SpinnerButton } from '../common';
import clsx from 'clsx';
import { trpc } from ':frontend/context/TrpcProvider';
import { Trash2Icon } from ':components/icons/basic';
import { SubscriptionUpsellModal } from '../orders/SubscriptionErrorModal';

type ClientTagButtonProps = Readonly<{
    tag: { name: string, color: string };
    onClick?: () => void;
}>;

export const ClientTagButton = forwardRef<HTMLButtonElement, ClientTagButtonProps>(({ tag, onClick }, ref) => {
    return (
        <button
            ref={ref}
            className='fl-client-tag-button px-2 py-1 rounded-md whitespace-nowrap hover:opacity-80 active:opacity-60 '
            style={{ backgroundColor: `#${tag.color}` }}
            onClick={onClick}
        >
            {tag.name}
        </button>
    );
});

type ClientTagDisplayProps = Readonly<{
    tag: { name: string, color: string };
}>;

export function ClientTagDisplay({ tag }: ClientTagDisplayProps) {
    return (
        <div className='px-2 py-1 rounded-md whitespace-nowrap w-fit' style={{ backgroundColor: `#${tag.color}`, height: '25px' }}>
            {tag.name}
        </div>
    );
}

type ClientTagsProps = Readonly<{
    client: ClientInfoFE;
    setClient: (client: ClientInfoFE) => void;
}>;

export function ClientTags({ client, setClient }: ClientTagsProps) {
    const { t } = useTranslation('components', { keyPrefix: 'clientTags' });
    const { clientTags } = useMaster();
    // All tags are ordered the same way, i.e., by their ids.
    const tags = useMemo(() => clientTags.filter(tag => client.tagIds.some(tagId => tag.id === tagId)), [ clientTags, client.tagIds ]);

    const [ show, setShow ] = useToggle(false);
    const [ showUpsellModal, setShowUpsellModal ] = useToggle(false);
    const [ editedTagId, setEditedTagId ] = useState<Id>();

    const onEditClose = useCallback(() => setEditedTagId(undefined), []);

    return (<>
        <div className='flex items-center gap-1 flex-wrap'>
            <div>
                <DropdownMenu.Root open={show} onOpenChange={setShow.value}>
                    <DropdownMenu.Trigger asChild>
                        <div><AddButton aria={t('add-button-aria')} /></div>
                    </DropdownMenu.Trigger>
                    <DropdownMenu.Content className='p-0' style={{ minWidth: '240px' }}>
                        {show && (
                            <AddTagMenu client={client} setClient={setClient} tags={tags} onClose={setShow.false} onError={setShowUpsellModal.true} />
                        )}
                    </DropdownMenu.Content>
                </DropdownMenu.Root>
            </div>
            {tags.map(tag => (
                <DropdownMenu.Root key={tag.id} open={editedTagId === tag.id} onOpenChange={onEditClose}>
                    <DropdownMenu.Trigger asChild>
                        <ClientTagButton key={tag.id} tag={tag} onClick={() => setEditedTagId(tag.id)} />
                    </DropdownMenu.Trigger>
                    <DropdownMenu.Content className='p-0'>
                        {editedTagId === tag.id && (
                            <EditTagMenu client={client} setClient={setClient} tag={tag} onClose={onEditClose} />
                        )}
                    </DropdownMenu.Content>
                </DropdownMenu.Root>
            ))}
        </div>
        <SubscriptionUpsellModal show={showUpsellModal} onHide={setShowUpsellModal.false}/>
    </>);
}

type AddTagMenuProps = Readonly<{
    client: ClientInfoFE;
    setClient: (client: ClientInfoFE) => void;
    tags: ClientTagFE[];
    onClose: () => void;
    onError: () => void;
}>;

function AddTagMenu({ client, setClient, tags, onClose, onError }: AddTagMenuProps) {
    const { t } = useTranslation('components', { keyPrefix: 'clientTags' });
    const { clientTags, setClientTags } = useMaster();
    const [ query, setQuery ] = useState('');
    const [ fetching, setFetching ] = useState<string>();
    const createTagMutation = trpc.$client.createClientTag.useMutation();
    const updateTagsMutation = trpc.$client.updateClientsTags.useMutation();

    const availableTags = useMemo(() => clientTags.filter(tag => !tags.some(clientTag => clientTag.id === tag.id)), [ clientTags, tags ]);
    const filteredTags = useMemo(() =>
        query.trim().length === 0
            ? availableTags
            : availableTags.filter(tag => tag.name.toLowerCase().includes(query.toLowerCase())),
    [ availableTags, query ]);
    const trimmedTags = useMemo(() => filteredTags.slice(0, MAX_DISPLAYED_TAGS), [ filteredTags ]);

    function createTag(input: string) {
        setFetching(FID_CREATE);
        createTagMutation.mutate({ name: input.trim(), color: newTagColor, clientId: client.id }, {
            onSuccess: response => {
                const newTag = ClientTagFE.fromServer(response);
                setClientTags(oldTags => sortClientTags([ ...oldTags, newTag ]));

                client.tagIds.push(newTag.id);
                setClient(client);

                onClose?.();
            },
            onError: onError,
            onSettled: () => {
                setFetching(undefined);
            },
        });
    }

    const newTagColor = useMemo(() => findNextColor(clientTags), [ clientTags ]);
    const isNewEnabled = query.trim().length > 0 && !filteredTags.some(tag => tag.name === query);

    function addTag(tag: ClientTagFE) {
        setFetching(tag.id);
        updateTagsMutation.mutate({ clientId: client.id, tagId: tag.id, type: 'add' }, {
            onSuccess: response => {
                const updatedClient = ClientInfoFE.fromServer(response);
                setClient(updatedClient);
                onClose?.();
            },
            onSettled: () => {
                setFetching(undefined);
            },
        });
    }

    return (
        <div>
            <div className='px-4 py-2'>
                <Form.Input
                    label={t('query-label')}
                    value={query}
                    onChange={e => setQuery(e.target.value)}
                    placeholder={t('query-placeholder')}
                    autoFocus
                />
            </div>
            <div className={clsx(!isNewEnabled && 'rounded-b-md overflow-hidden')}>
                {trimmedTags.map(tag => (
                    <SpinnerButton
                        key={tag.id}
                        className='fl-dropdown-button'
                        onClick={() => addTag(tag)}
                        fetching={fetching}
                        fid={tag.id}
                        icon={<div className='absolute pl-4 left-0'>
                            <ClientTagDisplay tag={tag} />
                        </div>}
                    >
                        <div style={{ height: '25px' }} />
                    </SpinnerButton>
                ))}
                {filteredTags.length > MAX_DISPLAYED_TAGS && (
                    <div className='py-2 px-4 text-secondary'>
                        <DotsHorizontalIcon size={22} />
                    </div>
                )}
            </div>
            {isNewEnabled && (<>
                <div className='fl-divider-light' />
                <div className='px-4 py-2 flex items-center gap-4'>
                    <SpinnerButton
                        size='tiny'
                        onClick={() => createTag(query)}
                        fetching={fetching}
                        fid={FID_CREATE}
                    >
                        {t('create-button')}
                    </SpinnerButton>
                    <ClientTagDisplay tag={{ name: query, color: newTagColor }} />
                </div>
            </>)}
        </div>
    );
}

const MAX_DISPLAYED_TAGS = 5;
const FID_CREATE = 'create';

type EditTagMenuProps = Readonly<{
    client: ClientInfoFE;
    setClient: (client: ClientInfoFE) => void;
    tag: ClientTagFE;
    onClose: () => void;
}>;

function EditTagMenu({ client, setClient, tag, onClose }: EditTagMenuProps) {
    const { t } = useTranslation('components', { keyPrefix: 'clientTags' });
    const { t: tc } = useTranslation('common', { keyPrefix: 'colors' });
    const { setClientTags } = useMaster();

    const [ formName, setFormName ] = useState(tag.name);
    const [ formHex, setFormHex ] = useState(tag.color);

    const [ fetching, setFetching ] = useState<string>();

    const updateTagMutation = trpc.$client.updateClientTag.useMutation();
    const deleteTagMutation = trpc.$client.deleteClientTag.useMutation();
    const updateTagsMutation = trpc.$client.updateClientsTags.useMutation();

    function removeTag() {
        setFetching(FID_REMOVE);
        updateTagsMutation.mutate({ clientId: client.id, tagId: tag.id, type: 'remove' }, {
            // TODO handle error
            onSuccess: response => {
                const newClient = ClientInfoFE.fromServer(response);
                setClient(newClient);
                onClose?.();
            },
            onSettled: () => {
                setFetching(undefined);
            },
        });
    }

    function updateTag() {
        setFetching(FID_UPDATE);
        updateTagMutation.mutate({ id: tag.id, name: formName.trim(), color: formHex }, {
            // TODO handle error
            onSuccess: response => {
                const updatedTag = ClientTagFE.fromServer(response);
                setClientTags(oldTags => sortClientTags(oldTags.map(oldTag => oldTag.id === tag.id ? updatedTag : oldTag)));
                onClose?.();
            },
            onSettled: () => {
                setFetching(undefined);
            },
        });
    }

    function deleteTag() {
        setFetching(FID_DELETE);
        deleteTagMutation.mutate({ id: tag.id }, {
            // TODO handle error
            onSuccess: () => {
                setClientTags(oldTags => oldTags.filter(oldTag => oldTag.id !== tag.id));
                onClose?.();
            },
            onSettled: () => {
                setFetching(undefined);
            },
        });
    }

    const isChange = getChange(tag, formName, formHex);

    return (
        <div>
            <div className='px-4 py-2'>
                <SpinnerButton
                    variant='outline'
                    size='tiny'
                    onClick={removeTag}
                    fetching={fetching}
                    fid={FID_REMOVE}
                >
                    <CloseIcon size={18} className='mr-2' />{t('remove-button')}
                </SpinnerButton>
            </div>
            <div className='fl-divider-light' />
            <div className='px-4 py-2'>
                <Form.Input
                    label={t('name-label')}
                    value={formName}
                    onChange={e => setFormName(e.target.value)}
                    placeholder={t('name-placeholder')}
                    autoFocus
                />
            </div>
            <div className='fl-divider-light' />
            <div className='flex flex-col'>
                <Form.Label className='px-4 pt-2 pb-1 m-0'>{t('color-label')}</Form.Label>
                {Object.entries(PREDEFINED_COLORS).map(([ color, hex ]) => (
                    <Button key={color} className='fl-dropdown-button gap-2' onClick={() => setFormHex(hex)}>
                        <div style={{ width: '16px', height: '16px', backgroundColor: `#${hex}` }} className='rounded-md' />
                        <span className='whitespace-nowrap'>{tc(color)}</span>
                        <div className='grow' />
                        {formHex === hex && (
                            <CheckIcon />
                        )}
                    </Button>
                ))}
            </div>
            <div className='fl-divider-light' />
            <div className='px-4 py-2 flex flex-col gap-2'>
                {isChange && (
                    <SpinnerButton
                        variant='primary'
                        size='tiny'
                        onClick={updateTag}
                        fetching={fetching}
                        fid={FID_UPDATE}
                    >
                        {t('update-button')}
                    </SpinnerButton>
                )}
                <SpinnerButton
                    variant='outline-danger'
                    size='tiny'
                    onClick={deleteTag}
                    fetching={fetching}
                    fid={FID_DELETE}
                >
                    <Trash2Icon className='mr-2' />{t('delete-button')}
                </SpinnerButton>
            </div>
        </div>
    );
}

const FID_REMOVE = 'remove';
const FID_DELETE = 'delete';
const FID_UPDATE = 'update';

function getChange(tag: ClientTagFE, name?: string, hex?: string): boolean {
    if (!name || !hex)
        return false;

    return tag.name !== name || tag.color !== hex;
}

const PREDEFINED_COLORS = {
    'purple': 'd5bce8',
    'blue': 'b6f1fe',
    'cyan': '88f8f8',
    'green': 'a9f9cd',
    'yellow': 'f9face',
    'orange': 'fac19d',
    'red': 'f88888',
    'pink': 'f7bfee',
} as const;

function findNextColor(tags: ClientTagFE[]): string {
    const usedColors = tags.map(tag => tag.color);
    const allColors = Object.values(PREDEFINED_COLORS);
    const availableColors = allColors.filter(color => !usedColors.includes(color));
    return availableColors[0] ?? allColors[0];
}

import { z } from 'zod';
import { zId } from ':utils/id';
import { zFileOutput, zFileUpsert } from './file';
import { zLocationOutput } from './location';
import { zRequiredString, zOptionalString } from ':utils/common';
import { getTaxRate, moneyFromServer, type Money, type TaxRateFE } from ':utils/money';

export enum ProductType {
    Session = 'session',
    Bundle = 'bundle',
    Digital = 'digital',
    Lead = 'lead',
    Membership = 'membership',
    Link = 'link',
    Custom = 'custom',
}

/**
 * Free product (price is undefined) is not the same as product with price 0. If it's free, it's like the product was a gift - it isn't sent to stripe, it doesn't show up in the invoice, etc.
 * There should be either both price and currency, or neither of them.
 */
export type ProductPricing = z.infer<typeof zProductPricing>;
const zProductPricing = z.object({
    currency: zId,
    /** In cents. */
    price: z.number(),
    /**
     * This is displayed above the price as the "original price before discount".
     * In cents.
     */
    originalPrice: z.number().optional(),
    vat: zId,
});

export type ProductPricingFE = {
    price: Money;
    originalPrice: Money | undefined;
    vat: TaxRateFE;
};

export function pricingFromServer(pricing: ProductPricing): ProductPricingFE;

export function pricingFromServer(pricing: ProductPricing | undefined): ProductPricingFE | undefined;

export function pricingFromServer(pricing: ProductPricing | undefined): ProductPricingFE | undefined {
    if (!pricing)
        return undefined;

    return {
        price: moneyFromServer(pricing.price, pricing.currency),
        originalPrice: pricing.originalPrice ? moneyFromServer(pricing.originalPrice, pricing.currency) : undefined,
        vat: getTaxRate(pricing.vat),
    };
}

export type BaseProductOutput = z.infer<typeof zBaseProductOutput>;
const zBaseProductOutput = z.object({
    id: zId,
    index: z.number(),
    slug: z.string(),
    isPublic: z.boolean(),
    title: z.string(),
    thumbnail: zFileOutput.optional(),
    description: z.string().optional(),
    limit: z.number().optional(),
    successMessage: z.string().optional(),
    buttonText: z.string(),
});

export type SessionProductOutput = z.infer<typeof zSessionProductOutput>;
const zSessionProductOutput = zBaseProductOutput.extend({
    type: z.literal(ProductType.Session),
    pricing: zProductPricing.optional(),
    /** in seconds (TODO implemented wrong somewhere?) */
    sessionsDuration: z.number(),
    location: zLocationOutput.optional(),
    schedulingUrl: z.string().optional(),
});

export type BundleProductOutput = z.infer<typeof zBundleProductOutput>;
const zBundleProductOutput = zSessionProductOutput.extend({
    type: z.literal(ProductType.Bundle),
    sessionsCount: z.number(),
});

export type LeadProductOutput = z.infer<typeof zLeadProductOutput>;
const zLeadProductOutput = zBaseProductOutput.extend({
    type: z.literal(ProductType.Lead),
    pricing: z.undefined(),
});

export type DigitalProductOutput = z.infer<typeof zDigitalProductOutput>;
const zDigitalProductOutput = zBaseProductOutput.extend({
    type: z.literal(ProductType.Digital),
    pricing: zProductPricing.optional(),
    url: z.string(),
});

export type CustomProductOutput = z.infer<typeof zCustomProductOutput>;
const zCustomProductOutput = zBaseProductOutput.extend({
    type: z.literal(ProductType.Custom),
    pricing: zProductPricing.optional(),
});

export type ProductOutput = z.infer<typeof zProductOutput>;
export const zProductOutput = z.discriminatedUnion('type', [
    zSessionProductOutput,
    zBundleProductOutput,
    zLeadProductOutput,
    zDigitalProductOutput,
    zCustomProductOutput,
]);

export const PRODUCT_DESCRIPTION_MAX_LENGTH = 1000;
export const PRODUCT_URL_MAX_LENGTH = 1000;

export type BaseProductUpsert = z.infer<typeof zBaseProductUpsert>;
export const zBaseProductUpsert = z.object({
    slug: zRequiredString(),
    isPublic: z.boolean(),
    title: zRequiredString(),
    description: zOptionalString(PRODUCT_DESCRIPTION_MAX_LENGTH),
    thumbnail: zFileUpsert.optional(),
    limit: z.number().optional(),
    successMessage: zOptionalString(PRODUCT_DESCRIPTION_MAX_LENGTH),
    buttonText: zRequiredString(),
});

export type SessionProductUpsert = z.infer<typeof zSessionProductUpsert>;
const zSessionProductUpsert = zBaseProductUpsert.extend({
    type: z.literal(ProductType.Session),
    pricing: zProductPricing.optional(),
    /** in seconds (TODO implemented wrong somewhere?) */
    sessionsDuration: z.number(),
    locationId: zId.optional(),
    schedulingUrl: zOptionalString(PRODUCT_URL_MAX_LENGTH),
});

export type BundleProductUpsert = z.infer<typeof zBundleProductUpsert>;
const zBundleProductUpsert = zSessionProductUpsert.extend({
    type: z.literal(ProductType.Bundle),
    sessionsCount: z.number(),
});

export type LeadProductUpsert = z.infer<typeof zLeadProductUpsert>;
const zLeadProductUpsert = zBaseProductUpsert.extend({
    type: z.literal(ProductType.Lead),
    pricing: z.undefined(),
});

export type DigitalProductUpsert = z.infer<typeof zDigitalProductUpsert>;
const zDigitalProductUpsert = zBaseProductUpsert.extend({
    type: z.literal(ProductType.Digital),
    pricing: zProductPricing.optional(),
    url: zRequiredString(PRODUCT_URL_MAX_LENGTH),
});

export type CustomProductUpsert = z.infer<typeof zCustomProductUpsert>;
const zCustomProductUpsert = zBaseProductUpsert.extend({
    type: z.literal(ProductType.Custom),
    pricing: zProductPricing.optional(),
});

export type ProductUpsert = z.infer<typeof zProductUpsert>;
export const zProductUpsert = z.discriminatedUnion('type', [
    zSessionProductUpsert,
    zBundleProductUpsert,
    zLeadProductUpsert,
    zDigitalProductUpsert,
    zCustomProductUpsert,
]);

export type ProductOrderEdit = z.infer<typeof zProductOrderEdit>;
export const zProductOrderEdit = z.map(
    /** Id of the product. */
    zId,
    /** New index of the product. */
    z.number(),
);

import { routesFE } from ':utils/routes';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Tabs } from ':components/shadcn';
import { Box2Icon, CalendarCheck2Icon, FileSendIcon } from ':components/icons/basic';
import { NewProductOrderTab } from './NewProductOrderTab';
import { NewEventOrderTab } from './NewEventOrderTab';
import { NewCustomOrderTab } from './NewCustomOrderTab';

export function DirectSalePage() {
    const { t } = useTranslation('pages', { keyPrefix: 'directSale' });

    const { key } = useParams();
    const navigate = useNavigate();
    const handleSelect = useCallback((newKey: string | null) => newKey && navigate(routesFE.directSale.resolve({ key: newKey })), [ navigate ]);

    return (
        <Tabs.Root value={key} onValueChange={handleSelect} className='flex flex-col h-full'>
            <div className='bg-white border-b border-secondary-50'>
                <div className='px-4 flex items-center justify-between'>
                    <Tabs.List>
                        <Tabs.Trigger value='product'><Box2Icon size='xs' />{t('product-tab')}</Tabs.Trigger>
                        <Tabs.Trigger value='event'><CalendarCheck2Icon size='xs' />{t('event-tab')}</Tabs.Trigger>
                        <Tabs.Trigger value='custom'><FileSendIcon size='xs' />{t('custom-tab')}</Tabs.Trigger>
                    </Tabs.List>
                </div>
            </div>
            <Tabs.Content value='product' className='fl-main-scroller h-full'>
                <NewProductOrderTab />
            </Tabs.Content>
            <Tabs.Content value='event' className='fl-main-scroller h-full'>
                <NewEventOrderTab />
            </Tabs.Content>
            <Tabs.Content value='custom' className='fl-main-scroller h-full'>
                <NewCustomOrderTab />
            </Tabs.Content>
        </Tabs.Root>
    );
}

import { Content, Topbar } from ':frontend/components/Layout';
import { Button } from ':components/shadcn';
import { ProductPublicDisplay } from ':components/store/product/ProductCard';
import { trpc } from ':frontend/context/TrpcProvider';
import { CreateProductCard } from ':frontend/components/product/CreateProductCard';
import { Link } from 'react-router-dom';
import { Pen2Icon } from ':components/icons/basic';
import { routesFE } from ':utils/routes';
import type { Id } from ':utils/id';

export function Products() {
    const productQuery = trpc.product.getProducts.useQuery();

    return (<>
        <Topbar>

        </Topbar>
        <Content>
            <div className='pb-8'>
                <CreateProductCard />
                {productQuery.data && (
                    <div className='flex flex-col gap-2'>
                        {productQuery.data.map(product => (
                            <ProductPublicDisplay
                                key={product.id}
                                product={product}
                                scheduleComponent={<Button>AAAA</Button>}
                                editComponent={<ProductEditLink id={product.id} />}
                            />
                        ))}
                    </div>
                )}
            </div>
        </Content>
    </>);
}

export function ProductEditLink({ id }: Readonly<{id: Id}>) {
    return (
        <Button variant='outline' size='tiny' className='px-0 w-[22px] h-[22px]'>
            <Link to={routesFE.products.detail.resolve({ id })}>
                <Pen2Icon size={12} className='!w-3 !h-3'/>
            </Link>
        </Button>
    );
}

import { useTranslation } from 'react-i18next';
import { Button, Form } from ':components/shadcn';
import { type Control, type FieldErrors } from 'react-hook-form';
import { RHFErrorMessage } from ':frontend/components/forms/ErrorMessage';
import { type UseTransformReturn } from ':frontend/utils/forms';
import { ControlledVatSelect } from '../forms/VatSelect';
import clsx from 'clsx';
import { type CustomOrderFormData } from './CustomOrderForm';
import { Trash2Icon } from ':components/icons/basic';

type CustomOrderFormItemFieldsProps = Readonly<{
    index: number;
    transform: UseTransformReturn<CustomOrderFormData>;
    control: Control<CustomOrderFormData>;
    errors: FieldErrors<CustomOrderFormData>;
    isRemovable?: boolean;
    onRemove: () => void;
}>;

export default function CustomOrderFormItemFields({ index, transform, control, errors, isRemovable, onRemove }: CustomOrderFormItemFieldsProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'create-custom-order.items' });
    const isFirstRow = index === 0;

    return (
        <div>
            <div className={clsx('flex gap-4', isFirstRow ? 'fl-custom-order-first-row' : 'fl-custom-order-not-first-row')}>
                <div className='col-1'>
                    <Form.Input
                        label={t('quantity-label')}
                        type='number'
                        // isn't price, but has the same rules
                        {...transform.registerPrice(`items.${index}.quantity`, { required: t('quantity-required') })}
                    />
                </div>
                <div className='grow'>
                    <Form.Textarea
                        label={t('name-label')}
                        placeholder={t('name-placeholder')}
                        {...transform.register(`items.${index}.label`, { required: t('name-required') })}
                        minRows={1}
                        style={{ paddingTop: 10, paddingBottom: 10 }}

                    />
                </div>
                <div className='col-2'>
                    <Form.Input
                        label={t('price-label')}
                        type='number'
                        {...transform.registerPrice(`items.${index}.price`, { required: t('price-required') })}
                    />
                </div>
                <div className='col-1'>
                    <Form.Label>{t('vat-label')}</Form.Label>
                    <ControlledVatSelect
                        control={control}
                        name={`items.${index}.vatId`}
                    />
                </div>
                {isRemovable && (
                    <div className='fl-remove-button'>
                        <Button variant='transparent' size='exact' onClick={onRemove}>
                            <Trash2Icon />
                        </Button>
                    </div>
                )}
            </div>
            <RHFErrorMessage errors={errors} name={`items.${index}.quantity`} />
            <RHFErrorMessage errors={errors} name={`items.${index}.label`} />
            <RHFErrorMessage errors={errors} name={`items.${index}.price`} />
        </div>
    );
}

import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Modal, Select, type SingleValue } from ':components/shadcn';
import { useMaster } from ':frontend/context/UserProvider';
import BankingForm, { arrayValuesToStrings, inputToForm } from ':frontend/components/settings/BankingForm';
import { BankAccountFE } from ':frontend/types/BankAccount';
import { getAllCurrencies, getCurrency } from ':utils/money';
import { processInputData } from ':utils/entity/bankAccountData';
import { SpinnerButton } from '../common';
import useNotifications from ':frontend/context/NotificationProvider';
import { createTranslatedErrorAlert, createTranslatedSuccessAlert } from '../notifications';
import { useToggle } from ':frontend/hooks';
import { useCached } from ':components/hooks';
import { type Id } from ':utils/id';
import type { BankAccountUpsert } from ':utils/entity/money';
import { trpc } from ':frontend/context/TrpcProvider';

type NoBankAccountModalProps = Readonly<{
    show: boolean;
    onClose: () => void;
    /** Only the currencies that are currently unsupported. */
    currencyIds: Id[];
}>;

export function NoBankAccountModal({ show, onClose, currencyIds }: NoBankAccountModalProps) {
    const { t } = useTranslation('components', { keyPrefix: 'noBankAccountModal' });
    const { bankAccounts } = useMaster();
    const [ isFetching, setIsFetching ] = useState(false);
    const canBeEditing = bankAccounts.length > 0;
    const [ isEditing, setIsEditing ] = useToggle(canBeEditing);

    useEffect(() => {
        if (show && !canBeEditing)
            setIsEditing.false();
    }, [ show, canBeEditing, setIsEditing ]);

    const cachedCurrencyId = useCached(currencyIds[0]);
    if (!cachedCurrencyId)
        return null;

    const currency = getCurrency(cachedCurrencyId);

    return (
        <Modal.Root open={show} onOpenChange={open => !open && onClose()}>
            <Modal.Content className='fl-modal-medium relative' closeButton={t('cancel-button')}>
                {/* Header content */}
                <div className='flex flex-col items-center'>
                    <img
                        src='/static/images/bank-accounts/credit-card.svg'
                        className='select-none drag-none'
                        alt='Credit card illustration'
                    />
                    <Modal.Header>
                        <Modal.Title className='text-center'>
                            {t(isEditing ? 'editing-title' : 'creating-title')}
                        </Modal.Title>
                        <p className='text-center leading-6 mb-4'>
                            {isEditing
                                ? t('editing-description', { currency: currency.label })
                                : t('creating-description')
                            }
                        </p>
                    </Modal.Header>

                    {/* Main content */}
                    <div className='mt-4 w-full'>
                        {isEditing ? (
                            <EditAccount
                                onClose={onClose}
                                currencyIds={currencyIds}
                                isFetching={isFetching}
                                setIsFetching={setIsFetching}
                            />
                        ) : (
                            <CreateAccount
                                onClose={onClose}
                                currencyIds={currencyIds}
                                isFetching={isFetching}
                                setIsFetching={setIsFetching}
                            />
                        )}
                    </div>

                    {/* Footer */}
                    {(isEditing || canBeEditing) && (
                        <Modal.Footer className='flex justify-end pt-2'>
                            <Button
                                variant='ghost'
                                className='no-underline'
                                onClick={setIsEditing.toggle}
                                disabled={isFetching}
                            >
                                {t(isEditing ? 'start-creating-button' : 'start-editing-button')}
                            </Button>
                        </Modal.Footer>
                    )}
                </div>
            </Modal.Content>
        </Modal.Root>
    );
}

type EditAccountProps = Readonly<{
    onClose: () => void;
    currencyIds: Id[];
    isFetching: boolean;
    setIsFetching: (isFetching: boolean) => void;
}>;

function EditAccount({ onClose, currencyIds, isFetching, setIsFetching }: EditAccountProps) {
    const { t } = useTranslation('components', { keyPrefix: 'noBankAccountModal' });
    const { addAlert } = useNotifications();
    const { bankAccounts, setBankAccounts } = useMaster();
    const [ selectedBankAccount, setSelectedBankAccount ] = useState<BankAccountFE>();

    const updateBankAccountMutation = trpc.money.updateBankAccount.useMutation();

    function updateAccount() {
        if (isFetching || !selectedBankAccount)
            return;

        const form = inputToForm(selectedBankAccount);
        // TODO check if all currencyIds are enabled
        form.currencyIds = enabledCurrencies;
        const editAccountData = processInputData(form.country, arrayValuesToStrings(form.numberParts), form.iban, form.swift);
        const edit = {
            raw: editAccountData,
            currencies: form.currencyIds,
            id: selectedBankAccount.id,
        };

        setIsFetching(true);
        updateBankAccountMutation.mutate(edit, {
            onError: () => {
                addAlert(createTranslatedErrorAlert());
            },
            onSuccess: response => {
                const updatedAccount = BankAccountFE.fromServer(response);
                setBankAccounts((oldAccounts: BankAccountFE[]) => oldAccounts.map(a => a.id !== updatedAccount.id ? a : updatedAccount));

                addAlert(createTranslatedSuccessAlert('common:bankAccount.edited-alert'));
                // TODO don't close if there are still unsupported currencies.
                // TODO base the modal on state instead of on callbacks.
            },
            onSettled: () => {
                setIsFetching(false);
                onClose();
            },
        });
    }

    const bankAccountOptions = useMemo(() => {
        return bankAccounts.map(bankAccount => bankAccountToOption(bankAccount));
    }, [ bankAccounts ]);

    const availableCurrencies = useMemo(() => {
        if (!selectedBankAccount)
            return [];

        const alreadyTaken = bankAccounts.filter(account => account.id !== selectedBankAccount.id).flatMap(account => account.currencies);
        return getAllCurrencies().filter(currency => !alreadyTaken.find(c => c.id === currency.id));
    }, [ selectedBankAccount, bankAccounts ]);

    const [ enabledCurrencies, setEnabledCurrencies ] = useState<Id[]>([]);

    // Ww want to disable saving unless at least one new currency is selected.
    const newCurrencySelected = useMemo(() => {
        return currencyIds.some(needed => enabledCurrencies.some(enabled => enabled === needed));
    }, [ currencyIds, enabledCurrencies ]);

    const innerOnChange = useCallback((option: SingleValue<ReturnType<typeof bankAccountToOption>>) => {
        if (option) {
            setSelectedBankAccount(option.value);
            setEnabledCurrencies(option.value.currencies.map(currency => currency.id));
        }
        else {
            setSelectedBankAccount(undefined);
            setEnabledCurrencies([]);
        }
    }, []);

    return (<>
        <Select
            value={selectedBankAccount ? bankAccountToOption(selectedBankAccount) : null}
            options={bankAccountOptions}
            onChange={innerOnChange}
            className='mb-4'
            placeholder={t('account-select-placeholder')}
        />
        {selectedBankAccount && (
            <div className='flex flex-col gap-2'>
                {getAllCurrencies().map(currency => (
                    <div className='flex' key={currency.id.toString()}>
                        <Form.Switch
                            label={currency.label}
                            disabled={!availableCurrencies.some(availableCurrency => availableCurrency.id === currency.id)}
                            checked={enabledCurrencies.some(c => c === currency.id)}
                            onCheckedChange={value => {
                                if (value)
                                    setEnabledCurrencies([ ...enabledCurrencies, currency.id ]);
                                else
                                    setEnabledCurrencies(enabledCurrencies.filter(c => c !== currency.id));
                            }}
                        />
                    </div>
                ))}
            </div>
        )}
        <SpinnerButton
            isFetching={isFetching}
            onClick={updateAccount}
            className='w-full mt-8'
            disabled={!newCurrencySelected}
        >
            {t('submit-edit-button')}
        </SpinnerButton>
    </>);
}

function bankAccountToOption(bankAccount: BankAccountFE) {
    return {
        value: bankAccount,
        label: bankAccountToLabel(bankAccount),
    };
}

function bankAccountToLabel(bankAccount: BankAccountFE) {
    return (
        <div className='flex justify-between items-center'>
            <div>{bankAccount.label}</div>
            <div className='flex gap-1'>
                {bankAccount.currencies.map(currency => (
                    <div key={currency.id} className='border border-primary rounded px-1' style={{ fontSize: 12, lineHeight: '18px' }}>
                        {currency.label}
                    </div>
                ))}
            </div>
        </div>
    );
}

type CreateAccountProps = Readonly<{
    onClose: () => void;
    currencyIds: Id[];
    isFetching: boolean;
    setIsFetching: (isFetching: boolean) => void;
}>;

function CreateAccount({ onClose, currencyIds, isFetching, setIsFetching }: CreateAccountProps) {
    const { addAlert } = useNotifications();
    const { setBankAccounts } = useMaster();

    const createBankAccountMutation = trpc.money.createBankAccount.useMutation();

    function createAccount(init: BankAccountUpsert) {
        setIsFetching(true);
        createBankAccountMutation.mutate(init, {
            onError: () => {
                addAlert(createTranslatedErrorAlert());
            },
            onSuccess: response => {
                const newAccount = BankAccountFE.fromServer(response);
                setBankAccounts((oldAccounts: BankAccountFE[]) => [ ...oldAccounts, newAccount ]);

                addAlert(createTranslatedSuccessAlert('common:bankAccount.created-alert'));
            },
            onSettled: () => {
                setIsFetching(false);
                onClose();
            },
        });
    }

    return (
        <BankingForm
            defaultCurrencyIds={currencyIds}
            onSubmit={createAccount}
            isFetching={isFetching}
        />
    );
}

import { useCallback, useEffect } from 'react';
import { GeneralSettings } from './GeneralSettings';
import { AdvancedSettings } from './AdvancedSettings';
import { InvoicingProfileSettings } from './InvoicingProfileSettings';
import { PaymentsSettings } from './PaymentsSettings';
import { InvoicingProfilesSettings } from './InvoicingProfilesSettings';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { routesFE } from ':utils/routes';
import { toMaster, useUser } from ':frontend/context/UserProvider';
import { OnboardingFE } from ':frontend/types/Onboarding';
import { OnboardingFlag } from ':utils/entity/user';
import { trpc } from ':frontend/context/TrpcProvider';
import { ModulesSettings } from './ModulesSettings';
import { Tabs } from ':components/shadcn';
import { WindowPlusIcon } from ':components/icons/basic';

export default function Settings() {
    const { t } = useTranslation('pages', { keyPrefix: 'settings' });
    const { key } = useParams();
    const navigate = useNavigate();

    const userContext = useUser();
    const { onboarding, setOnboarding } = userContext;
    const masterContext = toMaster(userContext);

    const handleSelect = useCallback((newKey: string | null) => newKey && navigate(routesFE.settings.resolve({ key: newKey })), [ navigate ]);

    const updateOnboardingMutation = trpc.user.updateOnboarding.useMutation();

    const syncOnboarding = useCallback(() => {
        updateOnboardingMutation.mutate({ [OnboardingFlag.SettingsOpened]: true }, {
            onSuccess: response => {
                setOnboarding(OnboardingFE.fromServer(response));
            },
        });
    }, []);

    useEffect(() => {
        if (onboarding.flags.settingsOpened)
            return;

        syncOnboarding();
    }, []);

    return (
        <Tabs.Root value={key} onValueChange={handleSelect} className='flex flex-col h-full'>
            <div className='border-b bg-secondary-100'>
                <div className='max-w-[800px] w-full mx-auto flex items-center justify-between gap-8'>
                    <Tabs.List>
                        <Tabs.Trigger value='general'>{t('general-tab')}</Tabs.Trigger>
                        {masterContext && (<>
                            {masterContext.teamSettings.isInvoicingEnabled &&
                                <Tabs.Trigger value='invoicing'>{t('invoicing-tab')}</Tabs.Trigger>
                            }
                            <Tabs.Trigger value='payments'>{t('payments-tab')}</Tabs.Trigger>
                        </>)}
                        <Tabs.Trigger value='advanced'>{t('advanced-tab')}</Tabs.Trigger>
                        {masterContext && (
                            <Tabs.Trigger value='modules' aria-label={t('modules-tab')}><WindowPlusIcon /></Tabs.Trigger>
                        )}
                    </Tabs.List>
                </div>
            </div>
            <div className='fl-main-scroller'>
                <div className='max-w-[800px] w-full mx-auto'>
                    <Tabs.Content value='general'>
                        <GeneralSettings />
                    </Tabs.Content>
                    {masterContext && (masterContext.profiles.length === 1 ? (
                        <Tabs.Content value='invoicing'>
                            <InvoicingProfileSettings />
                        </Tabs.Content>
                    ) : (
                        <Tabs.Content value='invoicing'>
                            <InvoicingProfilesSettings />
                        </Tabs.Content>
                    ))}
                    {masterContext && (
                        <Tabs.Content value='payments'>
                            <PaymentsSettings />
                        </Tabs.Content>
                    )}
                    <Tabs.Content value='advanced'>
                        <AdvancedSettings />
                    </Tabs.Content>
                    {masterContext && (
                        <Tabs.Content value='modules'>
                            <ModulesSettings />
                        </Tabs.Content>
                    )}
                </div>
            </div>
        </Tabs.Root>
    );
}

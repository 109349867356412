import { PersonForm } from ':frontend/components/settings/PersonForm';
import { PreferencesForm } from ':frontend/components/settings/PreferencesForm';
import { PasswordForm } from ':frontend/components/settings/PasswordForm';
import { useUser } from ':frontend/context/UserProvider';
import { useTranslation } from 'react-i18next';
import { Card } from ':components/shadcn';
import PageLink from ':frontend/components/settings/PageLink';
import { CustomDomain } from ':frontend/components/settings/CustomDomain';
import { TermsOfService } from ':frontend/components/settings/TermsOfService';
import { Globe2Icon, UserListIcon, Sliders3Icon, LockIcon } from ':components/icons/basic';

export function GeneralSettings() {
    const { t } = useTranslation('pages', { keyPrefix: 'settings.general' });
    const { appUser } = useUser();

    return (
        <div className='py-12 space-y-8'>
            <Card>
                <Card.Title className='flex items-center gap-2'>
                    <Globe2Icon className='h-5 text-warning-500' />
                    <span>{t('page-link-section-title')}</span>
                </Card.Title>
                <Card.Divider />
                <PageLink />
            </Card>

            <Card>
                <CustomDomain />
            </Card>

            <Card>
                <TermsOfService />
            </Card>

            <Card>
                <Card.Title className='flex items-center gap-2'>
                    <UserListIcon className='h-5 text-warning-500' />
                    <span>{t('personal-details-section-title')}</span>
                </Card.Title>
                <Card.Divider />
                <PersonForm />
            </Card>

            <Card>
                <Card.Title className='flex items-center gap-2'>
                    <Sliders3Icon className='h-5 text-warning-500' />
                    <span>{t('preferences-section-title')}</span>
                </Card.Title>
                <Card.Divider />
                <PreferencesForm />
            </Card>

            {!appUser.google.isAuth && (
                <Card>
                    <Card.Title className='flex items-center gap-2'>
                        <LockIcon className='h-5 text-warning-500' />
                        <span>{t('password-section-title')}</span>
                    </Card.Title>
                    <Card.Divider />
                    <PasswordForm />
                </Card>
            )}
        </div>
    );
}

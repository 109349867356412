import { useTranslation } from 'react-i18next';

export function NewEventOrderTab() {
    const { t } = useTranslation('pages', { keyPrefix: 'directSale' });

    return (
        <div className='fl-main-scroller'>
            <div className='max-w-[910px] w-full mx-auto py-12 flex flex-col items-stretch gap-6'>
                TODO event
            </div>
        </div>
    );
}

import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Tooltip } from ':components/shadcn';
import { type ClientInfoFE } from ':frontend/types/Client';
import { type Control, Controller, type FieldPath, type FieldValues, useFieldArray, useForm } from 'react-hook-form';
import CustomOrderFormItemFields from ':frontend/components/orders/CustomOrderFormItemFields';
import { ErrorMessage, RHFErrorMessage } from ':frontend/components/forms/ErrorMessage';
import { type MasterContext, useMaster } from ':frontend/context/UserProvider';
import { type CurrencyFE, type Money, type TaxRateFE, addVat, addVatAsNumber, getCurrency, getTaxRate, isUnderMinimalAmount } from ':utils/money';
import { type PlainObject, useTransform } from ':frontend/utils/forms';
import { type OrderFE, type FECustomItemInit } from ':frontend/types/orders/Order';
import { ControlledCurrencySelect } from ':frontend/components/forms/CurrencySelect';
import { type Id } from ':utils/id';
import { PlusIcon } from ':components/icons/old';
import { DateTime } from 'luxon';
import DateTimeDisplay from '../common/DateTimeDisplay';
import { floatToPercent, percentToFloat, toNumber, transformToPrice } from ':utils/math';
import { ControlledParticipantSelect } from '../client/ContinuousParticipantSelect';
import { type Participant, getClientIdentifier } from ':frontend/types/EventParticipant';
import { Table } from '../common';
import { MoneyDisplay, MoneyAmountDisplay } from ':components/custom';
import clsx from 'clsx';
import { CheckoutType, type Discount, type CustomCheckoutInput } from './checkout/useCheckout';
import { getI18nLocale } from ':utils/i18n';
import { Trash2Icon } from ':components/icons/basic';

export type PreselectedData = {
    clientId: Id;
} | {
    order: OrderFE;
};

type CustomOrderFormProps = Readonly<{
    clients: ClientInfoFE[];
    onSubmit: (output: CustomCheckoutInput) => void;
    onDiscard: () => void;
    onChange?: (isDirty: boolean) => void;
    preselected?: PreselectedData;
}>;

export default function CustomOrderForm({ clients, onSubmit, onDiscard, onChange, preselected }: CustomOrderFormProps) {
    const { t, i18n } = useTranslation('pages', { keyPrefix: 'create-custom-order' });
    const { t: tf } = useTranslation('common', { keyPrefix: 'form' });

    const userContext = useMaster();
    const { profiles } = userContext;

    const { register, handleSubmit, control, watch, setValue, formState: { errors, isDirty, isSubmitted }, getFieldState } = useForm<CustomOrderFormData>({
        defaultValues: computeDefaultValues(userContext, clients, preselected),
    });
    const transform = useTransform(register, setValue);

    const { fields, append, remove } = useFieldArray<CustomOrderFormData>({
        control,
        name: 'items',
        rules: {
            minLength: {
                value: 1,
                message: t('no-item-error'),
            },
        },
    });
    const [ items, currencyId, discount, rawParticipant, dueDays ] = watch([ 'items', 'currencyId', 'discount', 'participant', 'dueDays' ]);
    // This is ok, the participant is typed correctly.
    const participant = rawParticipant as Participant | undefined;
    const currency = getCurrency(currencyId);
    const priceSummary = computePriceSummary(items, currency, discount);
    const dueDate = DateTime.now().plus({ days: toNumber(dueDays) });

    useEffect(() => onChange?.(isDirty), [ isDirty ]);

    const isTooCheap = isUnderMinimalAmount(priceSummary.totalPrice);

    function handleValidSubmit(data: CustomOrderFormData) {
        if (isTooCheap)
            return;

        onSubmit(formToOutput(data));
    }

    useEffect(() => {
        if (!participant || !('info' in participant))
            return;

        const client = participant.info;

        if (!getFieldState('dueDays').isDirty) {
            const dueDays = client.dueDays
                ?? profiles.find(profile => profile.id === client.invoicingProfileId)?.dueDays
                ?? '';

            setValue('dueDays', dueDays);
        }
    }, [ participant ]);

    return (
        <Form.Root onSubmit={handleSubmit(handleValidSubmit)}>
            <div className='py-4 flex items-center justify-end'>
                <Button variant='ghost-danger' className='fl-btn_square' onClick={onDiscard}>
                    <Trash2Icon />
                    {t('discard-button')}
                </Button>
            </div>

            <div className='mt-4 flex'>
                <div className='col-4'>
                    <Form.Label>{t('client-label')}</Form.Label>
                    <ControlledParticipantSelect
                        clients={clients}
                        control={control}
                        name='participant'
                        rules={{ required: tf('client-required') }}
                    />
                    <RHFErrorMessage errors={errors} name='participant' />
                </div>
                <div className='col-4' />
                <div className='col-4'>
                    <Form.Label>{t('currency-label')}</Form.Label>
                    <ControlledCurrencySelect
                        control={control}
                        name='currencyId'
                    />
                </div>
            </div>
            <div className='mt-4 flex'>
                <div className='relative col-4'>
                    <Form.Input
                        label={t('due-days-label')}
                        {...transform.registerPositiveInteger('dueDays', { required: tf('due-days-required') })}
                    />
                    <span className='absolute right-2'><DateTimeDisplay dateTime={dueDate} date /></span>
                    <RHFErrorMessage errors={errors} name='dueDays' />
                </div>
            </div>
            <h2 className='mt-8'>{t('items-section-title')}</h2>
            <div className='flex flex-col gap-2 mt-4'>
                {fields.map((field, index) => (
                    <CustomOrderFormItemFields
                        key={field.id}
                        index={index}
                        transform={transform}
                        control={control}
                        errors={errors}
                        onRemove={() => remove(index)}
                        isRemovable={fields.length > 1}
                    />
                ))}
            </div>
            <div className='mt-4 flex'>
                <div
                    className='select-none cursor-pointer text-primary font-bold flex items-center gap-2'
                    onClick={() => append(createNewCustomItem(userContext))}
                >
                    <PlusIcon size={18} />
                    <span>{t('add-item-button')}</span>
                </div>
            </div>
            <div className='mt-4'>
                <ControlledDiscountInput
                    control={control}
                    name='discount'
                    discountAmount={priceSummary.discountPrice?.amount}
                    isShorter={items.length > 0}
                    isMultipleVats={priceSummary.isMultipleVats}
                />
            </div>
            <div className='flex mt-8'>
                <div className='col-8' />
                <div className='col-4 py-2'>
                    <PriceSummaryDisplay summary={priceSummary} />
                    {isTooCheap && isSubmitted && (
                        <ErrorMessage message={t('minimal-charge-error', {
                            money: priceSummary.totalPrice.currency.displayFull(priceSummary.totalPrice.currency.minimalAmount, getI18nLocale(i18n), true),
                        })} />
                    )}
                </div>
            </div>
            <div className='flex mt-2 mb-12'>
                <div className='col-8' />
                <div className='col-4'>
                    <Button type='submit' className='w-full'>
                        {t('continue-button')}
                    </Button>
                </div>
            </div>
        </Form.Root>
    );
}

function computeDefaultValues(userContext: MasterContext, clients: ClientInfoFE[], preselected?: PreselectedData): CustomOrderFormData {
    if (!preselected || 'clientId' in preselected) {
        const clientId = preselected?.clientId;
        const client = clientId ? clients.find(client => client.id === clientId) : undefined;
        const currencyId = userContext.teamSettings.currency.id;
        const participant: Participant | undefined = client ? { info: client, identifier: getClientIdentifier(client) } : undefined;

        return {
            participant,
            dueDays: client?.dueDays ?? '',
            currencyId,
            discount: undefined,
            items: [ createNewCustomItem(userContext) ],
        };
    }

    // Duplicate the current order. We allow only custom items since the event items would require duplicating the events.
    const order = preselected.order;
    const items = order.getBasicItems().map(item => ({
        label: item.title,
        quantity: item.quantity,
        price: item.unitPrice.amount,
        vatId: item.vat.id,
    }));
    const currencyId = order.price.currency.id;
    const participant = { info: order.client, identifier: getClientIdentifier(order.client) };

    return {
        participant,
        dueDays: order.issueDate.diff(order.dueDate).days,
        currencyId,
        items,
    };
}

function formToOutput(data: CustomOrderFormData): CustomCheckoutInput {
    if (!data.participant)
        throw new Error('Client has to be defined.');

    const currency = getCurrency(data.currencyId);
    const items: FECustomItemInit[] = data.items.map(item => ({
        label: item.label,
        quantity: toNumber(item.quantity),
        price: { amount: toNumber(item.price), currency },
        vat: getTaxRate(item.vatId),
    }));

    return {
        type: CheckoutType.Custom,
        // For some reason, this is also ok.
        client: data.participant as Participant,
        dueDays: data.dueDays !== '' ? data.dueDays : undefined,
        currency,
        items,
        discount: formDiscountToDiscount(data.discount),
    };
}

export function formDiscountToDiscount(input: FormDiscount | undefined): Discount | undefined {
    if (!input)
        return undefined;

    const percents = transformToPrice(input?.amountInPercent);
    if (percents === '' || percents === 0)
        return undefined;

    return {
        amount: percentToFloat(percents),
        label: input.label,
    };
}

export type CustomOrderFormData = PlainObject<{
    participant?: Participant;
    currencyId: Id;
    items: CustomItemFormData[];
    dueDays: number | '';
    discount?: FormDiscount;
}>;

export type FormDiscount = {
    amountInPercent: number | '';
    label: string;
}

type CustomItemFormData = {
    label: string;
    quantity: number | '';
    price: number | '';
    vatId: Id;
};

function createNewCustomItem(userContext: MasterContext): CustomItemFormData {
    return {
        label: '',
        quantity: 1,
        price: '',
        vatId: userContext.teamSettings.taxRate.id,
    };
}

type PriceSummary = {
    totalPrice: Money;
    discountPrice?: Money;
    vatBreakdown: VatSummary[];
    isOnlyZeroVat: boolean;
    isMultipleVats: boolean;
};

type VatSummary = {
    vat: TaxRateFE;
    withoutVat: Money;
    withVat: Money;
    difference: Money;
};

export function computePriceSummary(items: CustomItemFormData[], currency: CurrencyFE, discount: FormDiscount | undefined): PriceSummary {
    const inputsByVat = new Map<TaxRateFE, number>();
    items.forEach(item => {
        const vat = getTaxRate(item.vatId);
        const value = toNumber(item.price) * toNumber(item.quantity);
        const current = inputsByVat.get(vat) ?? 0;
        inputsByVat.set(vat, current + value);
    });
    const inputVatPairs = [ ...inputsByVat.entries() ].map(([ vat, amount ]) => ({ vat, amount })).filter(pair => pair.amount !== 0);

    if (inputVatPairs.length === 0) {
        return {
            totalPrice: { amount: 0, currency },
            vatBreakdown: [],
            isOnlyZeroVat: true,
            isMultipleVats: false,
        };
    }

    const isMultipleVats = inputVatPairs.length > 1;
    const isOnlyZeroVat = !isMultipleVats && inputVatPairs[0].vat.isZero;
    if (isMultipleVats || !discount || discount.amountInPercent === '') {
        const totalAmountWithVat = inputVatPairs.reduce((ans, { vat, amount }) => ans + addVatAsNumber(amount, vat).withVat, 0);
        return {
            totalPrice: { amount: totalAmountWithVat, currency },
            vatBreakdown: computeVatBreakdown(inputVatPairs, currency),
            isOnlyZeroVat,
            isMultipleVats,
        };
    }

    const { vat, amount } = inputVatPairs[0];

    const discountCoefficient = percentToFloat(discount.amountInPercent);
    const discountAmount = amount * (-discountCoefficient);
    const totalAmount = amount * (1 - discountCoefficient);

    return {
        totalPrice: { amount: addVatAsNumber(totalAmount, vat).withVat, currency },
        discountPrice: { amount: addVatAsNumber(discountAmount, vat).withVat, currency },
        vatBreakdown: computeVatBreakdown([ { vat, amount: totalAmount } ], currency),
        isOnlyZeroVat,
        isMultipleVats,
    };
}

function computeVatBreakdown(itemsByVat: { vat: TaxRateFE, amount: number }[], currency: CurrencyFE): VatSummary[] {
    return itemsByVat.map(({ vat, amount }) => ({
        vat,
        ...addVat({ amount, currency }, vat),
    }));
}

type DiscountInputProps = Readonly<{
    value?: FormDiscount;
    onChange: (value?: FormDiscount) => void;
    discountAmount: number | undefined;
    isShorter: boolean;
    isMultipleVats: boolean;
}>;

function DiscountInput({ value, onChange, discountAmount, isShorter, isMultipleVats }: DiscountInputProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'create-custom-order.discount' });
    /** In percent. */
    const [ addingDiscount, setAddingDiscount ] = useState<number | ''>();

    function addDiscount() {
        if (addingDiscount === undefined || addingDiscount === '')
            return;

        onChange({
            amountInPercent: addingDiscount,
            label: t('label-default', { percent: addingDiscount + ' %' }),
        });
        setAddingDiscount(undefined);
    }

    if (!value) {
        return (
            <div className='flex gap-4'>
                {addingDiscount === undefined ? (<>
                    {isMultipleVats ? (
                        <Tooltip
                            tooltipText={t('invalid-label')}
                            side='bottom'
                        >
                            <div className='opacity-50 font-bold flex items-center gap-2'>
                                <PlusIcon size={18} />
                                <span>{t('start-button')}</span>
                            </div>
                        </Tooltip>
                    ) : (
                        <div
                            className='select-none cursor-pointer text-primary font-bold flex items-center gap-2'
                            onClick={() => setAddingDiscount(defaultDiscountAmount)}
                        >
                            <PlusIcon size={18} />
                            <span>{t('start-button')}</span>
                        </div>
                    )}
                </>) : (<>
                    <div className='relative col-1'>
                        <Form.Input
                            type='number'
                            value={addingDiscount}
                            onChange={e => setAddingDiscount(transformToPrice(e.target.value))}
                        />
                        <span className='absolute right-2'>%</span>
                    </div>
                    <Button variant='primary' onClick={addDiscount} disabled={addingDiscount === '' || addingDiscount <= 0 || addingDiscount > 100}>
                        {t('finish-button')}
                    </Button>
                    <Button variant='outline' onClick={() => setAddingDiscount(undefined)}>
                        {t('cancel-button')}
                    </Button>
                </>)}
            </div>
        );
    }

    return (<>
        <div className={clsx('flex gap-4 fl-custom-order-discount-row', isMultipleVats && 'disabled')}>
            <div className='relative col-1'>
                <Form.Input
                    label={t('quantity-label')}
                    type='number'
                    value={value.amountInPercent}
                    onChange={e => onChange({ ...value, amountInPercent: transformToPrice(e.target.value) })}
                    disabled={isMultipleVats}
                />
                <span className='absolute right-2'>%</span>
            </div>
            <div className='grow'>
                <Form.Textarea
                    label={t('name-label')}
                    value={value.label}
                    onChange={e => onChange({ ...value, label: e.target.value })}
                    minRows={1}
                    style={{ paddingTop: 10, paddingBottom: 10 }}

                    disabled={isMultipleVats}
                />
            </div>
            <div className='col-2'>
                <Form.Label>{t('price-label')}</Form.Label>
                <div className='px-4' style={{ paddingTop: 10 }}>
                    {discountAmount !== undefined && <MoneyAmountDisplay amount={discountAmount} />}
                </div>
            </div>
            {isShorter && (<>
                <div />
                <div />
            </>)}
            <div className='col-1 flex justify-end fl-remove-button-outer'>
                <div className='fl-remove-button'>
                    <Button variant='transparent' size='exact' onClick={() => onChange(undefined)}>
                        <Trash2Icon />
                    </Button>
                </div>
            </div>
        </div>
        {isMultipleVats && (
            <div className='py-2 text-center'>{t('invalid-label')}</div>
        )}
    </>);
}

const defaultDiscountAmount = floatToPercent(0.1);

type ControlledDiscountInputProps<TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>> = {
    control: Control<TFieldValues>;
    name: TName;
    discountAmount: number | undefined;
    isShorter: boolean;
    isMultipleVats: boolean;
};

function ControlledDiscountInput<TFieldValues extends FieldValues>({ control, name, discountAmount, isShorter, isMultipleVats }: ControlledDiscountInputProps<TFieldValues>) {
    const InnerSelect = useCallback(({ field }: { field: { value?: FormDiscount, onChange: (value?: FormDiscount) => void } }) => {
        return (
            <DiscountInput
                value={field.value}
                onChange={field.onChange}
                discountAmount={discountAmount}
                isShorter={isShorter}
                isMultipleVats={isMultipleVats}
            />
        );
    }, [ discountAmount, isShorter, isMultipleVats ]);

    return (
        <Controller
            control={control}
            name={name}
            render={InnerSelect}
        />
    );
}

type PriceSummaryDisplayProps = Readonly<{
    summary: PriceSummary;
}>;

function PriceSummaryDisplay({ summary }: PriceSummaryDisplayProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'create-custom-order.price-summary' });
    return (
        <div>
            {summary.vatBreakdown.length > 0 && !summary.isOnlyZeroVat && (
                <div className='border-b border-text'>
                    <Table compact noLines>
                        <Table.Header>
                            <Table.HeaderCol className='text-right text-secondary-600'>{t('tax-label')}</Table.HeaderCol>
                            <Table.HeaderCol className='text-right text-secondary-600'>{t('without-vat-label')}</Table.HeaderCol>
                            <Table.HeaderCol className='text-right text-secondary-600'>{t('with-vat-label')}</Table.HeaderCol>
                        </Table.Header>
                        <Table.Body>
                            {summary.vatBreakdown.map(item => (
                                <Table.Row key={item.vat.toKey()}>
                                    <Table.Col className='text-right'>
                                        <span>{item.vat.label}</span>
                                    </Table.Col>
                                    <Table.Col className='text-right'>
                                        <MoneyDisplay money={item.withoutVat} />
                                    </Table.Col>
                                    <Table.Col className='text-right'>
                                        <MoneyDisplay money={item.withVat} />
                                    </Table.Col>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                </div>
            )}
            <div className='text-xl'>
                {t('total-price-label')}
                <span className='float-right'>
                    <MoneyDisplay money={summary.totalPrice} />
                </span>
            </div>
        </div>
    );
}

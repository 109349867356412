import { BoxHeartIcon, CoinsIcon, TrophyIcon } from ':components/icons/basic';
import { Button, Form, Modal } from ':components/shadcn';
import { ProductDirectSaleDisplay } from ':components/store/product/ProductCard';
import { CreateProductCard } from ':frontend/components/product/CreateProductCard';
import { trpc } from ':frontend/context/TrpcProvider';
import { Query } from ':frontend/utils/common';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { type UseProductOrderDispatch, type UseProductOrderState, useProductOrder } from ':frontend/components/orders/useProductOrder';
import type { ClientInfoFE } from ':frontend/types/Client';
import { useNavigate } from 'react-router-dom';
import { CheckoutModalInner, type CheckoutOutput } from ':frontend/components/orders/checkout/CheckoutModalInner';
import { ProductOrderItemFE } from ':frontend/types/orders/ProductOrderItem';
import { routesFE } from ':utils/routes';
import { ProductOrderForm } from ':frontend/components/orders/ProductOrderForm';
import { useClients } from ':frontend/hooks';
import type { ProductOutput } from ':utils/entity/product';

export function NewProductOrderTab() {
    const products = trpc.product.getProducts.useQuery();
    const { clients, addClients } = useClients();

    if (!products.data || !clients)
        return null;

    return (
        <NewProductOrderTabInner
            products={products.data}
            clients={clients}
            addClients={addClients}
        />
    );
}

type NewProductOrderTabInnerProps = Readonly<{
    products: ProductOutput[];
    clients: ClientInfoFE[];
    addClients: (clients: ClientInfoFE | ClientInfoFE[]) => void;
}>;

function NewProductOrderTabInner({ products, clients, addClients }: NewProductOrderTabInnerProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'directSale.product' });
    const [ query, setQuery ] = useState('');

    const filteredProducts = useMemo(() => {
        const filterQuery = new Query(...query.split(' '));

        // It probably isn't the most efficient way to compute the queries every single time, but it should be ok.
        return products.filter(product => {
            const productQuery = new Query(...product.title.split(' '));
            return productQuery.match(filterQuery);
        });
    }, [ products, query ]);

    const { state, dispatch } = useProductOrder();

    return (
        <div className='fl-main-scroller'>
            <div className='max-w-[910px] w-full mx-auto py-12 flex flex-col items-stretch gap-10'>
                <div className='flex gap-2'>
                    <Form.Input
                        size='compact'
                        placeholder={t('query-placeholder')}
                        value={query}
                        onChange={e => setQuery(e.target.value)}
                    />
                    <div className='w-6' />
                    <Button variant='white' size='small'><TrophyIcon />{t('most-profitable-button')}</Button>
                    <Button variant='white' size='small'><BoxHeartIcon />{t('most-selling-button')}</Button>
                    <Button variant='white' size='small'><CoinsIcon />{t('least-profitable-button')}</Button>
                </div>
                <div className='grid grid-cols-3 gap-3'>
                    {filteredProducts.map(product => (
                        <ProductDirectSaleDisplay
                            key={product.id}
                            product={product}
                            onClick={() => dispatch({ type: 'sellProduct', product })}
                        />
                    ))}
                    {filteredProducts.length === 0 && (
                        <div className='col-span-3 flex flex-col items-center'>
                            <span className='text-danger'>
                                {t(products.length === 0 ? 'no-products' : 'no-filtered-products')}
                            </span>
                            {products.length === 0 && (
                                <CreateProductCard className='mt-10' />
                            )}
                        </div>
                    )}
                </div>
            </div>

            <Modal.Root
                open={state.isModalOpen}
                onOpenChange={open => !open && dispatch({ type: 'closeModal' })}
            >
                <Modal.Content className='max-w-[688px] p-8' closeButton={t('modal.cancel-button')}>
                    <NewProductOrderModalInner
                        state={state}
                        dispatch={dispatch}
                        clients={clients}
                        addClients={addClients}
                        products={products}
                    />
                </Modal.Content>
            </Modal.Root>
        </div>
    );
}

type NewProductOrderModalInnerProps = Readonly<{
    state: UseProductOrderState;
    dispatch: UseProductOrderDispatch;
    products: ProductOutput[];
    clients: ClientInfoFE[];
    addClients: (clients: ClientInfoFE | ClientInfoFE[]) => void;
}>;

function NewProductOrderModalInner({ state, dispatch, products, clients, addClients }: NewProductOrderModalInnerProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'directSale.product.modal' });
    const navigate = useNavigate();

    const checkoutOutput = useCallback((action: CheckoutOutput) => {
        switch (action.type) {
        case 'back':
            dispatch({ type: 'form', operation: 'checkoutBack' });
            break;
        case 'close':
            dispatch({ type: 'closeModal' });
            break;
        case 'finish': {
            const item = action.orders[0].items[0];
            if (item instanceof ProductOrderItemFE && item.guest)
                navigate(routesFE.clients.detail.resolve({ id: item.guest.id, key: 'packages' }));
            break;
        }
        case 'addClients':
            addClients(action.clients);
            break;
        }
    }, [ dispatch, addClients, navigate ]);

    if (state.checkout) {
        return (
            <CheckoutModalInner input={state.checkout} output={checkoutOutput} />
        );
    }

    return (<>
        <Modal.Header>
            <Modal.Title className='text-body text-2xl font-semibold'>{t('title')}</Modal.Title>
        </Modal.Header>

        <div className='py-4'>
            <ProductOrderForm products={products} clients={clients} state={state} dispatch={dispatch} />
        </div>

        <Modal.Footer>
            <Button className='w-full' onClick={() => dispatch({ type: 'form', operation: 'checkoutStart' })}>
                {t('continue-button')}
            </Button>
        </Modal.Footer>
    </>);
}

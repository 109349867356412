import { useCallback, useMemo, useState, type ReactNode } from 'react';
import { Navigate } from './utils/common';
import { useTranslation } from 'react-i18next';
import { localizer, type NavigateAction } from '.';
import { type DateTime } from 'luxon';
import type { CalendarEvent } from ':frontend/types/calendar/Calendar';
import { Table } from ':frontend/components/common';
import { MoneyDisplay } from ':components/custom';
import ClientIconLink from ':frontend/components/client/ClientIconLink';
import type { TFunction } from 'i18next';
import EventStateBadge from ':frontend/components/event/EventStateBadge';
import { Button, Form } from ':components/shadcn';
import { type Signal, useSignal, computed } from '@preact/signals-react';
import { useNavigate } from 'react-router-dom';
import { routesFE } from ':utils/routes';
import { createActionState, type PreselectBackpay } from ':frontend/hooks';
import { EventNotesModal } from ':frontend/components/event/EventsTable';
import EventPaymentStateBadge from ':frontend/components/event/EventPaymentStateBadge';
import FilterRow, { useFilters, useFiltersApply, type UseFiltersControl } from ':frontend/components/common/filters/FilterRow';
import { CalendarEventStateFilter } from ':frontend/components/common/filters/EventStateFilter';
import createCalendarEventParticipantFilter from ':frontend/components/common/filters/CalendarEventParticipantFilter';
import { type ClientInfoFE } from ':frontend/types/Client';
import type { ViewObject } from './Views';
import { DayLongFormat, EventRangeFormat } from ':frontend/components/common/DateTimeDisplay';
import { toMaster, useUser } from ':frontend/context/UserProvider';
import { TeamMemberBadge } from ':frontend/components/team/TeamMemberBadge';
import { TeamMemberRole } from ':utils/entity/team';

type EventClickFunction = (event: CalendarEvent, e: React.SyntheticEvent<HTMLElement>) => void;

type AgendaProps = Readonly<{
    date: DateTime;
    events: CalendarEvent[];
    onSelectEvent?: EventClickFunction;
    clients: ClientInfoFE[];
}>;

function Agenda({ date, events, onSelectEvent, clients }: AgendaProps) {
    const { t } = useTranslation('components', { keyPrefix: 'calendar' });
    const checkedSignal = useSignal<AllCheckedEvents>({});

    const { startDay, endDay, range } = useMemo(() => {
        const startDay = localizer.startOf(date, 'month');
        const endDay = localizer.endOf(date, 'month');
        const range = localizer.range(startDay, endDay, 'day');

        return { startDay, endDay, range };
    }, [ date ]);

    const filters = useMemo(() => [
        CalendarEventStateFilter,
        createCalendarEventParticipantFilter(clients),
    ], [ clients ]);

    const filtersControl = useFilters(filters);
    const applyFilters = useFiltersApply(filtersControl);

    const agendaEvents = useMemo(() => {
        const filtered = events
            .filter(event => localizer.inRangeDay(event, startDay, endDay))
            .filter(applyFilters);

        filtered.sort((a, b) => +a.start - +b.start);

        return filtered;
    }, [ events, startDay, endDay, applyFilters ]);

    return (
        <div className='flex flex-col flex-1 border border-calendar-border'>
            <AgendaToolbar checkedSignal={checkedSignal} filtersControl={filtersControl} />
            <div className='flex flex-col flex-1 overflow-auto'>
                <Table noOuterLines>
                    <Table.Body>
                        {agendaEvents.length === 0 && (
                            <Table.Row>
                                <Table.Col colSpan={8} className='text-center text-xl py-12'>
                                    {t('noEventsInRange')}
                                </Table.Col>
                            </Table.Row>
                        )}
                        {range.map(day => (
                            <AgendaDay
                                key={+day}
                                day={day}
                                agendaEvents={agendaEvents}
                                onSelectEvent={onSelectEvent}
                                checkedSignal={checkedSignal}
                            />
                        ))}
                    </Table.Body>
                </Table>
            </div>
        </div>
    );
}

type AgendaToolbarProps = Readonly<{
    checkedSignal: Signal<AllCheckedEvents>;
    filtersControl: UseFiltersControl;
}>;

function AgendaToolbar({ checkedSignal, filtersControl }: AgendaToolbarProps) {
    const { t } = useTranslation('components', { keyPrefix: 'calendar' });
    const isMasterOrFreelancer = !!toMaster(useUser());

    const checkedIds = computed(
        () => Object.values(checkedSignal.value)
            .filter((dayEvents): dayEvents is DayCheckedEvents => !!dayEvents)
            .flatMap(
                dayEvents => Object.entries(dayEvents)
                    .filter(([ _, checked ]) => checked)
                    .map(([ id ]) => id),
            ),
    );

    const navigate = useNavigate();

    function invoiceSelected() {
        navigate(routesFE.orders.newBackpay, { state: createActionState<PreselectBackpay>('preselectBackpay', {
            eventIds: checkedIds.value,
        }) });
    }

    return (
        <div className='min-h-12 border-b border-calendar-border px-4 py-2 flex items-start'>
            <FilterRow control={filtersControl} />
            <div className='grow' />
            {isMasterOrFreelancer && (
                <Button className='tabular-nums fl-btn_compact' disabled={checkedIds.value.length === 0} onClick={invoiceSelected}>
                    {t('invoice-selected-button')}
                    {checkedIds.value.length > 0 && ` (${checkedIds.value.length})`}
                </Button>
            )}
        </div>
    );
}

type AgendaDayProps = Readonly<{
    day: DateTime;
    agendaEvents: CalendarEvent[];
    onSelectEvent?: EventClickFunction;
    checkedSignal: Signal<AllCheckedEvents>;
}>;

function AgendaDay({ day, agendaEvents, onSelectEvent, checkedSignal }: AgendaDayProps) {
    const dayEvents = useMemo(() => agendaEvents.filter(eventInDayComparator(day)), [ agendaEvents, day ]);
    const { checkedEvents, checkEvents } = useCheckedEvents(checkedSignal, +day);
    const { isChecked, isCheckingDisabled } = useMemo(() => {
        const billableEvents = dayEvents.filter(event => event.isBillable);
        return {
            isChecked: billableEvents.length > 0 && billableEvents.every(event => checkedEvents[event.id]),
            isCheckingDisabled: billableEvents.length === 0,
        };
    }, [ dayEvents, checkedEvents ]);

    return (<>
        {dayEvents.length > 0 && (
            <Table.Row className='bg-secondary-100'>
                <Table.Col style={{ width: 42 }}>
                    {!isCheckingDisabled && (
                        <Form.Checkbox checked={isChecked} onCheckedChange={value => checkEvents(dayEvents, value)} />
                    )}
                </Table.Col>
                <Table.Col colSpan={8}><DayLongFormat day={day} /></Table.Col>
            </Table.Row>
        )}
        {dayEvents.map(event => (
            <EventRow key={event.id} event={event} day={day} onSelectEvent={onSelectEvent} isChecked={!!checkedEvents[event.id]} checkEvents={checkEvents} />
        ))}
    </>);
}

/**
 * The day has to be aligned to the start of a day.
 */
function eventInDayComparator(day: DateTime) {
    const range = { start: day, end: day };
    return (event: CalendarEvent) => localizer.inEventRange(event, range, true);
}

type EventRowProps = Readonly<{
    event: CalendarEvent;
    day: DateTime;
    onSelectEvent?: EventClickFunction;
    isChecked: boolean;
    checkEvents: CheckEventsFunction;
}>;

function EventRow({ event, day, onSelectEvent, isChecked, checkEvents }: EventRowProps) {
    const { t } = useTranslation('components', { keyPrefix: 'calendar' });
    const [ isCollapsed, setIsCollapsed ] = useState(true);
    const masterContext = useUser();
    const isMaster = masterContext.role === TeamMemberRole.master;
    const isMasterOrFreelancer = !!toMaster(masterContext);

    return (
        <Table.Row style={{ lineHeight: '18px' }} className='select-none cursor-pointer fl-hoverable' onClick={e => onSelectEvent?.(event, e)}>
            <Table.Col className='align-top select-none cursor-default fl-hoverable-exception' onClick={e => e.stopPropagation()}>
                {isMasterOrFreelancer && event.isBillable && (
                    <Form.Checkbox checked={isChecked} onCheckedChange={value => checkEvents(event, value)} />
                )}
            </Table.Col>
            <Table.Col xs='auto' className='text-center align-top'>
                <EventRangeFormat start={event.start} end={event.end} currentDayStart={day} />
            </Table.Col>
            <Table.Col className='select-none cursor-default fl-hoverable-exception' truncate onClick={e => e.stopPropagation()}>
                {eventParticipants(event, isCollapsed, setIsCollapsed, t)}
            </Table.Col>
            <Table.Col className='align-top' truncate>
                <div className='flex items-center gap-2'>
                    {calendarBadge(event)}
                    <span className='truncate'>{event.title}</span>
                </div>
            </Table.Col>
            <Table.Col xs='auto' className='align-top text-right'>
                {event.resource.type === 'event' && event.resource.totalPrice && (
                    <MoneyDisplay money={event.resource.totalPrice} />
                )}
            </Table.Col>
            <Table.Col xs='auto' className='align-top'>
                {event.resource.type === 'event' && (
                    <EventStateBadge event={event.resource.event} />
                )}
            </Table.Col>
            <Table.Col xs='auto' className='align-top'>
                {event.resource.type === 'event' && (
                    <EventPaymentStateBadge event={event.resource.event} />
                )}
            </Table.Col>
            <Table.Col xs='auto' className='align-top leading-none select-none cursor-default fl-hoverable-exception' onClick={e => e.stopPropagation()}>
                {event.resource.type === 'event' && !!event.resource.event.notes && (
                    <EventNotesModal event={event.resource.event} size={18} />
                )}
            </Table.Col>
            {isMaster && (
                <Table.Col xs='auto'>
                    {event.resource.type === 'event' && (
                        <TeamMemberBadge appUserId={event.resource.event.ownerId} />
                    )}
                </Table.Col>
            )}
        </Table.Row>
    );
}

function eventParticipants(event: CalendarEvent, isCollapsed: boolean, setIsCollapsed: (newValue: boolean) => void, t: TFunction): ReactNode {
    const inner = eventParticipantsInner(event, isCollapsed);
    if (inner.length === 0)
        return null;

    const totalParticipants = event.resource.type === 'draft' ? 0 : event.resource.event.guests.length;

    return (
        <div className='flex flex-col gap-2'>
            <div className='flex items-center gap-2'>
                {inner[0]}
                {totalParticipants > 1 && (
                    <span className='whitespace-nowrap text-secondary-600 select-none cursor-pointer hover:underline' onClick={() => setIsCollapsed(!isCollapsed)}>
                        {isCollapsed ? t('showMore', { count: totalParticipants - 1 }) : t('showLess')}
                    </span>
                )}
            </div>
            {inner.slice(1)}
        </div>
    );
}

function eventParticipantsInner(event: CalendarEvent, isCollapsed: boolean): ReactNode[] {
    if (event.resource.type === 'event') {
        return getFirstNoneOrAll(event.resource.event.guests, isCollapsed)
            .map(participant => (
                <ClientIconLink key={participant.client.id} client={participant.client} />
            ));
    }

    if (event.resource.type === 'google') {
        return getFirstNoneOrAll(event.resource.event.guests, isCollapsed)
            .map(participant => (
                <span key={participant}>{participant}</span>
            ));
    }

    return [];
}

function getFirstNoneOrAll<T>(array: T[], onlyFirst: boolean): T[] {
    return !onlyFirst
        ? array
        : array.length > 0
            ? [ array[0] ]
            : [];
}

function calendarBadge(event: CalendarEvent): ReactNode {
    const resource = event.resource;
    if (resource.type === 'draft')
        return null;

    const color = resource.calendar?.color ?? '#4083F5';

    return (
        <div className='shrink-0' style={{ width: 10, height: 10, borderRadius: 3, backgroundColor: color }} />
    );
}

// The logic here is a little bit complicated due to performance reasons. If we kept the state in the Agenda component, each check or uncheck would cause the whole component to rerender. That means all event rows. However, rendering the events is actually quite expensive - there are dates and participants and a lot of formatting. So we keep the state in the days and then pass it up with a signal. This way, we need to rerender only one day at a time.

/** Indexed by the timestamp of the start of the day. */
type AllCheckedEvents = Record<number, DayCheckedEvents | undefined>;

type CheckEventsFunction = (events: CalendarEvent | CalendarEvent[], value: boolean) => void;
/** Indexed by event id. */
type DayCheckedEvents = Record<string, boolean | undefined>;

function useCheckedEvents(checkedSignal: Signal<AllCheckedEvents>, dayId: number) {
    const [ checkedEvents, setCheckedEvents ] = useState<DayCheckedEvents>({});

    const checkEvents = useCallback((events: CalendarEvent | CalendarEvent[], value: boolean) => {
        setCheckedEvents(oldState => {
            const newState = updateCheckedEvents(oldState, events, value);
            checkedSignal.value = updateAllCheckedEvents(checkedSignal.peek(), dayId, newState);
            return newState;
        });
    }, [ checkedSignal, dayId ]);

    return { checkedEvents, checkEvents };
}

function updateCheckedEvents(oldState: DayCheckedEvents, events: CalendarEvent | CalendarEvent[], value: boolean): DayCheckedEvents {
    const newState = { ...oldState };
    const eventsArray = Array.isArray(events) ? events : [ events ];

    for (const event of eventsArray) {
        if (event.isBillable)
            newState[event.id] = value;
    }

    return newState;
}

function updateAllCheckedEvents(allCheckedEvents: AllCheckedEvents, dayId: number, checkedEvents: DayCheckedEvents) {
    const newState = { ...allCheckedEvents };
    newState[dayId] = checkedEvents;

    return newState;
}

function navigateTo(date: DateTime, action: NavigateAction) {
    switch (action) {
    case Navigate.PREVIOUS:
        return localizer.add(date, -1, 'month');
    case Navigate.NEXT:
        return localizer.add(date, 1, 'month');
    default:
        return date;
    }
}

function getRange(date: DateTime) {
    const start = localizer.startOf(date, 'month');
    const end = localizer.endOf(date, 'month');
    return localizer.range(start, end);
}

const viewObject: ViewObject = {
    component: Agenda,
    navigateTo,
    getRange,
};

export default viewObject;

import { useState, type ReactNode } from 'react';
import type { InternalFieldName, UseFormRegisterReturn } from 'react-hook-form';
import { Button, Form } from ':components/shadcn';
import { Eye2Icon, Eye2SlashIcon } from ':components/icons/basic';

type PasswordInputProps<TFieldName extends InternalFieldName = InternalFieldName> = Readonly<{
    register: UseFormRegisterReturn<TFieldName>;
    label: ReactNode;
}>;

export default function PasswordInput({ register, label }: PasswordInputProps) {
    const [ show, setShow ] = useState(false);

    return (
        <div className='flex flex-col relative gap-2'>
            <label>{label}</label>
            <div className='relative'>
                <Form.Input
                    {...register}
                    type={show ? 'text' : 'password'}
                />
                <Button
                    onClick={() => setShow(!show)}
                    variant='transparent'
                    className='rounded-l-none h-auto py-0 absolute right-0 top-0 bottom-0 self-center'
                >
                    {show ? <Eye2SlashIcon size={24} /> : <Eye2Icon size={24} />}
                </Button>
            </div>
        </div>
    );
}

import { useTranslation } from 'react-i18next';
import { useMaster } from ':frontend/context/UserProvider';
import { OnboardingJourney } from ':frontend/components/onboarding/OnboardingJourney';
import { OrdersStatsDisplay } from ':frontend/components/orders/OrdersStatsDisplay';
import { Content, Topbar } from ':frontend/components/Layout';

export default function MasterDashboard() {
    const { t } = useTranslation('pages', { keyPrefix: 'dashboard' });
    const { onboarding } = useMaster();

    return (<>
        <Topbar>
            Look at my Topbar! My Topbar is amazing!
        </Topbar>
        <Content>
            {/* We want to fit exactly four tiles here. */}
            <div className='max-w-[1020px] w-full mx-auto pb-12'>
                {!onboarding.isFlagsFinished && (
                    <div style={{ padding: '16px 0px' }}>
                        <OnboardingJourney />
                    </div>
                )}
                <h2 style={{ margin: '48px 0px 24px 0px' }}>{t('stats-title')}</h2>
                <OrdersStatsDisplay />
            </div>
        </Content>
    </>);
}


import { type OmitId, type Id } from ':utils/id';
import { AddressFE, type EditableAddress, addressToUpsert } from ':frontend/types/Address';
import { optionalStringToPut } from ':frontend/utils/common';
import type { InvoicingIdentityOutput, InvoicingIdentityUpdate, InvoicingOverrideOutput, InvoicingProfileOutput, SubscriberSettingsOutput } from ':utils/entity/invoicing';
import type { LocaleCode } from ':utils/i18n';
import type { AddressUpsert } from ':utils/entity/address';
import type { FileOutput } from ':utils/entity/file';

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Common

abstract class CommonSettings {
    protected constructor(
        readonly id: Id,
        readonly hideEmailOnInvoice: boolean,
        /** This email overrides the client email on the invoice. */
        readonly email?: string,
        readonly address?: AddressFE,
        readonly cin?: string,
        readonly tin?: string,
    ) {}
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Supplier Settings

export class InvoicingProfileFE extends CommonSettings {
    private constructor(
        id: Id,
        readonly title: string,
        readonly locale: LocaleCode<'invoice'>,
        readonly dueDays: number,
        readonly legalName: string | undefined,
        hideEmailOnInvoice: boolean,
        email: string | undefined,
        address: AddressFE | undefined,
        cin: string | undefined,
        tin: string | undefined,
        readonly isLogoCustom: boolean,
        readonly logo: FileOutput | undefined,
        readonly isCondensedInvoice: boolean,
        readonly header?: string,
        readonly footer?: string,
        readonly customKey1?: string,
        readonly customValue1?: string,
        readonly customKey2?: string,
        readonly customValue2?: string,
    ) {
        super(id, hideEmailOnInvoice, email, address, cin, tin);
    }

    static fromServer(input: InvoicingProfileOutput): InvoicingProfileFE {
        return new InvoicingProfileFE(
            input.id,
            input.title,
            input.locale,
            input.dueDays,
            input.legalName,
            input.hideEmailOnInvoice,
            input.email,
            input.address ? AddressFE.fromServer(input.address) : undefined,
            input.cin,
            input.tin,
            input.isLogoCustom,
            input.logo,
            input.condensedInvoice,
            input.header ?? undefined,
            input.footer ?? undefined,
            input.customKey1 ?? undefined,
            input.customValue1 ?? undefined,
            input.customKey2 ?? undefined,
            input.customValue2 ?? undefined,
        );
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Subscriber Settings

export class SubscriberSettingsFE extends CommonSettings {
    private constructor(
        id: Id,
        readonly name: string,
        hideEmailOnInvoice: boolean,
        email?: string,
        address?: AddressFE,
        cin?: string,
        tin?: string,
    ) {
        super(id, hideEmailOnInvoice, email, address, cin, tin);
    }

    static fromServer(input: SubscriberSettingsOutput, name: string): SubscriberSettingsFE {
        return new SubscriberSettingsFE(
            input.id,
            name,
            input.hideEmailOnInvoice,
            input.email,
            input.address ? AddressFE.fromServer(input.address) : undefined,
            input.cin,
            input.tin,
        );
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Identity

export class InvoicingIdentityFE {
    private constructor(
        readonly id: Id,
        readonly name: string,
        readonly email?: string,
        readonly address?: AddressFE,
        readonly cin?: string,
        readonly tin?: string,
    ) {}

    static fromServer(input: InvoicingIdentityOutput): InvoicingIdentityFE {
        return new InvoicingIdentityFE(
            input.id,
            input.name,
            input.email ?? undefined,
            'address' in input && input.address ? AddressFE.fromServer(input.address) : undefined,
            input.cin ?? undefined,
            input.tin ?? undefined,
        );
    }
}

export type InvoicingIdentityUpdateToServer = Omit<InvoicingIdentityOutput, 'id' | 'address'> & {
    address: AddressUpsert;
};

export type InvoicingIdentityUpdateFE = {
    readonly name: string;
    readonly email: string;
    readonly address: EditableAddress;
    readonly cin: string;
    readonly tin: string;
};

export function invoicingIdentityUpdateToServer(input: InvoicingIdentityUpdateFE): InvoicingIdentityUpdate {
    return {
        name: input.name,
        email: optionalStringToPut(input.email),
        address: addressToUpsert(input.address),
        cin: optionalStringToPut(input.cin),
        tin: optionalStringToPut(input.tin),
    };
}

export class InvoicingOverrideFE {
    private constructor(
        readonly id: Id,
        readonly isInvoicingOverride?: boolean,
        readonly header?: string,
        readonly footer?: string,
        readonly customKey1?: string,
        readonly customValue1?: string,
        readonly customKey2?: string,
        readonly customValue2?: string,
        readonly locale?: LocaleCode<'invoice'>,
    ) {}

    static fromServer(input: InvoicingOverrideOutput): InvoicingOverrideFE {
        return new InvoicingOverrideFE(
            input.id,
            input.condensedInvoice,
            input.header,
            input.footer,
            input.customKey1,
            input.customValue1,
            input.customKey2,
            input.customValue2,
            input.locale,
        );
    }
}

/**
 * We don't use undefined values because we have to distinguish between undefined (the value shouldn't override the value from the invoicing profile) and an empty string (the value should override, i.e., delete the profile's value).
 * The dueDays is an exception because an invalid number is treated as no override.
 */
export type InvoicingOverrideToServer = Required<Omit<OmitId<InvoicingOverrideOutput>, 'dueDays'>> & {
    // The dueDays isn't included in the InvoicingOverrideOutput, because it's stored on the client.
    // However, it's a part of the invoicing overrides (logically), so it's updated with them.
    dueDays: number | undefined;
};

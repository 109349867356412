import { deepClone, deepEquals, zColor } from ':utils/common';
import { z } from 'zod';
import { zFileOutput, zFileUpsert } from './file';

export type StoreSocial = z.infer<typeof zStoreSocial>;
const zStoreSocial = z.object({
    type: z.string(),
    url: z.string(),
    title: z.string(),
});

export type StoreBio = z.infer<typeof zStoreBio>;
const zStoreBio = z.object({
    name: z.string(),
    headline: z.string().optional(),
    description: z.string().optional(),
    socials: z.array(zStoreSocial),
});

export type StoreBackground = z.infer<typeof zStoreBackground>;
export const zStoreBackground = z.object({
    type: z.enum([ 'solid', 'gradient' ]),
    color1: zColor,
    /** For gradients. */
    color2: zColor,
});

export type StoreDesign = z.infer<typeof zStoreDesign>;
export const zStoreDesign = z.object({
    background: zStoreBackground,
    card: z.object({
        corners: z.enum([ 'square', 'rounded' ]),
        shadow: z.enum([ 'none', 'soft', 'hard' ]),
        button: z.enum([ 'square', 'rounded', 'pill' ]),
        buttonBackground: zColor,
        buttonColor: zColor,
    }),
    font: z.string(),
    other: z.object({
        hideFlowlanceBadge: z.boolean(),
    }),
});

export function cloneStoreDesign(design: StoreDesign): StoreDesign {
    return deepClone(design);
}

export function isStoreDesignEqual(design: StoreDesign, original: StoreDesign): boolean {
    return deepEquals(design, original);
}

export type StoreOutput = z.infer<typeof zStoreOutput>;
export const zStoreOutput = z.object({
    slug: z.string(),
    bio: zStoreBio,
    design: zStoreDesign,
    image: zFileOutput.optional(),
});

/** Like PATCH (then like PUT for nested objects). */
export type StoreEdit = z.infer<typeof zStoreEdit>;
export const zStoreEdit = z.object({
    bio: zStoreBio,
    design: zStoreDesign,
    image: zFileUpsert.nullable(),
}).partial();

/** There is also the default font, Gilmer, which is not custom but is available (also, it's the default option). */
export const customFonts = [
    'Manrope',
    'Montserrat',
    'Poppins',
    'Raleway',
    'Roboto',
    'Space Grotesk',
    'Nunito',
    'Playfair Display',
    'Karla',
    'Fira Sans',
    'Lora',
    'Anton',
    'Inter',
    'Amiko',
    'Mulish',
    'Crimson Text',
    'Roboto Mono',
];

export function fontsNamesToUrl(fonts: string[]) {
    const payload = fonts.map(font => `family=${fontNameToDefinition(font)}`).join('&');
    return `https://fonts.googleapis.com/css2?${payload}&display=swap`;
}

function fontNameToDefinition(font: string) {
    return font.replace(/ /g, '+') + ':wght@500;600';
}

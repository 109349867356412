import { useMemo } from 'react';
import clsx from 'clsx';
import { type PaginationController } from ':frontend/hooks';
import { DEFAULT_ITEMS_PER_PAGE } from ':utils/query';
import { Pagination as RawPagination, PaginationContent, PaginationItem, PaginationEllipsis, PaginationButton, PaginationNext, PaginationPrevious } from ':components/shadcn';

type PaginationProps = {
    controller: PaginationController;
    itemsPerPage?: number;
    className?: string;
};

export default function Pagination({ controller, ...rest }: PaginationProps) {
    const itemsPerPage = rest.itemsPerPage ?? DEFAULT_ITEMS_PER_PAGE;
    const totalPages = Math.ceil(controller.totalItems / itemsPerPage);
    const pages = useMemo(() => generatePages(controller.page, totalPages), [ controller.page, totalPages ]);

    if (totalPages < 2)
        return null;

    return (
        <div className={clsx('fl-pagination my-4', rest.className)}>
            <RawPagination>
                <PaginationContent>
                    <PaginationPrevious disabled={controller.page === 1} onClick={() => controller.setPage(controller.page - 1)} />
                    {pages.map(page => typeof page === 'number' ? (
                        <PaginationItem key={page}>
                            <PaginationButton
                                isActive={page === controller.page}
                                onClick={() => controller.setPage(page)}
                            >
                                {page}
                            </PaginationButton>
                        </PaginationItem>
                    ) : (
                        <PaginationEllipsis key={page} className='fl-pagination-ellipsis' />
                    ))}
                    <PaginationNext disabled={controller.page === totalPages} onClick={() => controller.setPage(controller.page + 1)} />
                </PaginationContent>
            </RawPagination>
        </div>
    );
}

type PageDefinition = number | 'ellipsis-start' | 'ellipsis-end';

const DISPLAY_PAGES_AROUND = 2;

function generatePages(page: number, totalPages: number): PageDefinition[] {
    const pages: PageDefinition[] = [];

    const firstPage = page - DISPLAY_PAGES_AROUND;
    const lastPage = page + DISPLAY_PAGES_AROUND;

    if (firstPage > 1) {
        pages.push(1);

        if (firstPage === 3)
            pages.push(2);
        else if (firstPage > 3)
            pages.push('ellipsis-start');
    }

    for (let i = firstPage; i <= lastPage; i++)
        pages.push(i);

    if (lastPage < totalPages) {
        if (lastPage === totalPages - 2)
            pages.push(totalPages - 1);
        else if (lastPage < totalPages - 2)
            pages.push('ellipsis-end');

        pages.push(totalPages);
    }

    return pages.filter(p => typeof p !== 'number' || p >= 1 && p <= totalPages);
}

// Just copy pasted from React Icons. Replace when possible.

import * as React from 'react';

export interface IconContext {
    color?: string;
    size?: string;
    className?: string;
    style?: React.CSSProperties;
    attr?: React.SVGAttributes<SVGElement>;
}

export const DefaultContext: IconContext = {
    color: undefined,
    size: undefined,
    className: undefined,
    style: undefined,
    attr: undefined,
};

export const IconContext: React.Context<IconContext> = React.createContext && React.createContext(DefaultContext);

export interface IconTree {
  tag: string;
  attr: { [key: string]: string };
  child: IconTree[];
}

function Tree2Element(tree: IconTree[]): React.ReactElement[] {
    return (
        tree && tree.map((node, i) =>
            React.createElement(
                node.tag,
                { key: i, ...node.attr },
                Tree2Element(node.child),
            ),
        )
    );
}

export function GenIcon(data: IconTree) {
    return (props: IconBaseProps) => (
        <IconBase attr={{ ...data.attr }} {...props}>
            {Tree2Element(data.child)}
        </IconBase>
    );
}

export interface IconBaseProps extends React.SVGAttributes<SVGElement> {
    children?: React.ReactNode;
    size?: string | number;
    title?: string;
}

export type IconType = (props: IconBaseProps) => JSX.Element;
export function IconBase(props: IconBaseProps & { attr?: Record<string, string> }): JSX.Element {
    const elem = (conf: IconContext) => {
        const { attr, size, title, ...svgProps } = props;
        const computedSize = size || conf.size || '1em';
        let className;
        if (conf.className)
            className = conf.className;
        if (props.className)
            className = (className ? className + ' ' : '') + props.className;

        return (
            <svg
                stroke='currentColor'
                fill='currentColor'
                strokeWidth='0'
                {...conf.attr}
                {...attr}
                {...svgProps}
                className={className}
                style={{
                    color: conf.color,
                    ...conf.style,
                    ...props.style,
                }}
                height={computedSize}
                width={computedSize}
                xmlns='http://www.w3.org/2000/svg'
            >
                {title && <title>{title}</title>}
                {props.children}
            </svg>
        );
    };

    return IconContext !== undefined ? (
        <IconContext.Consumer>
            {(conf: IconContext) => elem(conf)}
        </IconContext.Consumer>
    ) : (
        elem(DefaultContext)
    );
}

import { trpc } from ':frontend/context/TrpcProvider';
import { createDisplayUrl, routesFE, routesStore } from ':utils/routes';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { StoreOverviewTab } from './StoreOverviewTab';
import { StoreDesignTab } from './StoreDesignTab';
import { Button, Tabs } from ':components/shadcn';
import { Clone1Icon, Copies6Icon, Eye2Icon, Palette1Icon } from ':components/icons/basic';
import { navigateNewTab } from ':frontend/utils/common';
import useNotifications from ':frontend/context/NotificationProvider';
import { createTranslatedSuccessAlert } from ':frontend/components/notifications';

export function StorePage() {
    const { t } = useTranslation('pages', { keyPrefix: 'store' });
    const store = trpc.store.getStore.useQuery();
    // This page should look like the actual store, so only the public products should be displayed.
    const products = trpc.product.getProducts.useQuery({ onlyPublic: true });

    const { key } = useParams();
    const navigate = useNavigate();
    const handleSelect = useCallback((newKey: string | null) => newKey && navigate(routesFE.store.resolve({ key: newKey })), [ navigate ]);
    const { addAlert } = useNotifications();

    if (!store.data || !products.data)
        return null;

    const storeUrl = routesStore.root.absoluteResolve({ store: store.data.slug });

    function openStorePreview() {
        navigateNewTab(storeUrl);
    }

    async function copyStoreUrl() {
        await navigator.clipboard.writeText(storeUrl);
        addAlert(createTranslatedSuccessAlert('common:copied-to-clipboard'));
    }

    return (
        <Tabs.Root value={key} onValueChange={handleSelect} className='flex flex-col h-full'>
            <div className='bg-white border-b border-secondary-50'>
                <div className='px-4 flex items-center justify-between'>
                    <Tabs.List>
                        <Tabs.Trigger value='overview'><Copies6Icon size='xs' />{t('overview-tab')}</Tabs.Trigger>
                        <Tabs.Trigger value='design'><Palette1Icon size='xs' />{t('design-tab')}</Tabs.Trigger>
                        <Tabs.Button onClick={openStorePreview}><Eye2Icon size='xs' />{t('preview-tab')}</Tabs.Button>
                    </Tabs.List>
                    <div className='grow' />
                    {/* TODO We still don't have the button that looks like a normal text. We have to add it at some point. */}
                    <Button variant='ghost' size='small' onClick={copyStoreUrl}>
                        <Clone1Icon />{createDisplayUrl(storeUrl)}
                    </Button>
                </div>
            </div>
            <Tabs.Content value='overview' className='fl-main-scroller h-full'>
                <StoreOverviewTab store={store.data} products={products.data} />
            </Tabs.Content>
            <Tabs.Content value='design' className='fl-main-scroller h-full'>
                <StoreDesignTab store={store.data} products={products.data} />
            </Tabs.Content>
        </Tabs.Root>
    );
}

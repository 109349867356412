import { useCallback, useEffect, useState } from 'react';
import { Modal, Spinner } from ':components/shadcn';
import { useTranslation } from 'react-i18next';
import { api } from ':frontend/utils/api';
import { type ChargeError, isChargeError, type CustomOrderInit, type ProductOrderInit, customOrderToServer, productOrderToServer, type EventOrderInitFE, eventOrderToServer } from ':frontend/types/orders/Order';
import { isDurationError, type DurationError } from ':frontend/types/Event';
import { getCurrency, priceFromServer } from ':utils/money';
import { MoneyDisplay } from ':components/custom';
import { isPlanExceededError, type PlanExceededError } from ':frontend/types/Subscription';
import { useCached } from ':components/hooks';
import { useMaster } from ':frontend/context/UserProvider';
import { CheckoutType } from './useCheckout';
import type { OrderInit } from ':utils/entity/order';

export type InvoicePreviewInit = {
    type: CheckoutType.Custom;
    init: CustomOrderInit;
} | {
    type: CheckoutType.Product;
    init: ProductOrderInit;
} | {
    type: CheckoutType.Event;
    init: EventOrderInitFE;
};

type InvoicePreviewModalProps = Readonly<{
    invoicePreview?: InvoicePreviewInit;
    onHide: () => void;
    onPlanExceededError: (error: PlanExceededError) => void;
}>;

export function InvoicePreviewModal({ invoicePreview, onHide, onPlanExceededError }: InvoicePreviewModalProps) {
    const { t, i18n } = useTranslation('components', { keyPrefix: 'invoicePreviewModal' });
    const [ pdfBlobUrl, setPdfBlobUrl ] = useState('');
    const [ error, setError ] = useState<PreviewError>();

    const fetchInvoicePreview = useCallback(async (data: OrderInit, signal: AbortSignal) => {
        const response = await api.backend.getInvoicePreview(data, signal);
        if (!response.status) {
            const newError = await getError(response.error);
            if (isPlanExceededError(newError)) {
                onPlanExceededError(newError);
                onHide();
            }
            else {
                setError(newError);
            }

            return;
        }

        const blobUrl = URL.createObjectURL(response.data);
        setPdfBlobUrl(blobUrl);
    }, [ onHide, onPlanExceededError ]);

    const cached = useCached(invoicePreview);
    const masterContext = useMaster();

    useEffect(() => {
        if (!cached)
            return;

        setPdfBlobUrl('');
        setError(undefined);

        const [ signal, abort ] = api.prepareAbort();

        switch (cached.type) {
        case CheckoutType.Custom: {
            const custom = customOrderToServer(cached.init, masterContext, i18n);
            fetchInvoicePreview({ custom }, signal);
            break;
        }
        case CheckoutType.Product: {
            const product = productOrderToServer(cached.init, masterContext, i18n);
            fetchInvoicePreview({ product }, signal);
            break;
        }
        case CheckoutType.Event: {
            const event = eventOrderToServer(cached.init, i18n);
            fetchInvoicePreview({ event }, signal);
            break;
        }
        }

        return abort;
    }, [ cached, fetchInvoicePreview ]);

    return (
        <Modal.Root
            open={!!invoicePreview}
            onOpenChange={open => {
                if (!open) {
                    onHide();
                    setPdfBlobUrl('');
                }
            }}
        >
            <Modal.Content className='max-w-[80vw] h-full max-h-[80vh] flex flex-col' closeButton={t('close-button')}>
                <Modal.Header>
                    <Modal.Title>
                        {t(error ? 'error-title' : 'title')}
                    </Modal.Title>
                </Modal.Header>

                <div className='grow'>
                    {pdfBlobUrl ? (
                        <iframe
                            className='w-full h-full rounded'
                            src={pdfBlobUrl}
                            title='Invoice Preview'
                        />
                    ) : (
                        <div className='flex justify-center items-center h-full'>
                            {error && invoicePreview ? (
                                <ErrorDisplay error={error} />
                            ) : (
                                <Spinner className='mx-auto' />
                            )}
                        </div>
                    )}
                </div>
            </Modal.Content>
        </Modal.Root>
    );
}

type PreviewError = ChargeError | DurationError | PlanExceededError;

async function getError(error: unknown): Promise<PreviewError | undefined> {
    if (!error || !(error instanceof Blob))
        return undefined;

    const json = await error.text();
    const errorObject = JSON.parse(json)?.error;

    return isChargeError(errorObject) || isDurationError(errorObject) || isPlanExceededError(errorObject)
        ? errorObject
        : undefined;
}

type ErrorDisplayProps = Readonly<{
    error: PreviewError;
}>;

function ErrorDisplay({ error }: ErrorDisplayProps) {
    const { t } = useTranslation('components', { keyPrefix: 'invoicePreviewModal.error' });

    return (
        <span className='text-xl text-danger'>
            {t(error.type)}
            {isChargeError(error) && (<>
                {' '}
                <MoneyDisplay money={{ amount: priceFromServer(error.required), currency: getCurrency(error.currency) }} />
            </>)}
        </span>
    );
}

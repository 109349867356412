import LocationForm, { type LocationFormData } from './LocationForm';
import { createTranslatedErrorAlert, createTranslatedSuccessAlert } from ':frontend/components/notifications';
import useNotifications from ':frontend/context/NotificationProvider';
import { useTranslation } from 'react-i18next';
import { PlatformType, type LocationOutput, type LocationUpsert } from ':utils/entity/location';
import { trpc } from ':frontend/context/TrpcProvider';
import { Modal, Card } from ':components/shadcn';
import { PinIcon } from ':components/icons/basic';

type NewLocationModalProps = Readonly<{
    defaultTitle?: string;
    show: boolean;
    onClose(): void;
    onLocationCreated: (location: LocationOutput) => void;
}>;

export default function NewLocationModal({ defaultTitle, show, onClose, onLocationCreated }: NewLocationModalProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'newLocation' });
    const { addAlert } = useNotifications();

    const createLocationMutation = trpc.location.createLocation.useMutation();

    function onSubmit(data: LocationFormData) {
        const init = locationFormToUpsert(data);
        createLocationMutation.mutate(init, {
            onError: () => {
                addAlert(createTranslatedErrorAlert());
                onClose();
            },
            onSuccess: response => {
                onLocationCreated(response);
                addAlert(createTranslatedSuccessAlert('common:locationCreatedAlert'));
                onClose();
            },
        });
    }

    return (
        <Modal.Root open={show} onOpenChange={open => !open && onClose()}>
            <Modal.Content className='gap-6' closeButton={t('cancel-button')}>
                <Modal.Header>
                    <Modal.Title className='flex items-center gap-2'>
                        <PinIcon className='h-5 text-primary' />
                        <span>{t('modal-title')}</span>
                    </Modal.Title>
                </Modal.Header>

                <Card.Divider className='my-0' />

                <LocationForm
                    defaultValues={{ title: defaultTitle }}
                    isFetching={createLocationMutation.isPending}
                    onSubmit={onSubmit}
                />
            </Modal.Content>
        </Modal.Root>
    );
}

export function locationFormToUpsert(data: LocationFormData): LocationUpsert {
    return {
        title: data.title,
        platform: data.platformType === PlatformType.custom ? data.platform : data.platformType,
        value: data.value,
    };
}

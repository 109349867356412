import { LockIcon } from ':components/icons/basic';
import { Form } from ':components/shadcn';

export function CustomDomain() {
    return (
        <div className='flex items-center justify-between gap-4'>
            <div className='flex items-center gap-4 h-full'>
                <Form.Switch label='Your custom domain' />
            </div>

            <div className='flex flex-row items-center justify-center gap-2 text-primary bg-primary-50 px-5 py-2.5 rounded-full'>
                <LockIcon />
                {/* TODO translation */}
                Coming soon
            </div>
        </div>
    );
}

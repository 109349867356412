import { Button, Form } from ':components/shadcn';

const TODO_LINK='todo';

const PageLink = () => {
    return (
        <div className='flex flex-col gap-4'>
            <Form.Input
                placeholder={'my.flowlance.com/' + TODO_LINK}
            />

            <Button disabled size='small' className='w-full'>Save</Button>
        </div>
    );
};

export default PageLink;

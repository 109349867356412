export enum Variant {
    Success = 'success',
    Error = 'danger',
    Warning = 'warning',
}

export enum Visibility {
    Global = 'global',
    Authorized = 'authorized',
    Unauthorized = 'unauthorized',
    Dev = 'dev',
}

export type Alert = {
    content: AlertContent;
    id: number;
    show: boolean;
    removedIn?: number; // Timestamp of removal (if there were one).
    visibility: Visibility;
};

export type AlertContent = {
    message: AlertMessage;
    variant: Variant;
    timeout?: number; // In seconds.
};

export type AlertMessage = string | (({ alertId }: { alertId: number }) => JSX.Element);

export const DEFAULT_SUCCESS_ALERT_TIMEOUT = 6;
export const DEFAULT_ERROR_ALERT_TIMEOUT = 10;

import { type OrderFE, type OrderInfoFE } from ':frontend/types/orders/Order';
import { isStripeOrder } from ':utils/entity/order';
import { FaStripeS, MdPendingActions, type IconBaseProps, type IconType } from ':components/icons/old';

function getOrderIcon(order: OrderFE | OrderInfoFE): IconType {
    return isStripeOrder(order) ? FaStripeS : MdPendingActions;
}

type PaymentMethodIconProps = Omit<IconBaseProps, 'order'> & {
    order: OrderFE | OrderInfoFE;
};

export default function PaymentMethodIcon({ order, ...rest }: PaymentMethodIconProps) {
    return (
        <span className={rest.className}>
            {getOrderIcon(order)({ size: rest.size ?? 20 })}
        </span>
    );
}

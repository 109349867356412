import { useCallback, type ReactNode } from 'react';
import clsx from 'clsx';

type RadioOption<TValue> = {
    value: TValue;
    label: ReactNode;
    aria: string;
    isDisabled?: boolean;
};

type RadioProps<TValue> = Readonly<{
    value: TValue;
    options: RadioOption<TValue>[];
    onChange: (value: TValue) => void;
}>;

export type RadioComponent<TValue> = (props: RadioProps<TValue>) => JSX.Element;

type TabRadioProps<TValue> = RadioProps<TValue> & Readonly<{
    disabled?: boolean;
    isCompact?: boolean;
}>;

// TODO Move to :components whenever possible.

export function TabRadio<TValue extends string>({ value, options, onChange, disabled }: TabRadioProps<TValue>) {
    const innerOnClick = useCallback((option: RadioOption<TValue>) => {
        if (option.value === value)
            return;

        onChange(option.value);
    }, [ value, onChange ]);

    return (
        <div className={clsx('flex gap-2', { 'opacity-0.5': disabled })}>
            {options.map(option => (
                <button
                    type='button'
                    key={option.value}
                    className={clsx('p-2 grow text-center rounded-md', {
                        'bg-primary-50 text-primary': option.value === value,
                        'opacity-0.5': option.isDisabled,
                    })}
                    onClick={() => innerOnClick(option)}
                    aria-label={option.aria}
                >
                    {option.label}
                </button>
            ))}
        </div>
    );
}

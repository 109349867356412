import { Form } from ':components/shadcn';
import { useForm } from 'react-hook-form';
import { AppUserSettingsFE, TeamSettingsFE } from ':frontend/types/settings';
import { SpinnerButton } from '../common';
import { useTranslation } from 'react-i18next';
import { ControlledCountrySelect, ControlledTimezoneSelect } from ':frontend/components/forms';
import type { CountryCode, TimezoneCode } from ':utils/i18n';
import type { AppUserSettingsEdit } from ':utils/entity/settings';
import { useUser } from ':frontend/context/UserProvider';
import { trpc } from ':frontend/context/TrpcProvider';
import useNotifications from ':frontend/context/NotificationProvider';
import { createTranslatedErrorAlert, createTranslatedSuccessAlert } from '../notifications';
import { useAnalytics } from ':frontend/types/analytics';
import type { CurrencyId, TaxRateId } from ':utils/id';
import { TeamMemberRole } from ':utils/entity/team';
import { ControlledCurrencySelect } from '../forms/CurrencySelect';
import { InfoTooltip } from '../forms/buttons';
import { ControlledVatSelect } from '../forms/VatSelect';
import { useEffect } from 'react';
import { getDefaultTaxRate } from ':utils/money';

type PreferencesFormData = {
    timezone: TimezoneCode;
    /** For freelancers, this is the same as the team country. */
    country: CountryCode;
    /** Specifically for the masters. */
    teamCountry?: CountryCode;
    currencyId?: CurrencyId;
    taxRateId?: TaxRateId;
};

export function PreferencesForm() {
    const { t } = useTranslation('pages', { keyPrefix: 'settings.general' });
    const { addAlert } = useNotifications();
    const analytics = useAnalytics();
    const userContext = useUser();
    const { settings, setSettings } = userContext;

    const { control, handleSubmit, watch, setValue } = useForm<PreferencesFormData>({ defaultValues: {
        timezone: settings.timezone,
        country: settings.country,
        teamCountry: userContext.role === TeamMemberRole.master ? userContext.teamSettings.country : undefined,
        currencyId: userContext.role !== TeamMemberRole.scheduler ? userContext.teamSettings.currency.id : undefined,
        taxRateId: userContext.role !== TeamMemberRole.scheduler ? userContext.teamSettings.taxRate.id : undefined,
    } });

    const updateAppUserSettingsMutation = trpc.user.updateAppUserSettings.useMutation();

    function onPreferencesSubmit(edit: AppUserSettingsEdit) {
        updateAppUserSettingsMutation.mutate(edit, {
            onError: () => {
                setSettings({ ...settings });
                addAlert(createTranslatedErrorAlert());
            },
            onSuccess: response => {
                analytics.settingsUpdated({ ...edit });
                setSettings(AppUserSettingsFE.fromServer(response.appUserSettings));
                if (userContext.role !== TeamMemberRole.scheduler)
                    userContext.setTeamSettings(TeamSettingsFE.fromServer(response.teamSettings!));

                addAlert(createTranslatedSuccessAlert('pages:settings.general.preferences-settings-success'));
            },
        });
    }

    // The tax rate options and selected value are automatically adjusted based on the team settings country.
    const countryForTaxRate = userContext.role === TeamMemberRole.master ? watch('teamCountry') : watch('country');

    useEffect(() => {
        setValue('taxRateId', getDefaultTaxRate().id);
    }, [ countryForTaxRate ]);

    return (
        <Form.Root onSubmit={handleSubmit(onPreferencesSubmit)}>
            <div className='gap-y-4'>
                <div className='flex flex-row gap-4 pb-4'>
                    <div className='flex-1'>
                        <Form.Label>{t('timezone-label')}</Form.Label>
                        <ControlledTimezoneSelect
                            control={control}
                            name='timezone'
                        />
                    </div>
                    <div className='flex-1'>
                        <Form.Label>{t('country-label')}</Form.Label>
                        <ControlledCountrySelect
                            control={control}
                            name='country'
                        />
                    </div>
                </div>
                {/*
                    The point is not to disable the locale select, but to kinda hide the fact that we currently support only two languages.
                    <div>
                        <Form.Label>{t('locale-label')}</Form.Label>
                        <ControlledLocaleSelect
                            control={control}
                            name='locale'
                            type='base'
                        />
                    </div>
                */}
                {userContext.role === TeamMemberRole.master && (
                    <div>
                        <Form.Label>{t('teamCountry-label')}</Form.Label>
                        <ControlledCountrySelect
                            control={control}
                            name='teamCountry'
                        />
                    </div>
                )}
                {userContext.role !== TeamMemberRole.scheduler && (<>
                    <div>
                        <Form.Label>{t(`currency-label-${userContext.role}`)}</Form.Label>
                        <ControlledCurrencySelect
                            control={control}
                            name='currencyId'
                        />
                    </div>
                    {userContext.teamSettings.isTaxesEnabled && (
                        <div>
                            <Form.Label>
                                {t(`taxRate-label-${userContext.role}`)}
                                <InfoTooltip text={t('taxRate-tooltip')} className='ml-2' />
                            </Form.Label>
                            <ControlledVatSelect
                                control={control}
                                name='taxRateId'
                                country={countryForTaxRate}
                            />
                        </div>
                    )}
                </>)}
            </div>
            <div className='mt-8'>
                <div>
                    <SpinnerButton
                        type='submit'
                        size='small'
                        className='w-full'
                        isFetching={updateAppUserSettingsMutation.isPending}
                    >
                        {t('save-button')}
                    </SpinnerButton>
                </div>
            </div>
        </Form.Root>
    );
}

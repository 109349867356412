type SwitchIconProps = Readonly<{
    checked?: boolean;
    className?: string;
}>

export function SwitchIcon({ checked, className }: SwitchIconProps) {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none' className={className}>
            {checked ? (
                <path d='M13.333 7.5 8.75 12.083 6.667 10' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round'/>
            ) : (
                <path d='m12.75 7.25-5.5 5.5m0-5.5 5.5 5.5' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round'/>
            )}
        </svg>
    );
}


'use client';

import { forwardRef, type ComponentPropsWithoutRef, type ElementRef } from 'react';
import * as AccordionPrimitive from '@radix-ui/react-accordion';
import { ChevronDownIcon } from ':components/icons/basic';
import { cn } from './utils';

const Root = AccordionPrimitive.Root;

const Item = forwardRef<
    ElementRef<typeof AccordionPrimitive.Item>,
    ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({ className, ...props }, ref) => (
    <AccordionPrimitive.Item
        ref={ref}
        className={cn('border-b border-secondary-50', className)}
        {...props}
    />
));
Item.displayName = AccordionPrimitive.Item.displayName;

const Trigger = forwardRef<
    ElementRef<typeof AccordionPrimitive.Trigger>,
    ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger>
>(({ className, children, ...props }, ref) => (
    <AccordionPrimitive.Header className='flex'>
        <AccordionPrimitive.Trigger
            ref={ref}
            className={cn(
                'px-6 py-4 flex flex-1 items-center gap-2 hover:text-primary transition-all group',
                className,
            )}
            {...props}
        >
            {children}
            <div className='grow' />
            <ChevronDownIcon size={12} className='shrink-0 transition-transform duration-200 group-data-[state=open]:rotate-180' />
        </AccordionPrimitive.Trigger>
    </AccordionPrimitive.Header>
));
Trigger.displayName = AccordionPrimitive.Trigger.displayName;

const Content = forwardRef<
    ElementRef<typeof AccordionPrimitive.Content>,
    ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ className, children, ...props }, ref) => (
    <AccordionPrimitive.Content
        ref={ref}
        className='overflow-hidden transition-all data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down'
        {...props}
    >
        <div className={cn('px-6 pb-6 pt-2', className)}>{children}</div>
    </AccordionPrimitive.Content>
));

Content.displayName = AccordionPrimitive.Content.displayName;

export const Accordion = {
    Root,
    Item,
    Trigger,
    Content,
};

import { getEnumValues, type EmptyIntersection } from ':utils/common';
import { fileToFileData, ImageType, zImageType, type FileData, type FileOutput } from ':utils/entity/file';
import { useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { Images2Icon, Pencil2Icon, Trash2Icon } from ':components/icons/basic';
import { useTranslation } from 'react-i18next';
import type { z } from 'zod';
import { routesFE } from ':utils/routes';
import { Cropper, type ReactCropperElement } from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { Button, Modal } from ':components/shadcn';
import { useCached } from ':components/hooks';
import { cn } from ':components/shadcn/utils';

const acceptedImageTypes = getEnumValues(ImageType);

// Either we have the original file from server, or we have the new file to upload, or we have nothing.
// It's up to the form to handle the difference between 'no file' and 'remove file'.
export type FileInputValue = FileOutput | FileData | undefined;

type ImageInputProps = Readonly<{
    id?: string;
    value: FileInputValue;
    onChange(value: FileInputValue): void;
    imageClass?: string;
}>;

// TODO I am not sure whether the whole preview url is necessary ... It might be worthy for uploading large files (not images), however in that case we might need to upload them in a different way entirely ...

export function ImageInput({ id, value, onChange, imageClass }: ImageInputProps) {
    // The createObjectUrl is synchronous, however it's much faster than the FileReader's readAsDataURL.
    // The reason is that the readAsDataURL reads the whole file, while the createObjectURL just creates a reference. So, we can use the first one for the preview and the second one for the upload to server.
    const [ previewUrl, setPreviewUrl ] = useState<string>();

    const handleChange = useCallback(async (file: File | null) => {
        setPreviewUrl(prev => {
            if (prev)
                // The url needs to be revoked because it doesn't get garbage collected.
                URL.revokeObjectURL(prev);

            if (!file) {
                onChange(undefined);
                return undefined;
            }

            fileToFileData(file).then(onChange);
            return URL.createObjectURL(file);
        });
    }, [ onChange ]);

    useEffect(() => {
        return () => {
            if (previewUrl)
                // Again, manual revoke.
                URL.revokeObjectURL(previewUrl);
        };
    });

    return (
        <RawFileInput
            id={id}
            thumbnailUrl={getThumbnailUrl(value, previewUrl)}
            acceptedTypes={acceptedImageTypes}
            onChange={handleChange}
            zZypeValidator={zImageType}
            imageClass={imageClass}
        />
    );
}

function getThumbnailUrl(value: FileInputValue, previewUrl?: string) {
    if (!value)
        return previewUrl;

    return 'hashName' in value
        ? routesFE.files.uploads(value.hashName)
        : value.dataUrl;
}

type CroppedImageInputProps = ImageInputProps & Readonly<{
    cropperOptions: CropperOptions;
}>;

export function CroppedImageInput({ id, value, onChange, imageClass, cropperOptions }: CroppedImageInputProps) {
    const [ rawData, setRawData ] = useState<FileData>();

    const handleChange = useCallback(async (file: File | null) => {
        if (!file) {
            setRawData(undefined);
            onChange(undefined);
            return;
        }

        setRawData(await fileToFileData(file));
    }, [ onChange ]);

    const handleCropClose = useCallback((croppedData: FileData | undefined) => {
        if (croppedData)
            onChange(croppedData);

        setRawData(undefined);
    }, [ onChange ]);

    return (<>
        <RawFileInput
            id={id}
            thumbnailUrl={getThumbnailUrl(value)}
            onChange={handleChange}
            acceptedTypes={acceptedImageTypes}
            zZypeValidator={zImageType}
            imageClass={imageClass}
        />
        <CropperModal
            rawData={rawData}
            onClose={handleCropClose}
            options={cropperOptions}
        />
    </>);
}

type CropperOptions = {
    modalDescription: string;
    /** The image selection will appear rounded, however the final image won't be rounded. */
    isSelectionRounded?: boolean;
} & ({
    /** Max width of the cropped image in pixels. */
    maxWidth: number;
    /** Max height of the cropped image in pixels. */
    maxHeight: number;
} | EmptyIntersection);

type CropperModalProps = Readonly<{
    rawData: FileData | undefined;
    onClose(cropped: FileData | undefined): void;
    options: CropperOptions;
}>;

function CropperModal({ rawData, onClose, options }: CropperModalProps) {
    const { t } = useTranslation('components', { keyPrefix: 'fileInput.cropper' });
    const cropperRef = useRef<ReactCropperElement>(null);
    const cached = useCached(rawData);

    const { modalDescription, isSelectionRounded, ...rest } = options;

    function handleConfirm() {
        if (!cached)
            return;

        if (!cropperRef.current) {
            onClose(undefined);
            return;
        }

        const { cropper } = cropperRef.current;
        const canvasOptions = 'maxWidth' in rest ? fixCropperOptions(cropper, rest) : {};

        const dataUrl = cropper.getCroppedCanvas(canvasOptions).toDataURL(cached.type);
        onClose({ ...cached, dataUrl });
    }

    return (
        <Modal.Root open={!!rawData} onOpenChange={open => !open && onClose(undefined)}>
            <Modal.Content closeButton={t('cancel-button')}>
                <Modal.Header>
                    <Modal.Title>{t('modal-title')}</Modal.Title>
                    <Modal.Description className='mt-2'>{modalDescription}</Modal.Description>
                </Modal.Header>
                {cached && (
                    <Cropper
                        ref={cropperRef}
                        src={cached.dataUrl}
                        className={clsx('h-80', isSelectionRounded && 'fl-image-cropper-rounded')}
                        aspectRatio={1}
                        viewMode={1}
                        minCropBoxHeight={10}
                        minCropBoxWidth={10}
                        responsive={true}
                        autoCropArea={1}
                        checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                        guides={true}
                    />
                )}
                <Modal.Footer>
                    <Button variant='secondary' onClick={() => onClose(undefined)}>
                        {(t('cancel-button'))}
                    </Button>
                    <Button onClick={handleConfirm}>
                        {t('confirm-button')}
                    </Button>
                </Modal.Footer>
            </Modal.Content>
        </Modal.Root>
    );
}

// There is a bug in the cropper library (they call it feature ...) and this is a workaround.
// See https://github.com/fengyuanchen/cropperjs/issues/892. However, the fix didn't work! So, we had to improvise.
// Not sure how this will work with different aspect ratios, though ...
function fixCropperOptions(cropper: Cropper, { maxWidth, maxHeight }: { maxWidth: number, maxHeight: number }) {
    const outputAspectRatio = maxWidth / maxHeight;
    const { aspectRatio } = cropper.getImageData();
    return (outputAspectRatio > aspectRatio) ? { maxWidth } : { maxHeight };
}

type RawFileInputProps = Readonly<{
    id?: string;
    thumbnailUrl: string | undefined;
    onChange: (fileList: File | null) => void;
    acceptedTypes: string[];
    zZypeValidator: z.Schema;
    imageClass?: string;
}>;

function RawFileInput({ id, thumbnailUrl, onChange, acceptedTypes, zZypeValidator, imageClass }: RawFileInputProps) {
    const { t } = useTranslation('components', { keyPrefix: 'fileInput.input' });
    const inputRef = useRef<HTMLInputElement>(null);
    const [ error, setError ] = useState<string>();

    function handleInput(fileList: FileList | null) {
        if (!fileList || fileList.length === 0)
            return;

        const file = fileList[0];
        const type = zZypeValidator.safeParse(file.type);
        if (type.error) {
            setError('filetype-not-supported');
            return;
        }

        onChange(file);
    }

    return (
        <div className='w-full'>
            <input
                type='file'
                id={id}
                onChange={e => {
                    const files = e.target.files;
                    handleInput(files);
                    // This is necessary to allow the same file to be uploaded again (so that the user can choose a different crop for example).
                    e.target.value = '';
                }}
                ref={inputRef}
                accept={acceptedTypes.join(',')}
                className='hidden'
                aria-hidden
                tabIndex={-1}
            />
            <div
                className={clsx(
                    'group p-3 flex items-center justify-center rounded-lg border leading-5',
                    thumbnailUrl ? 'gap-2 cursor-auto bg-secondary-50' : 'border-dashed',
                )}
                role='button'
                aria-label={t('dnd-div-aria')}
                tabIndex={0}
                onKeyDown={e => {
                    if (e.key === 'Enter' || e.key === ' ')
                        inputRef.current?.click();
                }}
                onDragOver={e => {
                    e.preventDefault();
                    e.dataTransfer.dropEffect = 'copy';
                }}
                onDrop={e => {
                    e.preventDefault();
                    handleInput(e.dataTransfer.files);
                }}
                onClick={() => {
                    if (!thumbnailUrl)
                        inputRef.current?.click();
                }}
            >
                {thumbnailUrl ? (<>
                    <div className='w-10' />
                    <div className='grow' />
                    <img src={thumbnailUrl} className={cn('max-h-8 h-full object-contain shrink overflow-hidden select-none drag-none', imageClass)} />
                    <div className='grow' />
                    <button
                        type='button'
                        onClick={() => inputRef.current?.click()}
                        aria-label={t('edit-button-aria')}
                        className='hover:text-primary'
                    >
                        <Pencil2Icon size='sm' />
                    </button>
                    <button
                        type='button'
                        onClick={() => onChange(null)}
                        aria-label={t('remove-button-aria')}
                        className='hover:text-danger-500'
                    >
                        <Trash2Icon size='sm' />
                    </button>
                </>) : (<>
                    <button
                        type='button'
                        onClick={e => {
                            e.stopPropagation();
                            inputRef.current?.click();
                        }}
                        aria-label={t('upload-button-aria')}
                        className='flex items-center gap-2 text-nowrap group-hover:text-primary'
                    >
                        <Images2Icon size='sm' />{t('upload-button')}
                    </button>
                    <span className='ps-1'>{t('dnd-text')}</span>
                </>)}
            </div>
            {error && (
                <p className='text-danger-500'>{error}</p>
            )}
        </div>
    );
}

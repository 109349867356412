import { useEffect, useState } from 'react';
import { EventFE } from ':frontend/types/Event';
import { Pagination, Table } from ':frontend/components/common';
import EventStateBadge from ':frontend/components/event/EventStateBadge';
import { getSkeletonArray } from ':utils/math';
import { useTranslation } from 'react-i18next';
import { usePagination, type PaginationController } from ':frontend/hooks';
// import localStorage from ':frontend/utils/localStorage';
import DateTimeDisplay from '../common/DateTimeDisplay';
import ClientIconLink from '../client/ClientIconLink';
import EventStateFilter from '../common/filters/EventStateFilter';
import FilterRow, { useFilters } from '../common/filters/FilterRow';
import { EventDetailLink } from ':frontend/pages/event/EventDetail';
import { EditNotesIcon, SortOrderIcon } from ':components/icons/old';
import { TeamMemberBadge } from '../team/TeamMemberBadge';
import { useUser } from ':frontend/context/UserProvider';
import { type Entity, type Id } from ':utils/id';
import type { EventState } from ':utils/entity/event';
import { TeamMemberRole } from ':utils/entity/team';
import { SortOrder, type EnumFilter } from ':utils/common';
import { trpc } from ':frontend/context/TrpcProvider';
import { Modal } from ':components/shadcn';

// const FILTER_KEY = 'events_table_filter_0';

const filters = [ EventStateFilter ];

type Filter = {
    state: EnumFilter<EventState>;
    clientIds: Id[];
};

type OrderBy = {
    start?: SortOrder;
};

type EventsTableProps = {
    client: Entity;
    filter?: Filter;
    orderBy?: OrderBy;
};

export default function EventsTable(props: EventsTableProps) {
    const { t } = useTranslation('components', { keyPrefix: 'eventsTable' });
    const [ events, setEvents ] = useState<EventFE[]>();
    // const [ filter, setFilter ] = useState<Filter>(props.filter ?? localStorage.get<Filter>(FILTER_KEY) ?? {
    //     state: {
    //         [EventState.ready]: false,
    //         [EventState.finished]: false,
    //         [EventState.canceled]: false,
    //     },
    //     clientIds: [],
    // });
    const [ orderBy, setOrderBy ] = useState<OrderBy>(props.orderBy ?? {
        start: SortOrder.Descending,
    });

    const filtersControl = useFilters(filters);
    const stateFilterToServer = filtersControl.toServer(EventStateFilter.name);

    const pagination = usePagination();
    const { page, setTotalItems, reset } = pagination;
    useEffect(() => reset(), [ filtersControl.state, reset ]);

    const eventsQuery = trpc.event.getEvents.useQuery({
        state: stateFilterToServer as EventState[],
        // participants: props.client ? [ props.client.id ] : filter.clientIds,
        participants: [ props.client.id ],
        page,
        orderBy,
    });

    useEffect(() => {
        if (!eventsQuery.data)
            return;

        const { items, total } = eventsQuery.data;
        setEvents(items.map(EventFE.fromServer));
        setTotalItems(total);
    }, [ eventsQuery.data, setTotalItems ]);

    const isMaster = useUser().role === TeamMemberRole.master;

    return (<>
        <FilterRow control={filtersControl} className='mb-4' />
        <Table>
            {!events ? (
                <Table.HeaderSkeleton height={17} />
            ) : (
                <Table.Header>
                    <Table.HeaderCol>{t('title-label')}</Table.HeaderCol>
                    {/* {props.client
                    ?  */}

                    <Table.HeaderCol>{t('other-clients-label')}</Table.HeaderCol>
                    {/* TODO enable filtering by clients when no client is provided */}
                    {/* : (
                        <Table.HeaderCol xs={3}>
                        <span className='mr-4'>{t('clients-label')}</span>
                        <ClientIdFilter
                        value={filter.clientIds}
                        onChange={clientIds => setFilter({ ...filter, clientIds })}
                        />
                        </Table.HeaderCol>
                        )
                        } */}
                    <Table.HeaderCol xs='auto'>
                        <div className='flex items-center gap-1'>
                            <span className='whitespace-nowrap'>{t('date-from-label')}</span>
                            <SortOrderIcon
                                orderBy={orderBy.start}
                                className='select-none cursor-pointer'
                                size={18}
                                onClick={() => setOrderBy({ start: orderBy.start === SortOrder.Ascending ? SortOrder.Descending : SortOrder.Ascending })}
                            />
                        </div>
                    </Table.HeaderCol>
                    <Table.HeaderCol xs='auto'>{t('state-label')}</Table.HeaderCol>
                    <Table.HeaderCol xs='auto'>{t('notes-label')}</Table.HeaderCol>
                    {isMaster && (
                        <Table.HeaderCol xs='auto'>{t('other-owner-label')}</Table.HeaderCol>
                    )}
                </Table.Header>
            )}
            <Table.Body>
                <EventsList events={events} client={props.client} pagination={pagination} />
            </Table.Body>
        </Table>
        <Pagination controller={pagination} />
    </>);
}

type EventsListProps = Readonly<{
    events?: EventFE[];
    client?: Entity;
    pagination: PaginationController;
}>;

function EventsList({ events, client, pagination }: EventsListProps) {
    const { t } = useTranslation('components', { keyPrefix: 'eventsTable' });
    const isMaster = useUser().role === TeamMemberRole.master;

    if (!events) {
        return <>{
            getSkeletonArray(pagination).map(id => <Table.RowSkeleton key={id} height={23} />)
        }</>;
    }

    if (events.length === 0)
        return <Table.Row><Table.Col colSpan={5} className='text-center text-xl py-12'>{t('no-events-text')}</Table.Col></Table.Row>;

    return <>
        {events.map(event => (
            <Table.Row className='select-none cursor-pointer fl-hoverable' key={event.id}>
                <Table.Col xs={3} truncate link>
                    <EventDetailLink event={event}>
                        {event.displayTitle}
                    </EventDetailLink>
                </Table.Col>
                <Table.Col truncate className='select-none cursor-default fl-hoverable-exception'>
                    <div className='flex gap-2'>
                        {event.guests
                            .filter(participants => !client || participants.client.id !== client.id)
                            .map(participants => <ClientIconLink
                                key={participants.client.id}
                                client={participants.client}
                            />)
                        }
                    </div>
                </Table.Col>
                <Table.Col xs='auto' link>
                    <EventDetailLink event={event}>
                        <DateTimeDisplay dateTime={event.start} />
                    </EventDetailLink>
                </Table.Col>
                <Table.Col xs='auto' link>
                    <EventDetailLink event={event}>
                        <EventStateBadge event={event} />
                    </EventDetailLink>
                </Table.Col>
                <Table.Col xs='auto' className='text-center select-none cursor-default fl-hoverable-exception'>
                    {!!event.notes && (
                        <EventNotesModal event={event} />
                    )}
                </Table.Col>
                {isMaster && (
                    <Table.Col xs='auto' className='select-none cursor-default fl-hoverable-exception'>
                        <div className='flex justify-center'>
                            <TeamMemberBadge appUserId={event.ownerId} />
                        </div>
                    </Table.Col>
                )}
            </Table.Row>
        ))}
    </>;
}

type EventNotesModalProps = Readonly<{
    event: EventFE;
    size?: number;
}>;

export function EventNotesModal({ event, size }: EventNotesModalProps) {
    const { t } = useTranslation('components', { keyPrefix: 'eventNotesModal' });
    const [ showModal, setShowModal ] = useState(false);

    return (<>
        <EditNotesIcon
            size={size === undefined ? 20 : size}
            className='fl-button-icon'
            onClick={() => setShowModal(true)}
        />
        <Modal.Root open={showModal} onOpenChange={open => !open && setShowModal(false)}>
            <Modal.Content closeButton={t('close-button')}>
                <Modal.Header>
                    <Modal.Title>{t('title')}</Modal.Title>
                </Modal.Header>

                <div className='whitespace-pre-wrap mt-4 p-4'>
                    {event.notes}
                </div>
            </Modal.Content>
        </Modal.Root>
    </>);
}

import { CrossedEyeIcon } from ':components/icons/old';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

type OnlyToYouLabelProps = Readonly<{
    className?: string;
}>;

export function OnlyToYouLabel({ className }: OnlyToYouLabelProps) {
    const { t } = useTranslation('components');

    return (
        <div className={clsx('text-base flex items-center gap-2 text-secondary-600', className)}>
            <CrossedEyeIcon size={18} />
            <span>{t('only-to-you-label')}</span>
        </div>
    );
}

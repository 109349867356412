'use client';

import { forwardRef, useId, type ComponentPropsWithoutRef, type ElementRef, type InputHTMLAttributes, type ReactNode } from 'react';
import * as LabelPrimitive from '@radix-ui/react-label';
import TextareaAutosize, { type TextareaAutosizeProps } from 'react-textarea-autosize';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import * as SwitchPrimitives from '@radix-ui/react-switch';
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';
import { cva, type VariantProps } from 'class-variance-authority';
import { cn } from './utils';
import { SwitchIcon } from ':components/icons/custom';
import { Slot } from '@radix-ui/react-slot';

// # Forms & labels
// All form components should have associated labels. It's nice to be able to click on the label and have the input focused (or toggle a checkbox). There are also accessibility reasons.
// However, there is a slight issue - the label needs a `htmlFor` attribute that matches the input's `id`. It's hard to ensure uniqueness of `id`s across the app, but we can (and should) use the react `useId` hook to generate them. But it isn't that simple, because it's a lot of pain to generate several ids in each form and then pass them to the inputs and labels.
// Luckily, in vast majority of cases, the labels were directly above the inputs (or after the checkboxes) and without any custom styling. So, we have the convenience `label` prop in all input elements. If it's provided, the label will be automatically generated with the correct `htmlFor` and `id` attributes.
// For edge cases, there is always the option to use the `<Label>` component directly instead of the prop.

// TODO Make the `htmlFor` attribute on labels required to ensure all labels have it. Before that, we should add the label prop to all other major inputs (custom select boxes, etc).

type FormProps = InputHTMLAttributes<HTMLFormElement>;

/** automatic formNoValidate */
const Root = forwardRef<HTMLFormElement, FormProps>(({ ...props }, ref) => (
    <form
        ref={ref}
        formNoValidate
        {...props}
    />
));

const RequiredIcon = () => {
    return (
        <span className='ml-1 mr-2 text-primary text-lg leading-3' aria-hidden='true'>*</span>
    );
};

const labelVariants = cva(
    'mb-1 block leading-5 peer-disabled:opacity-70 peer-disabled:cursor-auto',
);

type LabelProps = ComponentPropsWithoutRef<typeof LabelPrimitive.Root> & VariantProps<typeof labelVariants>;

const Label = forwardRef<ElementRef<typeof LabelPrimitive.Root>, LabelProps>(({ className, ...props }, ref) => (
    <LabelPrimitive.Root
        ref={ref}
        className={cn(labelVariants(), className)}
        {...props}
    />
));
Label.displayName = LabelPrimitive.Root.displayName;

type DescriptionProps = ComponentPropsWithoutRef<'p'> & {
    id: string;
};

const Description = forwardRef<HTMLParagraphElement, DescriptionProps>(({ className, ...props }, ref) => {
    return (
        <p
            ref={ref}
            className={cn('text-secondary-300', className)}
            {...props}
        />
    );
});
Description.displayName = 'Description';

const inputVariants = cva(`w-full px-4b border bg-white whitespace-nowrap placeholder:text-secondary-200
    hover:border-primary-200 focus-visible:border-primary focus-visible:outline-none
    disabled:pointer-events-none disabled:bg-secondary-100
    `, {
    variants: {
        variant: {
            outline: 'border-secondary-100',
            ghost: 'border-white',
        },
        size: {
            compact: 'h-9 rounded-md',
            default: 'h-13 rounded-lg',
        },
    },
    defaultVariants: {
        variant: 'outline',
        size: 'default',
    },
});

type InputProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'size'> & VariantProps<typeof inputVariants> & {
    /** If not provided, the text will be used as default. */
    type?: 'text' | 'password' | 'email' | 'tel' | 'number';
    label?: ReactNode;
    hideLabel?: boolean;
    isError?: boolean;
};

const Input = forwardRef<HTMLInputElement, InputProps>(({ className, id, type, variant, size, label, hideLabel, isError, ...props }, ref) => {
    const reactId = useId();
    const finalId = id ?? reactId;

    return (<>
        {label && <Label className={hideLabel ? 'sr-only' : undefined} htmlFor={finalId}>{label}</Label>}
        <input
            ref={ref}
            id={finalId}
            className={cn(inputVariants({ variant, size, className }), isError && 'border-danger-500')}
            type={type ?? 'text'}
            {...props}
        />
    </>);
});
Input.displayName = 'Input';

type TextareaProps = TextareaAutosizeProps & {
    label?: ReactNode;
    hideLabel?: boolean;
    isError?: boolean;
};

const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(({ className, id, label, hideLabel, isError, ...props }, ref) => {
    const reactId = useId();
    const finalId = id ?? reactId;

    return (<>
        {label && <Label className={hideLabel ? 'sr-only' : undefined} htmlFor={finalId}>{label}</Label>}
        <TextareaAutosize
            ref={ref}
            id={finalId}
            // The overflow-hidden is necessary to hide scrollbar, which would make the content smaller, thus forcing the text to wrap to more lines, thus necessitating more height, thus showing the scrollbar. Its a vicious cycle.
            className={cn(`w-full p-4b rounded-lg overflow-hidden leading-6 placeholder:whitespace-pre-line
                border border-secondary-100 hover:border-primary-200 focus-visible:border-primary focus-visible:outline-none
                bg-white placeholder:text-secondary-200`, isError && 'border-danger-500', className)}
            {...props}
        />
    </>);
});

type CheckedState<T extends boolean> = T extends true ? CheckboxPrimitive.CheckedState : boolean;

type CheckboxProps<T extends boolean> = Omit<
    ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>,
    'checked' | 'defaultChecked' | 'onCheckedChange'
> & {
    checked?: CheckedState<T>;
    defaultChecked?: CheckedState<T>;
    onCheckedChange?(checked: CheckedState<T>): void;
    label?: ReactNode;
};

const Checkbox = forwardRef<ElementRef<typeof CheckboxPrimitive.Root>, CheckboxProps<false>>(({ className, id, label, ...props }, ref) => {
    const reactId = useId();
    const finalId = id ?? reactId;

    return (<>
        <CheckboxPrimitive.Root
            ref={ref}
            id={finalId}
            className={cn(
                'peer h-4 w-4 shrink-0 rounded-sm border border-primary focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 data-[state=checked]:bg-primary data-[state=checked]:text-primary-foreground',
                className,
            )}
            {...props}
        >
            <CheckboxPrimitive.Indicator
                className={cn('flex items-center justify-center text-current')}
            >
                {/* TODO check icon */}
                {/* <Check className="h-4 w-4" /> */}
                <span className='h-4 w-4'>Y</span>
            </CheckboxPrimitive.Indicator>
        </CheckboxPrimitive.Root>
        {label && <Label htmlFor={finalId}>{label}</Label>}
    </>);
});
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

const Threebox = forwardRef<ElementRef<typeof CheckboxPrimitive.Root>, CheckboxProps<true>>(({ className, id, label, ...props }, ref) => {
    const reactId = useId();
    const finalId = id ?? reactId;

    return (<>
        <CheckboxPrimitive.Root
            ref={ref}
            id={finalId}
            // TODO unify with Checkbox
            className={cn(
                'peer h-4 w-4 shrink-0 rounded-sm border border-primary focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 data-[state=checked]:bg-primary data-[state=checked]:text-primary-foreground',
                className,
            )}
            {...props}
        >
            {/* TODO indeterminate state */}
            <CheckboxPrimitive.Indicator
                className={cn('flex items-center justify-center text-current')}
            >
                {/* TODO check icon */}
                {/* <Check className="h-4 w-4" /> */}
                <span className='h-4 w-4'>Y</span>
            </CheckboxPrimitive.Indicator>
        </CheckboxPrimitive.Root>
        {label && <Label htmlFor={finalId}>{label}</Label>}
    </>);
});
Threebox.displayName = CheckboxPrimitive.Root.displayName;

type SwitchProps = ComponentPropsWithoutRef<typeof SwitchPrimitives.Root> & {
    label?: ReactNode;
};

const Switch = forwardRef<ElementRef<typeof SwitchPrimitives.Root>, SwitchProps>(({ className, id, label, checked, ...props }, ref) => {
    const reactId = useId();
    const finalId = id ?? reactId;

    return (<>
        <SwitchPrimitives.Root
            ref={ref}
            id={finalId}
            className={cn(`
                peer inline-flex h-6 w-11 shrink-0
                bg-secondary-200 data-[state=checked]:bg-primary
                items-center rounded-full border-2 border-transparent transition-colors
                focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-primary-300 focus-visible:ring-offset-2
                disabled:opacity-50
                `, className,
            )}
            checked={checked}
            {...props}
        >
            <SwitchPrimitives.Thumb
                className={cn('bg-white block h-5 w-5 rounded-full ring-0 transition-transform data-[state=checked]:translate-x-5 data-[state=unchecked]:translate-x-0 pointer-events-none',
                )}
            >
                <SwitchIcon checked={checked} className='text-secondary-600' />
            </SwitchPrimitives.Thumb>
        </SwitchPrimitives.Root>
        {label && <Label htmlFor={finalId} className='mb-0'>{label}</Label>}
    </>);
});
Switch.displayName = SwitchPrimitives.Root.displayName;

type RadioGroupProps = ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root>;

const RadioGroup = forwardRef<ElementRef<typeof RadioGroupPrimitive.Root>, RadioGroupProps>(({ className, ...props }, ref) => {
    return (
        <RadioGroupPrimitive.Root
            ref={ref}
            className={cn('flex flex-col gap-3', className)}
            {...props}
        />
    );
});
RadioGroup.displayName = RadioGroupPrimitive.Root.displayName;

// The default item has to have label, because there isn't any other information to be displayed.
// The custom item has some content so it can hide the label.

type RadioItemProps = ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item> & {
    label: ReactNode;
    description?: ReactNode;
    direction?: 'row' | 'col';
};

const RadioItem = forwardRef<
    ElementRef<typeof RadioGroupPrimitive.Item>, RadioItemProps
>(({ id, label, description, className, direction = 'row', ...props }, ref) => {
    const reactId = useId();
    const finalId = id ?? reactId;
    const descriptionId = `${finalId}-description`;

    return (
        <div className={cn('flex items-center', direction === 'row' ? 'gap-3' : 'flex-col gap-2', className)}>
            <RadioGroupPrimitive.Item
                ref={ref}
                id={finalId}
                className={`
                    peer shrink-0 h-4 w-4 rounded-full border border-primary-400 hover:border-primary
                    focus:outline-none focus-visible:ring-2 focus-visible:ring-primary-300 focus-visible:ring-offset-2
                    disabled:pointer-events-none disabled:opacity-20 disabled:border-primary-400
                `}
                {...props}
            >
                <RadioGroupPrimitive.Indicator className='flex items-center justify-center'>
                    <div className='h-2 w-2 rounded-full bg-primary' />
                </RadioGroupPrimitive.Indicator>
            </RadioGroupPrimitive.Item>
            <div className={cn('peer-disabled:[&>label]:opacity-70 peer-disabled:[&>label]:cursor-auto', direction === 'col' && 'text-center')}>
                <Label htmlFor={finalId} className='leading-4 mb-0 cursor-pointer'>{label}</Label>
                {description && <Description id={descriptionId}>{description}</Description>}
            </div>
        </div>
    );
});
RadioItem.displayName = RadioGroupPrimitive.Item.displayName;

type CustomRadioItemProps = RadioItemProps & {
    hideLabel?: boolean;
};

const CustomRadioItem = forwardRef<
    ElementRef<typeof RadioGroupPrimitive.Item>, CustomRadioItemProps
>(({ id, label, hideLabel, description, className, direction = 'row', children, ...props }, ref) => {
    const reactId = useId();
    const finalId = id ?? reactId;
    const descriptionId = `${finalId}-description`;

    return (
        <div className={cn('flex items-center', direction === 'row' ? 'gap-3' : 'flex-col gap-2', className)}>
            <RadioGroupPrimitive.Item
                ref={ref}
                id={finalId}
                className={`
                    peer shrink-0 self-stretch relative rounded [&>*]:data-[state=checked]:text-primary [&>*]:data-[state=checked]:outline-primary
                    focus-visible:ring-2 focus-visible:ring-primary-300 focus-visible:ring-offset-2
                    disabled:pointer-events-none disabled:opacity-20
                `}
                {...props}
            >
                {/* We use outline here because the border-transparent doesn't work correctly with elements with gradient. */}
                <Slot className='rounded m-[2px] outline outline-2 outline-transparent hover:text-primary-400 hover:outline-primary-400 active:text-primary active:outline-primary'>
                    {children}
                </Slot>
                <RadioGroupPrimitive.Indicator className='absolute -top-2 -right-2'>
                    <SwitchIcon checked className='rounded-full bg-primary text-white' />
                </RadioGroupPrimitive.Indicator>
            </RadioGroupPrimitive.Item>
            {(!hideLabel || description) ? (
                <div className={cn('peer-disabled:[&>label]:opacity-70 peer-disabled:[&>label]:cursor-auto text-center', direction === 'col' && 'text-center')}>
                    <Label htmlFor={finalId} className={cn('leading-4 mb-0 cursor-pointer text-secondary-300', hideLabel && 'sr-only')}>{label}</Label>
                    {description && <Description id={descriptionId}>{description}</Description>}
                </div>
            ) : (
                <Label htmlFor={finalId} className='sr-only'>{label}</Label>
            )}
        </div>
    );
});
CustomRadioItem.displayName = 'Custom' + RadioGroupPrimitive.Item.displayName;

export const Form = {
    Root,
    RequiredIcon,
    Label,
    Description,
    Input,
    Textarea,
    Checkbox,
    Threebox,
    Switch,
    RadioGroup,
    RadioItem,
    CustomRadioItem,
};

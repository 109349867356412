import { ArrowLeftIcon, ArrowRightIcon, SpaceshipIcon, TrashXmarkIcon } from ':components/icons/basic';
import { Button, Modal } from ':components/shadcn';
import { productStyles } from ':components/store/product/ProductCard';
import { BlockNavigationModal } from ':frontend/components/BlockNavigationModal';
import { SpinnerButton } from ':frontend/components/common';
import { Content, Topbar } from ':frontend/components/Layout';
import { ProductDetailsForm, ProductPublishForm } from ':frontend/components/product/productForms';
import { useProductForms, type ProductFormsDispatch, type ProductFormsState } from ':frontend/components/product/useProductForms';
import { trpc } from ':frontend/context/TrpcProvider';
import { useBlockerModal } from ':frontend/hooks';
import { type ProductOutput, type ProductType } from ':utils/entity/product';
import { routesFE } from ':utils/routes';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useParams, type NavigateFunction } from 'react-router-dom';
import { phaseLabel, ProductPhonePreview } from './NewProduct';
import { useState } from 'react';
import { createErrorAlert, createTranslatedSuccessAlert } from ':frontend/components/notifications';
import useNotifications from ':frontend/context/NotificationProvider';

export function ProductDetail() {
    const { id } = useParams<{ id: ProductType }>();
    if (!id)
        throw new Error('Missing product id');

    const productQuery = trpc.product.getProduct.useQuery({ id });
    if (!productQuery.data)
        return null;

    return (
        <ProductDetailInner product={productQuery.data} />
    );
}

type ProductDetailInnerProps = Readonly<{
    product: ProductOutput;
}>;

function ProductDetailInner({ product }: ProductDetailInnerProps) {
    const type = product.type;
    const { t } = useTranslation('pages', { keyPrefix: 'newProduct' });
    // Let's disable the navigation in all cases because the user already did something (he selected the product type).
    const { navigateUnblocked, control } = useBlockerModal(true);
    const [ state, dispatch, isFetching ] = useProductForms(product, navigateUnblocked);

    return (<>
        <Topbar>
            <ProductDetailTopbar state={state} dispatch={dispatch} isFetching={isFetching} navigateUnblocked={navigateUnblocked} />
        </Topbar>
        <Content>
            <div className='max-w-[920px] mx-auto py-12 flex justify-between'>
                <div className='flex flex-col gap-6 w-[480px]'>
                    <div className='flex items-center gap-4'>
                        <div className={clsx('w-10 h-10 rounded-full flex items-center justify-center', productStyles[type].bg)}>
                            {productStyles[type].icon({ size: 22 })}
                        </div>
                        <div>
                            <h1 className='text-md leading-4 font-semibold text-secondary-900'>{t(`${state.phase}.page-title`, { type: state.type })}</h1>
                            <p className='leading-5 mt-1'>{t(`${state.phase}.page-description`)}</p>
                        </div>
                    </div>
                    {state.phase === 'details' ? (
                        <ProductDetailsForm state={state} dispatch={dispatch} />
                    ) : (
                        <ProductPublishForm state={state} dispatch={dispatch} />
                    )}
                </div>
                <ProductPhonePreview state={state} />
            </div>
        </Content>
        <BlockNavigationModal control={control} />
    </>);
}

type ProductDetailTopbarProps = Readonly<{
    state: ProductFormsState;
    dispatch: ProductFormsDispatch;
    isFetching: boolean;
    navigateUnblocked: NavigateFunction;
}>;

function ProductDetailTopbar({ state, dispatch, isFetching, navigateUnblocked }: ProductDetailTopbarProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'newProduct' });
    const { t: td } = useTranslation('pages', { keyPrefix: 'productDetail' });
    const [ isDeleteModalOpen, setIsDeleteModalOpen ] = useState(false);

    const deleteMutation = trpc.product.deleteProduct.useMutation();

    const { addAlert } = useNotifications();

    function deleteProduct() {
        deleteMutation.mutate({ id: state.original!.id }, {
            onError: error => {
                addAlert(createErrorAlert(error.data));
            },
            onSuccess: () => {
                addAlert(createTranslatedSuccessAlert('pages:productDetail:delete-success-alert'));
                navigateUnblocked(routesFE.products.list);
            },
        });
    }

    // TODO The phase labels are wrong (2 and 3 instead of 1 and 2).
    // They probably need a complete redesign tbh ...

    return (<>
        <div className='w-1/4 flex items-center gap-2'>
            <Button variant='danger' size='small' onClick={() => setIsDeleteModalOpen(true)}>
                {td('delete-button')}
            </Button>
            <Modal.Root open={isDeleteModalOpen} onOpenChange={open => !open && setIsDeleteModalOpen(false)}>
                <Modal.Content className='max-w-md p-8 gap-6' closeButton={td('deleteModal.cancel-button')}>
                    <Modal.Header className='items-center'>
                        <TrashXmarkIcon size={32} className='text-danger' />
                        <Modal.Title className='mt-5 text-center text-2xl leading-7'>{td('deleteModal.title')}</Modal.Title>
                        <Modal.Description className='mt-3 text-center text-lg leading-7'>{td('deleteModal.text')}</Modal.Description>
                    </Modal.Header>
                    <Modal.Footer className='grid grid-cols-2'>
                        <Button onClick={() => setIsDeleteModalOpen(false)} variant='secondary'>
                            {td('deleteModal.cancel-button')}
                        </Button>
                        <Button onClick={deleteProduct} variant='primary'>
                            {td('deleteModal.confirm-button')}
                        </Button>
                    </Modal.Footer>
                </Modal.Content>
            </Modal.Root>
            {state.phase === 'publish' && (
                <Button variant='transparent' size='small' onClick={() => dispatch({ type: 'phase', value: 'back' })}>
                    <ArrowLeftIcon size='sm' />
                    {t(`${state.phase}.back-button`)}
                </Button>
            )}
        </div>
        <div className='grow' />
        <div className='flex items-center gap-2'>
            {phaseLabel('details', state.phase, t)}
            <div className={clsx('h-[1.5px] w-10 bg-primary', state.phase === 'publish' ? 'bg-primary' : 'bg-secondary-400')} />
            {phaseLabel('publish', state.phase, t)}
        </div>
        <div className='grow' />
        <div className='w-1/4 text-end'>
            <SpinnerButton
                variant='dark'
                size='small'
                onClick={() => dispatch({ type: 'phase', value: 'continue' })}
                isFetching={isFetching}
            >
                {state.phase === 'publish' && <SpaceshipIcon />}
                {t(`${state.phase}.continue-button`)}
                {state.phase === 'details' && <ArrowRightIcon />}
            </SpinnerButton>
        </div>
    </>);
}

import { type Dispatch, useMemo, useRef, useState } from 'react';
import { OrderCreatedBeforeFilter, OrderCreatedAfterFilter } from ':frontend/components/common/filters/OrderDateFilter';
import OrderStateFilter from ':frontend/components/common/filters/OrderStateFilter';
import createOrderClientFilter, { filterName as orderClientFilterName } from ':frontend/components/common/filters/OrderClientFilter';
import { useClients, useToggle } from ':frontend/hooks';
import { Button, Tooltip } from ':components/shadcn';
import { useTranslation } from 'react-i18next';
import FilterRow, { useFilters } from ':frontend/components/common/filters/FilterRow';
import { api } from ':frontend/utils/api';
import DownloadFile, { type DownloadFileRef } from ':frontend/components/common/DownloadFile';
import { SpinnerButton } from ':frontend/components/common';
import { type TFunction } from 'i18next';
import { getEnumValues } from ':utils/common';
import { type Id } from ':utils/id';
import { ExportOrdersFormat, type ExportOrdersQuery, type OrderState } from ':utils/entity/order';
import type { DateTime } from 'luxon';
import { trpc } from ':frontend/context/TrpcProvider';

export function OrdersExport() {
    const { t } = useTranslation('pages', { keyPrefix: 'ordersExport' });
    const downloadRef = useRef<DownloadFileRef>(null);
    const [ isFetching, setIsFetching ] = useToggle(false);
    const [ selectedFormat, setSelectedFormat ] = useState(ExportOrdersFormat.PdfZip);

    const { count, filtersControl, query, isFetching: isFetchingCount } = useOrderCount(selectedFormat);

    async function fetchOrderExport() {
        const ref = downloadRef.current;
        if (!ref)
            return;

        setIsFetching.true();

        const response = await api.backend.exportOrders(query);
        if (!response.status)
            return;

        ref.download(response.data);

        setIsFetching.false();
    }

    return (
        <div className='pb-8'>
            <DownloadFile fileName={`orders.${FILE_EXTENSIONS[selectedFormat]}`} ref={downloadRef} />
            <h1>{t('page-title')}</h1>
            <div>
                <label className='mb-2'>{t('export-format')}</label>
                <br />
                <div>
                    {exportFormats.map(format => formatButton(format, selectedFormat, setSelectedFormat, t))}
                </div>
            </div>
            <div className='mt-4'>
                <label className='mb-2'>{t('filters')}</label>
                <br />
                <FilterRow control={filtersControl} />
            </div>
            <SpinnerButton
                className='mt-12'
                isFetching={isFetching || isFetchingCount}
                onClick={fetchOrderExport}
                // This key is used to refresh the button each time the size of the `count` string, so that its width isn't fixed on the size of the largest width.
                key={String(count ?? 0).length}
            >
                {t('export-button', { count })}
            </SpinnerButton>
        </div>
    );
}

function formatButton(format: ExportOrdersFormat, value: ExportOrdersFormat, setValue: Dispatch<ExportOrdersFormat>, t: TFunction) {
    return (
        <Tooltip tooltipText={t(`formats.${format}.description`)}>
            <Button size='tiny' variant={value === format ? 'primary' : 'white'} onClick={() => setValue(format)}>
                {t(`formats.${format}.title`)}
            </Button>
        </Tooltip>
    );
}

// ISDOC is not supported as of Nov 2, 2024 as supporting it under Bun is harder than it should be.
// See https://github.com/oven-sh/bun/issues/4726
const exportFormats = getEnumValues(ExportOrdersFormat).filter(format => format !== ExportOrdersFormat.Isdoc);

const FILE_EXTENSIONS = {
    [ExportOrdersFormat.PdfZip]: 'zip',
    [ExportOrdersFormat.Pdf]: 'pdf',
    [ExportOrdersFormat.Csv]: 'csv',
    [ExportOrdersFormat.Isdoc]: 'zip',
} as const;

function useOrderCount(format: ExportOrdersFormat) {
    const { clients } = useClients();
    const filters = useMemo(() => [
        OrderStateFilter,
        OrderCreatedAfterFilter,
        OrderCreatedBeforeFilter,
        createOrderClientFilter(clients ?? []),
    ], [ clients ]);

    const filtersControl = useFilters(filters);

    const query: ExportOrdersQuery = {
        format,
        state: filtersControl.toServer(OrderStateFilter.name) as OrderState[] | undefined,
        client: filtersControl.toServer(orderClientFilterName) as Id[] | undefined,
        createdAfter: filtersControl.toServer(OrderCreatedAfterFilter.name) as DateTime | undefined,
        createdStrictlyBefore: filtersControl.toServer(OrderCreatedBeforeFilter.name) as DateTime | undefined,
    };

    const countExportOrdersQuery = trpc.order.countExportOrders.useQuery(query);

    return {
        count: countExportOrdersQuery.data?.count,
        filtersControl,
        query,
        isFetching: countExportOrdersQuery.isFetching,
    };
}

import { useEffect, useReducer, type Dispatch } from 'react';
import { cloneStoreDesign, type StoreDesign, type StoreEdit, type StoreOutput } from ':utils/entity/store';
import type { TypedAction } from ':frontend/utils/common';
import { Updator, type FormPath } from ':frontend/utils/updator';
import { trpc } from ':frontend/context/TrpcProvider';
import { createTranslatedErrorAlert, createTranslatedSuccessAlert } from ':frontend/components/notifications';
import useNotifications from ':frontend/context/NotificationProvider';

export function useStoreDesign(input: StoreDesign) {
    const [ state, dispatch ] = useReducer(storeDesignReducer, input, computeInitialState);

    useEffect(() => {
        if (state.sync?.fetching)
            syncUpdate(state.sync.edit);
    }, [ !!state.sync?.fetching ]);

    const { addAlert } = useNotifications();
    const updateStoreMutation = trpc.store.updateStore.useMutation();
    const trpcUtils = trpc.useUtils();

    async function syncUpdate(edit: StoreEdit) {
        const response = await updateStoreMutation.mutateAsync(edit);
        if (updateStoreMutation.isError) {
            addAlert(createTranslatedErrorAlert());
            return;
        }

        addAlert(createTranslatedSuccessAlert('pages:store.design.updateSuccessAlert'));
        trpcUtils.store.getStore.setData(undefined, response);
        dispatch({ type: 'sync', operation: 'finish', store: response });
    }

    return {
        state,
        dispatch,
    };
}

export type UseStoreDesignState = {
    original: StoreDesign;
    form: StoreDesign;
    sync?: SyncState;
};

function computeInitialState(input: StoreDesign): UseStoreDesignState {
    return {
        original: input,
        form: cloneStoreDesign(input),
    };
}

export type UseStoreDesignDispatch = Dispatch<StoreDesignAction>;

type StoreDesignAction = InputAction | SuggestionAction | SyncAction;

function storeDesignReducer(state: UseStoreDesignState, action: StoreDesignAction): UseStoreDesignState {
    console.log('Reduce:', state, action);

    switch (action.type) {
    case 'input': return input(state, action);
    case 'suggestion': return suggestion(state, action);
    case 'sync': return sync(state, action);
    }
}

// Form input

type InputAction = TypedAction<'input', {
    field: FormPath<StoreDesign>;
    value: unknown;
}>;

function input(state: UseStoreDesignState, action: InputAction): UseStoreDesignState {
    if (!state.form)
        return state;

    const { form } = Updator.update(state, action.field, action.value);

    return { ...state, form };
}

// Suggestions - templates & colors

export type ColorSuggestion = { id: string, color1: string, color2?: string };

type SuggestionAction = TypedAction<'suggestion', {
    backgroundColor: ColorSuggestion;
} | {
    template: StoreDesign;
}>;

function suggestion(state: UseStoreDesignState, action: SuggestionAction): UseStoreDesignState {
    if ('template' in action) {
        return { ...state,
            form: cloneStoreDesign(action.template),
        };
    }

    const { color1, color2 } = action.backgroundColor;

    return { ...state,
        form: { ...state.form,
            background: { ...state.form.background,
                color1,
                color2: color2 ?? state.form.background.color2,
            },
        },
    };
}

// Synchronization

export type SyncState = {
    /** The store is being saved. The request is fired when this becomes true. This is an identifier of the button that caused the fetch. */
    fetching?: string;
    edit: StoreEdit;
};


type SyncAction = TypedAction<'sync', {
    operation: 'design';
    fid?: string;
} | {
    operation: 'finish';
    store: StoreOutput;
}>;

function sync(state: UseStoreDesignState, action: SyncAction): UseStoreDesignState {
    if (action.operation === 'finish')
        return computeInitialState(action.store.design);

    return { ...state, sync: { edit: { design: state.form }, fetching: action.fid } };
}

import { useCallback, useEffect } from 'react';
import { Button, Form } from ':components/shadcn';
import { useForm, type UseFormReturn } from 'react-hook-form';
import { type InvoicingProfileFE } from ':frontend/types/Invoicing';
import { useTranslation } from 'react-i18next';
import { optionalStringToPut } from ':frontend/utils/common';
import clsx from 'clsx';
import { useUser } from ':frontend/context/UserProvider';
import { Link } from 'react-router-dom';
import { routesFE } from ':utils/routes';
import { RHFErrorMessage } from ':frontend/components/forms/ErrorMessage';
import { ControlledCondensedInvoiceToggle } from '../forms/CondensedInvoiceToggle';
import { isCustomKeyValueValid, type InvoicingProfileAdvancedUpsert } from ':utils/entity/invoicing';
import { fileDataToServer, type FileUpsert } from ':utils/entity/file';
import useNotifications from ':frontend/context/NotificationProvider';
import { createTranslatedErrorAlert } from '../notifications';
import { type FileInputValue } from ':components/custom';
import { ControlledImageInput, ControlledSwitch } from '../forms/controlled';
import { LockIcon } from ':components/icons/basic';

type PersonalizationFormData = {
    isLogoCustom: boolean;
    logo: FileInputValue;
    isCondensedInvoice: boolean;
    header: string;
    footer: string;
    customKey1: string;
    customValue1: string;
    customKey2: string;
    customValue2: string;
};

export type PersonalizationFormOutput = {
    advanced: InvoicingProfileAdvancedUpsert;
    logo: FileUpsert | null | undefined;
};

const FREE_PLAN_INVOICE_LOGO_URL = '/static/logo192.png';

function inputToForm(input: InvoicingProfileFE, isCustomLogoEnabled: boolean): PersonalizationFormData {
    return {
        isLogoCustom: isCustomLogoEnabled && input.isLogoCustom,
        logo: input.logo,
        isCondensedInvoice: input.isCondensedInvoice,
        header: input.header ?? '',
        footer: input.footer ?? '',
        customKey1: input.customKey1 ?? '',
        customValue1: input.customValue1 ?? '',
        customKey2: input.customKey2 ?? '',
        customValue2: input.customValue2 ?? '',
    };
}

async function formToOutput(data: PersonalizationFormData): Promise<PersonalizationFormOutput> {
    const logo = data.logo === undefined
        ? null
        : 'dataUrl' in data.logo
            ? fileDataToServer(data.logo)
            : undefined;

    return {
        logo,
        advanced: {
            isLogoCustom: data.isLogoCustom,
            condensedInvoice: data.isCondensedInvoice,
            header: optionalStringToPut(data.header),
            footer: optionalStringToPut(data.footer),
            customKey1: optionalStringToPut(data.customKey1),
            customValue1: optionalStringToPut(data.customValue1),
            customKey2: optionalStringToPut(data.customKey2),
            customValue2: optionalStringToPut(data.customValue2),
        },
    };
}

export const HEADER_FOOTER_MAX_LENGTH = 255;
// Default DPI for mPDF is 96, which grants us 794px of width for A4. The logo takes up half of that.
// const LOGO_MAX_WIDTH = 794 / 2;
// However, this would not be pretty. Let's round it to 400px.
// FIXME Check this later.
const LOGO_MAX_WIDTH = 400;
const LOGO_MAX_HEIGHT = 50;
const LOGO_CLASS = 'max-h-[50px] h-[50px] max-w-[400px]';

type PersonalizationFormProps = Readonly<{
    input: InvoicingProfileFE;
    onSubmit: (output: PersonalizationFormOutput) => void;
    saveButton?: React.FC<{ onClick: () => void }>;
    onChange?: (isDirty: boolean) => void;
}>;

export function PersonalizationForm({ input, onSubmit, saveButton, onChange }: PersonalizationFormProps) {
    const { t } = useTranslation('components', { keyPrefix: 'invoicingForm' });

    const { subscription } = useUser();
    const isCustomLogoEnabled = subscription.restrictions.invoicing.customLogo;

    const form = useForm<PersonalizationFormData>({
        defaultValues: inputToForm(input, isCustomLogoEnabled),
    });
    const { register, handleSubmit, watch, formState: { errors, isDirty }, reset, control } = form;

    const { addAlert } = useNotifications();
    async function onValid(data: PersonalizationFormData) {
        try {
            const output = await formToOutput(data);
            onSubmit(output);
        }
        catch (err) {
            if (err instanceof Error && err.message === 'invalid-file-type')
                addAlert(createTranslatedErrorAlert('common:error.file.invalid-file-type'));
            else
                addAlert(createTranslatedErrorAlert());
        }
    }

    useEffect(() => onChange?.(isDirty), [ isDirty ]);
    useEffect(() => {
        reset(inputToForm(input, isCustomLogoEnabled));
    }, [ input ]);

    const headerLength = watch('header').length;
    const footerLength = watch('footer').length;
    const isLogoCustom = watch('isLogoCustom');

    const { validateCustomKey1, validateCustomKey2 } = useCustomFieldsValidation(form);

    return (
        <Form.Root onSubmit={handleSubmit(onValid)} className='space-y-8'>
            <div className='space-y-4'>
                <h5 className='text-lg'>{t('custom-logo-label')}</h5>

                <div className='flex justify-between items-center'>
                    <div className='flex item-center gap-x-2'>
                        <ControlledSwitch
                            control={control}
                            name='isLogoCustom'
                            label={t('isLogoCustom-label')}
                            disabled={!isCustomLogoEnabled}
                        />
                    </div>

                    {!isCustomLogoEnabled ? <Link to={routesFE.subscription.path}>
                        <Button variant='secondary' size='small'>
                            <LockIcon />{t('upgrade-plan-button')}
                        </Button>
                    </Link> : null}
                </div>

                {isLogoCustom ? (
                    <ControlledImageInput
                        control={control}
                        name='logo'
                        imageClass='max-h-[50px] h-[50px] max-w-[400px]'
                    />
                ) : (
                    <div className='p-3 flex items-center justify-center rounded-lg border bg-secondary-50'>
                        <img src={FREE_PLAN_INVOICE_LOGO_URL} className={clsx(LOGO_CLASS, 'select-none drag-none object-contain')} />
                    </div>
                )}
            </div>

            <div className='space-y-4'>
                <h5 className='text-lg font-semibold'>Invoice details</h5>

                <div>
                    <div>
                        <Form.Label>{t('condensed-invoice-label')}</Form.Label>
                        <ControlledCondensedInvoiceToggle
                            control={control}
                            name='isCondensedInvoice'
                        />
                    </div>
                </div>

                <div className='mt-4'>
                    <Form.Textarea
                        label={`${t('header-label')} (${headerLength}/${HEADER_FOOTER_MAX_LENGTH})`}
                        placeholder={t('header-placeholder')}
                        minRows={2}
                        {...register('header', { maxLength: HEADER_FOOTER_MAX_LENGTH })}
                        aria-describedby='header-textarea'
                    />
                </div>

                <div className='mt-4'>
                    <Form.Textarea
                        label={`${t('footer-label')} (${footerLength}/${HEADER_FOOTER_MAX_LENGTH})`}
                        placeholder={t('footer-placeholder')}
                        minRows={2}
                        {...register('footer', { maxLength: HEADER_FOOTER_MAX_LENGTH })}
                        aria-describedby='footer-textarea'
                    />
                </div>
            </div>

            <div className='space-y-4'>
                <h5 className='text-lg font-semibold'>{t('custom-fields-title')}</h5>

                <div className='fl-description-no-border leading-5'>{t('custom-fields-description')}</div>

                <div className='grid grid-cols-2 gap-4'>
                    <div>
                        <Form.Input
                            label={t('custom-key-1-label')}
                            {...register('customKey1', { validate: validateCustomKey1 })}
                            placeholder={t('custom-key-1-placeholder')}
                        />
                        <RHFErrorMessage errors={errors} name='customKey1' />
                    </div>
                    <div>
                        <Form.Input
                            label={t('custom-value-1-label')}
                            {...register('customValue1')}
                            placeholder={t('custom-value-1-placeholder')}
                        />
                    </div>
                </div>

                <div className='grid grid-cols-2 gap-4'>
                    <div>
                        <Form.Input
                            label={t('custom-key-2-label')}
                            {...register('customKey2', { validate: validateCustomKey2 })}
                        />
                        <RHFErrorMessage errors={errors} name='customKey2' />
                    </div>
                    <div>
                        <Form.Input
                            label={t('custom-value-2-label')}
                            {...register('customValue2')}
                        />
                    </div>
                </div>
            </div>

            <>{saveButton?.({ onClick: handleSubmit(onValid) })}</>
        </Form.Root>
    );
}

type ValidationFormDataType = {
    customKey1: string;
    customValue1: string;
    customKey2: string;
    customValue2: string;
};

export function useCustomFieldsValidation<T extends ValidationFormDataType>(form: UseFormReturn<T>) {
    const { t } = useTranslation('common', { keyPrefix: 'form' });
    const { getValues, trigger, watch, formState: { isSubmitted } } = form as unknown as UseFormReturn<ValidationFormDataType>;

    const validateCustomKey1 = useCallback((keyRaw: string) => {
        const key = keyRaw.trim();
        const value = getValues('customValue1').trim();
        if (!isCustomKeyValueValid(key, value))
            return t('custom-key-value-required');

        return true;
    }, [ getValues, t ]);

    useEffect(() => {
        if (isSubmitted)
            trigger('customKey1');
    }, [ watch('customValue1').trim(), t ]);

    const validateCustomKey2 = useCallback((keyRaw: string) => {
        const key = keyRaw.trim();
        const value = getValues('customValue2').trim();
        if (!isCustomKeyValueValid(key, value))
            return t('custom-key-value-required');

        return true;
    }, [ t, getValues ]);

    useEffect(() => {
        if (isSubmitted)
            trigger('customKey2');
    }, [ watch('customValue2').trim(), t ]);

    return { validateCustomKey1, validateCustomKey2 };
}

type MaxLengthTextProps = Readonly<{
    length: number;
    maxLength: number;
    id: string;
}>;

export function MaxLengthText({ length, maxLength, id }: MaxLengthTextProps) {
    return (
        <div className='relative'>
            <Form.Description id={id} className={clsx('absolute whitespace-nowrap right-0', length > maxLength ? 'text-danger' : 'text-secondary-600')}>
                {length} / {maxLength}
            </Form.Description>
        </div>
    );
}

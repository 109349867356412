import { useState, useEffect, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { type ClientInfoFE } from ':frontend/types/Client';
import { type MasterProductItemFromServer, type SchedulerProductItemFromServer, masterProductItemFromServer, schedulerProductItemFromServer } from ':frontend/types/orders/OrderItem';
import { ProductOrderItemFE, type GenericProductItem } from ':frontend/types/orders/ProductOrderItem';
import { ProductItemTile } from '../product/productDisplay';
import { useClients } from ':frontend/hooks';
import { useCached } from ':components/hooks';
import { EventForm } from '../event/EventForm';
import { useScheduling } from '../event/useEvent';
import { SpinnerButton } from '../common';
import { EventScheduleModal } from '../event/EventUpdateModal';
import { toMaster, useUser } from ':frontend/context/UserProvider';
import { trpc } from ':frontend/context/TrpcProvider';
import { Modal } from ':components/shadcn';

type ClientPackagesProps = Readonly<{
    client: ClientInfoFE;
}>;

export function ClientPackages({ client }: ClientPackagesProps) {
    const { t } = useTranslation('components', { keyPrefix: 'clientPackages' });

    const { clients, addClients } = useClients();
    const context: ClientsContext | undefined = useMemo(() => clients && { clients, addClients }, [ clients, addClients ]);

    const [ productItems, setProductItems ] = useState<GenericProductItem<true>[]>();
    const activeItems = useMemo(() => productItems?.filter(oi => !ProductOrderItemFE.isCompleted(oi)), [ productItems ]);
    const completedItems = useMemo(() => productItems?.filter(oi => ProductOrderItemFE.isCompleted(oi)), [ productItems ]);

    const isMasterOrFreelancer = !!toMaster(useUser());

    const productItemsQuery = trpc.order.getSchedulableProductItems.useQuery({ guestId: client.id });

    useEffect(() => {
        if (!productItemsQuery.data)
            return;

        const fetchedOrderItems: GenericProductItem[] = isMasterOrFreelancer
            ? (productItemsQuery.data as MasterProductItemFromServer[]).map(masterProductItemFromServer)
            : (productItemsQuery.data as SchedulerProductItemFromServer[]).map(schedulerProductItemFromServer);

        const schedulableItems = fetchedOrderItems.filter((item): item is GenericProductItem<true> => item.sessionsCount !== undefined);

        setProductItems(schedulableItems);
    }, [ productItemsQuery.data, isMasterOrFreelancer ]);

    const [ schedulingProductItem, setSchedulingProductItem ] = useState<GenericProductItem<true>>();
    const finishScheduling = useCallback((update: GenericProductItem<true>) => {
        setProductItems(oldItems => oldItems?.map(item => (item.id === update.id ? update : item)));
        setSchedulingProductItem(undefined);
    }, []);
    const closeScheduling = useCallback(() => setSchedulingProductItem(undefined), []);

    if (!activeItems || !completedItems || !context)
        return null;

    return (
        <div>
            <ScheduleProductModal
                productItem={schedulingProductItem}
                client={client}
                onSchedule={finishScheduling}
                onClose={closeScheduling}
                context={context}
            />
            <h2 className='mt-0 mb-8'>{t('active-title')}</h2>
            <div className='flex flex-wrap' style={{ gap: '20px' }}>
                {activeItems.map(productItem => (
                    <ProductItemTile
                        key={productItem.id}
                        productItem={productItem}
                        onSchedule={setSchedulingProductItem}
                        isActive={productItem === schedulingProductItem}
                        hideClientId={client.id}
                    />
                ))}
            </div>
            <h2 className='mt-12 mb-8'>{t('completed-title')}</h2>
            <div className='flex flex-wrap' style={{ gap: '20px' }}>
                {completedItems.map(productItem => (
                    <ProductItemTile
                        key={productItem.id}
                        productItem={productItem}
                        hideClientId={client.id}
                    />
                ))}
            </div>
        </div>
    );
}

export type ClientsContext = {
    clients: ClientInfoFE[];
    addClients: (clients: ClientInfoFE[]) => void;
};

type ScheduleProductModalProps = Readonly<{
    productItem?: GenericProductItem<true>;
    client: ClientInfoFE;
    onSchedule: (productItem: GenericProductItem<true>) => void;
    onClose: () => void;
    context: ClientsContext;
}>;

export function ScheduleProductModal({ productItem, client, onSchedule, onClose, context }: ScheduleProductModalProps) {
    const { t } = useTranslation('components', { keyPrefix: 'clientPackages' });

    const cachedProductItem = useCached(productItem);
    if (!cachedProductItem)
        return null;

    return (
        <Modal.Root
            open={!!productItem}
            onOpenChange={open => !open && onClose()}
        >
            <Modal.Content className='fl-modal-600 rounded-2xl p-6' closeButton={t('cancel-button')}>
                <ScheduleProductModalInner
                    productItem={cachedProductItem}
                    client={client}
                    onSchedule={onSchedule}
                    context={context}
                />
            </Modal.Content>
        </Modal.Root>
    );
}

type ScheduleProductModalInnerProps = Readonly<{
    productItem: GenericProductItem<true>;
    client: ClientInfoFE;
    onSchedule: (productItem: GenericProductItem<true>) => void;
    context: ClientsContext;
}>;

function ScheduleProductModalInner({ productItem, client, onSchedule, context }: ScheduleProductModalInnerProps) {
    const { t } = useTranslation('components', { keyPrefix: 'clientPackages' });
    const { state, dispatch } = useScheduling(productItem, client, onSchedule, context.addClients);
    const recurrenceCountLimit = productItem.sessionsCount - productItem.scheduledCount;

    return (<>
        <Modal.Header>
            <Modal.Title className='mt-0 mb-8'>
                {t('schedule-product-title')}
            </Modal.Title>
        </Modal.Header>

        {/* TODO Move from modal, so that both modals are in the same modal (or that there is just one modal).
                Either way, we don't want two modals over each other. */}
        {state.sync && <EventScheduleModal state={state.sync} dispatch={dispatch} />}

        <div className='mt-4'>
            <EventForm
                state={state}
                dispatch={dispatch}
                clients={context.clients}
                isForProduct
                recurrenceCountLimit={recurrenceCountLimit}
            />
        </div>

        <Modal.Footer className='mt-8 flex justify-end'>
            <SpinnerButton
                onClick={() => dispatch({
                    type: 'sync',
                    operation: { type: 'start', isBillLater: false },
                    fid: FID_FINISH,
                })}
                fetching={state.sync?.fetching}
                fid={FID_FINISH}
            >
                {t('finish-scheduling-button')}
            </SpinnerButton>
        </Modal.Footer>
    </>);
}

const FID_FINISH = 'finish';

import { useTranslation } from 'react-i18next';
import { setLocale } from ':frontend/types/i18n';
import { findFlagUrlByIso2Code } from 'country-flags-svg';
import { getI18nLocale, SUPPORTED_LOCALES, type LocaleCode, type LocaleType } from ':utils/i18n';
import clsx from 'clsx';

type LocaleToggleProps = Readonly<{
    className?: string;
}>;

export default function LocaleToggle({ className }: LocaleToggleProps) {
    const { i18n } = useTranslation('pages');

    function nextLocale() {
        return getI18nLocale(i18n) === SUPPORTED_LOCALES.base[0] ? SUPPORTED_LOCALES.base[1] : SUPPORTED_LOCALES.base[0];
    }

    function toggleLocale() {
        setLocale(nextLocale());
    }

    return (
        <button type='button' onClick={() => toggleLocale()} className={clsx('p-1 border-0', className)} style={{ background: 'none' }}>
            <LocaleIcon locale={getI18nLocale(i18n)} size={28} />
        </button>
    );
}

type LocaleIconProps<TType extends LocaleType> = Readonly<{
    locale: LocaleCode<TType>;
    size: number;
}>;

export function LocaleIcon<TType extends LocaleType>({ locale, size }: LocaleIconProps<TType>) {
    return (
        <img src={localeToFlagUrl[locale]} className='rounded-full object-cover' style={{ width: size, height: size }} />
    );
}


// The find function is really slow (O(n)) so we have to cache it in the map.
const localeToFlagUrl = [ ...new Set<string>(Object.values(SUPPORTED_LOCALES).flatMap(locales => locales)).values() ]
    .reduce((ans, locale) => {
        ans[locale] = findFlagUrlByIso2Code(locale.split('-')[1]);
        return ans;
    }, {} as Record<string, string>);

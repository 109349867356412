import { SpinnerButton } from ':frontend/components/common';
import { useTranslation } from 'react-i18next';
import { createTranslatedErrorAlert } from ':frontend/components/notifications';
import useNotifications from ':frontend/context/NotificationProvider';
import { type ClientFE } from ':frontend/types/Client';
import { trpc } from ':frontend/context/TrpcProvider';
import { Modal } from ':components/shadcn';

type DeleteClientModalProps = Readonly<{
    client?: ClientFE;
    onClose(): void;
    onDelete?(deletedClient: ClientFE): void;
}>;

export function DeleteClientModal({ client, onClose, onDelete }: DeleteClientModalProps) {
    // TODO cache the client

    const { t } = useTranslation('components', { keyPrefix: 'deleteClientModal' });
    const { addAlert } = useNotifications();

    const deleteClientMutation = trpc.$client.deleteClient.useMutation();

    function deleteClient() {
        if (!client)
            return;

        deleteClientMutation.mutate({ id: client.id }, {
            onError: () => {
                addAlert(createTranslatedErrorAlert());
                onClose();
            },
            onSuccess: () => {
                onDelete?.(client);
            },
        });
    }

    return (
        <Modal.Root
            open={!!client}
            onOpenChange={open => !open && onClose()}
        >
            <Modal.Content closeButton={t('close-button')}>
                <Modal.Header>
                    <Modal.Title>{t('title')}</Modal.Title>
                </Modal.Header>

                <div className='mt-4'>
                    <div className='text-center'>
                        {t('text')}
                        <br />
                    </div>
                </div>

                <Modal.Footer className='flex justify-center mt-6'>
                    <SpinnerButton
                        variant='danger'
                        isFetching={deleteClientMutation.isPending}
                        onClick={deleteClient}
                    >
                        {t('confirm-button')}
                    </SpinnerButton>
                </Modal.Footer>
            </Modal.Content>
        </Modal.Root>
    );
}

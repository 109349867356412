import { Button, DropdownMenu } from ':components/shadcn';
import { useTranslation } from 'react-i18next';
import { DotsHorizontalIcon } from ':components/icons/old';

type MoreButtonDropdownProps = Readonly<{
    children: React.ReactNode;
}>;

export default function MoreButtonDropdown({ children }: MoreButtonDropdownProps) {
    const { t } = useTranslation('common', { keyPrefix: 'buttons' });

    return (
        <DropdownMenu.Root>
            <DropdownMenu.Trigger asChild>
                <Button variant='ghost-secondary' className='fl-btn_square'>
                    <DotsHorizontalIcon size={22} />
                    {t('more-button-label')}
                </Button>
            </DropdownMenu.Trigger>
            <DropdownMenu.Content className='fl-dropdown-menu min-w-0 mt-2 -left-2'>
                <div className='fl-dropdown-menu-inner'>
                    {children}
                </div>
            </DropdownMenu.Content>
        </DropdownMenu.Root>
    );
}

import { type RawBankAccountData } from ':utils/entity/bankAccountData';
import { getCurrency, type CurrencyFE } from ':utils/money';
import { type Id } from ':utils/id';
import type { BankAccountOutput } from ':utils/entity/money';

export class BankAccountFE {
    private constructor(
        readonly id: Id,
        readonly currencies: CurrencyFE[],
        readonly country: string,
        /** Domestic bank account (or IBAN, if the country isn't supported). Will be displayed on the invoice (if it's different from IBAN). */
        readonly label: string,
        /** Will be displayed on the invoice (if it's defined). */
        readonly iban: string | undefined,
        readonly swift: string | undefined,
        readonly raw: RawBankAccountData,
    ) {}

    static fromServer(input: BankAccountOutput): BankAccountFE {
        return new BankAccountFE(
            input.id,
            input.currencies.map(getCurrency),
            input.country,
            input.label,
            input.iban,
            input.swift,
            input.raw,
        );
    }
}

export function isCurrencySupported(bankAccounts: BankAccountFE[], currencyId: Id): boolean {
    return !!bankAccounts.find(account => !!account.currencies.find(currency => currency.id === currencyId));
}

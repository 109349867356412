import { Fragment, useCallback, useState } from 'react';
import { SyncType, type EventSync } from ':frontend/types/Event';
import { Button, Form, Modal } from ':components/shadcn';
import { useTranslation } from 'react-i18next';
import { SpinnerButton } from '../common';
import { getEnumValues, parseEnumValue } from ':utils/common';
import { useCached } from ':components/hooks';
import { type SyncOperation, type SyncState, SyncModal, type UseEventModalDispatch, type SchedulingSyncState } from './useEvent';
import { RecurrenceRange } from ':utils/entity/event';

type EventUpdateModalProps = Readonly<{
    state: SyncState | undefined;
    dispatch: UseEventModalDispatch;
}>;

export default function EventUpdateModal({ state, dispatch }: EventUpdateModalProps) {
    const dispatchSync = useCallback((operation: SyncOperation, fid?: string) => dispatch({ type: 'sync', operation, fid }), [ dispatch ]);

    const cached = useCached(state);
    if (!cached)
        return null;

    const phase = cached.phase;
    if (phase === SyncModal.None)
        return null;

    const ModalInner = innerModals[phase];

    return (
        <Modal.Root
            open={!!state}
            onOpenChange={open => !open && dispatchSync({ type: phase, result: 'back' })}
        >
            <ModalInner
                usecase={getUsecase(cached.eventSync)}
                dispatchSync={dispatchSync}
                fetching={state?.fetching}
            />
        </Modal.Root>
    );
}
const innerModals = {
    [SyncModal.Recurrence]: RecurrenceRangeModalInner,
    [SyncModal.Notification]: NotificationModalInner,
    [SyncModal.ConfirmDelete]: ConfirmDeleteModalInner,
    [SyncModal.ConfirmInvoice]: ConfirmInvoiceModalInner,
};

// Recurrence range modal

type ModalInnerProps = Readonly<{
    usecase: string;
    dispatchSync: (operation: SyncOperation, fid?: string) => void;
    fetching: string | undefined;
}>;

function RecurrenceRangeModalInner({ usecase, dispatchSync, fetching }: ModalInnerProps) {
    const { t } = useTranslation('components', { keyPrefix: 'eventUpdateModal.recurrence' });
    const [ range, setRange ] = useState(rangeOptions[0]);

    return (
        <Modal.Content closeButton={t('back-button')} closeDisabled={!!fetching}>
            <Modal.Header>
                <Modal.Title>{t(`${usecase}.title`)}</Modal.Title>
            </Modal.Header>

            <div className='mt-4'>
                <Form.RadioGroup
                    value={range}
                    onValueChange={value => setRange(parseEnumValue(value, RecurrenceRange)!)}
                >
                    {rangeOptions.map(option => (
                        <Fragment key={option}>
                            <Form.RadioItem
                                label={t(`${option}-description`)}
                                value={option}
                                className='mb-4'
                            />
                        </Fragment>
                    ))}
                </Form.RadioGroup>

                <Modal.Footer className='flex gap-4 mt-4'>
                    <SpinnerButton
                        key={range}
                        onClick={() => dispatchSync({ type: SyncModal.Recurrence, result: range }, FID_RECURRENCE_CONFIRM)}
                        fetching={fetching}
                        fid={FID_RECURRENCE_CONFIRM}
                    >
                        {t(`${usecase}.confirm-button`)}
                    </SpinnerButton>
                    <Button
                        variant='outline'
                        onClick={() => dispatchSync({ type: SyncModal.Recurrence, result: 'back' })}
                        disabled={!!fetching}
                    >
                        {t('back-button')}
                    </Button>
                </Modal.Footer>
            </div>
        </Modal.Content>
    );
}

const FID_RECURRENCE_CONFIRM = 'recurrence-confirm';

const rangeOptions = getEnumValues(RecurrenceRange);

function NotificationModalInner({ usecase, dispatchSync, fetching }: ModalInnerProps) {
    const { t } = useTranslation('components', { keyPrefix: 'eventUpdateModal.notification' });

    return (
        <Modal.Content closeButton={t('back-button')} closeDisabled={!!fetching}>
            <Modal.Header>
                <Modal.Title>{t(`${usecase}.title`)}</Modal.Title>
            </Modal.Header>

            <div className='mt-4'>
                <Modal.Footer className='flex flex-col sm:flex-row gap-4'>
                    <SpinnerButton
                        onClick={() => dispatchSync({ type: SyncModal.Notification, result: 'send' }, FID_NOTIFICATION_SEND)}
                        fetching={fetching}
                        fid={FID_NOTIFICATION_SEND}
                    >
                        {t('send-button')}
                    </SpinnerButton>
                    <SpinnerButton
                        variant='outline'
                        onClick={() => dispatchSync({ type: SyncModal.Notification, result: 'dont-send' }, FID_NOTIFICATION_DONTSEND)}
                        fetching={fetching}
                        fid={FID_NOTIFICATION_DONTSEND}
                    >
                        {t('dont-send-button')}
                    </SpinnerButton>
                    <Button
                        variant='outline'
                        onClick={() => dispatchSync({ type: SyncModal.Notification, result: 'back' })}
                        disabled={!!fetching}
                    >
                        {t('back-button')}
                    </Button>
                </Modal.Footer>
            </div>
        </Modal.Content>
    );
}

const FID_NOTIFICATION_SEND = 'notification-send';
const FID_NOTIFICATION_DONTSEND = 'notification-dontsend';

function getUsecase(eventSync: EventSync): string {
    return eventSync.type !== 'transition'
        ? eventSync.type
        : eventSync.data.transition;
}

// Delete modal - if we wouldn't show any other modal when deleting, we should show at least this one.

function ConfirmDeleteModalInner({ dispatchSync, fetching }: ModalInnerProps) {
    const { t } = useTranslation('components', { keyPrefix: 'eventUpdateModal.confirmDelete' });

    return (
        <Modal.Content closeButton={t('back-button')} closeDisabled={!!fetching}>
            <Modal.Header>
                <Modal.Title>{t('title')}</Modal.Title>
            </Modal.Header>

            <div className='mt-4'>
                <Modal.Footer className='flex gap-4'>
                    <SpinnerButton
                        variant='danger'
                        onClick={() => dispatchSync({ type: SyncModal.ConfirmDelete, result: 'confirm' }, FID_DELETE_CONFIRM)}
                        fetching={fetching}
                        fid={FID_DELETE_CONFIRM}
                    >
                        {t('confirm-button')}
                    </SpinnerButton>
                    <Button
                        variant='outline'
                        onClick={() => dispatchSync({ type: SyncModal.ConfirmDelete, result: 'back' })}
                        disabled={!!fetching}
                    >
                        {t('back-button')}
                    </Button>
                </Modal.Footer>
            </div>
        </Modal.Content>
    );
}

const FID_DELETE_CONFIRM = 'delete-confirm';

function ConfirmInvoiceModalInner({ dispatchSync, fetching }: ModalInnerProps) {
    const { t } = useTranslation('components', { keyPrefix: 'eventUpdateModal.confirmInvoice' });

    return (
        <Modal.Content closeButton={t('back-button')} closeDisabled={!!fetching}>
            <Modal.Header>
                <Modal.Title>{t('title')}</Modal.Title>
            </Modal.Header>

            <div className='mt-4'>
                <p>{t('description')}</p>

                <Modal.Footer className='flex gap-4 mt-4'>
                    <SpinnerButton
                        onClick={() => dispatchSync({ type: SyncModal.ConfirmInvoice, result: 'confirm' }, FID_PRICE_CONFIRM)}
                        fetching={fetching}
                        fid={FID_PRICE_CONFIRM}
                    >
                        {t('confirm-button')}
                    </SpinnerButton>
                    <Button
                        variant='outline'
                        onClick={() => dispatchSync({ type: SyncModal.ConfirmInvoice, result: 'back' })}
                        disabled={!!fetching}
                    >
                        {t('back-button')}
                    </Button>
                </Modal.Footer>
            </div>
        </Modal.Content>
    );
}

const FID_PRICE_CONFIRM = 'price-confirm';


type EventScheduleModalProps = Readonly<{
    state: SchedulingSyncState | undefined;
    dispatch: UseEventModalDispatch;
}>;

export function EventScheduleModal({ state, dispatch }: EventScheduleModalProps) {
    const dispatchSync = useCallback((operation: SyncOperation, fid?: string) => dispatch({ type: 'sync', operation, fid }), [ dispatch ]);
    const cached = useCached(state);
    if (!cached)
        return null;

    const phase = cached.phase;
    if (phase === SyncModal.None)
        return null;

    const ModalInner = innerModals[phase];

    return (
        <Modal.Root
            open={!!state}
            onOpenChange={open => !open && dispatchSync({ type: phase, result: 'back' })}
        >
            <ModalInner
                usecase={SyncType.Schedule}
                dispatchSync={dispatchSync}
                fetching={state?.fetching}
            />
        </Modal.Root>
    );
}

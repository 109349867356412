import { GoogleIntegration } from ':frontend/components/integrations/GoogleIntegration';
import { LocationsEditor } from ':frontend/components/settings/LocationsEditor';
import { Card } from ':components/shadcn';
import { useTranslation } from 'react-i18next';
import { trpc } from ':frontend/context/TrpcProvider';
import { LocationDisplay } from ':frontend/components/location/LocationDisplay';
import { Pins2Icon } from ':components/icons/basic';
import { useState } from 'react';
import type { LocationOutput } from ':utils/entity/location';

export function AdvancedSettings() {
    const { t } = useTranslation('pages', { keyPrefix: 'settings.advanced' });
    const locations = trpc.location.getLocations.useQuery().data;
    const [ editingLocation, setEditingLocation ] = useState<LocationOutput | undefined>(undefined);
    const [ deletingLocation, setDeletingLocation ] = useState<LocationOutput | undefined>(undefined);

    return (
        <div className='space-y-8'>
            <h3 className='mt-8 text-2xl font-semibold'>{t('page-title')}</h3>

            <Card>
                <div className='flex items-center justify-between'>
                    <Card.Title className='flex items-center gap-2'>
                        <Pins2Icon className='h-5 text-warning-500' />
                        {t('locations-section-title')}
                    </Card.Title>

                    <LocationsEditor
                        editingLocation={editingLocation}
                        setEditingLocation={setEditingLocation}
                        deletingLocation={deletingLocation}
                        setDeletingLocation={setDeletingLocation}
                    />
                </div>

                <Card.Divider />

                <div className='space-y-4'>
                    {locations?.map(location => (
                        <div key={location.id}>
                            <LocationDisplay
                                location={location}
                                onEdit={() => setEditingLocation(location)}
                                onDelete={() => setDeletingLocation(location)}
                            />
                        </div>
                    ))}
                </div>
            </Card>

            <Card>
                <Card.Title className='flex items-center gap-2'>
                    {t('integrations-section-title')}
                </Card.Title>

                <Card.Divider />

                <div className='space-y-4'>
                    <GoogleIntegration />
                </div>
            </Card>
        </div>
    );
}

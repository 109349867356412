import React from 'react';
import clsx from 'clsx';
import DateContentRow from './DateContentRow';
import Header from './Header';
import { localizer } from '.';
import { getNow } from './localizer';

class TimeGridHeader extends React.Component {
    renderHeaderCells(range) {
        const today = getNow();

        return range.map((date, i) => {
            return (
                <div key={i} className={clsx('rbc-header flex grow items-center justify-center', localizer.isSameDate(date, today) && 'rbc-today')}>
                    <Header date={date} type='week' />
                </div>
            );
        });
    }

    render() {
        let {
            range,
            events,
            selected,
            selectable,
            scrollRef,
            onSelectEvent,
            onSelectSlot,
        } = this.props;

        return (
            <div
                ref={scrollRef}
                className='flex flex-none shadow-[0px_10px_14px_0px_rgba(33,33,50,0.03)]'
            >
                <div className='flex-none min-w-20 w-20' />
                <div className={clsx('flex grow min-w-0 flex-col border-l border-calendar-border')}>
                    <div className={clsx('flex min-h-12', range.length <= 1 && 'hidden')}>
                        {this.renderHeaderCells(range)}
                    </div>
                    <DateContentRow
                        isAllDay
                        minRows={0}
                        range={range}
                        events={events}
                        className='rbc-allday-cell'
                        selectable={selectable}
                        selected={selected}
                        onSelect={onSelectEvent}
                        onSelectSlot={onSelectSlot}
                    />
                </div>
            </div>
        );
    }
}

export default TimeGridHeader;

import { routesFE } from ':utils/routes';
import { pricingFromServer, ProductType, type ProductOutput, type ProductPricing } from ':utils/entity/product';
import type { IconType } from ':components/icons/common';
import { LinkProductIcon, DigitalProductIcon, LeadProductIcon, MembershipProductIcon, BundleProductIcon, SessionProductIcon, CustomProductIcon } from ':components/icons/store';
import { CartShoppingIcon, CircleHalfDottedClockIcon, MoneyBillsDollarIcon, NumberInputIcon, Trash2Icon } from ':components/icons/basic';
import clsx from 'clsx';
import type { ReactNode } from 'react';
import { MoneyDisplay } from ':components/custom';
import { useTranslation } from 'react-i18next';
import type { TFunction } from 'i18next';
import { locationPlatformTranslation, simpleLocationIcon } from ':components/icons/location';
import { Button } from ':components/shadcn';
import { secondsToMinutes } from ':utils/common';
import type { LocationOutput } from ':utils/entity/location';
import type { Id } from ':utils/id';
import type { FileData } from ':utils/entity/file';

// Make sure the PublicDisplay and PhonePreview functions are always in sync.
// The reason is that it's much easier to have two functions than to somehow simulate different screen size.

type ProductPublicDisplayProps = Readonly<{
    product: ProductOutput;
    scheduleComponent: ReactNode;
    editComponent?: ReactNode;
}>;

export function ProductPublicDisplay({ product, scheduleComponent, editComponent }: ProductPublicDisplayProps) {
    const { t } = useTranslation('components', { keyPrefix: 'productPublicDisplay' });
    const styles = productStyles[product.type];
    const attributes = useProductAttributes(product, t);

    // TODO will routesFE.files.uploads work in the store?
    return (
        <div className='fl-store-font fl-store-card fl-store-shadow bg-white p-6 w-full max-w-[600px] flex flex-col gap-4'>
            {product.thumbnail && (
                <img className='rounded-full w-14 h-14' src={routesFE.files.uploads(product.thumbnail.hashName)} />
            )}
            <div className='flex justify-between'>
                <h3 className={clsx('text-lg font-semibold flex items-center gap-2', styles.color)}>
                    {styles.icon({ size: 22, className: styles.iconColor })}
                    {product.title}
                </h3>
                {editComponent}
            </div>
            {product.description && (
                <p className='leading-5 whitespace-pre-line'>
                    {product.description}
                </p>
            )}
            <div className='flex max-md:flex-col gap-4 md:gap-2 md:items-center flex-wrap'>
                <ProductPricingDisplay product={product} />
                {attributes.length > 0 && (<>
                    <div className='md:hidden w-full h-px bg-secondary-100' />
                    {...attributes}
                </>)}
                <div className='grow flex'>
                    <div className='grow max-md:hidden' />
                    <Button asChild className='fl-store-button max-md:w-full' size='small'>
                        {scheduleComponent}
                    </Button>
                </div>
            </div>
        </div>
    );
}

export type ProductPreview = {
    id: Id;
    type: ProductType;
    title: string;
    thumbnail?: FileData;
    description?: string;
    pricing?: ProductPricing;
    sessionsCount?: number;
    sessionsDuration?: number;
    location?: LocationOutput;
    buttonText: string;
};

/**
 * Copy of the previous function but only for phones. And with preview data.
 */
export function ProductPhonePreviewDisplay({ product }: Readonly<{ product: ProductPreview }>) {
    const { t } = useTranslation('components', { keyPrefix: 'productPublicDisplay' });
    const styles = productStyles[product.type];
    const attributes = useProductAttributes(product, t);

    return (
        <div className='fl-store-font fl-store-card fl-store-shadow bg-white p-6 w-full max-w-[600px] flex flex-col gap-4'>
            {product.thumbnail && (
                <img className='rounded-full w-14 h-14' src={product.thumbnail.dataUrl} />
            )}
            <div className='flex justify-between'>
                <h3 className={clsx('text-lg font-semibold flex items-center gap-2', styles.color)}>
                    {styles.icon({ size: 22, className: styles.iconColor })}
                    {product.title}
                </h3>
                {/* {editComponent} */}
            </div>
            {product.description && (
                <p className='leading-5 whitespace-pre-line'>
                    {product.description}
                </p>
            )}
            <div className='flex flex-col gap-4 flex-wrap'>
                <ProductPricingDisplay product={product} />
                {attributes.length > 0 && (<>
                    <div className='w-full h-px bg-secondary-100' />
                    {...attributes}
                </>)}
                <div className='grow flex'>
                    <div className='grow hidden' />
                    <Button className='fl-store-button w-full' size='small'>
                        {product.buttonText}
                    </Button>
                </div>
            </div>
        </div>
    );
}

export function ProductDirectSaleDisplay({ product, onClick }: Readonly<{ product: ProductOutput, onClick: () => void }>) {
    const { t } = useTranslation('components', { keyPrefix: 'productPublicDisplay' });
    const styles = productStyles[product.type];
    const attributes = useProductAttributes(product, t);

    return (
        <div className='w-full h-[250px] bg-white p-6 rounded-2xl flex flex-col gap-4'>
            <h3 className={clsx('text-lg font-semibold flex items-center gap-2', styles.color)}>
                {styles.icon({ size: 22, className: styles.iconColor })}
                {product.title}
            </h3>
            {attributes.length > 0 && (<>
                <div className='w-full h-px bg-secondary-100' />
                {...attributes}
            </>)}
            <div className='grow' />
            <div className='flex justify-between'>
                <ProductPricingDisplay product={product} />
                <Button variant='outline' size='small' onClick={onClick}>
                    <CartShoppingIcon />{t('sell-button')}
                </Button>
            </div>
        </div>
    );
}

export function ProductInvoiceItemDisplay({ product, onRemove }: Readonly<{ product: ProductOutput, onRemove?: () => void }>) {
    const { t } = useTranslation('components', { keyPrefix: 'productPublicDisplay' });
    const styles = productStyles[product.type];
    const attributes = useProductAttributes(product, t);

    return (
        <div className='w-full bg-white border p-6 rounded-2xl shadow flex items-center gap-4'>
            <div className='grow'>
                <div className='flex items-center gap-4'>
                    <h3 className={clsx('grow text-lg font-semibold flex items-center gap-2', styles.color)}>
                        {styles.icon({ size: 22, className: styles.iconColor })}
                        {product.title}
                    </h3>
                    {product.pricing && (
                        <TabularPricingDisplay pricing={product.pricing} />
                    )}
                </div>
                <div className='my-4 w-full h-px bg-secondary-100' />
                <div className='h-4 flex items-center gap-4'>
                    {...attributes}
                </div>
            </div>
            {onRemove && (
                <Button variant='transparent' size='exact' className='shrink-0' onClick={onRemove} aria-label={t('remove-button')}>
                    <Trash2Icon />
                </Button>
            )}
        </div>
    );
}

export function ProductSelectOption({ data: { value: product } }: Readonly<{ data: { value: ProductOutput }} >) {
    const { t } = useTranslation('components', { keyPrefix: 'productPublicDisplay' });
    const styles = productStyles[product.type];
    const attributes = useProductAttributes(product, t);

    return (
        // There's already some padding from the default option component.
        <div className='grow py-2 rounded'>
            <div className='flex items-center gap-4'>
                <div className={clsx('grow text-lg leading-4 font-semibold flex items-center gap-2', styles.color)}>
                    {styles.icon({ size: 'sm', className: styles.iconColor })}
                    {product.title}
                </div>
                {product.pricing && (
                    <TabularPricingDisplay pricing={product.pricing} />
                )}
            </div>
            {attributes.length > 0 && (
                <div className='mt-1 h-4 flex items-center gap-3'>
                {...attributes}
                </div>
            )}
        </div>
    );
}

function styledIcon(icon: IconType, type: ProductType): ReactNode {
    return icon({ size: 'md', className: productStyles[type].iconColor });
}

function ProductPricingDisplay({ product }: Readonly<{ product: ProductOutput | ProductPreview }>) {
    const { t } = useTranslation('components', { keyPrefix: 'productPublicDisplay' });

    return (
        <div className={clsx('w-fit flex gap-1 py-2 px-4 rounded-full border', productStyles[product.type].border)}>
            {product.pricing ? (
                <PricingDisplay type={product.type} pricing={product.pricing} />
            ) : (
                <span className='text-secondary-600 leading-[18px]'>{t('free-price')}</span>
            )}
        </div>
    );
}

// Colourful pricing display for end-customers.

type PricingDisplayProps = Readonly<{
    type: ProductType;
    pricing: ProductPricing;
}>;

function PricingDisplay({ type, pricing }: PricingDisplayProps) {
    const { price, originalPrice } = pricingFromServer(pricing);

    // TODO membership - use /mo for month, /wk for week.
    // /m., /t. should be ok for cz. Or just use the english variant?

    return (
        <div className='flex items-center gap-2'>
            {styledIcon(MoneyBillsDollarIcon, type)}
            {originalPrice && <MoneyDisplay money={originalPrice} className='line-through text-secondary-400' />}
            <MoneyDisplay money={price} />
        </div>
    );
}

// Simple, plain numbers used for invoice items.

function TabularPricingDisplay({ pricing }: Readonly<{ pricing: ProductPricing }>) {
    const { price, vat } = pricingFromServer(pricing);

    return (
        <div>
            {!vat.isZero && (
                <span className='font-semibold mr-9'>{vat.label}</span>
            )}
            <MoneyDisplay money={price} />
        </div>
    );
}

// TODO merge ProductAmount and useProductAttributes?

type ProductAmountProps = {
    product: ProductOutput;
};

export function ProductAmount({ product }: ProductAmountProps) {
    if (product.type === ProductType.Session) {
        return (
            <div className='flex gap-1 items-center'>
                <CircleHalfDottedClockIcon size={16} className={productStyles[ProductType.Session].iconColor} />
                <span>{secondsToMinutes(product.sessionsDuration)} minutes</span>
            </div>
        );
    }
    else if (product.type === ProductType.Bundle) {
        return (
            <div className='flex gap-1 items-center'>
                <CircleHalfDottedClockIcon size={16} />
                <span>{product.sessionsCount} sessions</span>
            </div>
        );

    }
}

function useProductAttributes(product: ProductOutput | ProductPreview, t: TFunction): ReactNode[] {
    const styles = productStyles[product.type];
    const output: { icon: IconType, text: string }[] = [];

    if ('sessionsCount' in product)
        output.push({ icon: NumberInputIcon, text: t('sessionsCount', { count: product.sessionsCount }) });
    if ('sessionsDuration' in product)
        output.push({ icon: CircleHalfDottedClockIcon, text: t(product.type === ProductType.Session ? 'duration' : 'duration-per-session', { count: product.sessionsDuration }) });
    if ('location' in product && product.location)
        output.push({ icon: simpleLocationIcon(product.location), text: locationPlatformTranslation(product.location, t) });

    // TODO Digital product stuff (but not url, that's private).
    // TODO Link product should show url (that's public, because there's no price).

    return output.map(({ icon, text }, i) => (
        <div key={i} className='flex gap-1 items-center'>
            {icon({ size: 'sm', className: styles.iconColor })}
            <span>{text}</span>
        </div>
    ));
}

export const productStyles: {
    [key in ProductType]: {
        icon: IconType;
        iconColor: string;
        color: string;
        bg: string;
        border: string;
    };
} = {
    [ProductType.Session]: { icon: SessionProductIcon, iconColor: 'text-[#c48f00]', color: 'text-[#6b4e00]', bg: 'bg-[#ffeab3]', border: 'border-[#e7d299]' },
    [ProductType.Bundle]: { icon: BundleProductIcon, iconColor: 'text-[#b200cf]', color: 'text-[#5c006b]', bg: 'bg-[#f3d9f8]', border: 'border-[#e099ec]' },
    [ProductType.Digital]: { icon: DigitalProductIcon, iconColor: 'text-[#00cf5f]', color: 'text-[#006b31]', bg: 'bg-[#d9f8e7]', border: 'border-[#99ecbf]' },
    [ProductType.Lead]: { icon: LeadProductIcon, iconColor: 'text-[#4b4da3]', color: 'text-[#31326b]', bg: 'bg-[#e4e4f1]', border: 'border-[#b7b8da]' },
    [ProductType.Membership]: { icon: MembershipProductIcon, iconColor: 'text-[#96031a]', color: 'text-[#6b0213]', bg: 'bg-[#dfb3ba]', border: 'border-[#d59aa3]' },
    [ProductType.Link]: { icon: LinkProductIcon, iconColor: 'text-[#009c80]', color: 'text-[#006956]', bg: 'bg-[#b2e1d9]', border: 'border-[#99d7cc]' },
    [ProductType.Custom]: { icon: CustomProductIcon, iconColor: 'text-[#b20baa]', color: 'text-[#690664]', bg: 'bg-[#f3d9f8]', border: 'border-[#e09ddd]' },
};

import { Form } from ':components/shadcn';
import type { GoogleCalendar } from ':frontend/types/calendar/Google';

type GoogleCalendarsDisplayProps = Readonly<{
    calendars: GoogleCalendar[];
    activeIds: Set<string>;
    toggleCalendar: (calendar: GoogleCalendar, newValue: boolean) => void;
}>;

export default function GoogleCalendarsDisplay({ calendars, activeIds, toggleCalendar }: GoogleCalendarsDisplayProps) {
    // The default calendar should be already filtered out.
    return (
        <div className='flex flex-col gap-2'>
            {calendars.map(calendar => {
                const isEnabled = activeIds.has(calendar.id);

                return (
                    <div key={calendar.id}>
                        <Form.Checkbox
                            label={calendar.name}
                            checked={isEnabled}
                            onCheckedChange={value => toggleCalendar(calendar, value)}
                            style={{ '--fl-base-color': calendar.color }}
                        />
                    </div>
                );
            })}
        </div>
    );
}

'use client';

import { forwardRef, type ComponentPropsWithoutRef, type ElementRef, type HTMLAttributes } from 'react';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import { cn } from './utils';
import { XmarkIcon } from ':components/icons/basic';

const Root = DialogPrimitive.Root;

const Trigger = DialogPrimitive.Trigger;

const Portal = DialogPrimitive.Portal;

const Close = DialogPrimitive.Close;

type ModalOverlayProps = ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay> & {
    className?: string;
};

const Overlay = forwardRef<ElementRef<typeof DialogPrimitive.Overlay>, ModalOverlayProps>(({ className, ...props }, ref) => (
    <DialogPrimitive.Overlay
        ref={ref}
        className={cn(`
            fixed inset-0 z-50 bg-[rgba(184,161,230,0.10)] backdrop-blur-lg data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0
            overflow-y-auto max-h-screen grid place-items-center
            `, className,
        )}
        {...props}
    />
));
Overlay.displayName = 'ModalOverlay';

type ModalContentProps = ComponentPropsWithoutRef<typeof DialogPrimitive.Content> & {
    className?: string;
    /** The label of the close button for screen readers. If null, the close button won't be displayed at all. */
    closeButton: string | null;
    closeDisabled?: boolean;
};

const Content = forwardRef<ElementRef<typeof DialogPrimitive.Content>, ModalContentProps>(({ className, closeButton, closeDisabled, children, ...props }, ref) => (
    <Portal>
        <Overlay>
            <DialogPrimitive.Content
                ref={ref}
                className={cn(`
                    z-50 relative grid gap-4 w-full max-w-lg
                    p-6 border-2 border-primary bg-white rounded-2xl shadow-lg duration-200
                    data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95
                    `, className,
                )}
                {...props}
            >
                {children}
                {closeButton !== null && (
                    <DialogPrimitive.Close
                        className='absolute right-4 top-4 rounded-sm opacity-70 transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-secondary-600'
                        disabled={closeDisabled}
                    >
                        <span className='h-4 w-4'>
                            <XmarkIcon size='sm' />
                        </span>
                        <span className='sr-only'>{closeButton}</span>
                    </DialogPrimitive.Close>
                )}
            </DialogPrimitive.Content>
        </Overlay>
    </Portal>
));
Content.displayName = 'ModalContent';

const Header = ({
    className,
    ...props
}: HTMLAttributes<HTMLDivElement>) => (
    <div
        className={cn('flex flex-col', className)}
        {...props}
    />
);
Header.displayName = 'ModalHeader';

const Footer = ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
    <div
        className={cn('flex justify-end gap-2', className)}
        {...props}
    />
);
Footer.displayName = 'ModalFooter';

type ModalTitleProps = ComponentPropsWithoutRef<typeof DialogPrimitive.Title> & {
    className?: string;
};

const Title = forwardRef<ElementRef<typeof DialogPrimitive.Title>, ModalTitleProps>(({ className, ...props }, ref) => (
    <DialogPrimitive.Title
        ref={ref}
        className={cn('text-2lg font-semibold text-secondary-900', className)}
        {...props}
    />
));
Title.displayName = 'ModalTitle';

type ModalDescriptionProps = ComponentPropsWithoutRef<typeof DialogPrimitive.Description> & {
    className?: string;
};

const Description = forwardRef<ElementRef<typeof DialogPrimitive.Description>, ModalDescriptionProps>(({ className, ...props }, ref) => (
    <DialogPrimitive.Description
        ref={ref}
        className={className}
        {...props}
    />
));
Description.displayName = 'ModalDescription';

export const Modal = {
    Root,
    Portal,
    Overlay,
    Close,
    Trigger,
    Content,
    Header,
    Footer,
    Title,
    Description,
};

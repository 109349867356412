import { type Money, moneyFromServer, type TaxRateFE, priceToServer, type CurrencyFE } from ':utils/money';
import { optionalStringToPut } from ':frontend/utils/common';
import { DateTime } from 'luxon';
import { LogFE } from '../Log';
import { type Id, type OmitId } from ':utils/id';
import { InvoicingIdentityFE, invoicingIdentityUpdateToServer, type InvoicingIdentityUpdateFE } from ':frontend/types/Invoicing';
import { ClientInfoFE } from '../Client';
import { EventOrderItemFE, type EditableEventItem } from './EventOrderItem';
import { basicItemUpdateToServer, CustomOrderItemFE, type EditableBasicItem } from './CustomOrderItem';
import { orderItemFromServer, type OrderItemFE } from './OrderItem';
import { ProductOrderItemFE } from './ProductOrderItem';
import { type i18n } from 'i18next';
import { clientOrContactToServer, type Participant, getParticipantName, type PayingParticipant } from '../EventParticipant';
import { type EventFE } from '../Event';
import { type MasterContext } from ':frontend/context/UserProvider';
import { type TeamMemberFE } from '../Team';
import { createExampleId } from ':utils/id';
import { getEnumValues, type RequiredNonNull } from ':utils/common';
import type { z } from 'zod';
import type { zDiscountItemInit } from ':utils/entity/orderItem';
import { OrderState, OrderTransition, PaymentMethod, type EventOrderInit, type NotificationInit, type OrderCustomFields, type OrderEdit, type OrderInfoOutput, type OrderOutput, type SchedulerOrderInfoOutput, type zCustomOrderInit, type zProductOrderInit } from ':utils/entity/order';
import type { ProductOutput, ProductPricingFE } from ':utils/entity/product';

export const MAX_DESCRIPTION_LENGTH = 2000;
export const DEFAULT_PREFIX = 'FLOW-';

export const ORDER_STATE_VALUES = getEnumValues(OrderState);

export type InvoiceInfo = {
    readonly prefix: string;
    readonly index: number;
};

function invoiceInfoFromServer(input: OrderInfoOutput): InvoiceInfo | undefined {
    if (input.prefix === undefined || input.index === undefined)
        return undefined;

    return { prefix: input.prefix, index: input.index };
}

export class OrderInfoFE {
    private constructor(
        readonly id: Id,
        readonly client: ClientInfoFE,
        readonly title: string,
        readonly price: Money,
        readonly state: OrderState,
        readonly createdAt: DateTime,
        readonly issueDate: DateTime,
        readonly paymentMethod: PaymentMethod,
        readonly invoice: InvoiceInfo | undefined,
        /** AppUser */
        readonly schedulerId: Id | undefined,
    ) {}

    static fromServer(input: OrderInfoOutput): OrderInfoFE {
        return new OrderInfoFE(
            input.id,
            ClientInfoFE.fromServer(input.client),
            input.title,
            moneyFromServer(input.total, input.currencyId),
            input.state,
            input.createdAt,
            input.issueDate,
            input.paymentMethod,
            invoiceInfoFromServer(input),
            input.schedulerId,
        );
    }

    // If the order is free, it can't be marked as fulfilled. Its payment method must be noInvoice.
    // However, a non-free order with the noInvoice method can still be marked as fulfilled.
    get isFree(): boolean {
        return this.price.amount === 0;
    }

    static createExample(variant: number, currency: CurrencyFE, issuedAt: DateTime, state: OrderState): OrderInfoFE {
        const d = exampleVariants[variant];

        return new OrderInfoFE(
            createExampleId('orders'),
            ClientInfoFE.createExample(),
            d.title,
            { amount: d.price, currency },
            state,
            issuedAt,
            issuedAt,
            PaymentMethod.bankTransfer,
            undefined,
            undefined,
        );
    }
}

const exampleVariants = [
    { title: 'Free consultation', price: 0 },
    { title: '1:1 Session', price: 70 },
    { title: '3 month program (10 sessions)', price: 500 },
] as const;

export class SchedulerOrderInfoFE {
    private constructor(
        readonly id: Id,
        readonly client: ClientInfoFE,
        readonly title: string,
        readonly state: OrderState,
        readonly createdAt: DateTime,
    ) {}

    static fromServer(input: SchedulerOrderInfoOutput): SchedulerOrderInfoFE {
        return new SchedulerOrderInfoFE(
            input.id,
            ClientInfoFE.fromServer(input.client),
            input.title,
            input.state,
            DateTime.fromISO(input.createdAt),
        );
    }
}

function orderFieldsFromServer(input: OrderOutput): OrderCustomFields {
    return {
        header: input.header,
        footer: input.footer,
        customKey1: input.customKey1,
        customValue1: input.customValue1,
        customKey2: input.customKey2,
        customValue2: input.customValue2,
    };
}

export type EditableOrderFields = RequiredNonNull<OrderCustomFields>;

export function orderFieldsUpdateToServer(input: EditableOrderFields): OrderCustomFields {
    return {
        header: optionalStringToPut(input.header),
        footer: optionalStringToPut(input.footer),
        customKey1: optionalStringToPut(input.customKey1),
        customValue1: optionalStringToPut(input.customValue1),
        customKey2: optionalStringToPut(input.customKey2),
        customValue2: optionalStringToPut(input.customValue2),
    };
}

type InvoiceDetail = InvoiceInfo &{
    readonly variableSymbol: string;
};

function invoiceDetailFromServer(input: OrderOutput): InvoiceDetail | undefined {
    const info = invoiceInfoFromServer(input);
    if (!info || input.variableSymbol === undefined)
        return undefined;

    return { ...info, variableSymbol: input.variableSymbol };
}

export class OrderFE {
    protected constructor(
        readonly id: Id,
        readonly client: ClientInfoFE,
        readonly title: string,
        readonly isNotificationSent: boolean,
        readonly supplier: InvoicingIdentityFE,
        readonly subscriber: InvoicingIdentityFE,
        readonly price: Money,
        readonly state: OrderState,
        readonly createdAt: DateTime,
        readonly issueDate: DateTime,
        readonly dueDate: DateTime,
        readonly taxDate: DateTime,
        readonly isCondensedInvoice: boolean,
        readonly paymentMethod: PaymentMethod,
        readonly items: OrderItemFE[],
        readonly logs: LogFE[],
        readonly fields: OrderCustomFields,
        readonly invoice: InvoiceDetail | undefined,
    ) {}

    static fromServer(input: OrderOutput): OrderFE {
        return new OrderFE(
            input.id,
            ClientInfoFE.fromServer(input.client),
            input.title,
            input.isNotificationSent,
            InvoicingIdentityFE.fromServer(input.supplier),
            InvoicingIdentityFE.fromServer(input.subscriber),
            moneyFromServer(input.total, input.currencyId),
            input.state,
            input.createdAt,
            input.issueDate,
            input.dueDate,
            input.taxDate,
            input.condensedInvoice,
            input.paymentMethod,
            input.items.map(item => orderItemFromServer(item, input)).toSorted((a, b) => a.index - b.index),
            input.logs.map(LogFE.fromServer).sort(LogFE.compareDesc),
            orderFieldsFromServer(input),
            invoiceDetailFromServer(input),
        );
    }

    /** returns just EventOrderItem-s */
    getEventItems = (): EventOrderItemFE[] => (this.items.filter((value): value is EventOrderItemFE => (value instanceof EventOrderItemFE)));

    /** returns {Custom,Product}OrderItem-s, without EventOrderItem-s */
    getBasicItems = (): OrderItemFE[] =>
        (this.items.filter((value): value is CustomOrderItemFE | ProductOrderItemFE =>
            (value instanceof CustomOrderItemFE) || (value instanceof ProductOrderItemFE)));

    get isFree(): boolean {
        return this.price.amount === 0;
    }
}

type Notification = {
    email: string;
    cc: string[];
    subject: string;
    body: string;
};

export type DiscountItem = {
    price: Money;
    vat: TaxRateFE;
    label: string;
};

export function determineIsSendNotification(method: PaymentMethod): boolean | undefined {
    if (method === PaymentMethod.stripe)
        return true;
    if (method === PaymentMethod.noInvoice)
        return false;
}

enum ErrorType {
    MinimalCharge = 'order.minimalCharge',
    MaximalCharge = 'order.maximalCharge',
    NumberAlreadyUsed = 'order.numberAlreadyUsed',
}

export type ChargeError = {
    type: ErrorType.MinimalCharge | ErrorType.MaximalCharge;
    provided: number;
    required: number;
    currency: Id;
};

export function isChargeError(value: unknown): value is ChargeError {
    if (!value || typeof value !== 'object')
        return false;

    if (!('type' in value))
        return false;

    return value.type === ErrorType.MinimalCharge || value.type === ErrorType.MaximalCharge;
}

export type NumberAlreadyUsed = {
    type: ErrorType.NumberAlreadyUsed;
    prefix: string;
    index: number;
};

export function isNumberAlreadyUsed(value: unknown): value is NumberAlreadyUsed {
    if (!value || typeof value !== 'object')
        return false;

    if (!('type' in value))
        return false;

    return value.type === ErrorType.NumberAlreadyUsed;
}

export type OrderTransitionFE = OrderTransition.Fulfill | OrderTransition.Unfulfill;

const stateTransitionPrerequisites: {
    [key in OrderTransitionFE]: OrderState[];
} = {
    [OrderTransition.Fulfill]: [ OrderState.new, OrderState.overdue ],
    [OrderTransition.Unfulfill]: [ OrderState.fulfilled ],
};

export function canTransition(order: OrderFE | OrderInfoFE, transition: OrderTransitionFE): boolean {
    // Free orders can't be fulfilled or unfulfilled. However, if a new transition is added, this condition needs to be reviewed.
    if (order.isFree)
        return false;

    return stateTransitionPrerequisites[transition].includes(order.state);
}

export type OrderEditFE = {
    prefix?: string;
    index?: number;
    variableSymbol?: string;
    issueDate?: DateTime;
    dueDate?: DateTime;
    taxDate?: DateTime;
    isCondensedInvoice?: boolean;
    subscriber?: InvoicingIdentityUpdateFE;
    supplier?: InvoicingIdentityUpdateFE;
    fields?: EditableOrderFields;
    eventItems: EditableEventItem[];
    basicItems: EditableBasicItem[];
};

export enum SyncType {
    Update = 'update',
    Transition = 'transition',
    Delete = 'delete',
    SendNotification = 'sendNotification',
}

type TOrderSync<TType extends SyncType, TData> = {
    type: TType;
    data: TData;
}

export type OrderUpdate = TOrderSync<SyncType.Update, OrderEditFE>;
export type OrderDoTransition = TOrderSync<SyncType.Transition, { transition: OrderTransitionFE }>;
export type OrderDelete = TOrderSync<SyncType.Delete, undefined>;
export type OrderSendNotification = TOrderSync<SyncType.SendNotification, Notification>;

export type OrderSync = OrderUpdate | OrderDoTransition | OrderDelete | OrderSendNotification;

export function orderEditToServer({ data }: OrderUpdate): OmitId<OrderEdit> {
    const orderItems = [ ...data.eventItems, ...data.basicItems ].map(basicItemUpdateToServer);

    return {
        prefix: data.prefix,
        index: data.index,
        variableSymbol: data.variableSymbol,
        issueDate: data.issueDate,
        dueDate: data.dueDate,
        taxDate: data.taxDate,
        condensedInvoice: data.isCondensedInvoice,
        supplier: data.supplier && invoicingIdentityUpdateToServer(data.supplier),
        subscriber: data.subscriber && invoicingIdentityUpdateToServer(data.subscriber),
        fields: data.fields && orderFieldsUpdateToServer(data.fields),
        orderItems,
    };
}

export type CustomOrderInit = {
    client: Participant;
    dueDays: number | undefined;
    items: FECustomItemInit[];
    discountItems: DiscountItem[];
    paymentMethod: PaymentMethod;
    notification: Notification | undefined;
};

export type FECustomItemInit = {
    label: string;
    quantity: number;
    price: Money;
    vat: TaxRateFE;
};

export function customOrderToServer(init: CustomOrderInit, { settings }: MasterContext, i18n: i18n): z.infer<typeof zCustomOrderInit> {
    const { paymentMethod, dueDays, notification, client, discountItems } = init;
    const title = i18n.t('components:checkout.custom-order-title', { client: getParticipantName(client) });

    const currency = init.items[0].price.currency;
    const items = init.items.map(item => ({
        title: item.label,
        quantity: item.quantity,
        unitPrice: priceToServer(item.price.amount),
        vat: item.vat.id,
    }));

    discountItems
        .map(discountItemToServer)
        .forEach(item => items.push(item));

    return {
        title,
        paymentMethod,
        dueDays,
        notification: notification && notificationToServer(notification),
        client: clientOrContactToServer(client, settings),
        currencyId: currency.id,
        items,
    };
}

export type ProductOrderInit = {
    client: Participant;
    guest: Participant;
    scheduler?: TeamMemberFE;
    items: ProductItemInit[];
    discountItems: DiscountItem[];
    paymentMethod: PaymentMethod;
    notification: Notification | undefined;
};

export type ProductItemInit = {
    product: ProductOutput;
    pricing: ProductPricingFE | undefined;
};

export function productOrderToServer(init: ProductOrderInit, { settings }: MasterContext, i18n: i18n): z.infer<typeof zProductOrderInit> {
    const { paymentMethod, notification, client, guest, items, discountItems } = init;
    // TODO check the translations
    const title = items.length === 1
        ? i18n.t('components:checkout.product-order-single-item-title', { ...items[0].product, guest: getParticipantName(guest) })
        : i18n.t('components:checkout.product-order-title', { guest: getParticipantName(guest) });

    return {
        title,
        paymentMethod,
        scheduler: init.scheduler?.appUserId,
        notification: notification && notificationToServer(notification),
        client: clientOrContactToServer(client, settings),
        guest: clientOrContactToServer(guest, settings),
        productItems: items.map(item => item.product.id),
        discountItems: discountItems.map(discountItemToServer),
    };
}

function discountItemToServer(item: DiscountItem): z.infer<typeof zDiscountItemInit> {
    return {
        title: item.label,
        quantity: 1,
        unitPrice: priceToServer(item.price.amount),
        vat: item.vat.id,
        currencyId: item.price.currency.id,
    };
}

export type EventOrderInitFE = {
    paymentMethod: PaymentMethod;
    notification: Notification | undefined;
    forClients: EventItemsForClient[];
};

export type EventItemsForClient = {
    info: ClientInfoFE;
    participants: EventParticipantItem[];
};

export type EventParticipantItem = {
    event: EventFE;
    participant: PayingParticipant;
};

export function eventOrderToServer(init: EventOrderInitFE, i18n: i18n): EventOrderInit {
    const { paymentMethod, notification, forClients } = init;

    const eventItems = forClients
        .map(({ info, participants }) => ({
            title: i18n.t('components:checkout.event-order-title', { client: info.name }),
            participantIds: participants.map(p => p.participant.id),
        }))
        .filter(item => item.participantIds.length > 0);

    return {
        paymentMethod,
        notification: notification && notificationToServer(notification),
        eventItems,
    };
}

export function notificationToServer(notification: Notification): NotificationInit {
    return {
        email: optionalStringToPut(notification.email),
        cc: notification.cc,
        subject: notification.subject,
        body: notification.body,
    };
}

import type { NavigateAction } from '..';

export const Navigate: {
    [key: string]: NavigateAction;
} = {
    PREVIOUS: 'PREV',
    NEXT: 'NEXT',
    TODAY: 'TODAY',
    DATE: 'DATE',
};

// TODO move to some context?
// TODO fix - currently not working everywhere
export const showMultiDayTimes = false;

// How many slots there are in one section.
export const timeslots = 4;
// How much time (in minutes) each slot has.
export const step = 15;

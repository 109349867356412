import { useMemo, useState, type KeyboardEvent, useCallback } from 'react';
import { TaskFE } from ':frontend/types/Task';
import { Button, Form } from ':components/shadcn';
import { EditingPhase, useEditing, useToggle, useUpdating } from ':frontend/hooks';
import { CrossedEyeIcon, EyeIcon, PlusIcon } from ':components/icons/old';
import { ChevronDownIcon, ChevronRightIcon } from ':components/icons/basic';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { DeleteButton } from '../forms/buttons';
import { trpc } from ':frontend/context/TrpcProvider';

export function TaskList() {
    const { t } = useTranslation('components', { keyPrefix: 'taskList' });
    const [ completedVisible, setCompletedVisible ] = useToggle(false);
    const [ tasksVisible, setTasksVisible ] = useToggle(true);
    const tasksQuery = trpc.task.getTasks.useQuery();
    const trpcUtils = trpc.useUtils();

    const tasks = useMemo(() => {
        if (!tasksQuery.data)
            return;

        return tasksQuery.data.map(TaskFE.fromServer);

    }, [ tasksQuery.data ]);

    const uncompleted = useMemo(() => tasks?.filter(task => !task.isCompleted), [ tasks ]);
    const completed = useMemo(() => tasks?.filter(task => task.isCompleted), [ tasks ]);

    const updateTask = async (action: UpdateTaskAction) => {
        if (!tasksQuery.data || !tasks)
            return;
        const newTasks = computeTaskUpdate(tasks, action);
        trpcUtils.task.getTasks.setData(undefined, newTasks.map(task => ({
            id: task.id,
            text: task.text,
            createdAt: task.createdAt,
            completed: task.isCompleted,
        })));
    };

    return (
        <div className='fl-task-list'>
            <h2>
                <Button
                    className='fl-btn-default-empty mr-1'
                    variant='ghost-secondary'
                    size='tiny'
                    onClick={setTasksVisible.toggle}
                    aria-label={t('toggle-tasks-aria')}
                >
                    {tasksVisible ? (
                        <ChevronDownIcon size={24} />
                    ) : (
                        <ChevronRightIcon size={24} />
                    )}
                </Button>
                <span>{t('title')}</span>
            </h2>
            {tasksVisible && (<>
                <NewTaskRow onUpdate={updateTask} />
                <div className='flex flex-col'>
                    {uncompleted?.map(task => (
                        <TaskRow
                            key={task.id}
                            task={task}
                            onUpdate={updateTask}
                        />
                    ))}
                </div>
                {!!completed?.length && (
                    <Button
                        variant='secondary'
                        size='tiny' className='text-secondary flex items-center gap-1 ml-2 my-1'
                        onClick={setCompletedVisible.toggle}
                    >
                        {completedVisible ? (<>
                            <span>{t('hide-completed')}</span>
                            <CrossedEyeIcon size={18} />
                        </>) : (<>
                            <span>{t('show-completed')}</span>
                            <EyeIcon size={18} />
                        </>)}
                    </Button>
                )}
                {completedVisible && completed?.map(task => (
                    <TaskRow
                        key={task.id}
                        task={task}
                        onUpdate={updateTask}
                    />
                ))}
            </>)}
        </div>
    );
}

export type UpdateTaskAction = {
    type: 'create' | 'update' | 'delete';
    task: TaskFE;
};

function computeTaskUpdate(tasks: TaskFE[], { type, task }: UpdateTaskAction): TaskFE[] {
    if (type === 'create')
        return [ task, ...tasks ];

    if (type === 'delete')
        return tasks.filter(t => task.id !== t.id);

    const index = tasks.findIndex(t => task.id === t.id);
    if (index === -1)
        return tasks;

    const newTasks = [ ...tasks ];
    newTasks[index] = task;

    return newTasks;
}


type TaskRowProps = Readonly<{
    task: TaskFE;
    onUpdate: (action: UpdateTaskAction) => void;
}>;

function TaskRow({ task, onUpdate }: TaskRowProps) {
    const { t } = useTranslation('components', { keyPrefix: 'taskList' });
    const updateTaskMutation = trpc.task.updateTask.useMutation();
    const id = task.id;

    const syncText = useCallback(async (newValue: string) => {
        try {
            const response = await updateTaskMutation.mutateAsync({ id, text: newValue });
            onUpdate({ type: 'update', task: TaskFE.fromServer(response) });
            return true;
        }
        catch {
            return false;
        }
    }, [ onUpdate, id, updateTaskMutation ]);

    const { state: { value: text, phase: textPhase }, setValue: setText, doUpdate: updateText } = useEditing<string>(task.text, syncText);

    const syncIsCompleted = useCallback(async (newValue: boolean) => {
        try {
            const response = await updateTaskMutation.mutateAsync({ id, completed: newValue });
            onUpdate({ type: 'update', task: TaskFE.fromServer(response) });
            return true;
        }
        catch {
            return false;
        }
    }, [ onUpdate, id, updateTaskMutation ]);

    const [ isCompleted, updateIsCompleted, isUpdatingIsCompleted ] = useUpdating<boolean>(task.isCompleted, syncIsCompleted);


    const deleteTaskMutation = trpc.task.deleteTask.useMutation({
        onError: () => {
            // TODO Do something.
        },
        onSuccess: () => {
            onUpdate({ type: 'delete', task });
        },
    });

    return (
        <div className='fl-task-row flex items-center justify-around p-2 pl-4 gap-2 rounded rounded-md'>
            <Form.Checkbox
                label={t('toggle-task-aria')}
                checked={isCompleted}
                onCheckedChange={updateIsCompleted}
                className='fl-task-check'
                disabled={isUpdatingIsCompleted || deleteTaskMutation.isPending}
            />
            <TaskTextInput
                text={text}
                onChange={setText}
                placeholder={t('empty-task')}
                // We have to do this here because the doUpdate function behaves differently if there is a value passed to it.
                onConfirm={() => updateText()}
                disabled={textPhase === EditingPhase.Updating || deleteTaskMutation.isPending}
            />
            <DeleteButton aria={t('remove-task-aria')} className='fl-task-remove' onClick={() => deleteTaskMutation.mutate({ id })} />
        </div>
    );
}

type NewTaskRowProps = Readonly<{
    onUpdate: (action: UpdateTaskAction) => void;
}>;

function NewTaskRow({ onUpdate }: NewTaskRowProps) {
    const { t } = useTranslation('components', { keyPrefix: 'taskList' });
    const [ text, setText ] = useState('');

    const createTaskMutation = trpc.task.createTask.useMutation({
        onError: () => {
            // TODO Do something.
        },
        onSuccess: response => {
            onUpdate({ type: 'create', task: TaskFE.fromServer(response) });
            setText('');
        },
    });

    return (
        <div className='fl-task-row flex items-center w-full bg-secondary-200 text-secondary rounded rounded-md py-2 px-2 mb-1'>
            <PlusIcon size={24} style={{ margin: '0px 2px' }} />
            <div className='grow'>
                <TaskTextInput
                    text={text}
                    onChange={setText}
                    placeholder={t('new-task')}
                    onConfirm={() => createTaskMutation.mutate({ text })}
                    disabled={createTaskMutation.isPending}
                />
            </div>
        </div>
    );
}

type TaskTextInputProps = Readonly<{
    text: string;
    placeholder?: string;
    disabled: boolean;
    onChange: (newText: string) => void;
    onConfirm: () => void;
}>;

function TaskTextInput({ text, placeholder, disabled, onChange, onConfirm }: TaskTextInputProps) {
    function handleKeyDown(e: KeyboardEvent<HTMLInputElement>) {
        if (e.key === 'Enter') {
            e.preventDefault();
            (e.target as HTMLInputElement).blur();
        }
    }

    return (
        <Form.Input
            value={text}
            placeholder={placeholder}
            onChange={e => onChange(e.target.value)}
            onKeyDown={handleKeyDown}
            onBlur={onConfirm}
            disabled={disabled}
            className={clsx('fl-task-input pl-1 truncate', disabled && 'text-secondary')}
            aria-label={text || placeholder}
        />
    );
}

import { abbreviateStringToLength } from ':frontend/utils/common';
import { useTranslation } from 'react-i18next';
import { MapPinIcon, SettingsIcon } from ':components/icons/old';
import { Skeleton } from '../common';
import clsx from 'clsx';
import { getLocationLink, getPlatformType, PlatformType, type LocationOutput } from ':utils/entity/location';
import { Trash2Icon } from ':components/icons/basic';
import { OnlineLocationIcon } from ':components/icons/location';
import { Button } from ':components/shadcn';

type LocationDisplayProps = Readonly<{
    location: LocationOutput;
    onEdit?: () => void;
    onDelete?: () => void;
    isCompact?: boolean;
    className?: string;
}>;

export function LocationDisplay({ location, onEdit, onDelete, isCompact, className }: LocationDisplayProps) {
    const { t } = useTranslation('components', { keyPrefix: 'locationDisplay' });

    return (
        <div className={clsx('select-none flex items-center justify-between border rounded-2xl p-5', className)}>
            <a href={getLocationLink(location)} target='_blank' rel='noreferrer' className='flex items-center gap-4 no-underline text-inherit hover:underline truncate'>
                <LocationIcon location={location} size={isCompact ? 18 : 32} />

                {isCompact ? (
                    <div className='leading-6 text-secondary-900'>{location.title}</div>
                ) : (
                    <div className='space-y-1'>
                        <h2 className='text-secondary-900'>{location.title}</h2>

                        <div className='text-secondary'>{abbreviateStringToLength(location.value, 60)}</div>
                    </div>
                )}
            </a>

            <div className='flex items-center'>
                {onEdit && <Button variant='transparent' size='small' onClick={onEdit} className='text-primary text-sm'>
                    {t('edit-button')}
                </Button>}

                {onDelete && <Button variant='transparent' size='small' onClick={onDelete}>
                    <Trash2Icon className='h-3' />
                </Button>}
            </div>
        </div>
    );
}

type TryLocationDisplayProps = Readonly<{
    location?: LocationOutput;
    isDefined: boolean;
    isCompact?: boolean;
}>;

export function TryLocationDisplay({ location, isDefined, isCompact }: TryLocationDisplayProps) {
    return location
        ? <LocationDisplay location={location} isCompact={isCompact} />
        : <LocationPlaceholder isDefined={isDefined} isCompact={isCompact} />;
}

type LocationPlaceholderProps = Readonly<{
    isDefined: boolean;
    isCompact?: boolean;
}>;

export function LocationPlaceholder({ isDefined, isCompact }: LocationPlaceholderProps) {
    const { t } = useTranslation('common');

    if (isDefined)
        return <Skeleton height={isCompact ? 21 : 39} />;

    return (
        <div className='flex items-center'>
            <MapPinIcon size={18} className='mr-2' />
            <span className='text-secondary'>{t('location-placeholder')}</span>
        </div>
    );
}

type LocationIconProps = Readonly<{
    location: LocationOutput;
    size?: string | number;
    className?: string;
}>;

export function LocationIcon(props: LocationIconProps) {
    return PlatformTypeIcon({
        ...props,
        type: getPlatformType(props.location.platform),
    });
}

type PlatformTypeIconProps = Readonly<{
    type: PlatformType;
    size?: string | number;
    className?: string;
}>;

export function PlatformTypeIcon({ type, size, className }: PlatformTypeIconProps) {
    return type === PlatformType.custom
        ? <SettingsIcon style={{ width: size, height: size }} className={className} />
        : <OnlineLocationIcon type={type} size={size} className={className} />;
}

import { useCallback, useEffect, useMemo, useState } from 'react';
import { schedulerProductItemFromServer } from ':frontend/types/orders/OrderItem';
import { ProductOrderItemFE, type SchedulerProductItem } from ':frontend/types/orders/ProductOrderItem';
import { ProductItemTile, ProductTileSkeletons } from ':frontend/components/product/productDisplay';
import { type ClientsContext, ScheduleProductModal } from ':frontend/components/client/ClientPackages';
import { useClients } from ':frontend/hooks';
import { useTranslation } from 'react-i18next';
import { trpc } from ':frontend/context/TrpcProvider';

type ActiveProducts = {
    /** There is at least one unscheduled item. */
    schedulable: SchedulerProductItem[];
    /** Fully scheduled, but there is at least one unfinished event. */
    ongoing: SchedulerProductItem[];
};

export default function SchedulerDashboard() {
    const { t } = useTranslation('pages', { keyPrefix: 'dashboard' });
    const [ activeProducts, setActiveProducts ] = useState<ActiveProducts>();
    const [ schedulingProductItem, setSchedulingProductItem ] = useState<SchedulerProductItem>();

    const { clients, addClients } = useClients();
    const context: ClientsContext | undefined = useMemo(() => clients && { clients, addClients }, [ clients, addClients ]);

    const guest = useMemo(() => schedulingProductItem && clients?.find(c => c.id === schedulingProductItem?.guest?.id), [ clients, schedulingProductItem ]);

    const finishScheduling = useCallback((update: SchedulerProductItem) => {
        setActiveProducts(oldActive => updateActiveProducts(oldActive, update));
        setSchedulingProductItem(undefined);
    }, []);
    const closeScheduling = useCallback(() => setSchedulingProductItem(undefined), []);

    const productItemsQuery = trpc.order.getSchedulableProductItems.useQuery({ onlyActive: true });

    useEffect(() => {
        if (!productItemsQuery.data)
            return;

        const fetchedOrderItems = productItemsQuery.data.map(schedulerProductItemFromServer);
        setActiveProducts(createActiveProducts(fetchedOrderItems));
    }, [ productItemsQuery.data ]);

    return (
        <div className='fl-main-scroller' style={{ backgroundColor: '#F5F5F5' }}>
            {/* The scheduler dashboard is wider than the default one, because we want to fit 4 packages (285px each) here (as is on the client packages page). In order to keep the thin layout, we would have to either limit the packages to 240px, or display only three but make them much wider so that they would fill the whole width. */}
            <div className='max-w-[1200px] w-full mx-auto pb-12'>
                {/* // TODO some onboarding */}
                <h2 style={{ margin: '24px 0px' }}>{t('products-to-schedule-title')}</h2>
                <div className='flex flex-wrap' style={{ gap: '20px' }}>
                    {!activeProducts && (
                        <ProductTileSkeletons variant='product-item' />
                    )}
                    {activeProducts?.schedulable.map(productItem => (
                        <ProductItemTile
                            key={productItem.id}
                            productItem={productItem}
                            onSchedule={setSchedulingProductItem}
                            isActive={productItem === schedulingProductItem}
                        />
                    ))}
                    {activeProducts?.schedulable.length === 0 && (
                        <div className='bg-white rounded-2xl text-center w-full text-xl py-12'>
                            {t('nothing-to-schedule-text')}
                        </div>
                    )}
                </div>
                <h2 style={{ margin: '24px 0px' }}>{t('products-ongoing-title')}</h2>
                <div className='flex flex-wrap' style={{ gap: '20px' }}>
                    {!activeProducts && (
                        <ProductTileSkeletons variant='product-item' />
                    )}
                    {activeProducts?.ongoing.map(productItem => (
                        <ProductItemTile
                            key={productItem.id}
                            productItem={productItem}
                            onSchedule={setSchedulingProductItem}
                            isActive={productItem === schedulingProductItem}
                        />
                    ))}
                    {activeProducts?.ongoing.length === 0 && (
                        <div className='bg-white rounded-2xl text-center w-full text-xl py-12'>
                            {t('nothing-ongoing-text')}
                        </div>
                    )}
                </div>
            </div>
            {context && guest && (
                <ScheduleProductModal
                    productItem={schedulingProductItem}
                    client={guest}
                    onSchedule={finishScheduling}
                    onClose={closeScheduling}
                    context={context}
                />
            )}
        </div>
    );
}

function createActiveProducts(data: SchedulerProductItem<true>[]): ActiveProducts {
    const schedulable = data.filter(poi => !ProductOrderItemFE.isScheduled(poi));
    const ongoing = data.filter(poi => ProductOrderItemFE.isScheduled(poi));

    return { schedulable, ongoing };
}

function updateActiveProducts(oldActive: ActiveProducts | undefined, update: SchedulerProductItem): ActiveProducts | undefined {
    if (!oldActive)
        return oldActive;

    const allItems = [ ...oldActive.schedulable, ...oldActive.ongoing ]
        .map(item => item.id === update.id ? update : item);

    return createActiveProducts(allItems);
}

import { useEffect, useReducer } from 'react';
import { type ClientInfoFE } from ':frontend/types/Client';
import { Form } from ':components/shadcn';
import { Trans, useTranslation } from 'react-i18next';
import { Pagination, Skeleton } from ':frontend/components/common';
import { usePagination, useHashFragment } from ':frontend/hooks';
import { type EventFE } from ':frontend/types/Event';
import { Link } from 'react-router-dom';
import { PAGE_SIZE, notesReducer, initialState, type NotesState } from ':frontend/types/Notes';
import { routesFE } from ':utils/routes';
import { EventDetailLink } from '../event/EventDetail';
import DateTimeDisplay from ':frontend/components/common/DateTimeDisplay';
import { SendNotesButton } from ':frontend/components/event/EventNotes';
import { EditNotesIcon, SortOrderIcon } from ':components/icons/old';
import { SortOrder } from ':utils/common';
import { CLIENT_DETAIL_SCROLLER_ID } from './ClientDetail';
import { TeamMemberBadge } from ':frontend/components/team/TeamMemberBadge';
import { useUser } from ':frontend/context/UserProvider';
import { TeamMemberRole } from ':utils/entity/team';
import { trpc } from ':frontend/context/TrpcProvider';

type ClientNotesProps = Readonly<{
    client: ClientInfoFE;
}>;

export default function ClientNotes({ client }: ClientNotesProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'client.notes' });
    const pagination = usePagination();
    const [ state, dispatch ] = useReducer(notesReducer, initialState);
    useHashFragment(CLIENT_DETAIL_SCROLLER_ID, state.events);

    const utils = trpc.useUtils();

    async function fetchEvents(order: SortOrder) {
        dispatch({ type: 'fetchStart' });

        const response = await utils.event.getEvents.fetch({
            participants: [ client.id ],
            page: pagination.page,
            orderBy: {
                start: order,
            },
            notes: state.filter,
        });

        dispatch({
            type: 'fetchSuccess',
            response,
            pagination,
        });
    }

    useEffect(() => {
        fetchEvents(state.order);
    }, [ state.filter, pagination.page ]);

    function handleFilterChange(newFilter: string) {
        dispatch({
            type: 'filterChange',
            filter: newFilter,
            pagination,
        });
        // hide the Pagination
        pagination.setTotalItems(1);
        pagination.reset();
    }

    async function handleOrderChange() {
        const newOrder = state.order === SortOrder.Ascending ? SortOrder.Descending : SortOrder.Ascending;
        const totalEvents = pagination.totalItems;

        dispatch({
            type: 'orderChange',
            order: newOrder,
            totalEvents,
        });

        // for small lengths, the reducer orders the items in place
        if (totalEvents > PAGE_SIZE) {
            // FIXME: handleOrderChange gets called multiple times and responses arrive in wrong order
            // we want to abort old requests and ignore their responses
            await fetchEvents(newOrder);
        }

        // TODO: we probably want to reset the pagination,
        // but that causes multiple fetches (one in `if` ^, another in useEffect ^^)
    }

    return (
        <div>
            <div className='mb-8'>
                <div>
                    <h1 className='m-0'>{t('page-title')}</h1>
                </div>
                <div  className='flex items-center gap-4'>
                    <SortOrderIcon
                        orderBy={state.order}
                        className='select-none cursor-pointer shrink-0'
                        size={20}
                        onClick={handleOrderChange}
                    />
                    <Form.Input
                        onChange={e => handleFilterChange(e.target.value)}
                        value={state.filter}
                        placeholder={t('search-placeholder')}
                    />
                </div>
            </div>
            <ClientNotesContent client={client} state={state} />
            <Pagination controller={pagination} />
        </div>
    );
}

type ClientNotesContentProps = Readonly<{
    client: ClientInfoFE;
    state: NotesState;
}>;

function ClientNotesContent({ client, state }: ClientNotesContentProps) {
    if (state.error)
        return <ClientNotesError client={client} state={state} />;

    if (state.showFetching) {
        return (
            <div>
                <Skeleton height={96} className='mb-4' />
                <Skeleton height={96} className='mb-4' />
                <Skeleton height={96} />
            </div>
        );
    }

    if (state.events)
        return <NotesList client={client} events={state.events} />;

    return null;
}

function ClientNotesError({ client, state }: ClientNotesContentProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'client.notes' });

    if (state.error === 'noEvents') {
        return (
            <Trans
                i18nKey='no-events-found'
                t={t}
                components={{
                    a: <Link to={routesFE.clients.detail.resolve({ id: client.id, key: 'events' })} />,
                }}
                values={{ name: client.name }}
                className='text-lg'
            />
        );
    }

    if (state.error === 'noSearchResults')
        return <span className='text-lg'>{t('no-notes-found', { text: state.filter })}</span>;

    return <span className='text-lg'>{t('unknown-error')}</span>;
}

type NotesListProps = Readonly<{
    client: ClientInfoFE;
    events: EventFE[];
}>;

function NotesList({ client, events }: NotesListProps) {
    const isMaster = useUser().role === TeamMemberRole.master;

    return (
        <div className='flex flex-col gap-8'>
            {events.map(event => event.notes ? (
                <div key={event.id} id={event.id}>
                    <div className='flex items-center gap-4 py-2'>
                        <EditNotesIcon size={20} />
                        <EventDetailLink event={event} className='text-2lg text-inherit no-underline hover:underline'>{event.displayTitle}</EventDetailLink>
                        {isMaster && (
                            <TeamMemberBadge appUserId={event.ownerId} />
                        )}
                        <span className='text-secondary-600'><DateTimeDisplay dateTime={event.start} /></span>
                        <div className='grow' />
                        <SendNotesButton event={event} client={client} />
                    </div>
                    <div className='flex gap-4'>
                        <div style={{ width: 20 }} />
                        <div className='fl-notes-text px-4 py-2 w-full whitespace-pre-wrap'>
                            {event.notes}
                        </div>
                    </div>
                </div>
            ) : null)}
        </div>
    );
}

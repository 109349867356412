import { useCallback, useMemo } from 'react';
import { type ClientInfoFE } from ':frontend/types/Client';
import { useTranslation } from 'react-i18next';
import { type UseEventFormDispatch, type UseEventFormState } from './useEvent';
import { Form } from ':components/shadcn';
import { ArrowIcon, CalendarIcon, ClockIcon, MapPinIcon, UsersIcon } from ':components/icons/old';
import { ContinuousParticipantSelect, ParticipantRowLarge } from '../client/ContinuousParticipantSelect';
import { DatePicker, RecurrenceInput } from '../forms';
import { type DateTime } from 'luxon';
import { minutesToSeconds } from ':utils/common';
import type { TFunction } from 'i18next';
import { ErrorMessage } from ':frontend/components/forms/ErrorMessage';
import { LocationSelect } from '../location/LocationSelect';
import { LocationDisplay } from '../location/LocationDisplay';
import { DeleteButton } from '../forms/buttons';
import { useToggle } from ':frontend/hooks';
import type { Participant } from ':frontend/types/EventParticipant';
import { TeamMemberSelect } from '../team/TeamMemberSelect';
import { useUser } from ':frontend/context/UserProvider';
import { TeamMemberRole } from ':utils/entity/team';
import { computeRecurrenceCount, type Recurrence } from ':utils/recurrence';
import { computeEventDuration } from ':utils/entity/event';
import { trpc } from ':frontend/context/TrpcProvider';

type EventFormProps = Readonly<{
    state: UseEventFormState;
    dispatch: UseEventFormDispatch;
    clients: ClientInfoFE[];
    isForProduct?: boolean;
    recurrenceCountLimit?: number;
}>;

export function EventForm({ state, dispatch, clients, isForProduct, recurrenceCountLimit }: EventFormProps) {
    const { t } = useTranslation('components', { keyPrefix: 'eventForm' });
    const { t: tf } = useTranslation('common', { keyPrefix: 'form' });
    const [ isDescriptionFocus, setIsDescriptionFocus ] = useToggle(false);
    const { role } = useUser();
    const isTeamMaster = role === TeamMemberRole.master;

    const locations = trpc.location.getLocations.useQuery().data;
    const location = useMemo(() => locations?.find(location => location.id === state.form.locationId), [ locations, state.form.locationId ]);
    const isNew = !state.event;
    // Just an optimization, probably not necessary. Might be handled automatically in the new React compiler.
    const itemDispatch = useMemo(() => createItemDispatch(dispatch), [ dispatch ]);
    const error = validateEvent(state.form, tf);

    // Not the ideal solution, but it would be highly impractical to modify the continuous client select just for this.
    const guests = state.form.guests;
    const removeGuest = useCallback((participant: Participant) => {
        dispatch({ type: 'input', field: 'guests', value: guests.filter(p => p !== participant) });
    }, [ guests, dispatch ]);

    const { recurrence, start } = state.form;
    const eventsCount: number | undefined = useMemo(() => {
        if (recurrenceCountLimit === undefined)
            return undefined;

        const unlimitedCount = recurrence ? computeRecurrenceCount(recurrence, start) : 1;

        return Math.min(unlimitedCount, recurrenceCountLimit);
    }, [ recurrence, start, recurrenceCountLimit ]);

    return (
        <div className='flex flex-col gap-4'>
            {isForProduct ? (
                <div>
                    <Form.Input
                        label={t('title-label')}
                        placeholder={t('title-placeholder')}
                        value={state.form.title}
                        onChange={e => dispatch({ type: 'input', field: 'title', value: e.target.value })}
                    />
                    {state.error?.invalidForm === 'title' && (
                        <ErrorMessage message={tf('title-required')} />
                    )}
                </div>
            ) : (
                <div>
                    <div>
                        <Form.Input
                            className='text-2lg'
                            placeholder={t('title-placeholder')}
                            value={state.form.title}
                            onChange={e => dispatch({ type: 'input', field: 'title', value: e.target.value })}
                        />
                        {state.error?.invalidForm === 'title' && (
                            <ErrorMessage message={tf('title-required')} />
                        )}
                    </div>
                    <div className='py-2'>
                        <Form.Textarea
                            placeholder={t('description-placeholder')}
                            value={state.form.description}
                            onChange={e => dispatch({ type: 'input', field: 'description', value: e.target.value })}
                            onFocus={setIsDescriptionFocus.true}
                            onBlur={setIsDescriptionFocus.false}
                            minRows={1}
                            maxRows={isDescriptionFocus ? undefined : 3}
                            className={isDescriptionFocus ? undefined : 'line-clamp-3'}
                        />
                        {/* <RHFErrorMessage errors={errors} name='description' /> */}
                    </div>
                </div>
            )}

            <div>
                <div>
                    <Form.Label>{t('date-label')}</Form.Label>
                    <div className='gap-4'>
                        <CalendarIcon size={16} />
                        <DatePicker
                            selected={state.form.start}
                            onChange={itemDispatch.start}
                            type='date'
                        />
                    </div>
                </div>
                <div>
                    <Form.Label>{t('time-label')}</Form.Label>
                    <div className='justify-start'>
                        <ClockIcon size={16} className='mr-4 shrink-0' />
                        <DatePicker
                            selected={state.form.start}
                            onChange={itemDispatch.start}
                            type='time'
                        />
                        <ArrowIcon size={17} className='mx-4 shrink-0' />
                        <div>
                            <DatePicker
                                selected={state.form.end}
                                onChange={itemDispatch.end}
                                type='time'
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className='row'>
                {isNew && (
                    <div >
                        <Form.Label>{t('recurrence-label')}</Form.Label>
                        <RecurrenceInput
                            startDate={state.form.start}
                            value={state.form.recurrence}
                            onChange={itemDispatch.recurrence}
                            className='grow min-w-0'
                            countLimit={recurrenceCountLimit}
                        />
                        {eventsCount !== undefined && recurrence && (
                            <div className='mt-2 ml-4 text-sm text-secondary-600'>{t('events-count-label', { count: eventsCount })}</div>
                        )}
                    </div>
                )}
                <div>
                    <Form.Label>{t('location-label')}</Form.Label>
                    {!location && (
                        <MapPinIcon size={18} className='mr-4' />
                    )}
                    {location ? (
                        <LocationDisplay location={location} isCompact className='grow truncate' />
                    ) : (
                        <LocationSelect
                            immutableProps={{ variant: 'ghost', removeIndicator: true }}
                            className='grow'
                            value={state.form.locationId}
                            onChange={value => dispatch({ type: 'input', field: 'locationId', value })}
                        />
                    )}
                    {location && (
                        <DeleteButton
                            aria={t('delete-location-button-label')}
                            onClick={() => dispatch({ type: 'input', field: 'locationId', value: undefined })}
                            className='ml-4 shrink-0'
                        />
                    )}
                </div>
            </div>
            {error && <ErrorMessage message={error} />}

            <div>
                <Form.Label>{t('guests-label')}</Form.Label>
                <div className='gap-4'>
                    <UsersIcon size={18} className='shrink-0' style={{ marginTop: '2px' }} />
                    <div className='grow shrink' style={{ minWidth: 0 }}>
                        <ContinuousParticipantSelect
                            immutableProps={{ variant: 'ghost', removeIndicator: true }}
                            clients={clients}
                            value={guests}
                            onChange={value => dispatch({ type: 'input', field: 'guests', value })}
                            placeholder={t('guests-placeholder')}
                            hideValue
                        />
                    </div>
                </div>
                {guests.length > 0 && (
                    <div className='flex flex-col gap-4 mt-4'>
                        {guests.map(participant => (
                            <ParticipantRowLarge key={participant.identifier} participant={participant} remove={removeGuest} />
                        ))}
                    </div>
                )}
            </div>

            {isNew && isTeamMaster && (
                <div className='mt-4'>
                    <Form.Label>{t('scheduler-label')}</Form.Label>
                    <TeamMemberSelect
                        value={state.form.scheduler}
                        onChange={value => dispatch({ type: 'input', field: 'scheduler', value })}
                    />
                </div>
            )}
        </div>
    );
}

function createItemDispatch(dispatch: UseEventFormDispatch) {
    return {
        start: (value?: DateTime) => {
            if (!value)
                return;

            dispatch({ type: 'input', field: 'start', value });
        },
        end: (value?: DateTime) => {
            if (!value)
                return;

            dispatch({ type: 'input', field: 'end', value });
        },
        recurrence: (value?: Recurrence) => dispatch({ type: 'input', field: 'recurrence', value }),
    };
}

function validateEvent(event: { start: DateTime, end: DateTime }, tf: TFunction) {
    if (computeEventDuration(event) < minutesToSeconds(5))
        return tf('duration-less-than-5');

    return undefined;
}

import { useTranslation } from 'react-i18next';
import OrdersTable from ':frontend/components/orders/OrdersTable';
import { InfoCard } from ':frontend/components/settings/InfoCard';

export default function Orders() {
    const { t } = useTranslation('pages');

    return (
        <div className='mx-auto px-4 sm:px-6 lg:px-8 pb-8'>
            <h1>{t('orders.page-title')}</h1>
            <InfoCard infoKey='orders' className='mb-8' />
            <OrdersTable />
        </div>
    );
}

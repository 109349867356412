import { useCallback, type Dispatch } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Modal } from ':components/shadcn';
import { type FECustomItemInit, determineIsSendNotification } from ':frontend/types/orders/Order';
import { PaymentMethodSelect } from ':frontend/components/forms/PaymentMethodSelect';
import { SpinnerButton } from ':frontend/components/common';
import { MoneyDisplay } from ':components/custom';
import { ClientIconRow } from ':frontend/components/client/ClientIconLink';
import { FileIcon, SendIcon } from ':components/icons/old';
import { getClientOrContact } from ':frontend/types/EventParticipant';
import { PercentDisplay } from ':components/custom';
import { type CheckoutOutput } from './CheckoutModalInner';
import { CheckoutPhase, type CheckoutState, type CheckoutAction, type Cache, type CacheItem, CheckoutType } from './useCheckout';
import clsx from 'clsx';
import { floatToPercent } from ':utils/math';
import { PaymentMethod } from ':utils/entity/order';
import { ProductInvoiceItemDisplay } from ':components/store/product/ProductCard';

type CheckoutOverviewProps = Readonly<{
    output: Dispatch<CheckoutOutput>;
    state: CheckoutState;
    dispatch: Dispatch<CheckoutAction>;
    isFetching: boolean;
    createOrder: () => void;
}>;

export function CheckoutOverview({ output, state, dispatch, isFetching, createOrder }: CheckoutOverviewProps) {
    const { t } = useTranslation('components', { keyPrefix: 'checkout.overview' });
    const { cache, input, overview: { paymentMethod, isSendNotification } } = state;
    const discount = 'discount' in input ? input.discount : undefined;
    const determinedNotification = determineIsSendNotification(paymentMethod);

    const setPaymentMethod = useCallback((method: PaymentMethod) => {
        dispatch({ type: 'overview', paymentMethod: method });
    }, [ dispatch ]);

    return (<>
        <Modal.Header>
            <Modal.Title className='text-body text-2xl font-semibold'>{t('title')}</Modal.Title>
        </Modal.Header>

        {state.input.type !== CheckoutType.Event && (<>
            <div className='flex flex-col gap-4 mb-8'>
                {cache.items.map(displayCachedItem)}
                {discount && (
                    <div className='flex flex-col gap-2 p-4 bg-white rounded shadow-sm'>
                        <div className='flex justify-between leading-6'>
                            <h3 className='mb-0'>{discount.label}</h3>
                            <span className='text-lg'><PercentDisplay amount={floatToPercent(discount.amount)}/></span>
                        </div>
                        <div className='flex justify-end gap-2'>
                            {cache.overviewForCurrencies.map((forCurrency, index) => (
                                <span key={forCurrency.totalDiscount.currency.id}>
                                    <MoneyDisplay money={forCurrency.totalDiscount} />
                                    {index < cache.overviewForCurrencies.length - 1 && ','}
                                </span>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </>)}

        <h2 className='mb-4 mt-0'>{t('payment-type-title')}</h2>
        <PaymentMethodSelect
            value={paymentMethod}
            onChange={setPaymentMethod}
            currencyIds={cache.currencyIds}
            options={cache.paymentOptions}
        />

        <h2 className='mb-4 mt-0'>{t('overview-title')}</h2>
        <InvoicesTable cache={cache} paymentMethod={paymentMethod} dispatch={dispatch} />
        <div>
            <Form.Switch
                label={t('notify-label', { count: cache.clients.length })}
                checked={isSendNotification}
                disabled={determinedNotification !== undefined}
                onCheckedChange={value => dispatch({ type: 'overview', isSendNotification: value })}
            />
        </div>
        <div className='p-4 rounded-lg mt-4' style={{ backgroundColor: '#F5F5F5' }}>
            {isSendNotification ? (<>
                {/* TODO invoices length */}
                <div className='text-secondary-600'>{t('notify-description-true', { count: 1 })}</div>
                <Button
                    variant='outline'
                    size='tiny' className='mt-2'
                    onClick={() => dispatch({ type: 'phase', phase: CheckoutPhase.EmailPreview })}
                >
                    <SendIcon size={18} className='mr-2' />
                    {t('preview-email-button')}
                </Button>
            </>) : (
                <div className='text-secondary-600'>{t('notify-description-false', { count: 1 })}</div>
            )}
        </div>

        <Modal.Footer>
            <Button onClick={() => output({ type: 'back' })} variant='outline' className='w-1/4'>
                {t('back-button')}
            </Button>
            <SpinnerButton
                variant='primary'
                className='w-full mt-4'
                onClick={createOrder}
                isFetching={isFetching}
                style={{ height: '49px' }}
            >
                {t(paymentMethod !== PaymentMethod.noInvoice ? 'create-orders-button' : 'create-orders-without-payment-button')}
            </SpinnerButton>
        </Modal.Footer>
    </>);
}

function displayCachedItem(item: CacheItem, index: number) {
    if ('product' in item) {
        return (
            <ProductInvoiceItemDisplay key={index} product={item.product} />
        );
    }

    // TODO this shouldn't happen
    if ('event' in item)
        return null;

    return (
        <CustomItemDisplay key={index} item={item} />
    );
}

type CustomItemDisplay = Readonly<{
    item: FECustomItemInit;
    className?: string;
}>;

export function CustomItemDisplay({ item, className }: CustomItemDisplay) {
    return (
        <div className={clsx('p-4 bg-white rounded shadow-sm', className)}>
            <h3 className='text-base mb-0 leading-6 truncate'>{item.label}</h3>
            <div className='flex gap-4 mt-2'>
                <span className='mr-1'>{item.quantity}×</span>
                <span className='mr-1'><MoneyDisplay money={item.price} /></span>
            </div>
        </div>
    );
}

type InvoicesTableProps = Readonly<{
    cache: Cache;
    paymentMethod: PaymentMethod;
    dispatch: Dispatch<CheckoutAction>;
}>;

function InvoicesTable({ cache, paymentMethod, dispatch }: InvoicesTableProps) {
    const { t } = useTranslation('components', { keyPrefix: 'checkout.overview' });
    const { invoicesForClients, overviewForCurrencies } = cache;

    return (
        <div>
            <div className='flex flex-col border-b'>
                {invoicesForClients.map(forClient => (
                    <div key={forClient.client.identifier.toString()} className='border-t'>
                        {forClient.invoices.map((invoice, index) => (
                            <div key={invoice.price.currency.id} className='flex items-center gap-2 py-2'>
                                <div className='grow truncate'>
                                    {index === 0 && (
                                        <ClientIconRow client={getClientOrContact(forClient.client)} />
                                    )}
                                </div>
                                <span className='py-2 px-4 rounded' style={{ height: '32px', backgroundColor: '#F5F5F5' }}>
                                    <MoneyDisplay money={invoice.price} />
                                </span>
                                {paymentMethod !== PaymentMethod.noInvoice && (
                                    <Button variant='outline' size='tiny' onClick={() => dispatch({ type: 'invoicePreview', invoice })}>
                                        <FileIcon size={18} className='mr-2' />
                                        {t('preview-invoice-button')}
                                    </Button>
                                )}
                            </div>
                        ))}
                    </div>
                ))}
            </div>
            <div className='flex justify-end py-2'>
                <span className='leading-6 py-2 px-4 rounded flex gap-2 text-lg' style={{ backgroundColor: '#F5F5F5' }}>
                    <span>{t('total-price-label')}</span>
                    {overviewForCurrencies.map((forCurrency, index) => (
                        <span key={forCurrency.totalPrice.currency.id}>
                            <MoneyDisplay money={forCurrency.totalPrice} />
                            {index < overviewForCurrencies.length - 1 && ','}
                        </span>
                    ))}
                </span>
            </div>
        </div>
    );
}

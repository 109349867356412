import { DEFAULT_ITEMS_PER_PAGE } from ':utils/query';

export function random(min: number, max: number): number {
    if (min >= max)
        throw new Error('Min greater than or equal to max.');

    return min + Math.random() * (max - min);
}

export function getRandomSkeletonArray(min = 4, max = 10) {
    return [ ...Array(Math.round(random(min, max))) ].map((_, index) => index);
}

type PaginationController = {
    page: number;
    totalItems: number;
};

export function getSkeletonArray(controller?: PaginationController, itemsPerPage = DEFAULT_ITEMS_PER_PAGE): number[] {
    if (!controller)
        return getRandomSkeletonArray(itemsPerPage / 2, itemsPerPage);

    const numberOfItems = computeSkeletonItems(controller, itemsPerPage);
    return [ ...Array(numberOfItems) ].map((_, index) => index);
}

function computeSkeletonItems(controller: PaginationController, itemsPerPage: number): number {
    // nothing is fetched yet
    if (!controller.totalItems)
        return Math.floor(itemsPerPage / 2);

    // The abs is here in case of something goes wrong (i.e., some items are deleted but the page remains constant)
    const remainingItems = Math.abs(controller.totalItems - (controller.page - 1) * itemsPerPage);

    if (remainingItems > itemsPerPage)
        return itemsPerPage;

    return remainingItems;
}

export function roundToFixedDecimal(number: number, decimals: number): number {
    const order = Math.pow(10, decimals);
    return Math.round(number * order + Number.EPSILON) / order;
}

// For some javascript reasons this works.
function preciseDivisionByPowerOfTen(number: number, divisorPower: number, initialDecimalPrecision: number): number {
    // We round just in case the initial precision was a little bit off.
    const integer = Math.round(number * Math.pow(10, initialDecimalPrecision));
    return integer / Math.pow(10, divisorPower + initialDecimalPrecision);
}

function preciseMultiplicationByPowerOfTen(number: number, multiplicatorPower: number, finalDecimalPrecision: number): number {
    const integer = Math.round(number * Math.pow(10, multiplicatorPower + finalDecimalPrecision));
    return integer / Math.pow(10, finalDecimalPrecision);
}

// Decimal to which we have to round all real numbers.
const DEFAULT_DECIMAL_PRECISION = 2;

export function roundMoney(amount: number): number {
    return roundToFixedDecimal(amount, DEFAULT_DECIMAL_PRECISION);
}

const FLOAT_TO_PERCENT_POWER = 2;

export function percentToFloat(percent: number): number {
    return preciseDivisionByPowerOfTen(percent, FLOAT_TO_PERCENT_POWER, DEFAULT_DECIMAL_PRECISION);
}

export function floatToPercent(float: number): number {
    return preciseMultiplicationByPowerOfTen(float, FLOAT_TO_PERCENT_POWER, DEFAULT_DECIMAL_PRECISION);
}

// The server counts in the 0.01 units because of precision. It is required by Stripe in this format, too.
const PRICE_TO_SERVER_POWER = 2;

export function priceFromServer(input: number): number {
    return preciseDivisionByPowerOfTen(input, PRICE_TO_SERVER_POWER, DEFAULT_DECIMAL_PRECISION);
}

export function priceToServer(float: number): number {
    return preciseMultiplicationByPowerOfTen(float, PRICE_TO_SERVER_POWER, DEFAULT_DECIMAL_PRECISION);
}

export function transformToValidNumberOrEmptyNegative(value: unknown): number | '' | '-' {
    if (typeof value === 'number')
        return value;

    if (typeof value === 'string') {
        if (value === '-')
            return '-';

        const number = Number.parseFloat(value);
        if (!Number.isNaN(number))
            return number;
    }

    return '';
}

/**
 * The following functions return either valid (i.e., non-NaN) number or ''.
 * The reason is that the useForm hook quite don't like undefined so the empty string is the only way how to signalize invalid number.
 * It is also needed in order to allow the users to delete a value from the input and then type a correct one instead of replacing the number in one go.
 */
export function transformToValidNumberOrEmpty(value: unknown): number | '' {
    if (typeof value === 'number')
        return value;

    if (typeof value === 'string') {
        const number = Number.parseFloat(value);
        if (!Number.isNaN(number))
            return number;
    }

    return '';
}

export function transformToIntegerOrEmpty(value: unknown): number | '' {
    const number = transformToValidNumberOrEmpty(value);
    if (number === '')
        return '';

    return Math.round(number);
}

export function transformToPositiveIntegerOrEmpty(value: unknown): number | '' {
    const number = transformToValidNumberOrEmpty(value);
    if (number === '')
        return '';

    if (number < 1)
        return '';

    return Math.round(number);
}

export function transformToPositiveIntegerOrZero(value: unknown): number {
    const positiveInteger = transformToPositiveIntegerOrEmpty(value);

    return positiveInteger !== '' ? positiveInteger : 0;
}

/** For price inputs where negative values are allowed */
export function transformToPriceNegative(value: unknown): number | '' | '-' {
    const number = transformToValidNumberOrEmptyNegative(value);
    if (number === '')
        return '';

    if (number === '-')
        return '-';

    return roundToFixedDecimal(number, DEFAULT_DECIMAL_PRECISION);
}

export function transformToPrice(value: unknown): number | '' {
    const number = transformToValidNumberOrEmpty(value);
    if (number === '')
        return '';

    if (number < 0)
        return '';

    return roundToFixedDecimal(number, DEFAULT_DECIMAL_PRECISION);
}

export function toNumber(value: number | '' | '-'): number {
    return (value === '' || value === '-') ? 0 : value;
}

/**
 * A `number` could be NaN, or in some special cases not even a number (TS lying)
 * @see https://stackoverflow.com/a/8526029/8483877
 */
export function isValidNumber(n: unknown): n is number {
    return typeof n == 'number' && !isNaN(n) && isFinite(n);
}

export function between(value: number, min: number, max: number) {
    return Math.min(Math.max(value, min), max);
}

import { useCallback, type ReactNode, useMemo } from 'react';
import { AsyncCreatableSelect, AsyncSelect, type GroupBase, type OptionsOrGroups, type SelectConfig } from ':components/shadcn';
import { ClientContact, type ClientInfoFE } from ':frontend/types/Client';
import { Query, getNameFromEmail } from ':frontend/utils/common';
import { createOnChange, createValue, createValueWithFilter } from ':frontend/utils/forms';
import { canonizeEmail, isEmail } from ':utils/forms';
import { useTranslation } from 'react-i18next';
import { api } from ':frontend/utils/api';
import { useUser } from ':frontend/context/UserProvider';
import { FlowlanceLogo, IoPersonCircleOutline } from ':components/icons/old';
import ClientIconLink, { ClientIconRow } from './ClientIconLink';
import { getClientIdentifier, getClientOrContact, getContactIdentifier, getParticipantName, type Participant } from ':frontend/types/EventParticipant';
import { DeleteButton } from '../forms/buttons';
import { type Control, Controller, type FieldPath, type FieldValues, type UseControllerProps } from 'react-hook-form';
import type { TFunction } from 'i18next';

type ContinuousParticipantSelectProps = Readonly<{
    clients: ClientInfoFE[];
    value: Participant[];
    onChange: (participants: Participant[]) => void;
    hideValue?: boolean;
    immutableProps?: SelectConfig<Option>;
    className?: string;
    placeholder?: string;
}>;

export function ContinuousParticipantSelect({ clients, value, onChange, hideValue, immutableProps, ...rest }: ContinuousParticipantSelectProps) {
    const { t } = useTranslation('components', { keyPrefix: 'continuousParticipantSelect' });
    const { t: tf } = useTranslation('common', { keyPrefix: 'select' });
    const { appUser } = useUser();

    const loadOptions = useCallback(
        async (rawQuery: string) => loadOptionsFunction(rawQuery, clients, appUser.google.isContacts),
        [ clients, appUser.google.isContacts ],
    );

    const innerValue = useMemo(() => createValueWithFilter(
        participantToOption,
        true,
        value,
    ), [ value ]);

    const innerOnChange = useMemo(() => createOnChange(
        (option: Option) => {
            const identifier = optionToStringValue(option);
            return 'id' in option.value
                ? { info: option.value, identifier }
                : { contact: option.value, identifier };
        },
        true,
        onChange,
    ), [ onChange ]);

    const remove = useCallback((participant: Participant) => {
        onChange(value.filter(p => p !== participant));
    }, [ value, onChange ]);

    return (<>
        <AsyncCreatableSelect
            immutableProps={{ CustomOption, ...immutableProps }}
            isMulti
            isClearable={false}
            loadOptions={loadOptions}
            value={innerValue}
            onChange={innerOnChange}
            controlShouldRenderValue={false}
            {...creatableSelectConfig(tf)}
            {...commonSelectConfig(t)}
            {...rest}
        />
        {!hideValue && (
            <div className='flex flex-col gap-2 mt-2'>
                {value.map(participant => (
                    <ParticipantRow key={participant.identifier} participant={participant} remove={remove} />
                ))}
            </div>
        )}
    </>);
}

type ParticipantRowProps = Readonly<{
    participant: Participant;
    remove: (participant: Participant) => void;
}>;

export function ParticipantRow({ participant, remove }: ParticipantRowProps) {
    const { t } = useTranslation('components', { keyPrefix: 'continuousParticipantSelect' });

    return (
        <div className='flex items-center gap-4'>
            <div className='grow overflow-hidden'>
                <ClientIconLink client={getClientOrContact(participant)} newTab />
            </div>
            <DeleteButton
                aria={t('delete-button-aria', { name: getParticipantName(participant) })}
                className='shrink-0'
                onClick={() => remove(participant)}
            />
        </div>
    );
}

export function ParticipantRowLarge({ participant, remove }: ParticipantRowProps) {
    const { t } = useTranslation('components', { keyPrefix: 'continuousParticipantSelect' });

    return (
        <div className='flex items-center gap-4'>
            <ClientIconRow client={getClientOrContact(participant)} className='grow overflow-hidden' />
            <DeleteButton
                aria={t('delete-button-aria', { name: getParticipantName(participant) })}
                className='shrink-0'
                onClick={() => remove(participant)}
            />
        </div>
    );
}

async function loadOptionsFunction(rawQuery: string, fetchedClients: ClientInfoFE[], isContactsEnabled: boolean) {
    const fetchedContacts = await fetchContacts(rawQuery, isContactsEnabled);
    const query = new Query(getNameFromEmail(rawQuery));

    return computeCombinedClients(fetchedClients, fetchedContacts, query).map(valueToOption);
}

async function fetchContacts(query: string, isEnabled: boolean): Promise<ClientContact[]> {
    if (!isEnabled)
        return [];

    const response = await api.google.searchContacts(query);
    if (!response.status || !response.data.results)
        return [];

    return response.data.results.map(result => ClientContact.fromServer(result.person)).filter((contact): contact is ClientContact => !!contact);
}

/**
 * The map in the input is there purely for optimization (so we don't have to build it every time).
 */
function computeCombinedClients(fetchedClients: ClientInfoFE[], fetchedContacts: ClientContact[], query: Query): (ClientInfoFE | ClientContact)[] {
    // First, we try to match all our clients by the query. The matched ones are automatically included in the output.
    const matchedClients = fetchedClients.filter(client => client.query.match(query));

    // Some sets for faster lookups.
    const usedEmails = new Set(matchedClients.map(client => client.email));
    const clientEmails = new Set(fetchedClients.map(client => client.email));

    // Our clients that are matched by google but not by us.
    const matchedByGoogleClients: ClientInfoFE[] = [];
    const filteredContacts = fetchedContacts
        // We filter out the google contacts that are already used by our clients.
        .filter(contact => !usedEmails.has(contact.canonicalEmail))
        // However, if google found a contact that corresponds to our existing client but we didn't match it by the query, we want to use our client instead of the contact.
        .filter(contact => {
            if (!clientEmails.has(contact.canonicalEmail))
                return true;

            fetchedClients
                .filter(client => client.email === contact.canonicalEmail)
                .forEach(client => matchedByGoogleClients.push(client));
            return false;
        });

    return [
        ...matchedClients,
        ...matchedByGoogleClients,
        ...filteredContacts,
    ];
}

function creatableSelectConfig(tf: TFunction) {
    return {
        isValidNewOption: isValidNewOption,
        getNewOptionData: createNewOption,
        formatCreateLabel: (input: string) => (<>
            {tf('create-option-label') + ' '}
            <span className='text-black'>{canonizeEmail(input)}</span>
        </>),
    };
}

function commonSelectConfig(t: TFunction) {
    return {
        defaultOptions: true,
        getOptionValue: optionToStringValue,
        noOptionsMessage: () => t('no-options-message'),
        loadingMessage: () => t('loading-message'),
    };
}

type Option = {
    label: ReactNode;
    value: ClientInfoFE | ClientContact;
};

function optionToStringValue(option: Option): string {
    return 'id' in option.value
        ? getClientIdentifier(option.value)
        : getContactIdentifier(option.value);
}

function participantToOption(participant: Participant): Option {
    return 'contact' in participant
        ? valueToOption(participant.contact)
        : valueToOption(participant.info);
}

function valueToOption(client: ClientInfoFE | ClientContact): Option {
    return {
        label: client.name,
        value: client,
    };
}

function createNewOption(input: string, label: ReactNode): Option {
    return {
        label,
        value: ClientContact.fromEmail(input),
    };
}

function CustomOption({ data }: Readonly<{ data: Option }>) {
    const client = data.value;
    const isClient = 'id' in client;

    return (
        <div className='flex items-center'>
            {isClient ? (
                <FlowlanceLogo size={24} className='shrink-0' />
            ) : (
                <IoPersonCircleOutline size={24} className='shrink-0' />
            )}
            <div className='pl-2 overflow-hidden'>
                <div className='truncate'>{client.name}</div>
                <div className='truncate text-sm'>{client.email}</div>
            </div>
        </div>
    );
}

function isValidNewOption(input: string, selected: readonly Option[], available: OptionsOrGroups<Option, GroupBase<Option>>): boolean {
    if (!isEmail(input))
        return false;

    const canonicalEmail = canonizeEmail(input);

    return ![ ...selected, ...(available as Option[]) ].map(o => o.value).filter((o): o is ClientInfoFE => 'id' in o).some(client => client.email === canonicalEmail);
}

type SingleContinuousParticipantSelectProps = Readonly<{
    clients: ClientInfoFE[];
    value: Participant | undefined;
    onChange: (participant: Participant | undefined) => void;
    isCreatable?: boolean;
    immutableProps?: SelectConfig<Option>;
    autoFocus?: boolean;
    className?: string;
}>;

export function SingleContinuousParticipantSelect({ clients, value, onChange, isCreatable, immutableProps, ...rest }: SingleContinuousParticipantSelectProps) {
    const { t } = useTranslation('components', { keyPrefix: 'continuousParticipantSelect' });
    const { t: tf } = useTranslation('common', { keyPrefix: 'select' });
    const { appUser } = useUser();

    const loadOptions = useCallback(
        async (rawQuery: string) => loadOptionsFunction(rawQuery, clients, appUser.google.isContacts),
        [ clients, appUser.google.isContacts ],
    );

    const innerValue = useMemo(() => createValue(
        participantToOption,
        false,
        value,
    ), [ value ]);

    const innerOnChange = useMemo(() => createOnChange(
        (option: Option) => {
            const identifier = optionToStringValue(option);
            return 'id' in option.value
                ? { info: option.value, identifier }
                : { contact: option.value, identifier };
        },
        false,
        onChange,
    ), [ onChange ]);

    if (isCreatable) {
        return (
            <AsyncCreatableSelect
                immutableProps={{ CustomOption, ...immutableProps }}
                loadOptions={loadOptions}
                value={innerValue ?? null}
                onChange={innerOnChange}
                placeholder={t('placeholder')}
                {...creatableSelectConfig(tf)}
                {...commonSelectConfig(t)}
                {...rest}
            />
        );
    }

    return (
        <AsyncSelect
            immutableProps={{ CustomOption, ...immutableProps }}
            loadOptions={loadOptions}
            value={innerValue ?? null}
            onChange={innerOnChange}
            placeholder={t('placeholder')}
            {...commonSelectConfig(t)}
            {...rest}
        />
    );
}

type ControlledParticipantSelectProps<TFieldValues extends FieldValues> = {
    control: Control<TFieldValues>;
    name: FieldPath<TFieldValues>;
    clients: ClientInfoFE[];
    rules: UseControllerProps<TFieldValues>['rules'];
};

export function ControlledParticipantSelect<TFieldValues extends FieldValues>({ control, name, clients, rules }: ControlledParticipantSelectProps<TFieldValues>) {
    const InnerSelect = useCallback(({ field }: { field: { value?: Participant, onChange: (value?: Participant) => void } }) => {
        return (
            <SingleContinuousParticipantSelect
                isCreatable
                clients={clients}
                value={field.value}
                onChange={field.onChange}
            />
        );
    }, [ clients ]);

    return (
        <Controller
            control={control as Control<FieldValues>}
            name={name}
            render={InnerSelect}
            rules={rules as UseControllerProps<FieldValues>['rules']}
        />
    );
}

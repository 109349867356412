import { useMemo } from 'react';
import { type EditableEventItem, type EventOrderItemFE } from ':frontend/types/orders/EventOrderItem';
import { useTranslation } from 'react-i18next';
import DateTimeDisplay from '../common/DateTimeDisplay';
import { Table } from '../common';
import { MoneyDisplay } from ':components/custom';
import EventStateBadge from '../event/EventStateBadge';
import { addVat, addVatAsNumber, timesBy, type CurrencyFE, type Money } from ':utils/money';
import { Form } from ':components/shadcn';
import type { OrderFormState, UseOrderDispatch, UseOrderState } from './useOrder';
import clsx from 'clsx';
import { toNumber, transformToPriceNegative } from ':utils/math';
import { VatSelect } from '../forms/VatSelect';
import { AddButton, DeleteButton } from '../forms/buttons';
import { TranslatedErrorMessage } from '../forms/ErrorMessage';
import { type FormPath } from ':frontend/utils/updator';

type EventItemsTableProps = Readonly<{
    items: EventOrderItemFE[];
}>;

export default function EventItemsTable({ items }: EventItemsTableProps) {
    const { t } = useTranslation('components', { keyPrefix: 'eventItemsTable' });
    const totalPrice = useMemo(() => computeTotalPriceSum(items), [ items ]);
    const showVat = useMemo(() => items.some(item => !item.vat.isZero), [ items ]);

    return (
        <Table>
            <Table.Header>
                <Table.HeaderCol>{t('title-label')}</Table.HeaderCol>
                <Table.HeaderCol className='text-right'>{t('date-label')}</Table.HeaderCol>
                <Table.HeaderCol className='text-left'>{t('state-label')}</Table.HeaderCol>
                <Table.HeaderCol className='text-right'>{t('price-label')}</Table.HeaderCol>
                {showVat && (
                    <Table.HeaderCol className='text-right'>{t('vat-label')}</Table.HeaderCol>
                )}
            </Table.Header>
            <Table.Body>
                {items.map(item => (
                    <EventItemRow key={item.id} item={item} showVat={showVat} />
                ))}
                <Table.Row>
                    <Table.Col colSpan={5} className='text-right'>
                        <span className='mr-8'>{t('total-price-sum-label')}</span>
                        <MoneyDisplay money={totalPrice} />
                    </Table.Col>
                </Table.Row>
            </Table.Body>
        </Table>
    );
}

type EventItemRowProps = Readonly<{
    item: EventOrderItemFE;
    showVat: boolean;
}>;

function EventItemRow({ item, showVat }: EventItemRowProps) {
    const { event } = item;

    return (
        <Table.Row>
            <Table.Col>{item.title}</Table.Col>
            <Table.Col className='text-right'><DateTimeDisplay dateTime={event.start} /></Table.Col>
            <Table.Col className='text-left'><EventStateBadge event={event} /></Table.Col>
            <Table.Col className='text-right'><MoneyDisplay money={item.unitPrice} /></Table.Col>
            {showVat && (
                <Table.Col className='text-right'>{item.vat.label}</Table.Col>
            )}
        </Table.Row>
    );
}

/** sum(item => unitPrice * quantity * vat) */
function computeTotalPriceSum(items: EventOrderItemFE[]): Money {
    const amount = items.reduce((ans, item) => ans + computeItemTotalPrice(item).amount, 0);
    const currency = items[0].unitPrice.currency;

    return { amount, currency };
}

/** unitPrice * quantity * vat */
function computeItemTotalPrice(item: EventOrderItemFE): Money {
    return addVat(timesBy(item.unitPrice, item.quantity), item.vat).withVat;
}

type EventItemsFormProps = Readonly<{
    state: UseOrderState;
    form: OrderFormState;
    dispatch: UseOrderDispatch;
}>;

export function EventItemsForm({ state, form, dispatch }: EventItemsFormProps) {
    const { t } = useTranslation('components', { keyPrefix: 'eventItemsTable' });
    const common = useMemo(() => ({
        showVat: state.order.items.some(item => !item.vat.isZero),
        prices: computeFormPrices(form.eventItems, state.order.price.currency),
        isRemovingAllowed: form.eventItems.filter(item => !item.isDeleted).length > 1,
    }), [ form.eventItems, state.order.items, state.order.price ]);

    return (
        <div className='flex flex-col gap-2 grow'>
            {form.eventItems.map((item, index) => (
                <EventItemRowForm
                    key={item.id}
                    state={state}
                    dispatch={dispatch}
                    item={item}
                    index={index}
                    common={common}
                />
            ))}
            <div className='flex justify-end py-2' style={{ paddingRight: '48px' }}>
                <span className='mr-8'>{t('total-price-sum-label')}</span>
                <MoneyDisplay money={common.prices.total} />
            </div>
        </div>
    );
}

type CommonData = {
    showVat: boolean;
    prices: { items: Money[], total: Money };
    isRemovingAllowed: boolean;
}

type EventItemRowFormProps = Readonly<{
    state: UseOrderState;
    dispatch: UseOrderDispatch;
    item: EditableEventItem;
    index: number;
    common: CommonData;
}>;

function EventItemRowForm({ state, dispatch, item, index, common }: EventItemRowFormProps) {
    const { t } = useTranslation('components', { keyPrefix: 'eventItemsTable' });
    const isFirstRow = index === 0;

    function input(field: FormPath<EditableEventItem>, value: unknown) {
        dispatch({ type: 'input', field: `eventItems.${index}.${field}`, value });
    }

    return (
        <div className={clsx('row gx-2', !isFirstRow && 'fl-remove-labels')}>
            <div className='col'>
                <Form.Textarea
                    label={t('title-label')}
                    value={item.title}
                    onChange={e => input('title', e.target.value)}
                    minRows={1}
                    className='py-1'
                    disabled={item.isDeleted}
                />
                {!item.isDeleted && (
                    <TranslatedErrorMessage translationId={state.formErrors?.[`eventItems.${index}.title`]} />
                )}
            </div>
            <div className='col-auto'>
                <Form.Label className='px-4'>{t('date-label')}</Form.Label>
                <div className='py-2 px-4'><DateTimeDisplay dateTime={item.event.start} /></div>
            </div>
            <div className='col-2'>
                <Form.Input
                    label={t('price-label')}
                    type='number'
                    value={item.unitPrice}
                    onChange={e => input('unitPrice', transformToPriceNegative(e.target.value))}
                    disabled={item.isDeleted}
                />
            </div>
            {common.showVat && (
                <div className='col-1'>
                    <Form.Label>{t('vat-label')}</Form.Label>
                    <VatSelect
                        immutableProps={{ menuAlignment: 'top' }}
                        value={item.vat}
                        onChange={value => value && input('vat', value)}
                        className='rs-condensed'
                        isDisabled={item.isDeleted}
                    />
                </div>
            )}
            <div className='col-1'>
                <Form.Label>{t('total-price-label')}</Form.Label>
                <div className={clsx('text-right py-2', item.isDeleted && 'line-through')}>
                    <MoneyDisplay money={common.prices.items[index]} />
                </div>
            </div>
            <div className='col-auto'>
                <Form.Label className='w-0 opacity-0 whitespace-nowrap'>{t(item.isDeleted ? 'restore-item-button' : 'remove-item-button')}</Form.Label>
                <div className='py-1'>
                    {item.isDeleted ? (
                        <AddButton aria={t('restore-item-button')} onClick={() => input('isDeleted', false)} />
                    ) : (
                        <DeleteButton aria={t('remove-item-button')} onClick={() => input('isDeleted', true)} disabled={!common.isRemovingAllowed} />
                    )}
                </div>
            </div>
        </div>
    );
}

function computeFormItemTotalPrice(item: EditableEventItem, currency: CurrencyFE): Money {
    return {
        amount: addVatAsNumber(toNumber(item.unitPrice), item.vat).withVat,
        currency,
    };
}

function computeFormPrices(items: EditableEventItem[], currency: CurrencyFE): { items: Money[], total: Money } {
    const itemPrices = items.map(item => computeFormItemTotalPrice(item, currency));
    const amount = itemPrices.filter((_, i) => !items[i].isDeleted).reduce((ans, price) => ans + price.amount, 0);

    return {
        items: itemPrices,
        total: { amount, currency },
    };
}

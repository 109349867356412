import { createTypedTFunction } from ':utils/i18n';
import type { TFunction } from 'i18next';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export * from './useBlocker';
export * from './useDaysLeft';
export * from './useEditing';
export * from './useHistoryState';
export * from './useInvoiceBackpay';
export * from './useMonthLoader';
export * from './usePagination';
export * from './useSortOrder';
export * from './useToggle';
export * from './useHashFragment';
export * from './api/useClients';
export * from './api/useGoogleCalendars';

export function useTypedTranslation(type: string, namespace: string, keyPrefix: string): TFunction {
    const { t } = useTranslation(namespace, { keyPrefix });
    return useMemo(() => createTypedTFunction(t, type), [ t, type ]);
}

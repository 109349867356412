import { useState } from 'react';
import localStorage from ':frontend/utils/localStorage';
import { Button, Form, Modal } from ':components/shadcn';
import { useTranslation } from 'react-i18next';

const LS_CLOSED_KEY = 'use_desktop_modal_closed';

export function GoToDesktopModal() {
    const { t } = useTranslation('common', { keyPrefix: 'goToDesktopModal' });

    const [ checkboxChecked, setCheckboxChecked ] = useState(false);
    // non-existant value (null, after first open) gets automatically converted to true
    const [ showModal, setShowModal ] = useState(() => !localStorage.get<boolean>(LS_CLOSED_KEY));

    function close() {
        setShowModal(false);
    }

    return (
        <Modal.Root
            open={showModal}
            onOpenChange={open => !open && close()}
        >
            <Modal.Content className='fl-use-desktop-modal' closeButton={t('button')}>
                <Modal.Header>
                    <Modal.Title>{t('title')}</Modal.Title>
                </Modal.Header>

                <div className='mt-4'>
                    <p>{t('body')}</p>
                    <div className='mt-4'>
                        <Form.Checkbox
                            label={t('checkbox-label')}
                            checked={checkboxChecked}
                            onCheckedChange={value => {
                                setCheckboxChecked(value);
                                localStorage.set(LS_CLOSED_KEY, value);
                            }}
                        />
                    </div>
                </div>

                <Modal.Footer>
                    <Button onClick={close}>{t('button')}</Button>
                </Modal.Footer>
            </Modal.Content>

            {/* If you need to customize the backdrop */}
            <Modal.Overlay className='fl-use-desktop-modal-backdrop' />
        </Modal.Root>
    );
}

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card, Modal } from ':components/shadcn';
import { SpinnerButton } from ':frontend/components/common';
import { useMaster } from ':frontend/context/UserProvider';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { routesFE } from ':utils/routes';
import { type BankAccountFE } from ':frontend/types/BankAccount';
import { type CurrencyFE } from ':utils/money';
import { createErrorAlert, createTranslatedErrorAlert, createTranslatedSuccessAlert } from ':frontend/components/notifications';
import useNotifications from ':frontend/context/NotificationProvider';
import { trpc } from ':frontend/context/TrpcProvider';
import StripeLogo from ':components/icons/StripeLogo';
import { LockIcon, MoneyBillsDollarIcon, BankIcon, ArrowsExpandDiagonal6Icon, ArrowsReduceDiagonal1Icon, Trash2Icon } from ':components/icons/basic';
import { PayPalIcon } from ':components/icons/logos';
import UpsertBankAccountModal from ':frontend/components/settings/UpsertBankAccountModal';

const STRIPE_DASHBOARD_LINK = 'https://dashboard.stripe.com/';

export function PaymentsSettings() {
    const { t } = useTranslation('pages', { keyPrefix: 'settings.payments' });

    const { appUser } = useMaster();
    const [ searchParams ] = useSearchParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (searchParams.get('c') !== '1')
            return;

        if ('rewardful' in window && typeof window.rewardful === 'function') {
            window.rewardful('convert', { email: appUser.email });
            console.log('Rewardful converted.');
        }

        navigate(routesFE.payments.path);
    }, []);

    return (
        <div className='py-12 flex flex-col gap-6'>
            <Card>
                <Card.Title className='flex item-center gap-2'>
                    <MoneyBillsDollarIcon className='h-5 text-warning-500' />
                    <span>{t('payout-section-title')}</span>
                </Card.Title>

                <Card.Divider />

                <div className='space-y-4'>
                    <div className='flex items-center gap-4 border rounded-lg p-5' >
                        <div className='flex items-center'>
                            <StripeLogo className='w-[68px]' />
                        </div>

                        <div className='flex-1 self-center space-y-2'>
                            <p className='text-secondary-900'>Stripe</p>
                            <p className='text-sm text-secondary-400'>{t('stripe-text')}</p>
                        </div>

                        <StripeIntegration />
                    </div>

                    <div className='flex items-center gap-4 border rounded-lg p-5' >
                        <div>
                            <PayPalIcon className='w-[68px]' />
                        </div>

                        <div className='flex-1 self-center space-y-2'>
                            <p className='text-secondary-900'>PayPal</p>
                            <p className='text-sm text-secondary-400'>{t('paypal-text')}</p>
                        </div>

                        <div className='flex items-center items-center justify-center gap-2 text-[#0C3FE6] bg-[#EBF3FF] px-5 h-9 rounded-full'>
                            <LockIcon />
                            <span>{t('coming-soon')}</span>
                        </div>
                    </div>
                </div>
            </Card>

            <BankAccountsSettings />
        </div>
    );
}

function StripeIntegration() {
    const { t } = useTranslation('pages', { keyPrefix: 'payments.stripeIntegration' });
    const { team } = useMaster();
    const { addAlert } = useNotifications();

    const connectStripeMutation = trpc.team.connectStripe.useMutation({
        onError: () => {
            addAlert(createErrorAlert(connectStripeMutation.error));
        },
        onSuccess: response => {
            window.location.href = response.onboardingUrl;
        },
    });

    if (!team.isStripeConnected) {
        return <SpinnerButton
            isFetching={connectStripeMutation.isPending}
            onClick={() => connectStripeMutation.mutate()}
            className='fl-shadow-sm-dark'
            size='small'
        >
            {t('connect-stripe-button')}
        </SpinnerButton>;
    }
    else {
        return <>
            <Link to={STRIPE_DASHBOARD_LINK} target='_blank' rel='noreferrer'>
                <Button variant='primary' size='small'>{t('open-stripe-button')}</Button>
            </Link>
        </>;
    }
}

function BankAccountsSettings() {
    const { t } = useTranslation('pages', { keyPrefix: 'payments' });

    const { addAlert } = useNotifications();
    const { bankAccounts, setBankAccounts } = useMaster();

    const [ showBankAccounts, setShowBankAccounts ] = useState(false);
    const [ deletingAccount, setDeletingAccount ] = useState<BankAccountFE>();

    const [ showUpsertForm, setShowUpsertForm ] = useState(false);
    const [ editingBankAccount, setEditingBankAccount ] = useState<BankAccountFE>();

    function deleteAccount(account: BankAccountFE) {
        setBankAccounts(bankAccounts.filter(a => a.id !== account.id));
        setDeletingAccount(undefined);

        addAlert(createTranslatedSuccessAlert('common:bankAccount.deleted-alert'));
    }

    return (
        <Card>
            <div className='flex flex-row items-center justify-between'>
                <div onClick={() => setShowBankAccounts(!showBankAccounts)}>
                    <Card.Title className='flex item-center gap-2 cursor-pointer'>
                        <BankIcon className='h-5 text-warning-500' />
                        <span>{t('bank-accounts-title')}</span>
                    </Card.Title>
                </div>

                <div className='flex items-center'>
                    <Button variant='outline' size='small' onClick={() => {
                        setEditingBankAccount(undefined);
                        setShowUpsertForm(true);
                    }}>
                        {t('create-new-account-label')}
                    </Button>

                    <Button variant='transparent' onClick={() => setShowBankAccounts(!showBankAccounts)}>
                        {!showBankAccounts && <ArrowsExpandDiagonal6Icon className='h-4' />}
                        {showBankAccounts && <ArrowsReduceDiagonal1Icon className='h-4' />}
                    </Button>
                </div>
            </div>

            {showBankAccounts && <div className='pt-4 flex flex-col gap-4'>
                {bankAccounts.length === 0 && <div className='font-semibold text-danger-500 text-center'>{t('no-bank-accounts')}</div>}

                {bankAccounts.map(account => (
                    <BankAccountItem
                        key={account.id}
                        account={account}
                        onUpdate={account => {
                            setEditingBankAccount(account);
                            setShowUpsertForm(true);
                        }}
                        onDelete={setDeletingAccount}
                    />
                ))}
            </div>}

            <DeleteBankAccountModal
                account={deletingAccount}
                onClose={() => setDeletingAccount(undefined)}
                onDelete={deleteAccount}
            />

            <UpsertBankAccountModal
                bankAccount={editingBankAccount}
                show={showUpsertForm}
                onClose={() => setShowUpsertForm(false)}
                currencyIds={[]}
            />
        </Card>
    );
}

type BankAccountItemProps = Readonly<{
    account: BankAccountFE;
    onUpdate: (update: BankAccountFE) => void;
    onDelete: (account: BankAccountFE) => void;
}>;

function BankAccountItem({ account, onUpdate, onDelete }: BankAccountItemProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'payments' });

    return (
        <div className='flex justify-between items-center p-5 rounded-lg border border-secondary-200'>
            <div className='flex gap-4 items-center'>
                <BankIcon className='h-6 text-secondary-900' />

                <div className='space-y-2'>
                    <div>{account.label}</div>

                    <CurrenciesDisplay currencies={account.currencies} />
                </div>
            </div>

            <div className='flex items-center'>
                <Button variant='transparent' onClick={() => onUpdate(account)} className='text-primary text-sm'>
                    {t('edit-bank-account-button')}
                </Button>

                <Button variant='transparent' onClick={() => onDelete(account)}>
                    <Trash2Icon className='h-3' />
                </Button>
            </div>
        </div>
    );
}

type CurrenciesDisplayProps = Readonly<{
    currencies: CurrencyFE[];
}>;

function CurrenciesDisplay({ currencies }: CurrenciesDisplayProps) {
    return (
        <div className='text-secondary-400'>
            {currencies.map(currency => currency.label).join(', ')}
        </div>
    );
}

type DeleteBankAccountModalProps = Readonly<{
    account?: BankAccountFE;
    onClose: () => void;
    onDelete: (deletedLocation: BankAccountFE) => void;
}>;

function DeleteBankAccountModal({ account, onClose, onDelete }: DeleteBankAccountModalProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'payments.deleteBankAccountModal' });
    const { addAlert } = useNotifications();

    const deleteBankAccountMutation = trpc.money.deleteBankAccount.useMutation();

    function deleteAccount() {
        if (!account)
            return;

        deleteBankAccountMutation.mutate(account, {
            onError: () => {
                addAlert(createTranslatedErrorAlert());
                onClose();
            },
            onSuccess: () => {
                onDelete(account);
            },
        });
    }

    return (
        <Modal.Root
            open={!!account}
            onOpenChange={open => !open && onClose()}
        >
            <Modal.Content className='gap-6' closeButton={t('cancel-button')}>
                <Modal.Header className='space-y-4'>
                    <Trash2Icon size={32} className='mx-auto text-danger-500 stroke-2' />

                    <Modal.Title className='text-center font-semibold text-secondary-900 text-3xl'>
                        {t('title')}
                    </Modal.Title>
                </Modal.Header>

                <div className='text-center'>
                    {t('text')}
                </div>

                <Modal.Footer className='grid grid-cols-2 gap-4'>
                    <Button variant='secondary' onClick={onClose}>
                        {t('cancel')}
                    </Button>

                    <SpinnerButton
                        variant='danger'
                        isFetching={deleteBankAccountMutation.isPending}
                        onClick={deleteAccount}
                    >
                        {t('confirm-button')}
                    </SpinnerButton>
                </Modal.Footer>
            </Modal.Content>
        </Modal.Root>
    );
}

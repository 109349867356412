import { Component, createRef } from 'react';
import DayColumn from './DayColumn';
import { TimeGutter } from './TimeGutter';
import TimeGridHeader from './TimeGridHeader';
import { localizer } from '.';
import { showMultiDayTimes } from './utils/common';
import { getNow } from './localizer';

export default class TimeGrid extends Component {
    constructor(props) {
        super(props);

        this.scrollRef = createRef();
        this.contentRef = createRef();
        this._scrollRatio = null;
    }

    componentDidMount() {
        this.calculateScroll();
        this.applyScroll();
    }

    handleScroll = e => {
        if (this.scrollRef.current)
            this.scrollRef.current.scrollLeft = e.target.scrollLeft;

    };

    componentDidUpdate() {
        this.applyScroll();
    }

    handleSelectAlldayEvent = (...args) => {
    //cancel any pending selections so only the event click goes through.
        this.clearSelection();
        this.props.onSelectEvent(...args);
    };

    handleSelectAllDaySlot = (slots, slotInfo) => {
        const start = slots[0];
        const end = slots[slots.length - 1].plus({ day: 1 });

        this.props.onSelectSlot({
            slots,
            start,
            end,
            action: slotInfo.action,
        });
    };

    renderEvents(range, events, now) {
        return range.map((date, dayIndex) => {
            const daysEvents = events.filter(event => localizer.inRange(date, event.start, event.end, 'day'));

            return (
                <DayColumn
                    {...this.props}
                    min={localizer.merge(date, this.props.min)}
                    max={localizer.merge(date, this.props.max)}
                    isNow={localizer.isSameDate(date, now)}
                    key={dayIndex}
                    dayIndex={dayIndex}
                    date={date}
                    events={daysEvents}
                />
            );
        });
    }

    render() {
        const { range, min, max } = this.props;

        const start = range[0];
        const end = range[range.length - 1];
        const currentRange = { start, end };

        this.slots = range.length;

        const allDayEvents = [];
        const rangeEvents = [];

        this.props.events.filter(event => localizer.inEventRange(event, currentRange)).forEach(event => {
            const eStart = event.start;
            const eEnd = event.end;

            if (
                event.allDay ||
                localizer.startAndEndAreDateOnly(eStart, eEnd) ||
                (showMultiDayTimes && !localizer.isSameDate(eStart, eEnd))
            )
                allDayEvents.push(event);
            else
                rangeEvents.push(event);
        });

        allDayEvents.sort(localizer.sortEvents);

        return (
            <div className='rbc-time-view'>
                <TimeGridHeader
                    range={range}
                    events={allDayEvents}
                    selected={this.props.selected}
                    selectable={this.props.selectable}
                    scrollRef={this.scrollRef}
                    onSelectEvent={this.handleSelectAlldayEvent}
                    onSelectSlot={this.handleSelectAllDaySlot}
                />
                <div
                    ref={this.contentRef}
                    className='rbc-time-content fl-hide-scrollbar relative flex grow items-start w-full overflow-y-auto border-t border-calendar-border'
                    onScroll={this.handleScroll}
                >
                    <TimeGutter
                        date={start}
                        min={localizer.merge(start, min)}
                        max={localizer.merge(start, max)}
                    />
                    {this.renderEvents(
                        range,
                        rangeEvents,
                        getNow(),
                    )}
                </div>
            </div>
        );
    }

    clearSelection() {
        clearTimeout(this._selectTimer);
        this._pendingSelection = [];
    }

    applyScroll() {
    // If auto-scroll is disabled, we don't actually apply the scroll
        if (this._scrollRatio != null) {
            const content = this.contentRef.current;
            content.scrollTop = content.scrollHeight * this._scrollRatio;
            // Only do this once
            this._scrollRatio = null;
        }
    }

    calculateScroll(props = this.props) {
        const { min, max, scrollToTime } = props;

        const diffMillis = scrollToTime - localizer.startOf(scrollToTime, 'day');
        const totalMillis = localizer.diff(min, max, 'milliseconds');

        this._scrollRatio = diffMillis / totalMillis;
    }
}

import { useRef, useMemo, useLayoutEffect, type ReactNode, useCallback, useState } from 'react';
import { Spinner } from ':components/shadcn';
import { Button, type ButtonProps } from ':components/shadcn';

type BaseSpinnerButtonProps = Omit<ButtonProps, 'isFetching' | 'fetching' | 'fid' | 'isOverlay'> & {
    /** The icon is like content, but it will be displayed even if the button is fetching. */
    icon?: ReactNode;
};

// Type OR is not ideal here, because we would need to delete the other properties from the rest object.
type SpinnerButtonProps = BaseSpinnerButtonProps & {
    isFetching?: boolean;
    /**
     * If fetching === fid, then the button is fetching.
     * Else if !!fetching, then the button is disabled.
     */
    fetching?: string;
    fid?: string;
    /** If the button is under overlay, it shouldn't be disabled even during fetching. */
    isOverlay?: boolean;
};

export default function SpinnerButton(props: BaseSpinnerButtonProps & { isFetching: boolean | undefined }): JSX.Element;

export default function SpinnerButton(props: BaseSpinnerButtonProps & { fetching: string | undefined, fid: string, isOverlay?: boolean }): JSX.Element;

/**
 * This component acts like a button that turns into a spinner whenewer isFetching === true.
 * The button is disabled, however its dimensions remain constant.
 */
export default function SpinnerButton({ disabled, isFetching, fetching, fid, isOverlay, style, icon, ...rest }: SpinnerButtonProps) {
    const [ measurements, setMeasurements ] = useState<{ width?: number, height?: number }>({});
    const contentRef = useRef<HTMLButtonElement>(null);

    const doMeasurements = useCallback(() => {
        if (!contentRef.current)
            return;

        const newWidth = contentRef.current.getBoundingClientRect().width;
        const newHeight = contentRef.current.getBoundingClientRect().height;

        setMeasurements(({ width, height }) => ({
            width: (!width || newWidth > width) ? newWidth : width,
            height: (!height || newHeight > height) ? newHeight : height,
        }));
    }, []);

    const isFetchingInner = isFetching ?? (fid !== undefined && fetching === fid);
    const isDisabled = !!disabled || isFetchingInner || !(!fetching || isOverlay);

    useLayoutEffect(() => {
        doMeasurements();
        // This should be enought time for all animations to finish.
        const timer = setTimeout(doMeasurements, 500);
        return () => clearTimeout(timer);
    }, [ isFetchingInner, doMeasurements ]);

    const variant = useMemo(() => {
        if (rest.variant?.includes('outline'))
            return 'dark';
        else
            return 'light';
    }, [ rest.variant ]);

    return (
        <Button
            variant='primary'
            {...rest}
            disabled={isDisabled}
            ref={contentRef}
            style={(isFetchingInner ? { ...measurements, ...style } : style)}
        >
            {isFetchingInner ? (
                <Spinner
                    size='sm'
                />
            ) : (
                rest.children
            )}
            {icon}
        </Button>
    );
}

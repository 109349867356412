import clsx from 'clsx';
import { Button } from ':components/shadcn';
import { ChevronDownIcon, ChevronUpIcon } from ':components/icons/basic';

type SpoilerButtonProps = Readonly<{
    showTitle: string;
    hideTitle: string;
    isShow: boolean;
    setIsShow: (isShow: boolean) => void;
}>;

export function SpoilerButton({ showTitle, hideTitle, isShow, setIsShow }: SpoilerButtonProps) {
    return (
        <Button variant='white' size='tiny' onClick={() => setIsShow(!isShow)}>
            {isShow ? (<>
                <ChevronUpIcon size={18} className='mr-2' />
                <span>{hideTitle}</span>
            </>) : (<>
                <ChevronDownIcon size={18} className='mr-2' />
                <span>{showTitle}</span>
            </>)}
        </Button>
    );
}

type SpoilerButtonRowProps = Readonly<{
    title: string;
    isShow: boolean;
    setIsShow: (isShow: boolean) => void;
    className?: string;
}>;

export function SpoilerButtonRow({ title, className, ...rest }: SpoilerButtonRowProps) {
    return (
        <div className={clsx('flex items-center', className)}>
            <div className='grow border-b' />
            <SpoilerButton showTitle={title} hideTitle={title} {...rest} />
            <div className='grow border-b' />
        </div>
    );
}

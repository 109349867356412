import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { Form } from ':components/shadcn';
import useAuth from ':frontend/context/AuthProvider';
import { SpinnerButton } from ':frontend/components/common';
import { RHFErrorMessage } from ':frontend/components/forms/ErrorMessage';
import { useNavigationAction, type NavigationProperty } from ':frontend/hooks';
import { routesFE } from ':utils/routes';
import { FlowlanceBanner } from ':components/icons/old';
import { GoogleOauthButton } from ':frontend/components/auth/googleButton';
import { DividerWithLabel } from './Register';
import PasswordInput from ':frontend/components/forms/PasswordInput';
import useNotifications from ':frontend/context/NotificationProvider';
import { createErrorAlert } from ':frontend/components/notifications';
import { Visibility } from ':frontend/types/notifications';
import { trpc } from ':frontend/context/TrpcProvider';

export type PrefillEmail = NavigationProperty<'prefillEmail', string>;

type LoginFormData = {
    email: string;
    password: string;
};

export default function Login() {
    const { t } = useTranslation('pages', { keyPrefix: 'login' });
    const trpcUtils = trpc.useUtils();
    const [ isFetching, setIsFetching ] = useState(false);
    const prefilledEmail = useNavigationAction<PrefillEmail>('prefillEmail')?.data;

    const { auth } = useAuth();
    const { addAlert } = useNotifications();

    const postLogin = async (data: LoginFormData) => {
        setIsFetching(true);
        const result = await auth.loginWithEmail(data.email, data.password);
        await trpcUtils.invalidate();
        setIsFetching(false);
        if (!result.status)
            addAlert(createErrorAlert(result.error), Visibility.Unauthorized);
    };

    return (
        <div className='fl-unauthorized-page'>
            <div className='fl-centering-wrapper'>
                <div className='fl-unauthorized-card'>
                    <div className='fl-unauthorized-content flex flex-col items-center'>
                        <FlowlanceBanner />
                        <h1>{t('page-title')}</h1>
                        {!prefilledEmail && (<>
                            <GoogleOauthButton type='login' />
                            <DividerWithLabel label={t('or-label')} className='my-4' />
                        </>)}
                        <LoginEmailForm
                            onSubmit={postLogin}
                            isFetching={isFetching}
                            prefilledEmail={prefilledEmail}
                        />
                        <Link to={routesFE.resetPassword} className='self-end mt-2'>
                            {t('reset-password-link')}
                        </Link>
                        <div className='text-center mt-8'>
                            <Trans
                                i18nKey='register-link'
                                t={t}
                                components={{
                                    a: <Link to={routesFE.register.index} />,
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

type LoginEmailFormProps = Readonly<{
    onSubmit: (output: LoginFormData) => void;
    isFetching: boolean;
    prefilledEmail?: string;
}>;

function LoginEmailForm({ onSubmit, isFetching, prefilledEmail }: LoginEmailFormProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'login' });
    const { t: tf } = useTranslation('common', { keyPrefix: 'form' });
    const { register, handleSubmit, formState: { errors } } = useForm<LoginFormData>();

    return (
        <Form.Root onSubmit={handleSubmit(onSubmit)} className='w-full'>
            <div className='fl-form-label-inside'>
                <Form.Input
                    label={t('email-label')}
                    type='email'
                    {...register('email', { required: tf('email-required') })}
                    defaultValue={prefilledEmail}
                />
                <RHFErrorMessage errors={errors} name='email' />
            </div>
            <div className='fl-form-label-inside mt-4'>
                <PasswordInput
                    label={t('password-label')}
                    register={register('password', { required: tf('password-required') })}
                />
                <RHFErrorMessage errors={errors} name='password' />
            </div>
            <SpinnerButton
                type='submit'
                variant='primary'
                className='w-full mt-4'
                isFetching={isFetching}
            >
                {t('login-button')}
            </SpinnerButton>
        </Form.Root>
    );
}

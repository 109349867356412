import { forwardRef, type ButtonHTMLAttributes } from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import { cn } from './utils';

const buttonVariants = cva('rounded-full whitespace-nowrap inline-flex items-center justify-center gap-2 select-none disabled:pointer-events-none [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0 focus-visible:outline-none', {
    variants: {
        variant: {
            primary: 'text-white bg-primary hover:bg-primary/70 disabled:bg-primary/20',
            secondary: 'text-primary bg-primary-50 hover:bg-primary-100 disabled:bg-primary-50 disabled:opacity-30',
            dark: 'text-white bg-primary-800 hover:bg-primary-800/70 disabled:bg-primary-800/20',
            outline: 'border bg-white hover:border-black disabled:opacity-40',
            ghost: 'text-primary bg-transparent hover:bg-[#f5f5f5] disabled:opacity-40',
            /**
             * This button can be displayed over any kind of background. Use it when there is only a text that acts like a button.
             * It relies on opacity to show its state, so its color can be adjusted as needed. E.g., if on a dark background, it can be set to white.
             */
            transparent: 'bg-transparent hover:opacity-80 active:opacity-60 disabled:opacity-40',
            white: 'border bg-white hover:border-primary-300 disabled:opacity-30',
            danger: 'border border-danger-400 hover:border-danger-600 bg-danger-100 text-danger-800 hover:text-danger-900 disabled:opacity-30', // TODO
            'outline-danger': '', // TODO
            'ghost-secondary': '', // TODO
            'ghost-danger': '', // TODO
        },
        size: {
            exact: '',
            tiny: 'h-7 px-4 text-sm',
            small: 'h-9 px-5 text-base',
            medium: 'h-10 px-5 text-lg',
            large: 'h-11 px-6 text-2lg',
        },
    },
    compoundVariants: [ {
        variant: [ 'outline', 'white', 'danger' ],
        size: 'tiny',
        class: 'px-4b',
    }, {
        variant: [ 'outline', 'white', 'danger' ],
        size: [ 'small', 'medium' ],
        class: 'px-5b',
    }, {
        variant: [ 'outline', 'white', 'danger' ],
        size: 'large',
        class: 'px-6b',
    } ],
    defaultVariants: {
        variant: 'primary',
        size: 'medium',
    },
});

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & VariantProps<typeof buttonVariants> & {
    asChild?: boolean;
};

const Button = forwardRef<HTMLButtonElement, ButtonProps>(({ className, variant, size, asChild = false, ...props }, ref) => {
    const Component = asChild ? Slot : 'button';

    return (
        <Component
            ref={ref}
            className={cn(buttonVariants({ variant, size, className }))}
            type='button'
            {...props}
        />
    );
});
Button.displayName = 'Button';

export { Button, buttonVariants };

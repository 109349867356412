import { LockIcon } from ':components/icons/basic';
import { Form } from ':components/shadcn';

export function TermsOfService() {
    return (
        <div className='flex items-center justify-between gap-4'>
            <div className='flex items-center gap-4'>
                <Form.Switch label='Terms of Service' />
            </div>

            <div className='flex flex-row items-center justify-center gap-2 text-[#0C3FE6] bg-[#EBF3FF] px-5 py-2.5 rounded-full'>
                <LockIcon />
                {/* TODO translation */}
                Upgrade
            </div>
        </div>
    );
}

import { useEffect, useState } from 'react';
import { Form } from ':components/shadcn';
import { useForm } from 'react-hook-form';
import { type ClientFE } from ':frontend/types/Client';
import { RHFErrorMessage } from ':frontend/components/forms/ErrorMessage';
import { SpinnerButton } from '../common';
import { inputToForm as subscriberInputToForm, formToOutput as subscriberFormToOutput, type FormDataType as SubscriberFormData, SubscriberFormInner } from ':frontend/components/settings/SubscriberForm';
import { useTranslation } from 'react-i18next';
import { useNestedForm } from ':frontend/utils/forms';
import { EMAIL_SOFT_VALIDATION_PATTERN, canonizeEmail } from ':utils/forms';
import { type UserContext, useUser, toMaster } from ':frontend/context/UserProvider';
import { ControlledInvoicingProfileSelect, ControlledLocaleSelect, ControlledCountrySelect, ControlledTimezoneSelect } from ':frontend/components/forms';
import { SpoilerButtonRow } from '../common/SpoilerButton';
import type { CountryCode, LocaleCode, TimezoneCode } from ':utils/i18n';
import type { ClientWithProfileInit } from ':utils/entity/client';

type ClientFormProps = Readonly<{
    onSubmit: (output: ClientWithProfileInit) => void;
    isFetching: boolean;
    defaultValue?: ClientFE;
    onChange?: (isDirty: boolean) => void;
}>;

export default function ClientForm({ onSubmit, isFetching, defaultValue, onChange }: ClientFormProps) {
    const { t } = useTranslation('components', { keyPrefix: 'clientForm' });
    const { t: tf } = useTranslation('common', { keyPrefix: 'form' });

    const {
        form,
        handleSubmit,
        showPreferences,
        setShowPreferences,
        showMore,
        setShowMore,
        showProfileSelect,
    } = useClientForm(onSubmit, defaultValue, onChange);
    const { control, register, formState: { errors } } = form;

    return (
        <Form.Root onSubmit={handleSubmit}>
            <div className='mb-4'>
                <Form.Input
                    label={t('name-company-label')}
                    {...register('name', { required: tf('name-company-required') })}
                    placeholder={t('name-company-placeholder')}
                />
                <RHFErrorMessage errors={errors} name='name' />
            </div>
            <div className='gap-4'>
                <div>
                    <Form.Input
                        label={t('email-label')}
                        type='email'
                        {...register('email', {
                            required: tf('email-required'),
                            pattern: {
                                value: EMAIL_SOFT_VALIDATION_PATTERN,
                                message: tf('email-invalid'),
                            },
                        })}
                        placeholder={t('email-placeholder')}
                    />
                    <RHFErrorMessage errors={errors} name='email' />
                </div>
                <div>
                    <Form.Input
                        label={t('phone-number-label')}
                        {...register('phoneNumber', { required: false })}
                        placeholder={t('phone-number-placeholder')}
                    />
                </div>
            </div>
            <div className='mt-4'>
                <Form.Textarea
                    label={t('note-label')}
                    {...register('note', { required: false, maxLength: { value: 1000, message: tf('text-too-long', { count: 1000 }) } })}
                    placeholder={t('note-placeholder')}
                    minRows={2}
                />
                <RHFErrorMessage errors={errors} name='note' />
            </div>
            <SpoilerButtonRow title={t('show-more-toggle')} isShow={showMore} setIsShow={setShowMore} className='my-8' />
            {showMore && (<>
                <SubscriberFormInner form={form} isNew />
                <div className='mt-4 mb-4'>
                    <Form.Switch
                        label={t('preferences-toggle')}
                        checked={showPreferences}
                        onCheckedChange={setShowPreferences}
                    />
                </div>
                {showPreferences && (<>
                    <div className='fl-description-no-border'>{t('preferences-description')}</div>
                    <div className='gap-y-4'>
                        <div>
                            <Form.Label>{t('timezone-label')}</Form.Label>
                            <ControlledTimezoneSelect
                                control={control}
                                name='timezone'
                            />
                        </div>
                        <div>
                            <Form.Label>{t('locale-label')}</Form.Label>
                            <ControlledLocaleSelect
                                control={control}
                                name='locale'
                                type='base'
                            />
                        </div>
                        <div>
                            <Form.Label>{t('country-label')}</Form.Label>
                            <ControlledCountrySelect
                                control={control}
                                name='countryForPreferences'
                            />
                        </div>
                        {showProfileSelect && (
                            <div>
                                <Form.Label>{t('profile-label')}</Form.Label>
                                <ControlledInvoicingProfileSelect
                                    control={control}
                                    name='invoicingProfileId'
                                />
                            </div>
                        )}
                    </div>
                </>)}
            </>)}
            <div className='mt-12 justify-center'>
                <div>
                    <SpinnerButton
                        type='submit'
                        isFetching={isFetching}
                        className='w-full'
                    >
                        {t('save-button')}
                    </SpinnerButton>
                </div>
            </div>
        </Form.Root>
    );
}

function useClientForm(
    onSubmit: (output: ClientWithProfileInit) => void,
    defaultValue?: ClientFE,
    onChange?: (isDirty: boolean) => void,
) {
    const form = useForm<ClientFormData>();
    const isDirty = form.formState.isDirty;
    const userContext = useUser();
    const masterContext = toMaster(userContext);
    const [ showPreferences, setShowPreferences ] = useState(false);
    const [ showMore, setShowMore ] = useState(false);

    useEffect(() => onChange?.(isDirty), [ isDirty ]);

    const handleNestedSubmit = useNestedForm(form.handleSubmit);

    function onValidSubmit(data: ClientFormData) {
        onSubmit(formToOutput({ ...data, email: canonizeEmail(data.email) }));
    }

    useEffect(() => {
        form.reset(inputToForm(defaultValue, userContext));
    }, [ defaultValue ]);

    return {
        form,
        handleSubmit: handleNestedSubmit(onValidSubmit),
        showPreferences,
        setShowPreferences,
        showMore,
        setShowMore,
        showProfileSelect: masterContext && masterContext.profiles.length > 0,
    };
}

type ClientFormData = {
    name: string;
    email: string;
    phoneNumber: string;

    timezone: TimezoneCode;
    locale: LocaleCode;
    countryForPreferences: CountryCode;
    invoicingProfileId: string;

    note: string;
} & SubscriberFormData;

function inputToForm(client: ClientFE | undefined, { settings }: UserContext): ClientFormData {
    const subscriberFormData = subscriberInputToForm(client?.subscriberSettings ?? {
        hideEmailOnInvoice: false,
        name: '',
    });
    // The default country would be '', which is not undefined but it still isn't valid. So we use the settings country.
    subscriberFormData.country = subscriberFormData.country || settings.country;

    return {
        email: client?.email ?? '',
        phoneNumber: client?.phoneNumber ?? '',
        timezone: client?.timezone ?? settings.timezone,
        locale: client?.locale ?? settings.locale,
        countryForPreferences: client?.country ?? settings.country,
        invoicingProfileId: client?.invoicingProfileId as unknown as string,
        note: client?.note ?? '',
        ...subscriberFormData,
    };
}

function formToOutput(data: ClientFormData): ClientWithProfileInit {
    return {
        name: data.name,
        email: data.email,
        phoneNumber: data.phoneNumber,
        timezone: data.timezone,
        locale: data.locale,
        country: data.countryForPreferences,
        note: data.note,
        invoicingProfileId: data.invoicingProfileId,
        subscriberSettings: subscriberFormToOutput(data),
    };
}

import { useMaster } from ':frontend/context/UserProvider';
import { useTranslation } from 'react-i18next';
import { Card, Form } from ':components/shadcn';
import { useUpdating } from ':frontend/hooks';
import { trpc } from ':frontend/context/TrpcProvider';
import { useCallback } from 'react';
import { TeamSettingsFE } from ':frontend/types/settings';
import type { IconType } from ':components/icons/common';
import { LicenseIcon } from ':components/icons/basic';

export function ModulesSettings() {
    return (
        <div className='mt-12 flex flex-col gap-2'>
            <ModuleCard type='invoicing' Icon={LicenseIcon} />
            <ModuleCard type='taxes' Icon={LicenseIcon} />
        </div>
    );
}

const typeToFlag = {
    'invoicing': 'isInvoicingEnabled',
    'taxes': 'isTaxesEnabled',
} as const;

type ModuleCardProps = Readonly<{
    type: keyof typeof typeToFlag;
    Icon?: IconType;
}>;

function ModuleCard({ type, Icon }: ModuleCardProps) {
    const { t } = useTranslation('pages', { keyPrefix: `settings.modules.${type}` });
    const flag = typeToFlag[type];
    const { teamSettings, setTeamSettings } = useMaster();

    const updateFlagMutation = trpc.team.updateTeamFlag.useMutation();

    const syncState = useCallback(async (newValue: boolean) => {
        try {
            const response = await updateFlagMutation.mutateAsync({ flag, value: newValue });
            setTeamSettings(TeamSettingsFE.fromServer(response));
            return true;
        }
        catch {
            return false;
        }
    }, [ flag, updateFlagMutation, setTeamSettings ]);

    const [ state, updateState, isUpdatingState ] = useUpdating(teamSettings[flag], syncState);

    return (
        <Card className='space-y-4'>
            <div className='flex flex-row items-center justify-between'>                
                <div className='flex items-center justify-between gap-2'>                    
                    {Icon && <div className='size-10 rounded-full flex items-center justify-center bg-secondary-100'>
                        <Icon className='h-5 w-auto' />
                    </div>}

                    <Card.Title className='capitalize'>{type}</Card.Title>
                </div>             
                                    
                <Form.Switch
                    checked={state}
                    onCheckedChange={updateState}
                    disabled={isUpdatingState}
                />                
            </div>

            <p className='mt-4 mb-0 leading-5'>{t('description')}</p>
        </Card>
    );
}

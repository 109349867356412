import { type ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { DefaultFilterItemBadge, type Filter, type FilterDefinition, type FilterFunction, type FilterItemBadgeProps, type FilterMenuProps } from './FilterRow';
import { DropdownMenu, Select, type SingleValue } from ':components/shadcn';
import { Trans, useTranslation } from 'react-i18next';
import { type ClientInfoFE } from ':frontend/types/Client';
import { type Id } from ':utils/id';
import { type ClientTagFE } from ':frontend/types/ClientTag';
import { useMaster } from ':frontend/context/UserProvider';
import { ClientTagDisplay } from ':frontend/components/client/ClientTags';

const filterName = 'clientTag';

type FilterState = {
    selectedIds: Id[];
};

function FilterToggleMenu({ state, setState }: FilterMenuProps<FilterState>) {
    return (
        <DropdownMenu.Item className='p-4 bg-white'>
            <InnerFilter state={state} setState={setState} />
        </DropdownMenu.Item>
    );
}

function FilterRowMenu({ state, setState }: FilterMenuProps<FilterState>) {
    return (
        <div style={{ width: 200 }}>
            <InnerFilter state={state} setState={setState} />
        </div>
    );
}

function InnerFilter({ state, setState }: FilterMenuProps<FilterState>) {
    const { t } = useTranslation('common', { keyPrefix: `filters.${filterName}` });
    const { clientTags } = useMaster();
    const options: TagOption[] = useMemo(() => clientTags
        .filter(tag => !state.selectedIds.find(tagId => tagId === tag.id))
        .map(tag => ({ value: tag, label: tag.name })),
    [ clientTags, state ]);

    const [ innerValue, setInnerValue ] = useState<TagOption>();

    const handleOnChange = useCallback((option: SingleValue<TagOption>) => {
        if (!option)
            return;

        setState({ ...state, selectedIds: [ ...state.selectedIds, option.value.id ] });
        setInnerValue(undefined);
    }, [ state, setState ]);

    return (
        <Select
            value={innerValue ?? null}
            options={options}
            onChange={handleOnChange}
            placeholder={t('search-placeholder')}
            immutableProps={{ CustomOption }}
        />
    );
}

type TagOption = {
    value: ClientTagFE;
    label: ReactNode;
};

function CustomOption({ data }: Readonly<{ data: TagOption} >) {
    return (
        <div className='flex items-center'>
            <ClientTagDisplay tag={data.value} />
        </div>
    );
}

function FilterItemBadge({ item, onClose }: FilterItemBadgeProps<Id>) {
    const { t } = useTranslation('common', { keyPrefix: `filters.${filterName}` });
    const { clientTags } = useMaster();
    const tag = useMemo(() => clientTags.find(tag => tag.id === item), [ item, clientTags ]);

    useEffect(() => {
        if (!tag)
            onClose(item);
    }, [ tag, item ]);

    return (
        <DefaultFilterItemBadge item={item} onClose={onClose}>
            <Trans t={t} i18nKey='badge-label' components={{ sm: <span /> }} values={{ name: tag?.name }} />
        </DefaultFilterItemBadge>
    );
}

function remove(state: FilterState, item: Id): FilterState {
    return {
        selectedIds: state.selectedIds.filter(id => id !== item),
    };
}

function toItems(state: FilterState): Id[] {
    return state.selectedIds;
}

function createFilterFunction(state: FilterState): FilterFunction<ClientInfoFE> {
    if (state.selectedIds.length === 0)
        return () => true;

    return (data: ClientInfoFE) => state.selectedIds.every(id => data.tagIds.some(tagId => tagId === id));
}

function toServer(): undefined[] {
    return [];
}

const ClientTagFilter: FilterDefinition<Id, FilterState, ClientInfoFE> = {
    name: filterName,
    defaultValues: {
        selectedIds: [],
    },
    FilterToggleMenu,
    FilterRowMenu,
    FilterItemBadge,
    remove,
    toItems,
    createFilterFunction,
    toServer: toServer as (state: FilterState, previous: unknown | undefined) => undefined[],
};

export default ClientTagFilter as Filter;

import { useCallback, useMemo } from 'react';
import { Select, type SingleValue } from ':components/shadcn';
import { useTranslation } from 'react-i18next';
import { Controller, type Control, type FieldPath, type FieldValues } from 'react-hook-form';
import { type TFunction } from 'i18next';

type CondensedInvoiceType = 'condensed' | 'full';

type CondensedInvoiceToggleProps = Readonly<{
    value: boolean;
    onChange: (value: boolean) => void;
}>;

export function CondensedInvoiceToggle({ value, onChange }: CondensedInvoiceToggleProps) {
    const { t } = useTranslation('components', { keyPrefix: 'condensedInvoiceToggle' });

    const options = useMemo(() => availableValues.map(av => innerValueToOption(av, t)), [ t ]);
    const innerValue = useMemo(() => innerValueToOption(value ? 'condensed' : 'full', t), [ value, t ]);

    const innerOnChange = useCallback((option: SingleValue<Option>) => {
        if (option === null)
            return;

        onChange(option.value === 'condensed');
    }, [ onChange ]);

    return (
        <Select
            options={options}
            value={innerValue}
            onChange={innerOnChange}
        />
    );
}

const availableValues: CondensedInvoiceType[] = [
    'condensed',
    'full',
];

type Option = {
    value: CondensedInvoiceType;
    label: string;
};

function innerValueToOption(value: CondensedInvoiceType, t: TFunction): Option {
    return { value, label: t(`${value}-option`) };
}

type ControlledCondensedInvoiceToggleProps<TFieldValues extends FieldValues> = {
    control: Control<TFieldValues>;
    name: FieldPath<TFieldValues>;
};

export function ControlledCondensedInvoiceToggle<TFieldValues extends FieldValues>({ control, name }: ControlledCondensedInvoiceToggleProps<TFieldValues>) {
    const InnerSelect = useCallback(({ field }: { field: { value: boolean, onChange: (value: boolean) => void } }) => {
        return (
            <CondensedInvoiceToggle
                value={field.value}
                onChange={field.onChange}
            />
        );
    }, []);

    return (
        <Controller
            control={control}
            name={name}
            render={InnerSelect}
        />
    );
}

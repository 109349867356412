import type { ReactNode } from 'react';
import { getCommonProps, pathProps, type IconProps } from './common';

// Whenever you need an icon that's not included here yet, copy it from /resources/all.tsx.
// Keep the icons in alphabetical order and add it to basicIcons at the end.
// In general, the icons have the same name as in Ligma. However, the number might not be correct (e.g., Box1Icon and Box2Icon might have been swapped).
// The reason is that the numbers were reassigned (because there were icons like XYZ-3 but no XYZ-1 or XYZ-2).

export const ArrowLeftIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M2.75 9h12.5M7 13.25 2.75 9 7 4.75' />
</svg>);

export const ArrowRightIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M15.25 9H2.75M11 4.75 15.25 9 11 13.25' />
</svg>);

export const ArrowsExpandDiagonal6Icon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M10.75 2.75h4.5v4.5M15.25 2.75l-4.5 4.5M2.75 10.75v4.5h4.5M2.75 15.25l4.5-4.5' />
</svg>);

export const ArrowsReduceDiagonal1Icon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M15.25 7.25h-4.5v-4.5M10.75 7.25l4.5-4.5M7.25 15.25v-4.5h-4.5M7.25 10.75l-4.5 4.5' />
</svg>);

export const BankIcon = (props: IconProps) => (<svg {...getCommonProps(props, 22)}>
    <path d='M2.75 20.1666H19.25' {...pathProps.stroke} />
    <path d='M5.5 16.5V10.0834' {...pathProps.stroke} />
    <path d='M9.16663 16.5V10.0834' {...pathProps.stroke} />
    <path d='M12.8334 16.5V10.0834' {...pathProps.stroke} />
    <path d='M16.5 16.5V10.0834' {...pathProps.stroke} />
    <path d='M11 1.83337L18.3333 6.41671H3.66663L11 1.83337Z' {...pathProps.stroke} />
</svg>);

export const BankingMobileIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M12.25 1.75h-6.5a2 2 0 0 0-2 2v10.5a2 2 0 0 0 2 2h6.5a2 2 0 0 0 2-2V3.75a2 2 0 0 0-2-2' />
    <path {...pathProps.stroke} d='M10.817 6.95c-.394-.932-1.183-1.143-1.779-1.143-.554 0-2.01.295-1.875 1.692.094.98 1.019 1.346 1.827 1.49s1.981.452 2.01 1.635c.024 1-.875 1.683-1.962 1.683-1.038 0-1.76-.404-2.038-1.317M9 5.048v.645M9 12.307v.645' />
</svg>);

export const BankStatementIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M15.25 14.25V3.75a2 2 0 0 0-2-2h-8.5a2 2 0 0 0-2 2v10.5a2 2 0 0 0 2 2h8.5a2 2 0 0 0 2-2M5.75 10.25h6.5M5.75 13.25h6.5' />
    <path {...pathProps.stroke} d='m6.071 5.127 2.75-1.058a.5.5 0 0 1 .359 0l2.75 1.058a.5.5 0 0 1 .321.467v.657a.5.5 0 0 1-.5.5H6.25a.5.5 0 0 1-.5-.5v-.657a.5.5 0 0 1 .321-.467M9 6.75v3.5M6.75 6.75v3.5M11.25 6.75v3.5' />
</svg>);

export const Box2Icon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M9 2.25v6M13.25 5.25h-8.5a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h8.5a2 2 0 0 0 2-2v-6a2 2 0 0 0-2-2' />
    <path {...pathProps.stroke} d='m3 6.284 1.449-2.922A2 2 0 0 1 6.241 2.25h5.518a2 2 0 0 1 1.792 1.112L15 6.285M5.25 12.75h2' />
</svg>);

export const Box2PlusIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M5.25 9.25V6.083l6.75-3' />
    <path {...pathProps.stroke} d='M9.406 1.931 15.75 4.75 9.406 7.569a1 1 0 0 1-.812 0L2.25 4.75l6.344-2.819a1 1 0 0 1 .812 0M9 7.656v8.413M14.75 10.75v5M17.25 13.25h-5M2.25 4.75v7.85c0 .395.233.753.594.914l5.75 2.556a1 1 0 0 0 .812 0l1.552-.69M15.75 8.461V4.75' />
</svg>);

export const BoxHeartIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M9 2.25v6M3 6.284l1.449-2.922A2 2 0 0 1 6.241 2.25h5.518a2 2 0 0 1 1.792 1.112L15 6.285' />
    <path {...pathProps.stroke} d='M15.25 9.019V7.25a2 2 0 0 0-2-2h-8.5a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h3.881' />
    <path {...pathProps.stroke} d='M13.782 17.197a.48.48 0 0 0 .437 0c.729-.374 3.031-1.73 3.031-3.934.004-.968-.791-1.757-1.777-1.763a1.8 1.8 0 0 0-1.473.786 1.81 1.81 0 0 0-1.473-.786c-.985.006-1.78.794-1.777 1.763 0 2.205 2.303 3.56 3.031 3.934z' />
</svg>);

export const BugIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M9 15.25v-4.5M6.75 5.75V4.5a2.25 2.25 0 1 1 4.5 0v1.25M4.75 9.75h-3M4.75 7.25A2.75 2.75 0 0 1 2 4.5M4.75 12.25A2.75 2.75 0 0 0 2 15M13.25 9.75h3M13.25 7.25A2.75 2.75 0 0 0 16 4.5M13.25 12.25A2.75 2.75 0 0 1 16 15' />
    <path {...pathProps.stroke} d='M6.75 5.75h4.5a2 2 0 0 1 2 2V11A4.25 4.25 0 0 1 9 15.25 4.25 4.25 0 0 1 4.75 11V7.75a2 2 0 0 1 2-2' />
</svg>);

export const BugSlashIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M6.75 5.75V4.5a2.25 2.25 0 1 1 4.5 0v1.25M9 15.25v-2.5' />
    <path {...pathProps.stroke} d='M5.169 12.843A4.2 4.2 0 0 1 4.75 11V7.75a2 2 0 0 1 2-2h4.5c.293 0 .572.063.823.177M13.25 8.25V11a4.25 4.25 0 0 1-6.39 3.672M4.75 9.75h-3' />
    <path {...pathProps.stroke} d='M4.75 7.25A2.75 2.75 0 0 1 2 4.5M13.25 9.75h3M13.25 12.25A2.75 2.75 0 0 1 16 15M2 16 16 2' />
</svg>);

export const ButtonIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M16.25 8.917V5.75a2 2 0 0 0-2-2H3.75a2 2 0 0 0-2 2v3.5a2 2 0 0 0 2 2h3.681' />
    <path {...pathProps.stroke} d='m9.934 9.27 6.854 2.504a.323.323 0 0 1-.012.611L13.64 13.39l-1.004 3.137a.323.323 0 0 1-.61.012L9.52 9.684a.323.323 0 0 1 .414-.414' />
</svg>);

export const CalendarCheck2Icon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M5.75 2.75v-2M12.25 2.75v-2M2.25 6.25h13.5M15.75 9.621V4.75a2 2 0 0 0-2-2h-9.5a2 2 0 0 0-2 2v8.5a2 2 0 0 0 2 2h4.766M11.492 15l1.61 1.5 3.396-4.5' />
</svg>);

export const CalendarEventIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M5.75 2.75v-2M12.25 2.75v-2M13.75 2.75h-9.5a2 2 0 0 0-2 2v8.5a2 2 0 0 0 2 2h9.5a2 2 0 0 0 2-2v-8.5a2 2 0 0 0-2-2M2.25 6.25h13.5' />
    <path {...pathProps.strokeFill} d='M11.25 11.75a1 1 0 1 0 0-2 1 1 0 0 0 0 2' />
</svg>);

export const CalendarIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M5.75 2.75v-2M12.25 2.75v-2M13.75 2.75h-9.5a2 2 0 0 0-2 2v8.5a2 2 0 0 0 2 2h9.5a2 2 0 0 0 2-2v-8.5a2 2 0 0 0-2-2M2.25 6.25h13.5' />
</svg>);

export const CalendarPhoneIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M5.75 2.75v-2M12.25 2.75v-2M2.25 6.25h13.5M15.75 11.446V4.75a2 2 0 0 0-2-2h-9.5a2 2 0 0 0-2 2v8.5a2 2 0 0 0 2 2h4.636' />
    <path {...pathProps.fill} d='m10.418 12.905 1.604-1.382a.5.5 0 0 0 .145-.593l-.36-.882a.5.5 0 0 0-.582-.281l-1.111.365a.505.505 0 0 0-.351.502 6 6 0 0 0 5.605 5.605.504.504 0 0 0 .502-.351l.365-1.111a.5.5 0 0 0-.281-.583l-.882-.36a.5.5 0 0 0-.593.146l-1.382 1.604' />
    <path {...pathProps.stroke} d='m10.418 12.905 1.604-1.382a.5.5 0 0 0 .145-.593l-.36-.882a.5.5 0 0 0-.582-.281l-1.111.365a.505.505 0 0 0-.351.502 6 6 0 0 0 5.605 5.605.504.504 0 0 0 .502-.351l.365-1.111a.5.5 0 0 0-.281-.583l-.882-.36a.5.5 0 0 0-.593.146l-1.382 1.604' />
</svg>);

export const CartShoppingIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='m1.75 1.75 1.351.338a1 1 0 0 1 .747.825l1.153 7.838' />
    <path {...pathProps.stroke} d='M15.25 13.25H4.5a1.25 1.25 0 0 1 0-2.5h8.529a1 1 0 0 0 .949-.684l1.333-4a1 1 0 0 0-.949-1.316H4.118' />
    <path {...pathProps.fill} d='M3.75 17a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5M14.25 17a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5' />
</svg>);

export const Check1Icon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='m2.75 9.25 4 5 8.5-10.5' />
</svg>);

export const ChevronDownIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M15.25 6.5 9 12.75 2.75 6.5' />
</svg>);

export const ChevronLeftIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M11.5 15.25 5.25 9l6.25-6.25' />
</svg>);

export const ChevronRightIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M6.5 2.75 12.75 9 6.5 15.25' />
</svg>);

export const ChevronUpIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M2.75 11.5 9 5.25l6.25 6.25' />
</svg>);

export const CircleCheck1Icon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M9 16.25a7.25 7.25 0 1 0 0-14.5 7.25 7.25 0 0 0 0 14.5' />
    <path {...pathProps.stroke} d='M5.75 9.25 8 11.75l4.25-5.5' />
</svg>);

export const CircleDottedUserIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M9 9.75a2 2 0 1 0 0-4 2 2 0 0 0 0 4M8.999 16.25a7.2 7.2 0 0 0 3.827-1.101c-.481-1.67-2.003-2.899-3.827-2.899s-3.347 1.229-3.827 2.899a7.2 7.2 0 0 0 3.827 1.101' />
    <path {...pathProps.fill} d='M1.89 11.164a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5M2.973 5.722a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5M2.973 13.777a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5M1.89 8.336a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5M4.973 3.722a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5M7.586 2.639a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5M15.027 13.778a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5M16.11 8.336a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5M13.027 3.72a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5M16.11 11.164a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5M15.027 5.723a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5M10.414 2.64a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5' />
</svg>);

export const CircleHalfDottedClockIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M9 4.75V9l3.25 2.25' />
    <path {...pathProps.stroke} d='M9 1.75a7.25 7.25 0 0 1 0 14.5' />
    <path {...pathProps.fill} d='M3.871 14.877a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5M1.75 9.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5M3.871 4.623a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5M6.227 16.448a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5M2.3 12.524a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5M2.3 6.976a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5M6.227 3.052a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5' />
</svg>);

export const CircleInfoIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M9 16.25a7.25 7.25 0 1 0 0-14.5 7.25 7.25 0 0 0 0 14.5M9 12.819V8.25' />
    <path {...pathProps.fill} d='M9 6.75a1.001 1.001 0 0 1 0-2 1.001 1.001 0 0 1 0 2' />
</svg>);

export const CircleMinusIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M9 16.25a7.25 7.25 0 1 0 0-14.5 7.25 7.25 0 0 0 0 14.5M5.75 9h6.5' />
</svg>);

export const CirclePercentageIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M9 16.25a7.25 7.25 0 1 0 0-14.5 7.25 7.25 0 0 0 0 14.5' />
    <path {...pathProps.fill} d='M6.75 8a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5M11.25 12.5a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5' />
    <path {...pathProps.stroke} d='m6.25 11.75 5.5-5.5' />
</svg>);

export const CirclePlusIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M9 16.25a7.25 7.25 0 1 0 0-14.5 7.25 7.25 0 0 0 0 14.5M5.75 9h6.5M9 5.75v6.5' />
</svg>);

export const CircleUserIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M9 9.75a2 2 0 1 0 0-4 2 2 0 0 0 0 4M5.152 15.147a4.001 4.001 0 0 1 7.692 0' />
    <path {...pathProps.stroke} d='M9 16.25a7.25 7.25 0 1 0 0-14.5 7.25 7.25 0 0 0 0 14.5' />
</svg>);

export const Clock1Icon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M9 16.25a7.25 7.25 0 1 0 0-14.5 7.25 7.25 0 0 0 0 14.5' />
    <path {...pathProps.stroke} d='M9 4.75V9l3.25 2.25' />
</svg>);

export const Clone1Icon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M7.25 16.25h7a2 2 0 0 0 2-2v-7a2 2 0 0 0-2-2h-7a2 2 0 0 0-2 2v7a2 2 0 0 0 2 2' />
    <path {...pathProps.stroke} d='M3 12.605a2 2 0 0 1-1.25-1.855v-7a2 2 0 0 1 2-2h7c.839 0 1.558.517 1.855 1.25' />
</svg>);

export const CoinsIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M9.5 5.75c3.452 0 6.25-.895 6.25-2s-2.798-2-6.25-2-6.25.895-6.25 2 2.798 2 6.25 2' />
    <path {...pathProps.stroke} d='M3.25 3.75v3c0 1.104 2.798 2 6.25 2s6.25-.896 6.25-2v-3M9.5 5.75v3M6.25 5.458v3M12.75 5.458v3M2.25 11.25v3c0 1.104 2.798 2 6.25 2s6.25-.896 6.25-2v-3M8.5 13.25v3M5.25 12.958v3M11.75 12.958v3' />
    <path {...pathProps.stroke} d='M3.311 10.135c-.67.319-1.061.702-1.061 1.115 0 1.104 2.798 2 6.25 2s6.25-.896 6.25-2c0-.223-.119-.438-.33-.638' />
</svg>);

export const Copies6Icon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M2.75 8v4.917a1.75 1.75 0 0 0 1.75 1.75h9a1.75 1.75 0 0 0 1.75-1.75V8a1.75 1.75 0 0 0-1.75-1.75h-9A1.75 1.75 0 0 0 2.75 8m9.917-5.25H5.333' />
</svg>);

export const DiscountIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='m15.718 8.293-1.469-1.468V4.75a1 1 0 0 0-1-1h-2.075L9.708 2.282a1 1 0 0 0-1.414 0L6.825 3.75H4.75a1 1 0 0 0-1 1v2.075L2.282 8.293a1 1 0 0 0 0 1.414l1.468 1.468v2.075a1 1 0 0 0 1 1h2.075l1.468 1.468a1 1 0 0 0 1.414 0l1.467-1.468h2.076a1 1 0 0 0 1-1v-2.075l1.467-1.468a1 1 0 0 0 0-1.414' />
    <path {...pathProps.fill} d='M7 8a1 1 0 1 0 0-2 1 1 0 0 0 0 2M11 12a1 1 0 1 0 0-2 1 1 0 0 0 0 2' />
    <path {...pathProps.stroke} d='m6.75 11.25 4.5-4.5' />
</svg>);

export const DoorOpenIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M9.25 16V2a.5.5 0 0 0-.692-.462L3.865 3.494a1 1 0 0 0-.615.923v9.166a1 1 0 0 0 .615.923l4.692 1.955a.5.5 0 0 0 .692-.462zM11.75 3.25h2a1 1 0 0 1 1 1v9.5a1 1 0 0 1-1 1h-2M6.75 8.5v1' />
</svg>);

export const Eye2Icon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M9 11.75a2.75 2.75 0 1 0 0-5.5 2.75 2.75 0 0 0 0 5.5' />
    <path {...pathProps.stroke} d='M1.75 9S3.521 3.5 9 3.5 16.25 9 16.25 9' />
</svg>);

export const Eye2SlashIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M11.58 9.956a2.76 2.76 0 0 1-1.623 1.623M7.055 10.945a2.75 2.75 0 1 1 3.89-3.89' />
    <path {...pathProps.stroke} d='M1.75 9S3.521 3.5 9 3.5 16.25 9 16.25 9M2 16 16 2' />
</svg>);

export const FilePlusIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M5.75 6.75h2M5.75 9.75h4.5M15.16 6.25h-3.41a1 1 0 0 1-1-1V1.852M14.75 10.75v5' />
    <path {...pathProps.stroke} d='M15.25 8.3V6.664a1 1 0 0 0-.293-.707l-3.914-3.914a1 1 0 0 0-.707-.293H4.75a2 2 0 0 0-2 2v10.5a2 2 0 0 0 2 2h7.55M17.25 13.25h-5' />
</svg>);

export const FileSendIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M5.75 6.75h2M5.75 9.75h4.5M15.16 6.25h-3.41a1 1 0 0 1-1-1V1.852M14.75 11.25l2.5 2.5-2.5 2.5' />
    <path {...pathProps.stroke} d='M15.25 8.802V6.664a1 1 0 0 0-.293-.707l-3.914-3.914a1 1 0 0 0-.707-.293H4.75a2 2 0 0 0-2 2v10.5a2 2 0 0 0 2 2h7.5M17 13.75h-4.75' />
</svg>);

export const FileUserIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M5.75 6.75h2M5.75 9.75h4.5M15.16 6.25h-3.41a1 1 0 0 1-1-1V1.852' />
    <path {...pathProps.strokeFill} d='M14.64 12.75a1 1 0 1 0 0-2 1 1 0 0 0 0 2' />
    <path {...pathProps.stroke} d='M15.25 8.312V6.665a1 1 0 0 0-.293-.707l-3.914-3.914a1 1 0 0 0-.707-.293H4.75a2 2 0 0 0-2 2V14.25a2 2 0 0 0 2 2h4.843M16.742 17.25c.34 0 .594-.337.482-.658a2.733 2.733 0 0 0-5.166 0c-.112.321.142.658.482.658z' />
</svg>);

export const FillIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M14.375 16.75c1.036 0 1.875-.852 1.875-1.903 0-1.445-1.051-2.063-1.875-3.097-.824 1.034-1.875 1.652-1.875 3.097 0 1.051.839 1.903 1.875 1.903M1.82 8.75h11.851M7.023.749l6.867 8.168-5.886 4.946a2 2 0 0 1-2.835-.265l-2.967-3.595a2 2 0 0 1 .283-2.814l5.89-4.814' />
</svg>);

export const Globe2Icon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M9 16.25c1.657 0 3-3.246 3-7.25s-1.343-7.25-3-7.25S6 4.996 6 9s1.343 7.25 3 7.25M2.105 6.75h13.789M2.29 11.75h13.42' />
    <path {...pathProps.stroke} d='M9 16.25a7.25 7.25 0 1 0 0-14.5 7.25 7.25 0 0 0 0 14.5' />
</svg>);

export const GradientIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.fill} d='M6 6V3.75H2.75a1 1 0 0 0-1 1v2.5a1 1 0 0 0 1 1H8V6z' />
    <path {...pathProps.stroke} d='m.75 12.75 1.75-2 1.75 2v1.5a1 1 0 0 1-1 1h-1.5a1 1 0 0 1-1-1zM13.75 12.75l1.75-2 1.75 2v1.5a1 1 0 0 1-1 1h-1.5a1 1 0 0 1-1-1z' />
    <path {...pathProps.fill} d='M10 3.75H8V6h2zM12 6h-2v2.25h2z' />
    <path {...pathProps.stroke} d='M15.25 3.75H2.75a1 1 0 0 0-1 1v2.5a1 1 0 0 0 1 1h12.5a1 1 0 0 0 1-1v-2.5a1 1 0 0 0-1-1' />
</svg>);

export const GridPlus2Icon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M6.25 2.75h-2.5a1 1 0 0 0-1 1v2.5a1 1 0 0 0 1 1h2.5a1 1 0 0 0 1-1v-2.5a1 1 0 0 0-1-1M14.25 2.75h-2.5a1 1 0 0 0-1 1v2.5a1 1 0 0 0 1 1h2.5a1 1 0 0 0 1-1v-2.5a1 1 0 0 0-1-1M6.25 10.75h-2.5a1 1 0 0 0-1 1v2.5a1 1 0 0 0 1 1h2.5a1 1 0 0 0 1-1v-2.5a1 1 0 0 0-1-1M13 10.25v5M15.5 12.75h-5' />
</svg>);

export const ImageSparkle3Icon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='m4.445 15.227 5.64-5.641a2 2 0 0 1 2.828 0l2.336 2.336' />
    <path {...pathProps.stroke} d='M13.25 2.75h-8.5a2 2 0 0 0-2 2v8.5a2 2 0 0 0 2 2h8.5a2 2 0 0 0 2-2v-8.5a2 2 0 0 0-2-2' />
    <path {...pathProps.fill} d='m9.159 6.508-1.263-.421-.421-1.263c-.137-.408-.812-.408-.949 0l-.421 1.263-1.263.42a.5.5 0 0 0 0 .949l1.263.42.421 1.264a.501.501 0 0 0 .95 0l.421-1.263 1.263-.421a.5.5 0 0 0 0-.948z' />
</svg>);

export const Images2Icon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='m4 15.25 5.836-5.836a2 2 0 0 1 2.828 0L15.75 12.5' />
    <path {...pathProps.stroke} d='M13.75 4.75h-9.5a2 2 0 0 0-2 2v6.5a2 2 0 0 0 2 2h9.5a2 2 0 0 0 2-2v-6.5a2 2 0 0 0-2-2M4.75 1.75h8.5' />
    <path {...pathProps.fill} d='M5.75 9.5a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5' />
</svg>);

export const InputPasswordEditIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.fill} d='M5.5 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2M9 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2M12.5 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2' />
    <path {...pathProps.stroke} d='M16.25 7.274V6.75a2 2 0 0 0-2-2H3.75a2 2 0 0 0-2 2v4.5a2 2 0 0 0 2 2h5.391M13.207 15.401a1 1 0 0 0 .38-.238l3.303-3.303a1.23 1.23 0 0 0-.005-1.745 1.23 1.23 0 0 0-1.745-.005l-3.303 3.303a1 1 0 0 0-.238.38l-.849 2.457z' />
</svg>);

export const InputPasswordPointerIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.fill} d='M5 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2M8.5 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2' />
    <path {...pathProps.stroke} d='M15.75 9.795V6.75a2 2 0 0 0-2-2H3.25a2 2 0 0 0-2 2v4.5a2 2 0 0 0 2 2h5.632' />
    <path {...pathProps.stroke} d='m11.127 10.768 5.94 2.17a.28.28 0 0 1-.011.53l-2.72.87-.87 2.718a.28.28 0 0 1-.528.011l-2.17-5.94a.28.28 0 0 1 .359-.359' />
</svg>);

export const InvoiceDollarIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M14.75 3.75v12.5L12 14.75l-3 1.5-3-1.5-2.75 1.5V3.75a2 2 0 0 1 2-2h7.5a2 2 0 0 1 2 2' />
    <path {...pathProps.stroke} d='M10.723 6.556C10.349 5.67 9.6 5.47 9.035 5.47c-.526 0-1.907.28-1.78 1.606.09.93.968 1.277 1.735 1.414s1.88.429 1.907 1.55c.023.95-.83 1.598-1.861 1.598-.985 0-1.67-.383-1.934-1.25M9 4.75v.72M9 11.638v.612' />
</svg>);

export const LicenseIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M5.75 7.75h3.5M5.75 5.25h6.5M13.25 1.75h-8.5a2 2 0 0 0-2 2v10.5a2 2 0 0 0 2 2h8.5a2 2 0 0 0 2-2V3.75a2 2 0 0 0-2-2' />
    <path {...pathProps.stroke} d='M11.25 13.75a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3' />
</svg>);

export const Link5Icon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M8.5 6.827a3.5 3.5 0 0 0-.974.69l-.01.01a3.535 3.535 0 0 0 0 5l2.175 2.175a3.535 3.535 0 0 0 5 0l.01-.01a3.535 3.535 0 0 0 0-5l-.93-.93' />
    <path {...pathProps.stroke} d='M9.5 11.173c.351-.168.681-.398.973-.69l.01-.01a3.535 3.535 0 0 0 0-5L8.307 3.298a3.535 3.535 0 0 0-5 0l-.01.01a3.535 3.535 0 0 0 0 5l.931.931' />
</svg>);

export const LockIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M5.75 8.25V5a3.25 3.25 0 1 1 6.5 0v3.25M9 11.75v1' />
    <path {...pathProps.stroke} d='M12.75 8.25h-7.5a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h7.5a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2' />
</svg>);

export const MediaPlayIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='m5.245 2.878 9.492 5.256a.987.987 0 0 1 0 1.732l-9.492 5.256c-.669.371-1.495-.108-1.495-.866V3.744c0-.758.825-1.237 1.495-.866' />
</svg>);

export const MoneyBillsDollarIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M10.266 8.22c-.321-.519-.835-.644-1.235-.644-.421 0-1.526.224-1.423 1.285.072.745.774 1.022 1.387 1.131.613.11 1.504.343 1.526 1.241.019.76-.664 1.277-1.49 1.277-.683 0-1.185-.23-1.445-.744M9 7v.576M9 12.51V13M3.25 1.75h11.5' />
    <path {...pathProps.stroke} d='M14.25 4.75H3.75a2 2 0 0 0-2 2v6.5a2 2 0 0 0 2 2h10.5a2 2 0 0 0 2-2v-6.5a2 2 0 0 0-2-2' />
    <path {...pathProps.stroke} d='M5 4.75A3.25 3.25 0 0 1 1.75 8M13 4.75A3.25 3.25 0 0 0 16.25 8M5 15.25A3.25 3.25 0 0 0 1.75 12M13 15.25A3.25 3.25 0 0 1 16.25 12' />
</svg>);

export const MsgIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M9 1.75A7.25 7.25 0 0 0 1.75 9c0 1.319.358 2.552.973 3.617.43.806-.053 2.712-.973 3.633 1.25.068 2.897-.497 3.633-.973A7.192 7.192 0 0 0 9 16.25a7.25 7.25 0 0 0 0-14.5' />
</svg>);

export const MsgSmile2Icon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M9 1.75A7.25 7.25 0 0 0 1.75 9c0 1.319.358 2.552.973 3.617.43.806-.053 2.712-.973 3.633 1.25.068 2.897-.497 3.633-.973A7.192 7.192 0 0 0 9 16.25a7.25 7.25 0 0 0 0-14.5' />
    <path {...pathProps.stroke} d='M5.992 12A4.24 4.24 0 0 0 9 13.25a4.23 4.23 0 0 0 3-1.242' />
</svg>);

export const NumberInputIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M6.751 11V7s-.458.806-1.43.992' />
    <path {...pathProps.stroke} d='M11.25 13.25h-7.5a2 2 0 0 1-2-2v-4.5a2 2 0 0 1 2-2h7.5' />
    <path {...pathProps.fill} d='M13.5 8h3a.5.5 0 0 0 .4-.8l-1.5-2c-.188-.251-.61-.251-.8 0l-1.5 2a.497.497 0 0 0 .4.8M16.5 10h-3a.5.5 0 0 0-.4.799l1.5 2a.5.5 0 0 0 .8 0l1.5-2a.497.497 0 0 0-.4-.799' />
</svg>);

export const Nut2Icon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M9 11.25a2.25 2.25 0 1 0 0-4.5 2.25 2.25 0 0 0 0 4.5' />
    <path {...pathProps.stroke} d='M15.25 11.473V6.527a2 2 0 0 0-.997-1.73l-4.25-2.465a2 2 0 0 0-2.007 0L3.747 4.797a2 2 0 0 0-.997 1.73v4.946a2 2 0 0 0 .997 1.73l4.25 2.465a2 2 0 0 0 2.007 0l4.25-2.465a2 2 0 0 0 .997-1.73z' />
</svg>);

export const Palette1Icon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M8.303 16.217A7.25 7.25 0 0 1 1.756 8.69c.157-3.766 3.35-6.876 7.12-6.939A7.25 7.25 0 0 1 16.25 9a2.75 2.75 0 0 1-2.75 2.75h-2.963a1.366 1.366 0 0 0-1.203 2.014l.238.44c.26.483.206 1.074-.136 1.502-.274.342-.697.552-1.133.51' />
    <path {...pathProps.fill} d='M9 6a1 1 0 1 0 0-2 1 1 0 0 0 0 2M6.172 7.172a1 1 0 1 0 0-2 1 1 0 0 0 0 2M11.828 7.172a1 1 0 1 0 0-2 1 1 0 0 0 0 2M5 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2' />
</svg>);

export const Pen2Icon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='m10.547 4.422 3.03 3.03M2.75 15.25s3.599-.568 4.546-1.515l7.327-7.327a2.142 2.142 0 1 0-3.03-3.03l-7.327 7.327c-.947.947-1.515 4.546-1.515 4.546z' />
</svg>);

export const Pencil2Icon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='m13.952 7.578 1.109-1.11a1.5 1.5 0 0 0 0-2.12l-1.41-1.41a1.5 1.5 0 0 0-2.12 0l-1.11 1.11zM8.922 5.547l-4.775 4.775c-.25.25-.429.562-.52.904L2.5 15.499l4.273-1.127c.342-.09.654-.27.904-.52l4.775-4.775M10.673 7.297l-4.407 4.407' />
</svg>);

export const PinIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M14.778 7.266c0 2.622-3.428 6.833-5.004 8.631a1.028 1.028 0 0 1-1.551 0C6.647 14.1 3.219 9.889 3.219 7.266c0-3.49 2.987-5.516 5.78-5.516s5.779 2.026 5.779 5.516' />
    <path {...pathProps.stroke} d='M9 9.25a1.75 1.75 0 1 0 0-3.5 1.75 1.75 0 0 0 0 3.5' />
</svg>);

export const Pins2Icon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M12.249 7.425c0 2.382-3.114 6.208-4.545 7.84a.935.935 0 0 1-1.409 0C4.864 13.632 1.75 9.807 1.75 7.425c0-3.17 2.713-5.01 5.25-5.01s5.25 1.84 5.25 5.01z' />
    <path {...pathProps.stroke} d='M7 9.5A1.75 1.75 0 1 0 7 6a1.75 1.75 0 0 0 0 3.5M13.228 2.886c1.679.736 3.022 2.308 3.022 4.539 0 2.382-3.114 6.208-4.545 7.84a.93.93 0 0 1-.705.32' />
</svg>);

export const PlusIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M9 3.25v11.5M3.25 9h11.5' />
</svg>);

export const SectionIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M1.75 7.75v2.5a2 2 0 0 0 2 2h10.5a2 2 0 0 0 2-2v-2.5a2 2 0 0 0-2-2H3.75a2 2 0 0 0-2 2M3.75 15.75h10.5M3.75 2.25h10.5' />
</svg>);

export const SidebarLeft2ShowIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M4.75 5.75v6.5M11 11.25 13.25 9 11 6.75M13.25 9h-6' />
    <path {...pathProps.stroke} d='M3.75 15.25h10.5a2 2 0 0 0 2-2v-8.5a2 2 0 0 0-2-2H3.75a2 2 0 0 0-2 2v8.5a2 2 0 0 0 2 2' />
</svg>);

export const SidebarLeft3HideIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M6.25 12.5v2.75M6.25 2.75V5.5M8.75 6.5 6.25 9l2.5 2.5M6.25 9h6.5' />
    <path {...pathProps.stroke} d='M3.75 15.25h10.5a2 2 0 0 0 2-2v-8.5a2 2 0 0 0-2-2H3.75a2 2 0 0 0-2 2v8.5a2 2 0 0 0 2 2' />
</svg>);

export const Sliders3Icon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M13.25 5.25h3M1.75 5.25h7M11 7.5A2.25 2.25 0 1 0 11 3a2.25 2.25 0 0 0 0 4.5M4.75 12.75h-3M16.25 12.75h-7M7 15a2.25 2.25 0 1 0 0-4.5A2.25 2.25 0 0 0 7 15' />
</svg>);

export const SortBottomToTop1Icon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M11.5 6 9.25 3.75 11.5 1.5M2.25 3.75h4M2.25 9h10' />
    <path {...pathProps.stroke} d='M2.25 14.25h11.5a2 2 0 0 0 2-2v-6.5a2 2 0 0 0-2-2h-4.5' />
</svg>);

export const SpaceshipIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='m5.07 10.556-1.232.746a2 2 0 0 0-.956 1.92l.368 3.528 3.15-2M12.928 10.556l1.233.746a2 2 0 0 1 .956 1.92l-.368 3.528-3.151-2' />
    <path {...pathProps.stroke} d='M9 14.75h2.6c.37-.672 2.327-4.368.865-8.651C11.573 3.487 9.84 1.916 9 1.25c-.839.666-2.573 2.237-3.465 4.849-1.462 4.283.495 7.979.866 8.651zM9 16.75v-5.5' />
    <path {...pathProps.fill} d='M9 9a1.25 1.25 0 1 0 0-2.5A1.25 1.25 0 0 0 9 9' />
</svg>);

export const SquareLayoutGrid3Icon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M9 7.25v8M15.25 7.25H2.75M13.25 2.75h-8.5a2 2 0 0 0-2 2v8.5a2 2 0 0 0 2 2h8.5a2 2 0 0 0 2-2v-8.5a2 2 0 0 0-2-2' />
</svg>);

export const SquareSparkleIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='m9 5.75.921 2.329L12.25 9l-2.329.921L9 12.25l-.921-2.329L5.75 9l2.329-.921z' />
    <path {...pathProps.stroke} d='M13.25 2.75h-8.5a2 2 0 0 0-2 2v8.5a2 2 0 0 0 2 2h8.5a2 2 0 0 0 2-2v-8.5a2 2 0 0 0-2-2' />
</svg>);

export const SquareUserPlusIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M9 9.75a2 2 0 1 0 0-4 2 2 0 0 0 0 4M5.125 15.25a4.002 4.002 0 0 1 7.748 0' />
    <path {...pathProps.stroke} d='M15.25 8.5v4.75a2 2 0 0 1-2 2h-8.5a2 2 0 0 1-2-2v-8.5a2 2 0 0 1 2-2H9.5M14.75.75v5M17.25 3.25h-5' />
</svg>);

export const SquareWandSparkleIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.fill} d='m11.746 2.492-.946-.315-.316-.947c-.102-.306-.61-.306-.711 0l-.316.947-.946.315a.375.375 0 0 0 0 .712l.946.315.316.947a.374.374 0 0 0 .71 0l.316-.947.946-.315a.375.375 0 0 0 0-.712M16.659 5.99l-1.263-.421-.421-1.263c-.137-.408-.812-.408-.949 0l-.421 1.263-1.263.421a.5.5 0 0 0 0 .948l1.263.421.421 1.263a.501.501 0 0 0 .95 0l.421-1.263 1.263-.421a.5.5 0 0 0-.001-.948M15.25 2.5a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5' />
    <path {...pathProps.stroke} d='m7.956 8.043-6.413 6.413a1 1 0 0 0 0 1.414l.586.586a1 1 0 0 0 1.414 0l6.412-6.413a1 1 0 0 0 0-1.414l-.585-.586a1 1 0 0 0-1.414 0M8.285 15.25h4.965a2 2 0 0 0 2-2v-2.634M6.502 2.75H4.75a2 2 0 0 0-2 2v4.965M6.07 9.93l2 2' />
</svg>);

export const Tag3Icon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M3.25 2.25h4.922a2 2 0 0 1 1.414.586l5.75 5.75a2 2 0 0 1 0 2.828l-3.922 3.922a2 2 0 0 1-2.828 0l-5.75-5.75a2 2 0 0 1-.586-1.414V3.25a1 1 0 0 1 1-1' />
    <path {...pathProps.fill} d='M6.25 7.5a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5' />
</svg>);

export const TextTool2Icon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M9 6.25v5.5M11.25 6.25h-4.5M4.25 1.75h-2a.5.5 0 0 0-.5.5v2a.5.5 0 0 0 .5.5h2a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 0-.5-.5M15.75 1.75h-2a.5.5 0 0 0-.5.5v2a.5.5 0 0 0 .5.5h2a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 0-.5-.5M4.25 13.25h-2a.5.5 0 0 0-.5.5v2a.5.5 0 0 0 .5.5h2a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 0-.5-.5M15.75 13.25h-2a.5.5 0 0 0-.5.5v2a.5.5 0 0 0 .5.5h2a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 0-.5-.5M3.25 13.25v-8.5M13.25 14.75h-8.5M14.75 4.75v8.5M4.75 3.25h8.5' />
</svg>);

export const Trash2Icon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='m13.474 7.25-.374 7.105a2 2 0 0 1-1.997 1.895H6.898a2 2 0 0 1-1.997-1.895L4.527 7.25M2.75 4.75h12.5M6.75 4.75v-2a1 1 0 0 1 1-1h2.5a1 1 0 0 1 1 1v2' />
</svg>);

export const TrashXmarkIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='m13.474 7.25-.374 7.105a2 2 0 0 1-1.997 1.895H6.898a2 2 0 0 1-1.997-1.895L4.527 7.25M2.75 4.75h12.5M6.75 4.75v-2a1 1 0 0 1 1-1h2.5a1 1 0 0 1 1 1v2M7.23 8.732l3.537 3.536M10.767 8.732 7.23 12.268' />
</svg>);

export const TriangleWarningIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='m7.638 3.495-5.425 9.396a1.573 1.573 0 0 0 1.362 2.359h10.85a1.573 1.573 0 0 0 1.362-2.359l-5.425-9.396a1.573 1.573 0 0 0-2.724 0M9 6.5V10' />
    <path {...pathProps.fill} d='M9 13.57a1.001 1.001 0 0 1 0-2 1.001 1.001 0 0 1 0 2' />
</svg>);

export const TrophyIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M9.5 12.25s0 2.938 3.75 4h-8.5c3.75-1.062 3.75-4 3.75-4M5.287 9C1.47 9 1.75 3.75 1.75 3.75h2.237M12.715 9c3.818 0 3.536-5.25 3.536-5.25h-2.237' />
    <path {...pathProps.stroke} d='M14 1.75c-.625 6.531-2.281 10.219-4.75 10.5h-.5C6.281 11.969 4.625 8.281 4 1.75z' />
</svg>);

export const UserArrowRightIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M9 7.25a2.75 2.75 0 1 0 0-5.5 2.75 2.75 0 0 0 0 5.5M14.25 10.25l2.5 2.5-2.5 2.5M11.484 10.277A6.2 6.2 0 0 0 9 9.75a6.24 6.24 0 0 0-5.709 3.72c-.365.825.087 1.774.947 2.045A15.8 15.8 0 0 0 9 16.249c1.055 0 2.011-.111 2.871-.27M16.75 12.75h-5' />
</svg>);

export const UserListIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M6.809 7.25a2.25 2.25 0 1 0 0-4.5 2.25 2.25 0 0 0 0 4.5M10.847 14.817c.837-.291 1.266-1.257.866-2.048a5.494 5.494 0 0 0-9.812 0c-.4.791.028 1.757.866 2.048 1.031.358 2.408.683 4.04.683s3.01-.325 4.04-.683M16.25 3.25h-4.5M16.25 6.75h-4.5M16.25 10.25H13.5' />
</svg>);

export const UserPinIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M9 7.25a2.75 2.75 0 1 0 0-5.5 2.75 2.75 0 0 0 0 5.5' />
    <path {...pathProps.fill} d='M14.25 14a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5' />
    <path {...pathProps.stroke} d='M10.25 9.891A6.2 6.2 0 0 0 9 9.75a6.24 6.24 0 0 0-5.709 3.72c-.365.825.087 1.774.947 2.045A15.8 15.8 0 0 0 9 16.249c.303 0 .585-.022.873-.038M14.25 17s-2.75-1.509-2.75-3.75a2.75 2.75 0 1 1 5.5 0c0 2.241-2.75 3.75-2.75 3.75' />
</svg>);

export const Users1Icon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M11.039 14.817c.837-.291 1.266-1.257.866-2.048a5.494 5.494 0 0 0-9.812 0c-.4.791.028 1.757.866 2.048 1.03.358 2.408.683 4.04.683s3.009-.325 4.04-.683M14.532 13.555a11 11 0 0 0 1.566-.428c.702-.25 1.01-1.077.675-1.743a4.78 4.78 0 0 0-5.02-2.577M11.952 6.167c.177.045.356.083.547.083a2.25 2.25 0 1 0 0-4.5c-.334 0-.647.082-.933.213M7 7.25a2.75 2.75 0 1 0 0-5.5 2.75 2.75 0 0 0 0 5.5' />
</svg>);

export const Video1Icon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='m12.25 8 4.259-2.342a.5.5 0 0 1 .741.438v5.81a.5.5 0 0 1-.741.437l-4.259-2.342M10.25 3.75h-6.5a2 2 0 0 0-2 2v6.5a2 2 0 0 0 2 2h6.5a2 2 0 0 0 2-2v-6.5a2 2 0 0 0-2-2' />
    <path {...pathProps.fill} d='M4.75 7.5a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5' />
</svg>);

export const ViewAll1Icon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M8.25 2.75h-3.5a1 1 0 0 0-1 1v3.5a1 1 0 0 0 1 1h3.5a1 1 0 0 0 1-1v-3.5a1 1 0 0 0-1-1M15.25 4.75h-2a1 1 0 0 0-1 1v6.5a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1v-6.5a1 1 0 0 0-1-1M8.25 11.25h-5.5a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h5.5a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1' />
</svg>);

export const WalletContentIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M2.25 4.75v8.5a2 2 0 0 0 2 2h10.5a1 1 0 0 0 1-1v-6.5a1 1 0 0 0-1-1H4.25a2 2 0 0 1-2-2M2.25 4.75a2 2 0 0 1 2-2' />
    <path {...pathProps.fill} d='M12.75 12.25a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5' />
    <path {...pathProps.stroke} d='M6.031 4.255 7.238 1.79a1 1 0 0 1 1.336-.463l6.014 2.917' />
</svg>);

export const WindowLinkIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.fill} d='M4.25 6a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5M6.75 6a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5' />
    <path {...pathProps.stroke} d='M1.75 7.75h14.5M16.25 10.283V4.75a2 2 0 0 0-2-2H3.75a2 2 0 0 0-2 2v8.5a2 2 0 0 0 2 2h4.5' />
    <path {...pathProps.stroke} d='M12.75 16.75h-.5a1.5 1.5 0 0 1-1.5-1.5v-1a1.5 1.5 0 0 1 1.5-1.5h.5M15.25 16.75h.5a1.5 1.5 0 0 0 1.5-1.5v-1a1.5 1.5 0 0 0-1.5-1.5h-.5M13.25 14.75h1.5' />
</svg>);

export const WindowPlusIcon = (props: IconProps) => (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 18' fill='none'{...getCommonProps(props)}>
        <path d='M3.75 15.25L14.25 15.25C15.3546 15.25 16.25 14.3546 16.25 13.25V4.75C16.25 3.64543 15.3546 2.75 14.25 2.75L3.75 2.75C2.64543 2.75 1.75 3.64543 1.75 4.75L1.75 13.25C1.75 14.3546 2.64543 15.25 3.75 15.25Z' stroke='#5F5572' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round'/>
        <path d='M6.5 9.75H11.5' stroke='#5F5572' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round'/>
        <path d='M9 7.25V12.25' stroke='#5F5572' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round'/>
        <path d='M4.25 6C4.66421 6 5 5.66421 5 5.25C5 4.83579 4.66421 4.5 4.25 4.5C3.83579 4.5 3.5 4.83579 3.5 5.25C3.5 5.66421 3.83579 6 4.25 6Z' fill='#5F5572'/>
        <path d='M6.75 6C7.16421 6 7.5 5.66421 7.5 5.25C7.5 4.83579 7.16421 4.5 6.75 4.5C6.33579 4.5 6 4.83579 6 5.25C6 5.66421 6.33579 6 6.75 6Z' fill='#5F5572'/>
    </svg>);

export const XmarkIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M14 4 4 14M4 4l10 10' />
</svg>);

export const basicIcons: ((props: IconProps) => ReactNode)[] = [
    ArrowLeftIcon,
    ArrowRightIcon,
    ArrowsExpandDiagonal6Icon,
    ArrowsReduceDiagonal1Icon,
    BankIcon,
    BankingMobileIcon,
    BankStatementIcon,
    Box2Icon,
    Box2PlusIcon,
    BoxHeartIcon,
    BugIcon,
    BugSlashIcon,
    ButtonIcon,
    CalendarCheck2Icon,
    CalendarEventIcon,
    CalendarIcon,
    CalendarPhoneIcon,
    CartShoppingIcon,
    Check1Icon,
    ChevronDownIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    ChevronUpIcon,
    CircleCheck1Icon,
    CircleDottedUserIcon,
    CircleHalfDottedClockIcon,
    CircleInfoIcon,
    CircleMinusIcon,
    CirclePercentageIcon,
    CirclePlusIcon,
    CircleUserIcon,
    Clock1Icon,
    Clone1Icon,
    CoinsIcon,
    Copies6Icon,
    DiscountIcon,
    DoorOpenIcon,
    Eye2Icon,
    Eye2SlashIcon,
    FilePlusIcon,
    FileSendIcon,
    FileUserIcon,
    FillIcon,
    Globe2Icon,
    GradientIcon,
    GridPlus2Icon,
    ImageSparkle3Icon,
    Images2Icon,
    InputPasswordEditIcon,
    InputPasswordPointerIcon,
    InvoiceDollarIcon,
    LicenseIcon,
    Link5Icon,
    LockIcon,
    MediaPlayIcon,
    MoneyBillsDollarIcon,
    MsgIcon,
    MsgSmile2Icon,
    NumberInputIcon,
    Nut2Icon,
    Palette1Icon,
    Pen2Icon,
    Pencil2Icon,
    PinIcon,
    Pins2Icon,
    PlusIcon,
    SectionIcon,
    SidebarLeft2ShowIcon,
    SidebarLeft3HideIcon,
    Sliders3Icon,
    SortBottomToTop1Icon,
    SpaceshipIcon,
    SquareLayoutGrid3Icon,
    SquareSparkleIcon,
    SquareUserPlusIcon,
    SquareWandSparkleIcon,
    Tag3Icon,
    TextTool2Icon,
    Trash2Icon,
    TrashXmarkIcon,
    TriangleWarningIcon,
    TrophyIcon,
    UserArrowRightIcon,
    UserListIcon,
    UserPinIcon,
    Users1Icon,
    Video1Icon,
    ViewAll1Icon,
    WalletContentIcon,
    WindowLinkIcon,
    WindowPlusIcon,
    XmarkIcon,
];

import type { InvoicingProfileOutput } from ':utils/entity/invoicing';
import { TypedError } from './typed.error';
import type { ClientTagOutput } from ':utils/entity/client';

export class AccessDeniedError extends TypedError {
    static readonly type = 'generic.accessDenied';

    constructor() {
        super(AccessDeniedError.type, 403);
    }
}

export class NotFoundError extends TypedError {
    constructor() {
        super('generic.notFound', 404);
    }
}

export class TransitionError extends TypedError {
    constructor(
        readonly entity: string,
        readonly oldState: string,
        readonly transitionName: string,
    ) {
        super('generic.transition', 400);
    }
}

export class PlanExceededError extends TypedError {
    static readonly type = 'generic.planExceeded';

    constructor(
        readonly available: number,
        readonly cart: number,
    ) {
        super(PlanExceededError.type, 403);
    }
}

export class UnselectablePlanError extends TypedError {
    static readonly type = 'generic.unselectablePlan';

    constructor() {
        super(UnselectablePlanError.type, 400);
    }
}
import { type OneOf } from ':frontend/utils/common';
import { EventFE } from './Event';
import type { PaginationController } from ':frontend/hooks';
import { normalizeString, SortOrder } from ':utils/common';
import type { EventOutput } from ':utils/entity/event';

export type NotesState = {
    order: SortOrder;
    filter: string;
} & OneOf<[{
    events: EventFE[];
}, {
    showFetching: true;
}, {
    error: NotesError;
}]>;

type NotesError = 'noEvents' | 'noSearchResults' | 'unknownError';

export type NotesStateAction =
    FetchSuccessAction
    | OrderChangeAction
    | FilterChangeAction
    | PlainAction<NotesError | 'fetchStart'>;

type FetchSuccessAction = {
    type: 'fetchSuccess';
    response: { items: EventOutput[], total: number};
    pagination: PaginationController;
};

type OrderChangeAction = {
    type: 'orderChange';
    order: SortOrder;
    totalEvents: number;
};

type FilterChangeAction = {
    type: 'filterChange';
    filter: string;
    pagination: PaginationController;
};

type PlainAction<T extends string> = {
    type: T;
};

export const PAGE_SIZE = 15;

export const initialState: NotesState = {
    order: SortOrder.Descending,
    filter: '',
    showFetching: true,
};

export function notesReducer(state: NotesState, action: NotesStateAction): NotesState {
    switch (action.type) {
    case 'orderChange': return orderChange(state, action);
    case 'filterChange': return filterChange(state, action);
    case 'fetchStart': {
        if (state.filter)
            return state;

        return {
            ...permanent(state),
            showFetching: true,
        };
    }
    case 'fetchSuccess': return fetchSuccess(state, action);
    default: return state;
    }
}

function orderChange(state: NotesState, action: OrderChangeAction): NotesState {
    if (
        state.events &&
        action.totalEvents <= PAGE_SIZE &&
        state.order !== action.order
    ) {
        const orderedEvents = [ ...state.events ].reverse();
        return {
            ...state,
            order: action.order,
            events: orderedEvents,
        };
    }

    return {
        ...state,
        order: action.order,
    };
}

function filterChange(state: NotesState, action: FilterChangeAction): NotesState {
    if (action.filter === '') {
        // we're always gonna fetchEvents and we don't wanna show empty 'no-results-found'
        return {
            order: state.order,
            filter: action.filter,
            showFetching: true,
        };
    }

    if (!state.events) {
        return {
            ...state,
            filter: action.filter,
        };
    }

    const filteredEvents = state.events.filter(event => eventMatched(event, action.filter));
    return filteredEvents.length > 0
        ? {
            order: state.order,
            filter: action.filter,
            events: filteredEvents,
        } : {
            order: state.order,
            filter: action.filter,
            showFetching: true,
        };
}

function fetchSuccess(state: NotesState, action: FetchSuccessAction): NotesState {
    const { response: { items, total }, pagination } = action;

    const itemsWithNotes = items.filter(item => !!item.notes);
    pagination.setTotalItems(total);

    if (itemsWithNotes.length == 0) {
        return state.filter
            ? {
                ...permanent(state),
                error: 'noSearchResults',
            } : {
                ...permanent(state),
                error: 'noEvents',
            };
    }

    const newEvents = itemsWithNotes.map(EventFE.fromServer);
    if (state.filter) {
        const filteredEvents = newEvents.filter(event => eventMatched(event, state.filter));
        return {
            ...permanent(state),
            events: filteredEvents,
        };
    }

    return {
        ...permanent(state),
        events: newEvents,
    };
}

function permanent({ order, filter }: NotesState) {
    return { order, filter };
}

function eventMatched(event: EventFE, filter: string) {
    const normalizedFilter = normalizeString(filter);

    const normalizedTitle = normalizeString(event.title);
    if (normalizedTitle.includes(normalizedFilter))
        return true;

    const normalizedNotes = normalizeString(event.notes);
    if (normalizedNotes.includes(normalizedFilter))
        return true;

    return false;
}

import { useCallback, useEffect, useState, type FocusEvent } from 'react';
import { Button, Form, Tooltip, Spinner, Modal } from ':components/shadcn';
import { type EventFE, EventsOperationOutputFE, MAX_NOTES_LENGTH } from ':frontend/types/Event';
import { useTranslation } from 'react-i18next';
import { EditingPhase, useEditing, useToggle, type UseToggleSet } from ':frontend/hooks';
import { useCached } from ':components/hooks';
import useNotifications from ':frontend/context/NotificationProvider';
import { createTranslatedErrorAlert, createTranslatedSuccessAlert } from '../notifications';
import { CheckIcon, EditNotesIcon, NotesIcon, SendIcon } from ':components/icons/old';
import { SpinnerButton } from '../common';
import clsx from 'clsx';
import { type ClientInfoFE } from ':frontend/types/Client';
import type { UseEventDispatch, UseEventState } from './useEvent';
import { DeleteButton } from '../forms/buttons';
import { Link } from 'react-router-dom';
import { routesFE } from ':utils/routes';
import { trpc } from ':frontend/context/TrpcProvider';
import { RecurrenceRange } from ':utils/entity/event';
import { OnlyToYouLabel } from '../common/OnlyToYouLabel';

type StateDispatchProps = Readonly<{
    state: UseEventState;
    dispatch: UseEventDispatch;
}>;

export default function EventNotes({ state, dispatch }: StateDispatchProps) {
    const { t } = useTranslation('components', { keyPrefix: 'eventNotes' });
    const [ isShow, setIsShow ] = useState(!!state.event?.notes);

    if (!isShow) {
        return (
            <Button
                variant='outline'
                onClick={() => setIsShow(true)}
            >
                <NotesIcon size={18} className='mr-2' />
                {t('open-notes-button')}
            </Button>
        );
    }

    return state.event ? (
        <EditableEventNotes state={state} dispatch={dispatch} setIsShow={setIsShow} />
    ) : (
        <InitialEventNotes state={state} dispatch={dispatch} setIsShow={setIsShow} />
    );
}

type InitialEventNotesProps = StateDispatchProps & Readonly<{
    setIsShow: (value: boolean) => void;
}>;

function InitialEventNotes({ state, dispatch, setIsShow }: InitialEventNotesProps) {
    const { t } = useTranslation('components', { keyPrefix: 'eventNotes' });
    const [ isFocus, setIsFocus ] = useToggle(false);

    function onDelete() {
        dispatch({ type: 'input', field: 'initialNotes', value: '' });
        setIsShow(false);
    }

    return (
        <div>
            <div className='flex items-center pt-4 pb-2'>
                <h3 className='m-0 leading-6'>{t('notes-title')}</h3>
                <div className='grow' />
                <OnlyToYouLabel />
                <DeleteButton aria={t('close-notes-button')} onClick={onDelete} />
            </div>
            <div className='pt-1'></div>
            <div className='fl-event-notes-inner px-4 py-2'>
                <Form.Textarea
                    placeholder={t('notes-placeholder')}
                    className={isFocus ? undefined : 'line-clamp-3'}
                    minRows={3}
                    maxRows={isFocus ? undefined : 3}
                    value={state.form.initialNotes}
                    onChange={e => dispatch({ type: 'input', field: 'initialNotes', value: e.target.value })}
                    onBlur={setIsFocus.false}
                    onFocus={setIsFocus.true}
                />
            </div>
        </div>
    );
}

function maxLengthRule(value: string) {
    return value.length <= MAX_NOTES_LENGTH;
}

type EditableEventNotesProps = StateDispatchProps & Readonly<{
    setIsShow: (value: boolean) => void;
}>;

function EditableEventNotes({ state, dispatch, setIsShow }: EditableEventNotesProps) {
    const { t } = useTranslation('components', { keyPrefix: 'eventNotes' });
    const [ isFocus, setIsFocus ] = useToggle(false);
    const event = state.update?.event ?? state.event!;
    const onUpdate = useCallback((updatedEvent: EventFE) => dispatch({ type: 'update', notes: updatedEvent.notes, eventId: updatedEvent.id }), [ dispatch ]);
    const { innerValue, phase, setValue, onBlur, doUpdate } = useEditableEventNotes(event, onUpdate, setIsFocus);

    async function deleteNotes() {
        // The synced value is empty, we just close the input.
        if (!event.notes) {
            setIsShow(false);
            return;
        }

        const result = await doUpdate({ value: '' });
        if (result)
            setIsShow(false);
    }

    const isUpdating = phase === EditingPhase.Updating;

    return (
        <div>
            <div>
                <div className='flex items-center pt-4 pb-2'>
                    {event.guests.length === 1 ? (
                        <Link
                            to={routesFE.clients.detail.resolve(
                                { id: event.guests[0].client.id, key: 'notes' },
                                { '#fragment': event.id },
                            )}
                            className='text-inherit no-underline hover:underline flex items-center gap-4'
                        >
                            <EditNotesIcon size={20} />
                            <span>{t('notes-title')}</span>
                        </Link>
                    ) : (
                        <div className='flex items-center gap-4'>
                            <EditNotesIcon size={20} />
                            <span>{t('notes-title')}</span>
                        </div>
                    )}
                    <div className='grow' />
                    <OnlyToYouLabel />
                    <DeleteButton aria={t('close-notes-button')} onClick={deleteNotes} className='fl-blur-button-target' disabled={isUpdating} />
                </div>
            </div>
            <div>
                <div className='flex fl-event-notes-inner px-4 py-2'>
                    <Form.Textarea
                        placeholder={t('notes-placeholder')}
                        className={isFocus ? undefined : 'line-clamp-3'}
                        minRows={3}
                        maxRows={isFocus ? undefined : 3}
                        value={innerValue}
                        onChange={e => setValue(e.target.value)}
                        onBlur={onBlur}
                        disabled={isUpdating}
                        onFocus={setIsFocus.true}
                    />
                    <div className='fl-editable-notes-icon'>
                        {isUpdating ? (
                            <div><Spinner size='sm' /></div>
                        ) : (
                            <div><CheckIcon size={16} /></div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

function useEditableEventNotes(event: EventFE, onUpdate: (event: EventFE) => void, setIsFocus: UseToggleSet) {
    const { addAlert } = useNotifications();

    const updateEventMutation = trpc.event.updateEvent.useMutation();

    const syncNotes = useCallback(async (notes: string) => {
        try {
            const response = await updateEventMutation.mutateAsync({ id: event.id, range: RecurrenceRange.This, sendNotification: false, notes });
            const newEvent = EventsOperationOutputFE.fromServer(response).events.find(e => e.id === event.id);
            if (!newEvent)
                return false;

            onUpdate(newEvent);
            addAlert(createTranslatedSuccessAlert('components:eventNotes.notes-update-success-alert'));

            return true;
        }
        catch {
            addAlert(createTranslatedErrorAlert());
            return false;
        }
    }, [ addAlert, onUpdate, event.id, updateEventMutation ]);

    const { state: { value: innerValue, phase }, setValue, doUpdate } = useEditing(event.notes, syncNotes, { rule: maxLengthRule });

    async function onBlur(event: FocusEvent) {
        const isTargetButton = event.relatedTarget?.classList.contains('fl-blur-button-target');
        if (isTargetButton) {
            // If the user clicks on the delete button, we want to only fire deleting the notes.
            setIsFocus.false();
            return;
        }

        await doUpdate();
        setIsFocus.false();
    }

    return { innerValue, phase, setValue, onBlur, doUpdate };
}

type SendNotesButtonProps = Readonly<{
    event: EventFE;
    client?: ClientInfoFE;
    className?: string;
    disabled?: boolean;
    isCompact?: boolean;
}>;

export function SendNotesButton({ event, client, className, disabled, isCompact }: SendNotesButtonProps) {
    const { t } = useTranslation('components', { keyPrefix: 'eventNotes' });
    const [ showModal, setShowModal ] = useToggle(false);

    return (<>
        <SendNotesModal
            event={showModal ? event : undefined}
            client={client}
            onHide={setShowModal.false}
        />
        <Button
            variant='outline'
            className={clsx('fl-btn_compact', className)}
            onClick={setShowModal.true}
            disabled={disabled}
        >
            <SendIcon size={18} />
            {!isCompact && (
                <span className='ml-2'>{t('send-notes-button', { count: event.guests.length })}</span>
            )}
        </Button>
    </>);
}

enum FetchingType {
    One = 'one',
    All = 'all',
}

type SendNotesModalProps = Readonly<{
    event?: EventFE;
    client?: ClientInfoFE;
    onHide: () => void;
}>;

function SendNotesModal({ event: uncachedEvent, client, onHide }: SendNotesModalProps) {
    const { t } = useTranslation('components', { keyPrefix: 'eventNotes.sendNotesModal' });
    const [ fetching, setFetching ] = useState<FetchingType>();

    const event = useCached(uncachedEvent);

    useEffect(() => {
        if (event)
            setFetching(undefined);
    }, [ event ]);

    const { addAlert } = useNotifications();

    const sendNotesMutation = trpc.event.sendNotes.useMutation();

    function sendNotes(type: FetchingType) {
        if (!event)
            return;

        const participantIds = (client && type === FetchingType.One)
            ? [ event.findParticipantIdByClientId(client.id)! ]
            : event.guests.map(p => p.id);

        setFetching(type);
        sendNotesMutation.mutate({ participantIds }, {
            onError: () => {
                addAlert(createTranslatedErrorAlert());
            },
            onSuccess: () => {
                addAlert(createTranslatedSuccessAlert('components:eventNotes.sendNotesModal.success-alert'));
                onHide();
            },
            onSettled: () => {
                setFetching(undefined);
            },
        });
    }

    if (!event)
        return null;

    return (
        <Modal.Root
            open={!!uncachedEvent}
            onOpenChange={open => !open && onHide()}
        >
            <Modal.Content closeButton={t('cancel-button')} closeDisabled={!!fetching}>
                <Modal.Header>
                    <Modal.Title>{t('title')}</Modal.Title>
                </Modal.Header>

                <div className='mt-4 flex flex-col gap-4'>
                    {client ? (<>
                        <SpinnerButton
                            onClick={() => sendNotes(FetchingType.One)}
                            variant='primary'
                            isFetching={fetching === FetchingType.One}
                            disabled={fetching === FetchingType.All}
                        >
                            {t('send-to-one-button')}
                        </SpinnerButton>

                        {event.guests.length > 1 && (
                            <Tooltip
                                tooltipText={event?.guests.map(p => p.client.name).join('\n')}
                                className='whitespace-pre-line'
                            >
                                <div>
                                    <SpinnerButton
                                        onClick={() => sendNotes(FetchingType.All)}
                                        variant='white'
                                        isFetching={fetching === FetchingType.All}
                                        disabled={fetching === FetchingType.One}
                                        className='w-full'
                                    >
                                        {t('send-to-all-button')}
                                    </SpinnerButton>
                                </div>
                            </Tooltip>
                        )}
                    </>) : (
                        // If the client isn't selected, there is always only one button
                        <SpinnerButton
                            onClick={() => sendNotes(FetchingType.All)}
                            variant='primary'
                            isFetching={fetching === FetchingType.All}
                        >
                            {t('send-button', { count: event.guests.length })}
                        </SpinnerButton>
                    )}

                    <Modal.Footer className='pt-2'>
                        <Button
                            variant='outline-danger'
                            onClick={onHide}
                            disabled={!!fetching}
                        >
                            {t('cancel-button')}
                        </Button>
                    </Modal.Footer>
                </div>
            </Modal.Content>
        </Modal.Root>
    );
}

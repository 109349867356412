import { cn } from './utils';
import type { ReactNode } from 'react';

type CardProps = Readonly<{
    children?: ReactNode;
    className?: string;
}>;

export function Card({ children, className } : CardProps) {
    return (
        // TODO Why these arbitrary values for border color and shadow?
        <div className={cn('p-6 rounded-xl bg-white border border-[#e6e6e6] shadow-[0px_5px_15px_0px_rgba(0,0,0,0.05)]', className)}>
            {children}
        </div>
    );
}

type CardDividerProps = Readonly<{
    className?: string;
}>;

Card.Divider = ({ className } : CardDividerProps) => <div className={cn('border-b border-[#e6e6e6] my-5', className)} />;

type CardTitleProps = Readonly<{
    children?: ReactNode;
    className?: string;
}>;

Card.Title = ({ children, className } : CardTitleProps) => <h3 className={cn('text-lg text-secondary-700', className)}>{children}</h3>;

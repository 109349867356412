import { useTranslation } from 'react-i18next';
import { Form } from ':components/shadcn';
import { useForm } from 'react-hook-form';
import { SpinnerButton } from ':frontend/components/common';
import { RHFErrorMessage } from ':frontend/components/forms/ErrorMessage';
import { ControlledStringSelect } from '../forms/controlled';

export type PersonFormData = {
    firstName: string;
    lastName: string;
    profession: string;
};

type RegisterPersonFormProps = Readonly<{
    onSubmit: (output: PersonFormData) => void;
    isFetching: boolean;
}>;

export default function RegisterPersonForm({ onSubmit, isFetching }: RegisterPersonFormProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'register.personForm' });
    const { t: tp } = useTranslation('pages', { keyPrefix: 'register.personForm.profession' });
    const { t: tf } = useTranslation('common', { keyPrefix: 'form' });
    const { register, control, handleSubmit, formState: { errors } } = useForm<PersonFormData>();

    return (
        <Form.Root onSubmit={handleSubmit(onSubmit)} className='w-full'>
            <div className='fl-form-label-inside'>
                <Form.Input
                    label={t('first-name-label')}
                    {...register('firstName', { required: tf('first-name-required') })}
                />
                <RHFErrorMessage errors={errors} name='firstName' />
            </div>
            <div className='fl-form-label-inside mt-4'>
                <Form.Input
                    label={t('first-name-label')}
                    {...register('lastName', { required: tf('last-name-required') })}
                />
                <RHFErrorMessage errors={errors} name='lastName' />
            </div>
            <div className='fl-form-label-inside mt-4'>
                <Form.Label>{t('profession-label')}</Form.Label>
                <ControlledStringSelect
                    control={control}
                    name='profession'
                    options={professionOptions}
                    t={tp}
                    rules={{ required: tf('profession-required') }}
                    placeholder=''
                    isSearchable={false}
                />
                <RHFErrorMessage errors={errors} name='profession' />
            </div>
            <SpinnerButton
                type='submit'
                className='w-full mt-4'
                isFetching={isFetching}
            >
                {t('finish-button')}
            </SpinnerButton>
        </Form.Root>
    );
}

export const professionOptions = [
    'tutor',
    'mentor',
    'coach',
    'therapist',
    'consultant',
    'gym-trainer',
    'photographer',
    'marketing',
    'other',
];

import { useState } from 'react';
import { Button, Tooltip } from ':components/shadcn';
import { SpinnerButton } from '../common';
import { useTranslation } from 'react-i18next';
import { api } from ':frontend/utils/api';
import { useUser } from ':frontend/context/UserProvider';
import clsx from 'clsx';
import { createTranslatedErrorAlert } from '../notifications';
import useNotifications from ':frontend/context/NotificationProvider';
import { Link } from 'react-router-dom';
import { routesFE } from ':utils/routes';
import { type AppUserFE } from ':frontend/types/AppUser';
import { CircleCheck1Icon } from ':components/icons/basic';

export function GoogleIntegration() {
    const { t } = useTranslation('pages', { keyPrefix: 'integrations.google' });
    const { state, isFetching, connect } = useGoogleIntegration();

    return (
        <div className='border rounded-2xl p-5'>            
            <div className='flex gap-4 items-start'>
                <img className='h-8 w-auto' src={`/static/integration-icons/google-${state === IntegrationState.Partial ? 'email' : 'calendar'}.svg`} />            

                <div className='space-y-2'>           
                    <div className='space-y-1'>
                        <div className='space-y-1'>
                            <h2 className='text-secondary-900'>{t('product')}</h2>
                        
                            <div className='text-secondary'>{t('description')}</div>
                        </div>
                    </div>
                
                    <div>
                        {state === IntegrationState.Partial ? (<>
                            {t(`features-${state}-active`).split(';').map(text => (
                                <BulletFeature text={text} key={text} isActive={true} />
                            ))}
                            {t(`features-${state}-available`).split(';').map(text => (
                                <BulletFeature text={text} key={text} isActive={false} />
                            ))}
                        </>) : (<>
                            {t(`features-${state}`).split(';').map(text => (
                                <BulletFeature text={text} key={text} isActive={state === IntegrationState.All} />
                            ))}
                        </>)}
                    </div>
                </div>

                <div  className='flex items-center w-fit'>
                    {state === IntegrationState.All ? (
                        <Link to={routesFE.calendar}>
                            <Button variant='ghost' size='small'>
                                {t(`action-button-${state}`)}
                            </Button>
                        </Link>
                    ) : (
                        <SpinnerButton
                            isFetching={isFetching}
                            onClick={() => connect()}
                            size='small'
                        >
                            {t(`action-button-${state}`)}
                        </SpinnerButton>
                    )}
                </div>
            </div>
        </div>       
    );
}

function useGoogleIntegration() {
    const { addAlert } = useNotifications();
    const { appUser } = useUser();
    const [ isFetching, setIsFetching ] = useState(false);

    async function connect() {
        if (isFetching)
            return;

        const connectRes = await api.backend.googleIntegration();
        setIsFetching(false);
        if (!connectRes.status || !connectRes.data.redirectUrl) {
            addAlert(createTranslatedErrorAlert());
            return;
        }

        window.location.href = connectRes.data.redirectUrl;
    }

    return {
        state: getIntegrationState(appUser),
        isFetching,
        connect,
    };
}

enum IntegrationState {
    None = 'none',
    Partial = 'partial',
    All = 'all',
}

function getIntegrationState(appUser: AppUserFE) {
    // TODO this needs to be rethinked. There are more states now.

    if (!appUser.google.calendarId)
        return IntegrationState.None;

    return appUser.google.isContacts ? IntegrationState.All : IntegrationState.Partial;
}

type BulletFeatureProps = {
    text: string;
    isActive: boolean;
};

function BulletFeature({ text, isActive }: BulletFeatureProps) {
    return (
        <div className='flex items-center gap-2'>
            <CircleCheck1Icon className={clsx('h-5', isActive ? 'text-success' : 'text-primary')} />
            <span>{text}</span>
        </div>
    );
}

export function GoogleIntegrationBadge() {
    const { t } = useTranslation('pages', { keyPrefix: 'integrations.google' });
    const { state, isFetching, connect } = useGoogleIntegration();

    if (state === IntegrationState.All)
        return null;

    return (<div>
        <Tooltip
            side='left'
            tooltipText={t(`badge-text-${state}`)}
        >
            <div>
                <SpinnerButton
                    isFetching={isFetching}
                    className='w-full'
                    onClick={connect}
                    variant='white'
                >
                    <div className='w-[22px] h-[22px] pr-1 pb-1 mr-2'>
                        <img src={`/static/integration-icons/google-${state === IntegrationState.Partial ? 'email' : 'calendar'}.svg`} />
                    </div>
                    <div>
                        {t(`badge-title-${state}`)}
                    </div>
                </SpinnerButton>
            </div>
        </Tooltip>
    </div>);
}

import { Button } from ':components/shadcn';
import { ChevronDownIcon, ChevronUpIcon } from ':components/icons/basic';

type OpenCloseChevronButtonProps = {
    disabled?: boolean;
    className?: string;
    opened: boolean;
    onClick: () => void;
};

/** @deprecated */
export function OpenCloseChevronButton({ disabled, className, opened, onClick }: OpenCloseChevronButtonProps) {
    return (
        <Button
            disabled={disabled}
            onClick={onClick}
            className={className}
            variant='secondary'
        >
            {opened ? <ChevronUpIcon size={18} /> : <ChevronDownIcon size={18} />}
        </Button>
    );

}

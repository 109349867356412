import { useCallback, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Form, Modal } from ':components/shadcn';
import { MoreButtonDropdown, SpinnerButton } from ':frontend/components/common';
import { DEFAULT_PREFIX, OrderFE, canTransition, type OrderTransitionFE } from ':frontend/types/orders/Order';
import OrderStateBadge from ':frontend/components/orders/OrderStateBadge';
import BasicItemsTable, { BasicItemsForm } from ':frontend/components/orders/BasicItemsTable';
import EventItemsTable, { EventItemsForm } from ':frontend/components/orders/EventItemsTable';
import { Trans, useTranslation } from 'react-i18next';
import useNotifications from ':frontend/context/NotificationProvider';
import { createErrorAlert, createTranslatedSuccessAlert } from ':frontend/components/notifications';
import { INDEX_DISPLAY_DIGITS, InvoiceNumberDisplay, getInvoiceNumber } from ':frontend/components/orders/invoiceNumber';
import { CheckIcon, CircleSlashIcon, CloseIcon, DownloadIcon, EyeIcon, InvoiceIcon, MdModeEdit, ReceiptIcon, SendIcon } from ':components/icons/old';
import ClientIconLink from ':frontend/components/client/ClientIconLink';
import DateTimeDisplay from ':frontend/components/common/DateTimeDisplay';
import EditableTextInput from ':frontend/components/forms/EditableTextInput';
import { createActionState, useToggle } from ':frontend/hooks';
import { useCached } from ':components/hooks';
import { type LogFE } from ':frontend/types/Log';
import { useOrder, type OrderFormState, type UseOrderDispatch, type UseOrderState, computeFullPrefix, type OrderFormWithInvoice, hasInvoice } from ':frontend/components/orders/useOrder';
import { type TFunction } from 'i18next';
import { CountrySelect, DatePicker } from ':frontend/components/forms';
import { transformToPositiveIntegerOrZero } from ':utils/math';
import { type DateTime } from 'luxon';
import clsx from 'clsx';
import { HEADER_FOOTER_MAX_LENGTH, MaxLengthText } from ':frontend/components/settings/PersonalizationForm';
import { TranslatedErrorMessage } from ':frontend/components/forms/ErrorMessage';
import { SpoilerButtonRow } from ':frontend/components/common/SpoilerButton';
import { routesBE, routesFE } from ':utils/routes';
import type { PreselectCustom } from './NewCustomOrder';
import { CondensedInvoiceToggle } from ':frontend/components/forms/CondensedInvoiceToggle';
import { type EmailPreviewState } from ':frontend/components/orders/checkout/useCheckout';
import { EmailPreviewForm } from ':frontend/components/orders/checkout/CheckoutEmailPreview';
import { trpc } from ':frontend/context/TrpcProvider';
import { isStripeOrder, OrderTransition } from ':utils/entity/order';
import { Trash2Icon } from ':components/icons/basic';

// TODO unify the initial fetching with the rest of the hook? Or not?
export default function OrderDetail() {
    const { id } = useParams();

    const orderOutput = trpc.order.getOrder.useQuery({ id: id! });

    const order = useMemo(() => {
        if (!orderOutput.data)
            return;

        return OrderFE.fromServer(orderOutput.data);
    }, [ orderOutput.data ]);

    if (!order)
        return null;

    return (
        <OrderDetailFetched order={order} />
    );
}

type OrderDetailFetchedProps = Readonly<{
    order: OrderFE;
}>;

function OrderDetailFetched({ order }: OrderDetailFetchedProps) {
    const { state, dispatch } = useOrder(order);

    return (
        <OrderDetailInner state={state} dispatch={dispatch} />
    );
}

type StateDispatchProps = Readonly<{
    state: UseOrderState;
    dispatch: UseOrderDispatch;
}>;

function OrderDetailInner({ state, dispatch }: StateDispatchProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'orderDetail' });
    const { addAlert } = useNotifications();

    const updateOrderMutation = trpc.order.updateOrder.useMutation();
    // TODO move to reducer
    const syncTitle = useCallback(async (newTitle: string) => {
        if (newTitle === '')
            return false;

        try {
            const response = await updateOrderMutation.mutateAsync({ id: state.order.id, title: newTitle });
            dispatch({ type: 'reset', order: OrderFE.fromServer(response) });
            addAlert(createTranslatedSuccessAlert('pages:orderDetail.editTitleSuccessAlert'));

            return true;
        }
        catch {
            addAlert(createErrorAlert(updateOrderMutation.error));

            return false;
        }
    }, [ state.order.id, addAlert, dispatch, updateOrderMutation ]);

    return (
        <div className='flex flex-col h-full'>
            <div className='max-w-[1200px] w-full mx-auto py-4 flex items-center gap-x-2'>
                <div className='grow overflow-hidden'>
                    <EditableTextInput
                        className='flex items-center gap-4 text-xl mb-2'
                        viewClassName='truncate'
                        value={state.order.title}
                        syncFunction={syncTitle}
                        disabled={!!state.form}
                    />
                    <div className='flex items-center gap-4'>
                        {state.order.invoice && <InvoiceNumberDisplay invoice={state.order.invoice} />}
                        <OrderPaymentMethodDisplay order={state.order} />
                    </div>
                </div>
                {!state.form && (<>
                    <TransitionOrderButtons state={state} dispatch={dispatch} />
                    {state.order.invoice && (<>
                        <SendNotificationModal state={state} dispatch={dispatch} />
                        <a href={routesBE.public.invoice.resolve({ id: state.order.id })} target='_blank' rel='noreferrer' className='no-underline'>
                            <Button variant='ghost-secondary' className='fl-btn_square'>
                                <EyeIcon size={22} />
                                {t('view-invoice-button')}
                            </Button>
                        </a>
                    </>)}
                    <DuplicateOrderButton order={state.order} />
                </>)}
                {editOrderButtons(state, dispatch, t)}
                {!state.form && (
                    <MoreButtonDropdown>
                        <DeleteOrderModal state={state} dispatch={dispatch} />
                        {state.order.invoice && (
                            <a href={routesBE.public.invoice.resolve({ id: state.order.id })} download={getInvoiceNumber(state.order.invoice) + '.pdf'} className='no-underline'>
                                <Button variant='ghost-secondary' className='fl-btn_square'>
                                    <DownloadIcon size={22} />
                                    {t('download-invoice-button')}
                                </Button>
                            </a>
                        )}
                    </MoreButtonDropdown>
                )}
            </div>
            <div className='border-y' style={{ backgroundColor: '#F5F5F5' }}>
                <div className='max-w-[1200px] w-full mx-auto flex items-center gap-8 py-4'>
                    <OrderStateBadge order={state.order} />
                    <ClientIconLink client={state.order.client} />
                </div>
            </div>
            <div className='fl-main-scroller'>
                <div className='max-w-[1200px] w-full mx-auto py-8 flex flex-col gap-8'>
                    {state.form ? (<>
                        {state.order.invoice && (
                            <OrderForm state={state} dispatch={dispatch} />
                        )}
                        {state.order.getEventItems().length > 0 && (
                            <EventItemsForm state={state} form={state.form} dispatch={dispatch} />
                        )}
                        {state.order.getBasicItems().length > 0 && (
                            <BasicItemsForm state={state} form={state.form} dispatch={dispatch} />
                        )}
                    </>) : (<>
                        {state.order.invoice && (
                            <OrderDisplay state={state} dispatch={dispatch} />
                        )}
                        {state.order.getEventItems().length > 0 && (
                            <EventItemsTable items={state.order.getEventItems()} />
                        )}
                        {state.order.getBasicItems().length > 0 && (
                            <BasicItemsTable items={state.order.getBasicItems()} />
                        )}
                        {state.order.invoice && !!state.order.logs.length && (
                            <OrderLogsList order={state.order} />
                        )}
                    </>)}
                </div>
            </div>
        </div>
    );
}

type OrderLogsListProps = Readonly<{
    order: OrderFE;
}>;

function OrderLogsList({ order }: OrderLogsListProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'orderDetail' });

    return (
        <div className='flex flex-col gap-2'>
            <h2 className='m-0 mb-2'>{t('logs-title')}</h2>
            {order.logs.map(log => (
                <OrderLogsRow key={log.id} log={log} />
            ))}
        </div>
    );
}

type OrderLogsRowProps = Readonly<{
    log: LogFE;
}>;

function OrderLogsRow({ log }: OrderLogsRowProps) {
    const { t } = useTranslation('components', { keyPrefix: 'historyLogs' });

    return (
        <div className='flex gap-4'>
            <span className='text-secondary'><DateTimeDisplay dateTime={log.date} /></span>
            <div>
                <Trans
                    t={t}
                    i18nKey={log.translationId}
                    values={log.data}
                    components={{
                        b: <span />,
                    }}
                />
            </div>
        </div>
    );
}

function TransitionOrderButtons({ state, dispatch }: StateDispatchProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'orderDetail' });
    const transitionOrder = useCallback((transition: OrderTransitionFE) => dispatch({ type: 'sync', operation: 'transition', transition, fid: transition }), [ dispatch ]);
    if (isStripeOrder(state.order))
        return null;

    return (<>
        {canTransition(state.order, OrderTransition.Fulfill) &&
            <SpinnerButton
                variant='ghost-secondary'
                className='fl-btn_square'
                onClick={() => transitionOrder(OrderTransition.Fulfill)}
                fetching={state.sync?.fetching}
                fid={OrderTransition.Fulfill}
            >
                <ReceiptIcon size={22} />
                {t('fulfill-order-button')}
            </SpinnerButton>
        }
        {canTransition(state.order, OrderTransition.Unfulfill) &&
            <SpinnerButton
                variant='ghost-secondary'
                className='fl-btn_square'
                onClick={() => transitionOrder(OrderTransition.Unfulfill)}
                fetching={state.sync?.fetching}
                fid={OrderTransition.Unfulfill}
            >
                <CircleSlashIcon size={22} />
                {t('unfulfill-order-button')}
            </SpinnerButton>
        }
    </>);
}

function SendNotificationModal({ state, dispatch }: StateDispatchProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'orderDetail.sendNotificationModal' });
    const { sendNotification, order, sync } = state;
    const cached = useCached(sendNotification);
    const isFetching = !!sync?.fetching;

    return (<>
        <Modal.Root
            open={!!sendNotification}
            onOpenChange={() => dispatch({ type: 'sendNotification', operation: 'close' })}
        >
            {cached && (<>
                {cached.phase === 'emailPreview' ? (
                    <EmailPreview emailPreview={cached.emailPreview} dispatch={dispatch} />
                ) : (<>
                    <Modal.Content closeButton={t('cancel-button')}>
                        <Modal.Header>
                            <Modal.Title>{t('title')}</Modal.Title>
                        </Modal.Header>

                        <div className='mt-4 text-center'>
                            {t(`text${order.isNotificationSent ? '-again' : ''}`)}
                        </div>

                        <Modal.Footer className='flex justify-end gap-4 mt-6'>
                            <Button
                                variant='outline'
                                size='tiny' className='mt-2'
                                onClick={() => dispatch({ type: 'sendNotification', operation: 'emailPreview' })}
                            >
                                <SendIcon size={18} className='mr-2' />
                                {t('preview-email-button')}
                            </Button>
                            <SpinnerButton
                                size='tiny'
                                onClick={() => dispatch({
                                    type: 'sync',
                                    operation: 'delete',
                                    fid: FID_SEND_NOTIFICATION,
                                })}
                                fetching={state.sync?.fetching}
                                fid={FID_SEND_NOTIFICATION}

                            >
                                {t('confirm-button')}
                            </SpinnerButton>
                            <Button
                                variant='outline'
                                onClick={() => dispatch({ type: 'sendNotification', operation: 'close' })}
                                disabled={isFetching}
                            >
                                {t('cancel-button')}
                            </Button>
                        </Modal.Footer>
                    </Modal.Content>
                </>)}
            </>)}
        </Modal.Root>

        <Button
            variant='ghost-secondary'
            className='fl-btn_square'
            onClick={() => dispatch({ type: 'sendNotification', operation: 'open' })}
        >
            {order.isNotificationSent ? (<>
                <CheckIcon size={22} />
                {t('open-again-button')}
            </>) : (<>
                <SendIcon size={22} />
                {t('open-button')}
            </>)}
        </Button>
    </>);
}

//! xs={auto} ==== css= width: fit-content;
const FID_SEND_NOTIFICATION = 'send-notification';

type EmailPreviewProps = Readonly<{
    emailPreview: EmailPreviewState;
    dispatch: UseOrderDispatch;
}>;

function EmailPreview({ emailPreview, dispatch }: EmailPreviewProps) {
    const { t } = useTranslation('components', { keyPrefix: 'checkout.emailPreview' });
    const isChanged = emailPreview.isChanged;

    return (
        <div className='fl-checkout-email-preview' style={{ padding: '20px' }}>
            <h2 className='m-0'>{t('title')}</h2>
            <EmailPreviewForm state={emailPreview} dispatch={dispatch} />
            <div className='flex justify-end gap-2 mt-4'>
                {isChanged && (
                    <Button variant='outline' onClick={() => dispatch({ type: 'emailPreview', operation: 'reset' })}>
                        {t('reset-button')}
                    </Button>
                )}
                <Button onClick={() => dispatch({ type: 'sendNotification', operation: 'overview' })}>
                    {t('back-button')}
                </Button>
            </div>
        </div>
    );
}

function editOrderButtons(state: UseOrderState, dispatch: UseOrderDispatch, t: TFunction) {
    if (!state.form) {
        return (
            <Button
                variant='ghost-secondary'
                className='fl-btn_square'
                onClick={() => dispatch({ type: 'form', operation: 'edit' })}
            >
                <MdModeEdit size={22} />
                {t('edit-form-button')}
            </Button>
        );
    }

    return (<>
        <SpinnerButton
            variant='primary'
            className='fl-btn_square'
            onClick={() => dispatch({ type: 'sync', operation: 'save', fid: FID_SAVE })}
            fetching={state.sync?.fetching}
            fid={FID_SAVE}
            disabled={!!state.formErrors}
        >
            <Trash2Icon />
            {t('save-form-button')}
        </SpinnerButton>
        <Button
            variant='ghost-danger'
            className='fl-btn_square'
            onClick={() => dispatch({ type: 'form', operation: 'discard' })}
        >
            <CloseIcon size={22} />
            {t('discard-form-button')}
        </Button>
    </>);
}

const FID_SAVE = 'save';

function DeleteOrderModal({ state, dispatch }: StateDispatchProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'orderDetail.deleteOrderModal' });
    const [ showModal, setShowModal ] = useToggle(false);
    const isFetching = !!state.sync?.fetching;

    return (<>
        <Button
            variant='ghost-danger'
            className='fl-btn_square'
            onClick={setShowModal.true}
        >
            <Trash2Icon />
            {t('open-button')}
        </Button>

        <Modal.Root
            open={showModal}
            onOpenChange={open => !isFetching && !open && setShowModal.false()}
        >
            <Modal.Content closeButton={t('cancel-button')}>
                <Modal.Header>
                    <Modal.Title>{t('title')}</Modal.Title>
                </Modal.Header>

                <div className='mt-4 text-center'>
                    {t('text')}
                </div>

                <Modal.Footer className='flex justify-end gap-4 mt-6'>
                    <Button
                        variant='outline-danger'
                        size='tiny'
                        onClick={setShowModal.false}
                        disabled={isFetching}
                    >
                        {t('cancel-button')}
                    </Button>
                    <SpinnerButton
                        size='tiny'
                        onClick={() => dispatch({
                            type: 'sync',
                            operation: 'delete',
                            fid: FID_DELETE,
                        })}
                        fetching={state.sync?.fetching}
                        fid={FID_DELETE}
                    >
                        {t('confirm-button')}
                    </SpinnerButton>
                </Modal.Footer>
            </Modal.Content>
        </Modal.Root>
    </>);
}

const FID_DELETE = 'delete';

function DuplicateOrderButton({ order }: Readonly<{ order: OrderFE }>) {
    const { t } = useTranslation('pages', { keyPrefix: 'orderDetail' });

    const isEvents = useMemo(() => order.getEventItems().length > 0, [ order ]);
    if (isEvents)
        return null;

    return (
        <Link
            to={routesFE.orders.newCustom}
            state={createActionState<PreselectCustom>('preselectCustom', { orderId: order.id })}
            className='no-underline'
        >
            <Button
                variant='ghost-secondary'
                className='fl-btn_square'
            >
                <InvoiceIcon size={22} />
                {t('duplicate-order-button')}
            </Button>
        </Link>
    );
}

function OrderDisplay({ state }: StateDispatchProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'orderDetail' });
    const { order } = state;

    const showDetails = !!(
        order.supplier.cin
        ?? order.supplier.tin
        ?? order.subscriber.cin
        ?? order.subscriber.tin
        ?? order.fields.customKey1
        ?? order.fields.customKey2
    );

    const showTaxDate = useMemo(() => state.order.items.some(i => !i.vat.isZero), [ state.order.items ]);

    return (
        <div className='flex flex-col'>
            <div className='gx-90'>
                <div>
                    <h3 className='text-secondary-600'>{t('supplier-label')}</h3>
                    {addressDisplay(state, 'supplier')}
                </div>
                <div className='border-x'>
                    <h3 className='text-secondary-600'>{t('middle-column-label')}</h3>
                    <div className='flex flex-col gap-2'>
                        <div className='flex justify-between'>
                            <span className='text-secondary-600'>{t('issue-date-label')}</span>
                            <DateTimeDisplay dateTime={order.issueDate} date />
                        </div>
                        <div className='flex justify-between'>
                            <span className='text-secondary-600'>{t('due-date-label')}</span>
                            <DateTimeDisplay dateTime={order.dueDate} date />
                        </div>
                        {showTaxDate && (
                            <div className='flex justify-between'>
                                <span className='text-secondary-600'>{t('tax-date-label')}</span>
                                <DateTimeDisplay dateTime={order.taxDate} date />
                            </div>
                        )}
                        {!isStripeOrder(order) && order.invoice && (
                            <div className='flex justify-between'>
                                <span className='text-secondary-600'>{t('variable-symbol-label')}</span>
                                <span>{order.invoice.variableSymbol}</span>
                            </div>
                        )}
                    </div>
                </div>
                <div>
                    <h3 className='text-secondary-600'>{t('subscriber-label')}</h3>
                    {addressDisplay(state, 'subscriber')}
                </div>
            </div>
            {showDetails && (
                <div className='gx-90'>
                    <div className='pt-4'>{detailsDisplay(state, 'supplier', t)}</div>
                    <div className='border-x' />
                    <div className='pt-4'>{detailsDisplay(state, 'subscriber', t)}</div>
                </div>
            )}
        </div>
    );
}

function addressDisplay(state: UseOrderState, type: 'supplier' | 'subscriber') {
    const identity = state.order[type];
    const address = identity.address;

    return (
        <div className='flex flex-col gap-1'>
            <div>{identity.name}</div>
            <div>{identity.email}</div>
            {address?.line1 && (
                <div>{address?.line1}</div>
            )}
            {(address?.postalCode || address?.city) && (
                <div>{address?.postalCode}{address?.postalCode && ', '}{address?.city}</div>
            )}
            {address?.country && (
                <div>{address?.country}</div>
            )}
        </div>
    );
}

function detailsDisplay(state: UseOrderState, type: 'supplier' | 'subscriber', t: TFunction) {
    const identity = state.order[type];
    const fields = state.order.fields;

    return (
        <div className='flex flex-col gap-1'>
            {identity.cin && (
                <div className='flex justify-between'>
                    <span className='text-secondary-600'>{t('cin-label')}</span>
                    <span>{identity.cin}</span>
                </div>
            )}
            {identity.tin && (
                <div className='flex justify-between'>
                    <span className='text-secondary-600'>{t('tin-label')}</span>
                    <span>{identity.tin}</span>
                </div>
            )}
            {type === 'supplier' && fields.customKey1 && (
                <div className='flex justify-between'>
                    <span className='text-secondary-600'>{fields.customKey1}</span>
                    <span>{fields.customValue1}</span>
                </div>
            )}
            {type === 'supplier' && fields.customKey2 && (
                <div className='flex justify-between'>
                    <span className='text-secondary-600'>{fields.customKey2}</span>
                    <span>{fields.customValue2}</span>
                </div>
            )}
        </div>
    );
}

function OrderForm({ state, dispatch }: StateDispatchProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'orderDetail' });
    const [ isShowMore, setIsShowMore ] = useState(true);
    const { order, form } = state;

    const dispatchDate = useMemo(() => ({
        issue: (value?: DateTime) => value && dispatch({ type: 'input', field: 'issueDate', value }),
        due: (value?: DateTime) => value && dispatch({ type: 'input', field: 'dueDate', value }),
        tax: (value?: DateTime) => value && dispatch({ type: 'input', field: 'taxDate', value }),
    }), [ dispatch ]);

    // TODO use form items instead
    const showTaxDate = useMemo(() => state.order.items.some(i => !i.vat.isZero), [ state.order.items ]);

    if (!form)
        return null;

    return (
        <div className='flex flex-col gap-4'>
            <div className='gx-90'>
                <div>
                    <h3 className='text-secondary-600'>{t('supplier-label')}</h3>
                    {identityForm(state, dispatch, 'supplier', t)}
                </div>
                <div>
                    <h3 className='text-secondary-600'>{t('middle-column-label')}</h3>
                    <div className='flex flex-col gap-2'>
                        {hasInvoice(form) && (
                            <div className='items-center gx-2'>
                                <div className='text-secondary-600'>{t('number-label')}</div>
                                {invoiceNumberInput(form, state, dispatch)}
                            </div>
                        )}
                        <div className='items-center fl-input-text-right'>
                            <div className='text-secondary-600'>{t('issue-date-label')}</div>
                            <div><DatePicker selected={form.issueDate} onChange={dispatchDate.issue} type='date' /></div>
                        </div>
                        <div className='items-center fl-input-text-right'>
                            <div className='text-secondary-600'>{t('due-date-label')}</div>
                            <div><DatePicker selected={form.dueDate} onChange={dispatchDate.due} type='date' /></div>
                        </div>
                        {showTaxDate && (
                            <div  className='items-center fl-input-text-right'>
                                <div className='text-secondary-600'>{t('tax-date-label')}</div>
                                <div><DatePicker selected={form.taxDate} onChange={dispatchDate.tax} type='date' /></div>
                            </div>
                        )}
                        {!isStripeOrder(order) && hasInvoice(form) && (<>
                            <div className='items-center fl-input-text-right'>
                                <div className='text-secondary-600'>{t('variable-symbol-label')}</div>
                                <div>
                                    <Form.Input
                                        className='tabular-nums'
                                        value={form.invoice.variableSymbol}
                                        onChange={event => dispatch({ type: 'input', field: 'invoice.variableSymbol', value: event.target.value })}
                                    />
                                </div>
                            </div>
                            {form.invoice.variableSymbol !== form.invoice.generatedVariableSymbol && (
                                <div>
                                    <div />
                                    <div>
                                        <Button
                                            variant='outline' size='tiny' className='w-full'
                                            onClick={() => dispatch({ type: 'form', operation: 'generateVariableSymbol' })}
                                        >
                                            {t('generate-variable-symbol-button')}
                                        </Button>
                                    </div>
                                </div>
                            )}
                        </>)}
                    </div>
                </div>
                <div>
                    <h3 className='text-secondary-600'>{t('subscriber-label')}</h3>
                    {identityForm(state, dispatch, 'subscriber', t)}
                </div>
            </div>
            <SpoilerButtonRow title={t('toggle-details-button')} isShow={isShowMore} setIsShow={setIsShowMore} />
            {isShowMore && detailsForm(form, dispatch, t)}
        </div>
    );
}

function invoiceNumberInput(form: OrderFormWithInvoice, state: UseOrderState, dispatch: UseOrderDispatch) {
    const isChangeable = state.masterContext.subscription.restrictions.invoicing.customLogo;

    function updateIndex(value: string) {
        const parsed = transformToPositiveIntegerOrZero(value);
        if (parsed.toString().length > INDEX_DISPLAY_DIGITS)
            return;

        dispatch({ type: 'input', field: 'invoice.index', value: parsed });
    }

    const fullPrefix = computeFullPrefix(form, state.masterContext);
    const error = state.error?.numberAlreadyUsed;
    const displayError = error?.prefix === fullPrefix && error?.index === form.invoice.index;

    return (<>
        <div className={!isChangeable ? 'w-6/12 flex items-center gap-2 tabular-nums' : 'flex items-center gap-2 tabular-nums'}>
            <div className='flex'>
                {!isChangeable && (
                    <span className='bg-secondary-100 rounded-l-full px-2 tabular-nums'>
                        {DEFAULT_PREFIX}
                    </span>
                )}
                <Form.Input
                    className={clsx('tabular-nums', !isChangeable && 'rounded-l-none')}
                    value={form.invoice.formPrefix}
                    onChange={event => dispatch({ type: 'input', field: 'invoice.formPrefix', value: event.target.value })}
                />
            </div>
        </div>
        -
        <div className='fl-input-text-right'>
            <Form.Input
                className='tabular-nums'
                value={form.invoice.index}
                onChange={event => updateIndex(event.target.value)}
            />
        </div>
        {displayError && (
            <TranslatedErrorMessage translationId={'common:form.invoice-number-already-used'} />
        )}
    </>);
}

function identityForm(state: UseOrderState, dispatch: UseOrderDispatch, type: 'supplier' | 'subscriber', t: TFunction) {
    const form = state.form;
    if (!form)
        return null;

    const identity = form[type];

    return (
        <div className='fl-invoicing-identity-form flex flex-col gap-2'>
            <div>
                <Form.Input
                    label={t('name-company-label')}
                    value={identity.name}
                    onChange={e => dispatch({ type: 'input', field: `${type}.name`, value: e.target.value })}
                    placeholder={t('name-company-label')}
                />
                <TranslatedErrorMessage translationId={state.formErrors?.[`${type}.name`]} />
            </div>
            <div>
                <Form.Input
                    label={t('email-label')}
                    value={identity.email}
                    onChange={e => dispatch({ type: 'input', field: `${type}.email`, value: e.target.value })}
                    placeholder={t('email-label')}
                />
            </div>
            <div>
                <Form.Input
                    label={t('city-label')}
                    value={identity.address.city}
                    onChange={e => dispatch({ type: 'input', field: `${type}.address.city`, value: e.target.value })}
                    placeholder={t('city-label')}
                />
            </div>
            <div>
                <Form.Input
                    label={t('line1-label')}
                    value={identity.address.line1}
                    onChange={e => dispatch({ type: 'input', field: `${type}.address.line1`, value: e.target.value })}
                    placeholder={t('line1-label')}
                />
            </div>
            <div className='gx-2'>
                <div>
                    <Form.Input
                        label={t('postal-code-label')}
                        value={identity.address.postalCode}
                        onChange={e => dispatch({ type: 'input', field: `${type}.address.postalCode`, value: e.target.value })}
                        placeholder={t('postal-code-label')}
                    />
                </div>
                <div>
                    <Form.Label>{t('country-label')}</Form.Label>
                    <CountrySelect
                        value={identity.address.country}
                        onChange={value => dispatch({ type: 'input', field: `${type}.address.country`, value })}
                        placeholder={t('country-label')}
                        isClearable
                    />
                </div>
            </div>
        </div>
    );
}

function detailsForm(form: OrderFormState, dispatch: UseOrderDispatch, t: TFunction) {
    return (
        <div>
            <div className='gx-90 fl-invoicing-identity-form'>
                <div>{identityDetailsForm(form, dispatch, 'supplier', t)}</div>
                <div>
                    <div className='items-center fl-input-text-right'>
                        <div className='text-secondary-600'>{t('condensed-invoice-label')}</div>
                        <div>
                            <CondensedInvoiceToggle
                                value={form.isCondensedInvoice}
                                onChange={value => dispatch({ type: 'input', field: 'isCondensedInvoice', value })}
                            />
                        </div>
                    </div>
                </div>
                <div>{identityDetailsForm(form, dispatch, 'subscriber', t)}</div>
            </div>
            <div className='mt-8'>
                <Form.Textarea
                    label={t('header-label')}
                    placeholder={t('header-label')}
                    minRows={2}
                    value={form.customFields.header}
                    onChange={e => dispatch({ type: 'input', field: 'customFields.header', value: e.target.value })}
                    aria-describedby='header-textarea'
                />
                <MaxLengthText length={form.customFields.header?.length ?? 0} maxLength={HEADER_FOOTER_MAX_LENGTH} id='header-textarea' />
            </div>
            <div className='mt-4'>
                <Form.Textarea
                    label={t('footer-label')}
                    placeholder={t('footer-label')}
                    minRows={2}
                    value={form.customFields.footer}
                    onChange={e => dispatch({ type: 'input', field: 'customFields.footer', value: e.target.value })}
                    aria-describedby='footer-textarea'
                />
                <MaxLengthText length={form.customFields.footer?.length ?? 0} maxLength={HEADER_FOOTER_MAX_LENGTH} id='footer-textarea' />
            </div>
        </div>
    );
}

function identityDetailsForm(form: OrderFormState, dispatch: UseOrderDispatch, type: 'supplier' | 'subscriber', t: TFunction) {
    const identity = form[type];

    return (
        <div className='fl-invoicing-identity-form flex flex-col gap-2'>
            <div className='gx-2'>
                <div>
                    <Form.Input
                        label={t('cin-label')}
                        value={identity.cin}
                        onChange={e => dispatch({ type: 'input', field: `${type}.cin`, value: e.target.value })}
                        placeholder={t('cin-label')}
                    />
                </div>
                <div>
                    <Form.Input
                        label={t('tin-label')}
                        value={identity.tin}
                        onChange={e => dispatch({ type: 'input', field: `${type}.tin`, value: e.target.value })}
                        placeholder={t('tin-label')}
                    />
                </div>
            </div>
            {type === 'supplier' && (<>
                <div className='gx-2'>
                    <div>
                        <Form.Input
                            label={t('custom-key-1-label')}
                            value={form.customFields.customKey1}
                            onChange={e => dispatch({ type: 'input', field: `customFields.customKey1`, value: e.target.value })}
                            placeholder={t('custom-key-1-label')}
                        />
                    </div>
                    <div>
                        <Form.Input
                            label={t('custom-value-1-label')}
                            value={form.customFields.customValue1}
                            onChange={e => dispatch({ type: 'input', field: `customFields.customValue1`, value: e.target.value })}
                            placeholder={t('custom-value-1-label')}
                        />
                    </div>
                </div>
                <div className='gx-2'>
                    <div>
                        <Form.Input
                            label={t('custom-key-2-label')}
                            value={form.customFields.customKey2}
                            onChange={e => dispatch({ type: 'input', field: `customFields.customKey2`, value: e.target.value })}
                            placeholder={t('custom-key-2-label')}
                        />
                    </div>
                    <div>
                        <Form.Input
                            label={t('custom-value-2-label')}
                            value={form.customFields.customValue2}
                            onChange={e => dispatch({ type: 'input', field: `customFields.customValue2`, value: e.target.value })}
                            placeholder={t('custom-value-2-label')}
                        />
                    </div>
                </div>
            </>)}
        </div>
    );
}

type OrderPaymentMethodDisplayProps = Readonly<{
    order: OrderFE;
    className?: string;
}>;

function OrderPaymentMethodDisplay({ order, className }: OrderPaymentMethodDisplayProps) {
    const { t } = useTranslation('components', { keyPrefix: 'paymentMethodSelect' });

    return (
        <div className={clsx('fl-order-payment-method', className)}>
            {t(`${order.paymentMethod}-title`)}
        </div>
    );
}

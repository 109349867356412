import { type OrderFE, type OrderInfoFE, type SchedulerOrderInfoFE } from ':frontend/types/orders/Order';
import { useTranslation } from 'react-i18next';
import { CircleCrossIcon, CircleFullIcon, CircleMostlyFullIcon, CircleSemiFullIcon } from ':components/icons/old';
import clsx from 'clsx';
import { OrderState } from ':utils/entity/order';

export const ORDER_STATE_DESCRIPTIONS = {
    [OrderState.fulfilled]: { color: '#6BB358', label: 'state-fulfilled', icon: CircleFullIcon },
    [OrderState.new]: { color: '#F0BA51', label: 'state-new', icon: CircleSemiFullIcon },
    [OrderState.overdue]: { color: '#C75B61', label: 'state-overdue', icon: CircleMostlyFullIcon },
    // The danger color. TODO: provide all these colors from a central file.
    // TODO this state is currently impossible! We don't support that from the FE side and there are no canceled orders in the production DB! We should decide whether we want to support this state someday. If not, we should delete it.
    [OrderState.canceled]: { color: '#FF0C55', label: 'state-canceled', icon: CircleCrossIcon },
} as const;

const iconSizes = {
    'small': 16,
    'large': 20,
} as const;

type OrderStateBadgeProps = {
    size?: keyof typeof iconSizes;
    className?: string;
} & ({
    order: OrderFE | OrderInfoFE | SchedulerOrderInfoFE;
} | {
    state: OrderState;
});

export default function OrderStateBadge(props: OrderStateBadgeProps) {
    const { size = 'small' } = props;
    const { t } = useTranslation('common');

    const state = 'order' in props ? props.order.state : props.state;
    const iconSize = iconSizes[size];
    const description = ORDER_STATE_DESCRIPTIONS[state];

    return (
        <div className={clsx('flex items-center gap-x-2', props.className)}>
            <span style={{ color: description.color }}>
                {description.icon({ size: iconSize })}
            </span>
            {t(`order.${description.label}`)}
        </div>
    );
}

export function AllOrderStates() {
    return (
        <div className='flex flex-col gap-2'>
            {Object.values(OrderState).map(state => (
                <OrderStateBadge key={state} state={state} />
            ))}
        </div>
    );
}

import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { Form } from ':components/shadcn';
import { RHFErrorMessage } from ':frontend/components/forms/ErrorMessage';
import { SpinnerButton } from ':frontend/components/common';
import useNotifications from ':frontend/context/NotificationProvider';
import { Variant, Visibility } from ':frontend/types/notifications';
import { routesFE } from ':utils/routes';
import { GoogleOauthButton } from ':frontend/components/auth/googleButton';
import { FlowlanceBanner } from ':components/icons/old';
import useAuth from ':frontend/context/AuthProvider';
import { createTranslatedErrorAlert } from ':frontend/components/notifications';
import { canonizeEmail } from ':utils/forms';

type ResetPasswordFormData = {
    email: string;
};

export default function ResetPassword() {
    const { t } = useTranslation('pages', { keyPrefix: 'resetPassword' });
    const { t: tf } = useTranslation('common', { keyPrefix: 'form' });
    const [ isFetching, setIsFetching ] = useState(false);
    const [ isGoogleAccount, setIsGoogleAccount ] = useState(false);
    const { auth } = useAuth();

    const { register, handleSubmit, formState: { errors } } = useForm<ResetPasswordFormData>();
    const { addAlert } = useNotifications();

    async function onSubmit(data: ResetPasswordFormData) {
        const canonizedEmail = canonizeEmail(data.email);
        setIsFetching(true);
        const response = await auth.resetPassword(canonizedEmail);
        setIsFetching(false);

        if (!response.status) {
            if (response.error === 'auth.resetPassword.google') {
                setIsGoogleAccount(true);
                return;
            }

            setIsGoogleAccount(false);
            addAlert(createTranslatedErrorAlert());
            return;
        }

        addAlert({ message: t('success-alert'), variant: Variant.Success }, Visibility.Global);
    }

    return (
        <div className='fl-unauthorized-page'>
            <div className='fl-centering-wrapper'>
                <div className='fl-unauthorized-card'>
                    <div className='fl-unauthorized-content flex flex-col items-center'>
                        <FlowlanceBanner />
                        <h1>{t('page-title')}</h1>
                        <Form.Root onSubmit={handleSubmit(onSubmit)} className='w-full'>
                            <div className='fl-form-label-inside'>
                                <Form.Input
                                    label={t('email-label')}
                                    type='email'
                                    {...register('email', { required: tf('email-required') })}
                                />
                            </div>
                            <RHFErrorMessage errors={errors} name='email' />
                            <SpinnerButton
                                type='submit'
                                className='w-full mt-4'
                                isFetching={isFetching}
                            >
                                {t('reset-password-button')}
                            </SpinnerButton>
                        </Form.Root>
                        <Link to={routesFE.login.index.path} className='self-end mt-2'>
                            {t('login-link')}
                        </Link>
                        {isGoogleAccount && (
                            <div className='rounded border border-danger bg-danger/10 p-2 mt-8 w-full'>
                                <p className='text-center text-danger'>{t('via-google')}</p>
                                <GoogleOauthButton type='login' />
                            </div>
                        )}
                        <div className='text-center mt-8'>
                            <Trans
                                i18nKey='register-link'
                                t={t}
                                components={{
                                    a: <Link to={routesFE.register.index} />,
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

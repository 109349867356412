import { useId, type ReactNode } from 'react';
import { Button, Card, Form } from ':components/shadcn';
import type { ProductFormsDispatch, ProductFormsState } from './useProductForms';
import { useTranslation } from 'react-i18next';
import type { IconType } from ':components/icons/common';
import { productStyles } from ':components/store/product/ProductCard';
import { ProductType } from ':utils/entity/product';
import { ButtonIcon, CalendarPhoneIcon, CircleInfoIcon, CirclePercentageIcon, Eye2Icon, InputPasswordEditIcon, InputPasswordPointerIcon, Link5Icon, LockIcon, MoneyBillsDollarIcon, MsgSmile2Icon, PlusIcon, WindowLinkIcon } from ':components/icons/basic';
import type { TFunction } from 'i18next';
import clsx from 'clsx';
import { CurrencySelect } from '../forms/CurrencySelect';
import { CroppedImageInput } from ':components/custom';
import { useMaster } from ':frontend/context/UserProvider';
import { VatSelect } from '../forms/VatSelect';
import { trpc } from ':frontend/context/TrpcProvider';
import { createDisplayUrl, routesStore } from ':utils/routes';
import { createSlug } from ':utils/common';
import { useTypedTranslation } from ':frontend/hooks';
import { LocationSelect } from '../location/LocationSelect';
import { TranslatedErrorMessage } from '../forms/ErrorMessage';
import { transformToPositiveIntegerOrEmpty, transformToPrice } from ':utils/math';

type StateDispatchProps = Readonly<{
    state: ProductFormsState;
    dispatch: ProductFormsDispatch;
}>;

export function ProductDetailsForm({ state, dispatch }: StateDispatchProps) {
    const tt = useTypedTranslation(state.type, 'components', 'productDetailsForm');

    return (<>
        {commonCard(state, dispatch, tt)}
        {!pricingDisabledTypes.includes(state.type) && (
            <PricingCard state={state} dispatch={dispatch} />
        )}
        {(state.type === ProductType.Session || state.type === ProductType.Bundle) && sessionDetailsCard(state, dispatch, tt)}
        {state.type === ProductType.Digital && digitalDetailsCard(state, dispatch, tt)}
        {state.type === ProductType.Lead && leadDetailsCard(state, dispatch, tt)}
        {state.type === ProductType.Membership && membershipDetailsCard(state, dispatch, tt)}
        {state.type === ProductType.Link && linkDetailsCard(state, dispatch, tt)}
        {state.type === ProductType.Custom && customDetailsCard(state, dispatch, tt)}
        {!successDisabledTypes.includes(state.type) && (
            <SuccessCard state={state} dispatch={dispatch} />
        )}
    </>);
}

const pricingDisabledTypes = [ ProductType.Lead, ProductType.Link ];
const successDisabledTypes = [ ProductType.Link ];

function defaultCard(type: ProductType, title: string, icon: IconType, content: ReactNode) {
    return (
        <Card className='flex flex-col gap-4'>
            <h3 className='flex items-center gap-2'>{styledIcon(icon, type)}<span className='text-lg leading-4'>{title}</span></h3>
            <div className='h-px w-full bg-secondary-100 mt-1 mb-2' />
            {content}
        </Card>
    );
}

function styledIcon(icon: IconType, type: ProductType): ReactNode {
    return icon({ size: 'md', className: productStyles[type].iconColor });
}

function commonCard(state: ProductFormsState, dispatch: ProductFormsDispatch, t: TFunction) {
    // eslint-disable-next-line
    const thumbnailId = useId();

    return defaultCard(state.type, t('common-card-title'), InputPasswordPointerIcon, <>
        <div>
            <Form.Input
                label={<>{t('title-label')}<Form.RequiredIcon /></>}
                placeholder={t('title-placeholder')}
                isError={!!state.details.formErrors?.title}
                value={state.details.form.title}
                onChange={e => dispatch({ type: 'details', field: 'title', value: e.target.value })}
                size='compact'
            />
            <TranslatedErrorMessage translationId={state.details.formErrors?.title} />
        </div>
        <div>
            <Form.Textarea
                label={t('description-label')}
                placeholder={t('description-placeholder')}
                isError={!!state.details.formErrors?.description}
                value={state.details.form.description}
                onChange={e => dispatch({ type: 'details', field: 'description', value: e.target.value })}
                minRows={2}
            />
            <TranslatedErrorMessage translationId={state.details.formErrors?.description} />
        </div>
        <div>
            <Form.Label htmlFor={thumbnailId}>
                {<>{t('thumbnail-label')} <span className='text-secondary-400'>{t('thumbnail-label-more', { maxSize: THUMBNAIL_MAX_SIZE })}</span></>}
            </Form.Label>
            <CroppedImageInput
                id={thumbnailId}
                value={state.details.form.thumbnail}
                onChange={value => dispatch({ type: 'details', field: 'thumbnail', value })}
                imageClass='max-h-14 h-14 w-14 rounded-full'
                cropperOptions={{
                    modalDescription: t('thumbnail-cropper-description', { maxSize: THUMBNAIL_MAX_SIZE }),
                    isSelectionRounded: true,
                    maxWidth: THUMBNAIL_MAX_SIZE,
                    maxHeight: THUMBNAIL_MAX_SIZE,
                }}
            />
        </div>
    </>);
}

// Four times the desired size - just to be sure.
// Well it's not exactly four times, it's a little bit more, but 224 px isn't pretty.
const THUMBNAIL_MAX_SIZE = 15 * 4 * 4;

function PricingCard({ state, dispatch }: StateDispatchProps) {
    const tt = useTypedTranslation(state.type, 'components', 'productDetailsForm');
    const { teamSettings } = useMaster();
    const currencyId = useId();
    const vatId = useId();

    return defaultCard(state.type, tt('pricing-card-title'), MoneyBillsDollarIcon, <>
        <Form.RadioGroup
            value={state.details.form.basePrice === undefined ? 'free' : 'paid'}
            onValueChange={value => dispatch({ type: 'details', field: 'basePrice', value: value === 'free' ? undefined : '' })}
        >
            {[ 'free', 'paid' ].map(value => (
                <Form.RadioItem key={value} value={value} label={tt(`basePrice-${value}-label`)} description={tt(`basePrice-${value}-description`)} />
            ))}
        </Form.RadioGroup>
        {state.details.form.basePrice !== undefined && (<>
            <div className='ps-7 grid grid-cols-3 gap-2 items-end'>
                <div className={clsx(!teamSettings.isTaxesEnabled && 'col-span-2')}>
                    <Form.Input
                        label={<>{tt('basePrice-label')}<Form.RequiredIcon /></>}
                        placeholder={tt('basePrice-placeholder')}
                        isError={!!state.details.formErrors?.basePrice}
                        type='number'
                        value={state.details.form.basePrice}
                        onChange={e => dispatch({ type: 'details', field: 'basePrice', value: transformToPrice(e.target.value) })}
                        size='compact'
                    />
                    <TranslatedErrorMessage translationId={state.details.formErrors?.basePrice} />
                </div>
                <div>
                    <Form.Label htmlFor={currencyId} className='sr-only'>{tt('currency-label')}</Form.Label>
                    <CurrencySelect
                        id={currencyId}
                        immutableProps={{ size: 'compact' }}
                        value={state.details.form.currency}
                        onChange={value => dispatch({ type: 'details', field: 'currency', value })}
                    />
                </div>
                {teamSettings.isTaxesEnabled && (
                    <div>
                        <Form.Label htmlFor={vatId}>{tt('vat-label')}</Form.Label>
                        <VatSelect
                            id={vatId}
                            immutableProps={{ size: 'compact' }}
                            value={state.details.form.vat}
                            onChange={value => dispatch({ type: 'details', field: 'vat', value })}
                        />
                    </div>
                )}
            </div>
            {state.type === ProductType.Membership && (
                <div className='ps-7'>
                    <Form.RadioGroup
                        value={state.details.form.pricingPeriod}
                        onValueChange={value => dispatch({ type: 'details', field: 'pricingPeriod', value })}
                    >
                        {[ 'monthly', 'weekly' ].map(value => (
                            <Form.RadioItem key={value} value={value} label={tt(`pricingPeriod-${value}-label`)} description={tt(`pricingPeriod-${value}-description`)} />
                        ))}
                    </Form.RadioGroup>
                </div>
            )}
            <div className='h-px w-full bg-secondary-100' />
            <FormSwitchWithIcon
                translationPrefix='discountedPrice-toggle'
                checked={state.details.form.discountedPrice !== undefined}
                onCheckedChange={value => dispatch({ type: 'details', field: 'discountedPrice', value: value ? '' : undefined })}
                icon={CirclePercentageIcon}
                type={state.type}
            />
            {state.details.form.discountedPrice !== undefined && (
                <div>
                    <Form.Input
                        label={<>{tt('discountedPrice-label')}<Form.RequiredIcon /></>}
                        placeholder={tt('discountedPrice-placeholder')}
                        isError={!!state.details.formErrors?.discountedPrice}
                        type='number'
                        value={state.details.form.discountedPrice}
                        onChange={e => dispatch({ type: 'details', field: 'discountedPrice', value: transformToPrice(e.target.value) })}
                        size='compact'
                    />
                    <TranslatedErrorMessage translationId={state.details.formErrors?.discountedPrice} />
                </div>
            )}
        </>)}
    </>);
}

type FormSwitchWithIconProps = Readonly<{
    translationPrefix: string;
    checked: boolean;
    onCheckedChange: (value: boolean) => void;
    icon: IconType;
    type: ProductType;
}>;

function FormSwitchWithIcon({ translationPrefix, checked, onCheckedChange, icon, type }: FormSwitchWithIconProps) {
    const tt = useTypedTranslation(type, 'components', 'productDetailsForm');
    const id = useId();
    const descriptionId = `${id}-description`;

    return (
        <div className='flex items-center gap-2'>
            {styledIcon(icon, type)}
            <div>
                <Form.Label htmlFor={id} className='leading-3'>{tt(`${translationPrefix}-label`)}</Form.Label>
                <Form.Description id={descriptionId} className='leading-4'>{tt(`${translationPrefix}-description`)}</Form.Description>
            </div>
            <div className='grow' />
            <Form.Switch
                id={id}
                aria-describedby={descriptionId}
                checked={checked}
                onCheckedChange={onCheckedChange}
            />
        </div>
    );
}

function sessionDetailsCard(state: ProductFormsState, dispatch: ProductFormsDispatch, t: TFunction) {
    // eslint-disable-next-line
    const locationSelectId = useId();
    // eslint-disable-next-line
    const schedulingUrlDescriptionId = useId();

    return defaultCard(state.type, t('session-details-card-title'), productStyles[state.type].icon, <>
        {state.type === ProductType.Bundle && (
            <div>
                <Form.Input
                    label={<>{t('sessions-label')}<Form.RequiredIcon /></>}
                    isError={!!state.details.formErrors?.sessions}
                    type='number'
                    value={state.details.form.sessions}
                    onChange={e => dispatch({ type: 'details', field: 'sessions', value: transformToPositiveIntegerOrEmpty(e.target.value) })}
                    size='compact'
                />
                <TranslatedErrorMessage translationId={state.details.formErrors?.sessions} />
            </div>
        )}
        <div>
            <Form.Input
                label={<>{t('duration-label')}<Form.RequiredIcon /><span className='text-secondary-400'>{t('duration-label-more')}</span></>}
                placeholder={t('duration-placeholder')}
                isError={!!state.details.formErrors?.duration}
                type='number'
                value={state.details.form.duration}
                onChange={e => dispatch({ type: 'details', field: 'duration', value: transformToPositiveIntegerOrEmpty(e.target.value) })}
                size='compact'
            />
            <TranslatedErrorMessage translationId={state.details.formErrors?.duration} />
        </div>
        <div>
            <Form.Label htmlFor={locationSelectId}>{t('location-label')}</Form.Label>
            <LocationSelect
                immutableProps={{ size: 'compact' }}
                id={locationSelectId}
                value={state.details.form.locationId}
                onChange={value => dispatch({ type: 'details', field: 'locationId', value })}
            />
        </div>
        <div>
            {buttonTextInput(state, dispatch, t)}
        </div>
        <div className='h-px w-full bg-secondary-100 mt-2 mb-1' />
        <h4 className='flex items-center gap-2'>
            {styledIcon(CalendarPhoneIcon, state.type)}
            <span className='text-base leading-3'>{t('scheduling-title')}</span>
        </h4>
        <div className='h-px w-full bg-secondary-100 mt-1' />

        <Form.RadioGroup
            value={state.details.form.scheduling}
            onValueChange={value => dispatch({ type: 'details', field: 'scheduling', value })}
        >
            <Form.RadioItem
                value='enabled'
                label={<>{t(`scheduling-enabled-label`)}<ProductComingSoonBadge type={state.type} className='ms-1' /></>}
                description={t(`scheduling-enabled-description`)}
                disabled
            />
            {[ 'disabled', 'custom' ].map(value => (
                <Form.RadioItem key={value} value={value} label={t(`scheduling-${value}-label`)} description={t(`scheduling-${value}-description`)} />
            ))}
        </Form.RadioGroup>
        {state.details.form.scheduling === 'custom' && (
            <div className='ps-7'>
                <Form.Input
                    label={<>{t('schedulingUrl-label')}<Form.RequiredIcon /></>}
                    hideLabel
                    placeholder={t('schedulingUrl-placeholder')}
                    isError={!!state.details.formErrors?.schedulingUrl}
                    aria-describedby={schedulingUrlDescriptionId}
                    value={state.details.form.schedulingUrl}
                    onChange={e => dispatch({ type: 'details', field: 'schedulingUrl', value: e.target.value })}
                    size='compact'
                />
                <TranslatedErrorMessage translationId={state.details.formErrors?.schedulingUrl} />
                <Form.Description
                    id={schedulingUrlDescriptionId}
                    className='mt-2 flex items-center gap-2 leading-5 text-secondary-400'
                >
                    <CircleInfoIcon size='xs' className='shrink-0' />{t('schedulingUrl-description')}
                </Form.Description>
            </div>
        )}
    </>);
}

export function ProductComingSoonBadge({ type, className }: { type: ProductType, className?: string }) {
    const { t } = useTranslation('components', { keyPrefix: 'productTypeTile' });
    const { color, bg } = productStyles[type];

    return (
        <span className={clsx(color, bg, 'py-1 px-2 w-fit rounded-full text-xs', className)}>{t('coming-soon-text')}</span>
    );
}

function digitalDetailsCard(state: ProductFormsState, dispatch: ProductFormsDispatch, t: TFunction) {
    return defaultCard(state.type, t('digital-details-card-title'), productStyles[state.type].icon, <>
        <div>
            {urlInput(state, dispatch, t, true)}
        </div>
        <div>
            {buttonTextInput(state, dispatch, t)}
        </div>
        {/* TODO backlogged
        <div>
            limited offer
        </div> */}
    </>);
}

function leadDetailsCard(state: ProductFormsState, dispatch: ProductFormsDispatch, t: TFunction) {
    return defaultCard(state.type, t('lead-details-card-title'), productStyles[state.type].icon, <>
        <div>
            {urlInput(state, dispatch, t)}
        </div>
        <div>
            {buttonTextInput(state, dispatch, t)}
        </div>
        {/* TODO backlogged <div>
            limited offer
        </div> */}
    </>);
}

function membershipDetailsCard(state: ProductFormsState, dispatch: ProductFormsDispatch, t: TFunction) {
    return defaultCard(state.type, t('membership-details-card-title'), productStyles[state.type].icon, <>
        <div>
            <Form.Input
                label={t('url-label')}
                placeholder={t('url-placeholder')}
                value={state.details.form.url}
                onChange={e => dispatch({ type: 'details', field: 'url', value: e.target.value })}
                size='compact'
            />
        </div>
        <div>
            {buttonTextInput(state, dispatch, t)}
        </div>
        {/* TODO backlogged <div>
            limited offer
        </div> */}
    </>);
}

function linkDetailsCard(state: ProductFormsState, dispatch: ProductFormsDispatch, t: TFunction) {
    return defaultCard(state.type, t('link-details-card-title'), productStyles[state.type].icon, <>
        <div>
            {urlInput(state, dispatch, t)}
        </div>
        <FormSwitchWithIcon
            translationPrefix='isShowUrl'
            checked={state.details.form.isShowUrl}
            onCheckedChange={value => dispatch({ type: 'details', field: 'isShowUrl', value })}
            icon={ButtonIcon}
            type={state.type}
        />
        <div>
            {buttonTextInput(state, dispatch, t)}
        </div>
        <FormSwitchWithIcon
            translationPrefix='isShowButton'
            checked={state.details.form.isShowButton}
            onCheckedChange={value => dispatch({ type: 'details', field: 'isShowButton', value })}
            icon={WindowLinkIcon}
            type={state.type}
        />
    </>);
}

function customDetailsCard(state: ProductFormsState, dispatch: ProductFormsDispatch, t: TFunction) {
    return defaultCard(state.type, t('custom-details-card-title'), productStyles[state.type].icon, <>
        <div>
            {buttonTextInput(state, dispatch, t)}
        </div>
        {/* TODO backlogged <div>
            limited offer
        </div> */}
    </>);
}

function buttonTextInput(state: ProductFormsState, dispatch: ProductFormsDispatch, t: TFunction) {
    return (<>
        <Form.Input
            label={<>{t('buttonText-label')}<Form.RequiredIcon /></>}
            placeholder={t('buttonText-placeholder')}
            isError={!!state.details.formErrors?.buttonText}
            value={state.details.form.buttonText}
            onChange={e => dispatch({ type: 'details', field: 'buttonText', value: e.target.value })}
            size='compact'
        />
        <TranslatedErrorMessage translationId={state.details.formErrors?.buttonText} />
    </>);
}

function urlInput(state: ProductFormsState, dispatch: ProductFormsDispatch, t: TFunction, isRequired?: boolean) {
    return (<>
        <Form.Input
            label={<>{t('url-label')}{isRequired && <Form.RequiredIcon />}</>}
            placeholder={t('url-placeholder')}
            value={state.details.form.url}
            onChange={e => dispatch({ type: 'details', field: 'url', value: e.target.value })}
            size='compact'
        />
        {isRequired && <TranslatedErrorMessage translationId={state.details.formErrors?.url} />}
    </>);
}

function SuccessCard({ state, dispatch }: StateDispatchProps) {
    const tt = useTypedTranslation(state.type, 'components', 'productDetailsForm');
    const successMessageDescriptionId = useId();
    const { appUser: { firstName } } = useMaster();

    return defaultCard(state.type, tt('success-card-title'), MsgSmile2Icon, <>
        <div className='-mt-2'>
            <Form.Textarea
                label={tt('successMessage-label')}
                hideLabel
                placeholder={tt('successMessage-placeholder', { firstName })}
                isError={!!state.details.formErrors?.successMessage}
                aria-describedby={successMessageDescriptionId}
                value={state.details.form.successMessage}
                onChange={e => dispatch({ type: 'details', field: 'successMessage', value: e.target.value })}
                minRows={2}
            />
            <TranslatedErrorMessage translationId={state.details.formErrors?.successMessage} />
            <Form.Description
                id={successMessageDescriptionId}
                className='mt-2 flex items-center gap-2 leading-5 text-secondary-400'
            >
                <CircleInfoIcon size='xs' className='shrink-0' />{tt('successMessage-description')}
            </Form.Description>
        </div>
    </>);
}

export function ProductPublishForm({ state, dispatch }: StateDispatchProps) {
    const { t } = useTranslation('components', { keyPrefix: 'productPublishForm' });
    // No need to create a typed TFunction synce the translations are the same for all types.

    return (<>
        {visibilityCard(state, dispatch, t)}
        <SlugCard state={state} dispatch={dispatch} />
        {collectInfoCard(state, dispatch, t)}
    </>);
}

function visibilityCard(state: ProductFormsState, dispatch: ProductFormsDispatch, t: TFunction) {
    return defaultCard(state.type, t('visibility-card-title'), Eye2Icon, <>
        <Form.RadioGroup
            value={state.publish.form.visibility}
            onValueChange={value => dispatch({ type: 'publish', field: 'visibility', value })}
        >
            {[ 'public', 'private' ].map(value => (
                <Form.RadioItem key={value} value={value} label={t(`visibility-${value}-label`)} description={t(`visibility-${value}-description`)} />
            ))}
        </Form.RadioGroup>
    </>);
}

function SlugCard({ state, dispatch }: StateDispatchProps) {
    const { t } = useTranslation('components', { keyPrefix: 'productPublishForm' });
    const store = trpc.store.getStore.useQuery();
    const descriptionId = useId();
    const displayUrlPrefix = store.data?.slug && createDisplayUrl(routesStore.root.absoluteResolve({ store: store.data?.slug }));
    const finalSlug = createSlug(state.publish.form.slug);

    return defaultCard(state.type, t('slug-card-title'), Link5Icon, <>
        <div className='flex gap-1'>
            <div className='grow'>
                <Form.Input
                    label={<>{t('slug-label')}<Form.RequiredIcon /></>}
                    placeholder={t('slug-placeholder', { suggested: state.publish.suggestedSlug })}
                    isError={!!state.publish.formErrors?.slug}
                    aria-describedby={descriptionId}
                    value={state.publish.form.slug}
                    onChange={e => dispatch({ type: 'publish', field: 'slug', value: e.target.value })}
                    size='compact'
                    disabled={!state.isCustomDomainAllowed}
                />
                <TranslatedErrorMessage translationId={state.publish.formErrors?.slug} />
            </div>
            {!state.isCustomDomainAllowed && (
                <Button variant='secondary' size='small'>
                    <LockIcon />{t('upgrade-to-edit-button')}
                </Button>
            )}
        </div>
        <Form.Description id={descriptionId}>
            <span className='text-secondary-400'>{displayUrlPrefix}/</span>
            <span>{finalSlug}</span>
        </Form.Description>
    </>);
}

function collectInfoCard(state: ProductFormsState, dispatch: ProductFormsDispatch, t: TFunction) {
    return defaultCard(state.type, t('collect-info-card-title'), InputPasswordEditIcon, <>
        <p className='text-secondary-400'>{t('collect-info-coming-soon')}</p>
        <div className='flex items-center'>
            <Button variant='outline' size='small' className='w-fit' disabled>
                <PlusIcon />{t('add-field-button')}
            </Button>
            <ProductComingSoonBadge type={state.type} className='ms-2' />
        </div>
    </>);
}

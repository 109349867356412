import { type ReactNode, useCallback, useMemo, useState } from 'react';
import { Button, Form, Select, type SingleValue } from ':components/shadcn';
import type { UseProductOrderDispatch, UseProductOrderState } from './useProductOrder';
import { useTranslation } from 'react-i18next';
import { type ClientInfoFE } from ':frontend/types/Client';
import { between, transformToPrice } from ':utils/math';
import { TranslatedErrorMessage } from '../forms/ErrorMessage';
import { SingleContinuousParticipantSelect } from '../client/ContinuousParticipantSelect';
import { type FormDiscount } from './CustomOrderForm';
import { TeamMemberSelect } from '../team/TeamMemberSelect';
import { useMaster } from ':frontend/context/UserProvider';
import { TeamMemberRole } from ':utils/entity/team';
import type { ProductOutput } from ':utils/entity/product';
import { CircleMinusIcon, CirclePlusIcon, DiscountIcon, InvoiceDollarIcon } from ':components/icons/basic';
import { ProductInvoiceItemDisplay, ProductSelectOption } from ':components/store/product/ProductCard';

type ProductOrderFormProps = Readonly<{
    products: ProductOutput[];
    clients: ClientInfoFE[];
    state: UseProductOrderState;
    dispatch: UseProductOrderDispatch;
}>;

export function ProductOrderForm({ products, clients, state, dispatch }: ProductOrderFormProps) {
    const { t } = useTranslation('components', { keyPrefix: 'productOrderForm' });
    const items = state.form.items;
    const { teamSettings, role } = useMaster();

    const isTeamMaster = role === TeamMemberRole.master;

    return (
        <div className='flex flex-col gap-3'>

            <div>
                <Form.Label className='font-semibold mb-2'>{t('guest-label')}</Form.Label>
                <SingleContinuousParticipantSelect
                    isCreatable
                    clients={clients}
                    value={state.form.guest}
                    onChange={value => dispatch({ type: 'input', field: 'guest', value })}
                />
                <TranslatedErrorMessage translationId={state.formErrors?.['guest']} />
            </div>
            {teamSettings.isInvoicingEnabled && (
                state.form.isLinked ? (
                    <Button variant='outline' size='small' className='mt-1 w-full' onClick={() => dispatch({ type: 'input', field: 'isLinked', value: false })}>
                        <InvoiceDollarIcon />{t('unlink-button')}
                    </Button>
                ) : (<>
                    <div className='pt-3'>
                        <Form.Label className='font-semibold mb-2'>{t('client-label')}</Form.Label>
                        <SingleContinuousParticipantSelect
                            isCreatable
                            clients={clients}
                            value={state.form.client}
                            onChange={value => dispatch({ type: 'input', field: 'client', value })}
                        />
                        <TranslatedErrorMessage translationId={state.formErrors?.['client']} />
                    </div>
                </>)
            )}

            {isTeamMaster && (<>
                <div className='pt-3'>
                    <Form.Label className='font-semibold mb-2'>{t('scheduler-label')}</Form.Label>
                    <TeamMemberSelect
                        value={state.form.scheduler}
                        onChange={value => dispatch({ type: 'input', field: 'scheduler', value })}
                    />
                    <TranslatedErrorMessage translationId={state.formErrors?.['scheduler']} />
                </div>
            </>)}

            <Form.Label className='font-semibold mt-3 -mb-1'>{t('items-label')}</Form.Label>
            {items.map((item, index) => (
                <ProductInvoiceItemDisplay
                    key={index}
                    product={item.product}
                    onRemove={items.length > 1
                        ? () => dispatch({ type: 'form', operation: 'remove', index })
                        : undefined
                    }
                />
            ))}

            <div className='mt-1'>
                <ProductSelect products={products} dispatch={dispatch} />
            </div>

            <DiscountInput state={state} dispatch={dispatch} />
        </div>
    );
}

type ProductSelectProps = Readonly<{
    products: ProductOutput[];
    dispatch: UseProductOrderDispatch;
}>;

function ProductSelect({ products, dispatch }: ProductSelectProps) {
    const { t } = useTranslation('components', { keyPrefix: 'productOrderForm' });
    const [ isShow, setIsShow ] = useState(false);
    const productOptions = useMemo(() => products.map(productToOption), [ products ]);
    const selectProduct = useCallback((option: SingleValue<ProductOption>) => {
        if (!option)
            return;

        dispatch({ type: 'form', operation: 'newItem', product: option.value });
        setIsShow(false);
    }, [ dispatch ]);

    if (!isShow) {
        return (
            <Button variant='outline' size='small' className='w-full' onClick={() => setIsShow(true)}>
                <CirclePlusIcon />{t('add-product-button')}
            </Button>
        );
    }

    return (
        <Select
            immutableProps={{ size: 'compact', CustomOption: ProductSelectOption }}
            placeholder={t('add-product-placeholder')}
            options={productOptions}
            value={null as SingleValue<ProductOption>}
            onChange={selectProduct}
            menuIsOpen
            autoFocus
            onBlur={() => setIsShow(false)}
        />
    );
}

type ProductOption = {
    value: ProductOutput;
    label: ReactNode;
};

function productToOption(product: ProductOutput): ProductOption {
    return {
        value: product,
        label: product.title,
    };
}

type DiscountInputProps = Readonly<{
    state: UseProductOrderState;
    dispatch: UseProductOrderDispatch;
}>;

function DiscountInput({ state, dispatch }: DiscountInputProps) {
    const { t } = useTranslation('components', { keyPrefix: 'productOrderForm' });
    const discount = state.form.discount;

    function addDiscount() {
        const defaultDiscount: FormDiscount = {
            amountInPercent: 10,
            label: t('discount-label-default'),
        };
        dispatch({ type: 'input', field: 'discount', value: defaultDiscount });
    }

    function handleChange(value: string) {
        const transformed = transformToPrice(value);
        if (transformed === '') {
            dispatch({ type: 'input', field: 'discount.amountInPercent', value: '' });
            return;
        }
        const bounded = between(transformed, 0, 100);
        dispatch({ type: 'input', field: 'discount.amountInPercent', value: bounded });
    }

    if (!discount) {
        return (
            <Button variant='secondary' size='small' className='w-full' onClick={() => addDiscount()}>
                <DiscountIcon />{t('add-discount')}
            </Button>
        );
    }

    return (
        <div className='flex gap-2 items-center'>
            <div className='relative shrink-0' style={{ width: '100px' }}>
                <Form.Input
                    size='compact'
                    className='bg-primary-50'
                    type='number'
                    value={discount.amountInPercent}
                    onChange={e => handleChange(e.target.value)}
                    autoFocus
                />
                <span className='absolute right-2 top-[10px]'>%</span>
            </div>
            <Form.Input
                size='compact'
                className='bg-primary-50'
                value={discount.label}
                onChange={e => dispatch({ type: 'input', field: 'discount.label', value: e.target.value })}
                autoFocus
            />
            <Button
                variant='transparent'
                size='tiny'
                onClick={() => dispatch({ type: 'input', field: 'discount', value: undefined })}
                aria-label={t('remove-discount-button')}
            >
                <CircleMinusIcon />
            </Button>
        </div>
    );
}

import { useEffect, useMemo, useState } from 'react';
import { Card, Modal, Form } from ':components/shadcn';
import { Controller, useFieldArray, useForm, type FieldErrors, type UseFormRegister } from 'react-hook-form';
import { SpinnerButton } from '../common';
import { ErrorMessage, RHFErrorMessage } from ':frontend/components/forms/ErrorMessage';
import { useTranslation } from 'react-i18next';
import { useNestedForm } from ':frontend/utils/forms';
import { type BankAccountFE } from ':frontend/types/BankAccount';
import { getAllCurrencies } from ':utils/money';
import { useMaster } from ':frontend/context/UserProvider';
import { getCountrySpecification, getInputFieldsFromAccountData, isCountrySupported, processInputData, supportedCountries, validateIban, validateSwift, type SupportedCountry, type BankAccountNumberPart } from ':utils/entity/bankAccountData';
import { TabRadio } from '../forms/Radio';
import { ControlledCountrySelect } from '../forms';
import { useToggle } from ':frontend/hooks';
import { type Id } from ':utils/id';
import type { CountryCode } from ':utils/i18n';
import type { BankAccountUpsert } from ':utils/entity/money';
import { InfoTooltip } from '../forms/buttons';
import { BankStatementIcon } from ':components/icons/basic';

/*
 * react-hook-form doesn't support flat arrays (just string[])
 * @see https://github.com/orgs/react-hook-form/discussions/7770#discussioncomment-2126583
 */
type ArrayStringValue = {
    value: string;
};

type BankingFormData = {
    country: SupportedCountry | 'iban';
    numberParts: ArrayStringValue[];
    currencyIds: Id[];
    iban: string;
    swift: string;
};

type BankingFormProps = Readonly<{
    input?: BankAccountFE;
    defaultCurrencyIds?: Id[];
    onSubmit: (output: BankAccountUpsert) => void;
    isFetching: boolean;    
    isAddForm?: boolean;
}>;

export default function BankingForm({ input, defaultCurrencyIds, onSubmit, isFetching, isAddForm = false }: BankingFormProps) {
    const { t } = useTranslation('components', { keyPrefix: 'bankingForm' });
    const { teamSettings, bankAccounts } = useMaster();

    const inputData = useMemo(() => inputToForm(input, teamSettings.country, defaultCurrencyIds), [ input, teamSettings.country, defaultCurrencyIds ]);
    const { register, handleSubmit, control, reset, watch, setValue, formState: { errors, isValid, isDirty } } = useForm<BankingFormData>({
        defaultValues: inputData,
    });

    const handleNestedSubmit = useNestedForm(handleSubmit);
    const [ waitingForUpdate, setWaitingForUpdate ] = useState(false);

    const { fields } = useFieldArray({
        control,
        name: 'numberParts',
    });

    useEffect(() => {
        if (!waitingForUpdate)
            return;

        setWaitingForUpdate(false);
        reset(inputToForm(input, teamSettings.country));
    }, [ input ]);

    const [ isError, setError ] = useToggle(false);

    function onValid(data: BankingFormData) {
        setWaitingForUpdate(true);
        let newAccountData;
        try {
            newAccountData = processInputData(data.country, arrayValuesToStrings(data.numberParts), data.iban, data.swift);
        }
        catch {
            setError.true();
            return;
        }

        setError.false();
        onSubmit({ raw: newAccountData, currencies: data.currencyIds });
    }

    const availableCurrencies = useMemo(() => {
        const alreadyTaken = bankAccounts.filter(account => account.id !== input?.id).flatMap(account => account.currencies);
        return getAllCurrencies().filter(currency => !alreadyTaken.find(c => c.id === currency.id));
    }, [ input, bankAccounts ]);

    const country = watch('country');
    const [ lastNonIbanCountry, setLastNonIbanCountry ] = useState<SupportedCountry>(getInitialNonIbanCountry(country, teamSettings.country));

    useEffect(() => {
        if (country !== 'iban')
            setLastNonIbanCountry(country);
    }, [ country, setValue ]);

    const tabOptions = useMemo(() => [
        { value: 'accountNumber', label: t('account-number'), aria: t('account-number') },
        { value: 'iban', label: t('iban'), aria: t('iban') },
    ], [ t ]);

    function changeTab(value: string) {
        setValue('country', value === 'iban' ? 'iban' : lastNonIbanCountry);
        setError.false();
    }

    const countrySpecification = useMemo(() => getCountrySpecification(country), [ country ]);

    function changeCountry(country?: CountryCode) {
        if (!country || !isCountrySupported(country))
            return;

        const info = getCountrySpecification(country);
        setValue('numberParts', createEmptyInputs(info.parts.length));
        setError.false();
    }

    return (
        <Form.Root onSubmit={handleNestedSubmit(onValid)} className='space-y-6'>
            <Modal.Header>
                <Modal.Title className='flex items-center gap-2'>
                    <BankStatementIcon className='h-5 text-danger-500' />
                    <span>{isAddForm ? t('add-new-title') : t('edit-title')}</span>        
                </Modal.Title>
            </Modal.Header>

            <Card.Divider className='my-0' />
            
            <TabRadio
                value={country === 'iban' ? 'iban' : 'accountNumber'}
                onChange={changeTab}
                options={tabOptions}
            />

            {country !== 'iban' && (<>
                <div>
                    <Form.Label className='flex items-center gap-1'>
                        {t('bank-account-country')}
                        <InfoTooltip text={t('bank-account-country-tooltip')} />
                    </Form.Label>

                    <ControlledCountrySelect
                        control={control}
                        name='country'
                        filterCountries={supportedCountries}
                        onChange={changeCountry}
                    />
                </div>

                <div className='flex gap-2'>
                    {fields.map((field, index) => (
                        <div className={fields.length === 1 ? 'w-full' : ''} key={field.id}>
                            <NumberPartInput part={countrySpecification.parts[index]} index={index} register={register} errors={errors} />
                        </div>
                    ))}
                </div>
            </>)}

            {country === 'iban' && (
                <>
                    <div>
                        <Form.Input
                            label={t('iban-input')}
                            key='iban'
                            {...register('iban', {
                                required: t('error.iban-not-entered'),
                                validate: input => validateIban(input) || t('error.iban-invalid'),
                            })}
                        />
                        <RHFErrorMessage errors={errors} name={'iban'} />
                    </div>

                    <div>
                        <Form.Input
                            label={t('swift-input')}
                            key='swift'
                            {...register('swift', {
                                required: t('error.swift-not-entered'),
                                validate: input => validateSwift(input) || t('error.swift-invalid'),
                            })}
                        />
                        <RHFErrorMessage errors={errors} name={'swift'} />
                    </div>
                </>
            )}

            <div>
                <Form.Label>{t('currencies-label')}</Form.Label>

                <Controller
                    control={control}
                    name='currencyIds'
                    render={({ field: { value, onChange } }) => (
                        <div className='flex gap-x-4 gap-y-2 flex-wrap'>
                            {getAllCurrencies().map(currency => (
                                <div key={currency.id.toString()}>
                                    <Form.Switch
                                        label={currency.label}
                                        disabled={!availableCurrencies.some(c => c.id === currency.id)}
                                        checked={value.some(c => c === currency.id)}
                                        onCheckedChange={checked => {
                                            if (checked)
                                                onChange([ ...value, currency.id ]);
                                            else
                                                onChange(value.filter(c => c !== currency.id));
                                        }}
                                    />
                                </div>
                            ))}
                        </div>
                    )}
                />
            </div>            

            {isError && (
                <div>
                    <ErrorMessage message={t('error-message')} />
                </div>
            )}

            <div>
                <SpinnerButton
                    className='w-full'
                    type='submit'
                    isFetching={isFetching}
                    disabled={!isValid || !isDirty}
                >
                    {t('save-button')}
                </SpinnerButton>            
            </div>
        </Form.Root>
    );
}

type NumberPartInputProps = Readonly<{
    part: BankAccountNumberPart;
    index: number;
    register: UseFormRegister<BankingFormData>;
    errors: FieldErrors<BankingFormData>;
}>;

function NumberPartInput({ part, index, register, errors }: NumberPartInputProps) {
    const { t } = useTranslation('components', { keyPrefix: 'bankingForm' });
    // Fallback to generic label if country-specific label is not found. E.g., for 'branch-code' and 'CZ', we try (in this order):
    //  - part-label.CZ.branch-code
    //  - part-label.branch-code
    const labelIds = [ `part-label.${part.country}.${part.id}`, `part-label.${part.id}` ];
    // Fallback to generic label (similarly as above). However, if no label is found, we don't show any placeholder (see the default placeholder below).
    const placeholderIds = [ `part-placeholder.${part.country}.${part.id}`, `part-placeholder.${part.id}` ];

    return (<>
        <Form.Input
            label={t(labelIds) + (part.required ? '*' : '')}
            placeholder={t(placeholderIds, DEFAULT_PLACEHOLDER)}
            {...register(`numberParts.${index}.value`, {
                required: {
                    value: part.required,
                    message: t('error.required-not-entered'),
                },
                maxLength: part.bbanPart ? {
                    value: part.bbanPart!.getLength(),
                    message: t('error.value-too-long'),
                } : undefined,
            })}
        />
        <RHFErrorMessage errors={errors} name={`numberParts.${index}.value`} />
    </>);
}

const DEFAULT_PLACEHOLDER = '';

function createEmptyInputs(count: number): ArrayStringValue[] {
    return [ ...Array(count) ].map(() => ({ value: '' }));
}

function stringsToArrayValues(strings: string[]): ArrayStringValue[] {
    return strings.map(str => ({ value: str }));
}

export function arrayValuesToStrings(values: ArrayStringValue[]): string[] {
    return values.map(val => val.value);
}

export function inputToForm(input?: BankAccountFE, userCountry?: string, defaultCurrencyIds?: Id[]): BankingFormData {
    if (!input) {
        const defaultCountry = userCountry ? getDefaultCountry(userCountry) : 'iban';
        const countryInfo = getCountrySpecification(defaultCountry);

        return {
            country: defaultCountry,
            numberParts: createEmptyInputs(countryInfo.parts.length),
            currencyIds: defaultCurrencyIds ?? [],
            iban: '',
            swift: '',
        };
    }

    if (input.raw.country === 'iban') {
        return {
            country: 'iban',
            numberParts: [],
            currencyIds: input?.currencies.map(currency => currency.id),
            iban: input.raw.parts.iban,
            swift: input.raw.parts.swift,
        };
    }

    return {
        country: input.raw.country,
        numberParts: stringsToArrayValues(getInputFieldsFromAccountData(input.raw)),
        currencyIds: input.currencies.map(currency => currency.id),
        iban: '',
        swift: '',
    };
}

function getDefaultCountry(userCountry: string): SupportedCountry | 'iban' {
    return isCountrySupported(userCountry) ? userCountry : 'iban';
}

function getInitialNonIbanCountry(country: SupportedCountry | 'iban', settingsCountry: CountryCode): SupportedCountry {
    if (country !== 'iban')
        return country;

    const defaultCountry = getDefaultCountry(settingsCountry);
    if (defaultCountry !== 'iban')
        return defaultCountry;

    return supportedCountries[0];
}

import { type ReactElement, type ReactNode } from 'react';
import { useLocalStorageState } from ':frontend/utils/localStorage';
import { Button } from ':components/shadcn';
import { Trans, useTranslation } from 'react-i18next';
import { CloseButton } from '../forms/buttons';
import { DoubleArrowIcon, BankIcon, CalendarCheckIcon, CalendarClockIcon, ChainIcon, CircleSlashIcon, DiagonalArrowsIcon, EyeIcon, FlowlanceLogo, InvoiceIcon, RepeatIcon, SmileIcon, UsersIcon, WalletIcon, FaCirclePlay, AiOutlineSafety, IoWarningOutline } from ':components/icons/old';
import clsx from 'clsx';
import StripeSLogo from ':components/icons/StripeSLogo';
import { routesFE } from ':utils/routes';
import { Link } from 'react-router-dom';

const INFO_CARDS_KEY = 'info-cards';

type InfoKey = 'orders' | 'clients' | 'bankAccounts' | 'bankIntegration' | 'backpay' | 'stripeIntegration' | 'invoicingProfiles';

type InfoCardProps = Readonly<{
    infoKey: InfoKey;
    className?: string;
    primaryButton?: ReactNode;
    extraContent?: ReactNode;
}>;

export function InfoCard({ infoKey, className, primaryButton, extraContent }: InfoCardProps) {
    const { t } = useTranslation('common', { keyPrefix: `infoCards.${infoKey}` });
    const { t: td } = useTranslation('common', { keyPrefix: 'infoCards.default' });
    const info = infos[infoKey];

    const secondary: LinkProps | undefined = info.readMoreLink === undefined
        ? undefined
        : {
            label: t('secondary-button-label', { defaultValue: false }) || td('secondary-button-label'),
            href: info.readMoreLink,
        };

    return (
        <InfoCardCloseable
            infoKey={infoKey}
            title={t('title')}
            description={<Trans components={transComponents}>{t('description', { defaultValue: false })}</Trans>}
            icon={info.icon}
            mediaContent={info.mediaContent}
            extraContent={extraContent}
            primary={primaryButton}
            secondary={secondary}
            closeable={!info.notCloseable}
            className={className}
        />
    );
}

const transComponents: Record<string, ReactElement> = {
    s: <span />,
    pr: <span className='text-primary' />,
    ol: <ol className='mb-0' />,
    ul: <ul className='mb-0' />,
    li: <li />,
    callLink: <a href='https://app.lemcal.com/@patrikkruntorad' target='_blank' rel='noreferrer' />,
    dashboardLink: <Link to={routesFE.dashboard} />,
    backpayLink: <Link to={routesFE.orders.newBackpay} />,
    customOrderLink: <Link to={routesFE.orders.newCustom} />,
};

type InfoCardCloseableProps = Omit<InfoCardCommonProps, 'onClose'> & {
    infoKey: InfoKey;
};

type InfoCardLocalStorageState = {
    closed: boolean;
};

function InfoCardCloseable({ infoKey, ...commonProps }: InfoCardCloseableProps) {
    const { cardState, close } = useInfoCardState(infoKey);

    if (cardState.closed)
        return null;

    return (
        <InfoCardCommon {...commonProps} onClose={close} />
    );
}

export function useInfoCardState(infoKey: InfoKey) {
    const [ infoCardsState, setInfoCardsState ] = useLocalStorageState<Record<string, InfoCardLocalStorageState>>(INFO_CARDS_KEY);
    const currentState = infoCardsState?.[infoKey] ?? { closed: false };
    if (infos[infoKey].notCloseable)
        currentState.closed = false;

    function open() {
        setInfoCardsState({ ...infoCardsState, [infoKey]: { ...currentState, closed: false } });
    }

    function close() {
        setInfoCardsState({ ...infoCardsState, [infoKey]: { ...currentState, closed: true } });
    }

    return {
        infoCardsState,
        setInfoCardsState,
        cardState: currentState,
        open,
        close,
    };
}

type LinkProps = Readonly<{
    href: string;
    label: ReactNode;
}>;

type InfoCardCommonProps = Readonly<{
    title: string;
    description?: ReactNode;
    icon?: ReactNode;
    mediaContent?: ReactNode;
    extraContent?: ReactNode;
    primary?: ReactNode;
    secondary?: LinkProps;
    onClose: () => void;
    closeable?: boolean;
    className?: string;
}>;

function InfoCardCommon({ title, description, icon, mediaContent, extraContent, onClose, primary, secondary, closeable = true, className }: InfoCardCommonProps) {
    return (
        <div className={clsx('flex justify-center', className)}>
            <div className='fl-info-card flex gap-4 pt-4 pb-4 pl-8 pr-[3px] border-0 w-full' style={{ maxWidth: '800px' }}>
                <div className='flex flex-col grow pl-1 pb-4'>
                    {icon && (
                        <div className='mb-4'>
                            {icon}
                        </div>
                    )}
                    <h2 className='mt-0 mb-2'>{title}</h2>
                    <div className='grow fl-description-no-border mb-0'>{description}</div>
                    {extraContent}
                    {(primary || secondary) && (
                        <div className='flex gap-2 mt-8'>
                            {primary}
                            {secondary && (
                                <a href={secondary.href} target='_blank' rel='noreferrer'>
                                    <Button size='tiny' className='fl-shadow-sm-dark' variant='ghost'>{secondary.label}</Button>
                                </a>
                            )}
                        </div>
                    )}
                </div>
                {mediaContent && (
                    <div className='shrink-0 py-4 flex items-center'>
                        {mediaContent}
                    </div>
                )}
                {closeable ? (
                    <div>
                        <CloseButton aria={`Close ${title}`} onClick={onClose} />
                    </div>
                ) : (
                    <div className='shrink-0' style={{ width: '40px' }} />
                )}
            </div>
        </div>
    );
}

type Info = {
    icon: ReactNode;
    mediaContent?: ReactNode;
    readMoreLink?: string;
    notCloseable?: boolean;
}

export const infos: Record<InfoKey, Info> = {
    orders: {
        icon: roundedIcon(<InvoiceIcon size={16} />),
        mediaContent: (
            <VideoThumbnail
                href='https://www.youtube.com/watch?v=k0ySZEAoSsE'
                img='/static/images/info-cards/video-thumbnail.png'
                duration='0:59'
                text={'Learn how\nto create\ninvoices\nin seconds'}
            />
        ),
        // readMoreLink: BLOG_URL_PREFIX + 'how-to-set-up-payment',  // not up-to-date as of May 29, 2024
    },
    clients: {
        icon: doubleIcon(roundedIcon(<UsersIcon size={18} />), roundedIcon(<FlowlanceLogo size={24} />, 'bg-white')),
        mediaContent: <ClientsMediaContent />,
    },
    bankAccounts: {
        icon: roundedIcon(<BankIcon size={18} />),
        mediaContent: (
            <VideoThumbnail
                href='https://www.youtube.com/watch?v=e1gSbGwShqA'
                img='/static/images/info-cards/video-thumbnail.png'
                duration='1:16'
                text={'Set up your\npayments\nand start\nmaking money'}
            />
        ),
    },
    bankIntegration: {
        icon: doubleIcon(roundedIcon(<FlowlanceLogo size={24} />, 'bg-white'), roundedIcon(<BankIcon size={18} />)),
        mediaContent: <BankIntegrationMediaContent />,
        readMoreLink: 'https://flowlance.notion.site/Integrace-s-bankami-eaed6736122b46ac815cfad64bb6d8da',
        notCloseable: true,
    },
    backpay: {
        icon: roundedIcon(<CalendarCheckIcon size={18} />),
        mediaContent: (
            <VideoThumbnail
                href='https://www.youtube.com/watch?v=vCEO53U6beM'
                img='/static/images/info-cards/video-thumbnail.png'
                duration='0:36'
                text={'Get paid for\nall your\nclients\nat once'}
            />
        ),
    },
    stripeIntegration: {
        icon: doubleIcon(roundedIcon(<FlowlanceLogo size={24} />, 'bg-white'), <StripeSLogo />),
        mediaContent: <StripeMediaContent />,
        readMoreLink: 'https://stripe.com',
        notCloseable: true,
    },
    invoicingProfiles: {
        icon: roundedIcon(<DiagonalArrowsIcon size={22} />),
        mediaContent: (
            <VideoThumbnail
                href='https://www.youtube.com/watch?v=kFdVC4LGlas'
                img='/static/images/info-cards/video-thumbnail.png'
                duration='0:29'
                text={'Set up your\ncompany\'s\nbilling info'}
            />
        ),
    },
};

function roundedIcon(icon: ReactNode, className?: string): ReactNode {
    return (
        <div
            className={clsx('rounded-full bg-primary text-white flex items-center justify-center fl-shadow-sm-dark', className)}
            style={{ width: '36px', height: '36px', lineHeight: '36px' }}
        >
            {icon}
        </div>
    );
}

function doubleIcon(icon1: ReactNode, icon2: ReactNode): ReactNode {
    return (
        <div className='flex items-center gap-2'>
            {icon1}
            <DoubleArrowIcon size={17} />
            {icon2}
        </div>
    );
}

type VideoThumbnailCommonProps = Readonly<{
    /** eg. {'Invoices from\nevents: Learn the\npower of\nFlowlance'} */
    text: string;
    /** eg. 'https://www.youtube.com/watch?v=dQw4w9WgXcQ' */
    href: string;
    /**
     * eg. '/images/info-cards/video-thumbnail-backpay.png'
     * Store in the /packages/static/ directory. The img path must start with /static/
     */
    img: string;
    /** eg. '1:34' */
    duration: string;
}>;

function VideoThumbnail({ text, href, img, duration }: VideoThumbnailCommonProps) {
    const { t } = useTranslation('common', { keyPrefix: 'infoCards.default' });

    return (
        <a href={href} target='_blank' rel='noreferrer' className='select-none drag-none no-underline'>
            <div className='fl-video-thumbnail relative'>
                <span className='text-white no-underline whitespace-pre-wrap w-2/4 pl-2'>
                    {text}
                </span>
                <div className='w-2/4 px-2'>
                    <img src={img} className='rounded-lg w-full' alt={text} />
                </div>

                <div className='flex items-center justify-center absolute w-full h-full'>
                    <div className='flex items-center bg-primary rounded-full p-1 text-white'>
                        <FaCirclePlay size={26} />
                        <span className='text-center px-2'>{t('video-thumbnail-watch')}</span>
                    </div>
                </div>

                <div className='absolute bottom-0 right-0 pr-1 pb-2'>
                    <span className='bg-black/50 bg-black rounded-md py-1 px-2 text-white'>
                        {duration}
                    </span>
                </div>
            </div>
        </a>
    );
}

function ClientsMediaContent() {
    const { t } = useTranslation('common', { keyPrefix: 'infoCards.clients' });
    return (
        <div className='flex flex-col gap-4'>
            <div className='flex items-center'><EyeIcon size={16} className='text-primary mr-4' />{t('media-eye')}</div>
            <div className='flex items-center'><InvoiceIcon size={16} className='text-primary mr-4' />{t('media-invoice')}</div>
            <div className='flex items-center'><CircleSlashIcon size={16} className='text-primary mr-4' />{t('media-slash')}</div>
        </div>
    );
}

function StripeMediaContent() {
    const { t } = useTranslation('common', { keyPrefix: 'infoCards.stripeIntegration' });
    return (
        <div className='flex flex-col gap-4'>
            <div className='flex items-center'><CalendarClockIcon size={16} className='text-primary mr-4' />{t('media-clock')}</div>
            <div className='flex items-center'><ChainIcon size={16} className='text-primary mr-4' />{t('media-chain')}</div>
            <div className='flex items-center'><WalletIcon size={16} className='text-primary mr-4' />{t('media-wallet')}</div>
            <div className='flex items-center'><SmileIcon size={16} className='text-primary mr-4' />{t('media-smile')}</div>
        </div>
    );
}

function BankIntegrationMediaContent() {
    const { t } = useTranslation('common', { keyPrefix: 'infoCards.bankIntegration' });
    return (
        <div className='flex flex-col gap-4'>
            <div className='flex items-center'><RepeatIcon size={16} className='text-primary mr-4' />{t('media-up-to-date')}</div>
            <div className='flex items-center'><ChainIcon size={16} className='text-primary mr-4' />{t('media-automatic')}</div>
            <div className='flex items-center'><AiOutlineSafety size={16} className='text-primary mr-4' />{t('media-safety')}</div>
            <div className='flex items-center'><IoWarningOutline size={16} className='text-primary mr-4' />{t('media-experimental')}</div>
        </div>
    );
}

import { useCallback, useEffect, useRef, type ReactNode, type UIEvent } from 'react';
import { useToggle } from ':frontend/hooks';
import clsx from 'clsx';
import { Link, matchPath, useLocation } from 'react-router-dom';
import { FlowlanceLogo } from ':components/icons/old';
import { useTranslation } from 'react-i18next';
import { toMaster, useUser } from ':frontend/context/UserProvider';
import { cn } from ':components/shadcn/utils';
import { routesFE } from ':utils/routes';
import useAuth from ':frontend/context/AuthProvider';
import { useSubscriptionUpsert } from ':frontend/pages/Subscriptions';
import { TeamMemberRole } from ':utils/entity/team';
import { SubscriptionTierCode, SubscriptionTierPaymentPeriod } from ':utils/entity/subscription';
import { Button } from ':components/shadcn';
import { SpinnerButton } from './common';
import type { IconType } from ':components/icons/common';
import { Box2Icon, CalendarIcon, Palette1Icon, SidebarLeft2ShowIcon, SidebarLeft3HideIcon, Sliders3Icon, Tag3Icon, Users1Icon, ViewAll1Icon, WalletContentIcon } from ':components/icons/basic';
import { signal } from '@preact/signals-react';

type LayoutProps = Readonly<{
    children: ReactNode;
}>;

export function Layout({ children }: LayoutProps) {
    return (
        <div className='h-screen w-screen flex'>
            <Sidebar />
            <div className='grow flex flex-col bg-secondary-50'>
                {children}
            </div>
        </div>
    );
}

function Sidebar() {
    const [ isCollapsed, setIsCollapsed ] = useToggle(false);
    const userContext = useUser();
    const { role } = userContext;

    return (
        // Nice.
        <div className={clsx('shrink-0 h-full bg-white transition-all border-r border-secondary-50', isCollapsed ? 'w-[69px]' : 'w-[200px]')}>
            <div className='h-full flex flex-col p-4 gap-1'>
                <div className='flex justify-between overflow-hidden'>
                    <Link to={routesFE.root} className='leading-6 w-9 min-w-9 p-[6px] rounded-md hover:bg-primary-50 active:bg-primary-100'>
                        <FlowlanceLogo size={24} cut />
                    </Link>
                    <Button size='small' variant='ghost' className={clsx('[&_svg]:size-5 px-2 text-secondary-300 transition-opacity', isCollapsed && 'opacity-0')} onClick={setIsCollapsed.true}>
                        <SidebarLeft3HideIcon />
                    </Button>
                </div>
                <div className='h-9'>
                    <Button size='small' variant='ghost' className={clsx('[&_svg]:size-5 px-2 text-secondary-300 transition-opacity', !isCollapsed && 'opacity-0 pointer-events-none')} onClick={setIsCollapsed.false}>
                        <SidebarLeft2ShowIcon />
                    </Button>
                </div>
                <div className='flex flex-col gap-1'>
                    {overviewMenuItems.filter(item => !item.roles || item.roles.includes(role)).map(item => (
                        <MenuLink key={item.nameTranslationId} item={item} isCollapsed={isCollapsed} />
                    ))}
                </div>
                <div className='grow' />
                <TodoMenu />
                <div className='grow' />
                <div className='flex flex-col gap-1'>
                    {userMenuItems.map(item => (
                        <MenuLink key={item.nameTranslationId} item={item} isCollapsed={isCollapsed} />
                    ))}
                </div>
            </div>
        </div>
    );
}

type MenuLinkProps = Readonly<{
    item: MenuItemBase;
    isCollapsed?: boolean;
}>;

function MenuLink({ item, isCollapsed }: MenuLinkProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'mainMenu' });
    const location = useLocation();
    const isCurrent = item.to === location.pathname;
    const isMatched = isMenuItemMatched(item, location.pathname);

    return (
        <Link
            className={cn('p-2 rounded-md leading-4 flex items-center gap-2 overflow-hidden hover:bg-primary-50 active:bg-primary-100',
                // If we are on the current page, we can't click on it again.
                isCurrent && 'pointer-events-none',
                // However, if we are on a subpage but not on of the main pages, we can click on the link to go back to the corresponding main page. So the link is only highlighted, but not disabled.
                isMatched && 'bg-primary-50 text-primary',
                isCollapsed && 'width-5',
            )}
            to={item.to}
        >
            <span className='min-w-5'>
                {item.icon({ size: 20 })}
            </span>
            <span className='text-nowrap'>
                {t(item.nameTranslationId)}
            </span>
        </Link>
    );
}

type MenuItemBase = Readonly<{
    nameTranslationId: string;
    to: string;
    match?: string | string[];
    icon: IconType;
    roles?: TeamMemberRole[];
}>;

const overviewMenuItems: MenuItemBase[] = [ {
    nameTranslationId: 'dashboard',
    icon: ViewAll1Icon,
    to: routesFE.dashboard,
}, {
    nameTranslationId: 'products',
    icon: Box2Icon,
    to: routesFE.products.list,
}, {
    nameTranslationId: 'store',
    icon: Palette1Icon,
    to: routesFE.store.resolve({ key: 'overview' }),
    roles: [ TeamMemberRole.master, TeamMemberRole.freelancer ],
    match: [ routesFE.store.path ],
}, {
    nameTranslationId: 'clients',
    icon: Users1Icon,
    to: routesFE.clients.list,
    match: [ routesFE.clients.detail.path, routesFE.clients.new, routesFE.clients.import, routesFE.orders.newBackpay ],
}, {
    nameTranslationId: 'calendar',
    icon: CalendarIcon,
    to: routesFE.calendar,
}, {
    nameTranslationId: 'orders',
    icon: Tag3Icon,
    to: routesFE.orders.list,
    match: routesFE.orders.detail.path,
}, {
    nameTranslationId: 'direct-sale',
    icon: WalletContentIcon,
    to: routesFE.directSale.resolve({ key: 'product' }),
    match: routesFE.directSale.path,
}, {
    nameTranslationId: 'team',
    // TODO
    icon: Sliders3Icon,
    to: routesFE.team,
    roles: [ TeamMemberRole.master ],
} ];

const userMenuItems: MenuItemBase[] = [ {
    nameTranslationId: 'user-settings',
    icon: Sliders3Icon,
    to: routesFE.settings.resolve({ key: 'general' }),
}, {
    nameTranslationId: 'subscription',
    icon: Sliders3Icon,
    to: routesFE.subscription.path,
    roles: [ TeamMemberRole.master, TeamMemberRole.freelancer ],
} ];

function getMatchPaths(item: MenuItemBase): string[] {
    if (!item.match)
        return [ item.to ];
    if (typeof item.match === 'string')
        return [ item.to, item.match ];

    return [ item.to, ...item.match ];
}

function isMenuItemMatched(item: MenuItemBase, pathName: string): boolean {
    return getMatchPaths(item).some(path => matchPath(path, pathName));
}

function TodoMenu() {
    const { t } = useTranslation('pages', { keyPrefix: 'mainMenu' });
    const userContext = useUser();
    const { role, subscription, team } = userContext;
    const isMasterOrFreelancer = !!toMaster(userContext);

    const { auth } = useAuth();

    const { buySubscription, isFetching } = useSubscriptionUpsert();

    const showUpgradeButton = isMasterOrFreelancer && (!subscription || subscription.code !== SubscriptionTierCode.paid || subscription.isTrial);


    return (
        <div className='flex flex-col'>
            {role !== TeamMemberRole.freelancer && (
                <span className='font-bold text-primary' style={{ fontSize: '18px' }}>
                    {team.title}
                </span>
            )}
            <Button variant='white' size='small' onClick={() => auth.logout()}>{t('logout')}</Button>
            {showUpgradeButton && (
                <SpinnerButton
                    isFetching={isFetching}
                    variant='primary'
                    size='small'
                    className='w-full'
                    onClick={() => buySubscription(SubscriptionTierCode.paid, SubscriptionTierPaymentPeriod.yearly)}
                >
                    {t('upgrade-to-pro')}
                </SpinnerButton>
            )}
            {isMasterOrFreelancer && (<>
                <Link to={routesFE.orders.newCustom}>
                    <Button variant='white' size='small'>{t('custom-order')}</Button>
                </Link>
                <Link to={routesFE.orders.newBackpay}>
                    <Button variant='white' size='small'>{t('backpay-order')}</Button>
                </Link>
            </>)}
        </div>
    );
}

// This is the almighty Topbar that changes color when the Content is scrolled.
// To achieve this effect, both components need to be used together.
// More precisely, if the Content doesn't need to scroll, or the Topbar has always the same color, it can be used without Content.
// Nevertheless, there is no real downside in using both.

const isScrolledSignal = signal(false);

type TopbarProps = Readonly<{
    children?: ReactNode;
}>;

export function Topbar({ children }: TopbarProps) {
    return (
        <div className={clsx('h-[61px] py-3 px-6 flex items-center transition-[background-color] border-b border-secondary-50', isScrolledSignal.value ? 'bg-white' : 'bg-secondary-50')} id='fl-topbar'>
            {children}
        </div>
    );
}

type ContentProps = Readonly<{
    children?: ReactNode;
}>;

export function Content({ children }: ContentProps) {
    const scrollerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        // We have to reset the signal when the component is unmounted so that the new page won't start with colored topbar (each page starts at the top).
        return () => {
            isScrolledSignal.value = false;
        };
    }, []);

    const handleScroll = useCallback((e: UIEvent<HTMLDivElement>) => {
        const isScrolledNow = !!(e.target as unknown as { scrollTop: number }).scrollTop;

        if (isScrolledSignal.peek() === isScrolledNow)
            return;

        isScrolledSignal.value = isScrolledNow;
    }, []);

    return (
        <div className='fl-main-scroller' onScroll={handleScroll} ref={scrollerRef}>
            {children}
        </div>
    );
}

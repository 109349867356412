type IconProps = Readonly<{
    size?: string | number;
    className?: string;
    cut?: boolean;
}>;

export default function FlowlanceLogo({ size, className, cut }: IconProps) {
    const src = cut ? '/static/flowlance_logo_6_cut.svg' : '/static/flowlance_logo_6.svg';
    return <img src={src} style={{ width: size, height: size }} className={className} />;
}

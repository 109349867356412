import { createDefaultFilterRowDropdown, DefaultFilterItemBadge, type Filter, type FilterDefinition, type FilterFunction, type FilterItemBadgeProps, type FilterMenuProps } from './FilterRow';
import { Button, Form } from ':components/shadcn';
import { Trans, useTranslation } from 'react-i18next';
import { compareArrays, emptyFunction } from ':frontend/utils/common';
import { type ClientInfoFE } from ':frontend/types/Client';
import { CLIENT_STATE_VALUES, ClientStateBadge } from ':frontend/components/client/ClientStateBadge';
import { ClientState } from ':utils/entity/client';
import { enumFilterToArray } from ':utils/common';

const filterName = 'clientState';

type FilterState = {
    [key in ClientState]: boolean;
};

function FilterToggleMenu({ state, setState }: FilterMenuProps<FilterState>) {
    return (<>
        {CLIENT_STATE_VALUES.map(clientState => (
            <Button key={clientState} className='fl-dropdown-button py-2 pl-4 pr-12 flex items-center justify-start' onClick={() => setState({ ...state, [clientState]: !state[clientState] })}>
                {/**
                  * This is needed in order to make the checkbox clickable. Without this, when clicking on the checkbox, the state is changing, but the checkbox still looks the same. Only after changing the state on other client state, the checkbox will change.
                  * For some reason, nothing else really works. It is possible to use something like key={'' + state[clientState]} to force the component to re-render, but this seems unnecessary.
                  * It is probably caused by the whole Dropdown thing that somehow manipulates the clients.
                  */}
                <Form.Checkbox checked={state[clientState]} className='mr-4'
                    onClick={e => {
                        setState({ ...state, [clientState]: !state[clientState] });
                        e.stopPropagation();
                    }}
                    onChange={emptyFunction}
                />
                <ClientStateBadge state={clientState} />
            </Button>
        ))}
    </>);
}

function FilterItemBadge({ item, onClose }: FilterItemBadgeProps<ClientState>) {
    const { t } = useTranslation('common', { keyPrefix: `filters.${filterName}` });

    return (
        <DefaultFilterItemBadge item={item} onClose={onClose}>
            <Trans t={t} i18nKey={`${item}-badge-label`} components={{ sm: <span /> }} />
        </DefaultFilterItemBadge>
    );
}

function remove(state: FilterState, item: ClientState): FilterState {
    return {
        ...state,
        [item]: false,
    };
}

function toItems(state: FilterState): ClientState[] {
    return Object.entries(state)
        .filter(([ , value ]) => value)
        .map(([ key ]) => key as ClientState);
}

function createFilterFunction(state: FilterState): FilterFunction<ClientInfoFE> {
    if (toItems(state).length === 0)
        return () => true;

    return (data: ClientInfoFE) => !!state[data.state];
}

function toServer(state: FilterState, previous: string[] | undefined): string[] {
    const current = enumFilterToArray(state).sort();
    return previous && compareArrays(current, previous)
        ? previous
        : current;
}

const ClientStateFilter: FilterDefinition<ClientState, FilterState, ClientInfoFE> = {
    name: filterName,
    defaultValues: {
        [ClientState.lead]: false,
        [ClientState.active]: false,
        [ClientState.inactive]: false,
    },
    FilterToggleMenu,
    FilterRowMenu: createDefaultFilterRowDropdown(filterName, FilterToggleMenu),
    FilterItemBadge,
    remove,
    toItems,
    createFilterFunction,
    toServer: toServer as (state: FilterState, previous: unknown | undefined) => string[],
};

export default ClientStateFilter as Filter;

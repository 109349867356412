import { useTranslation } from 'react-i18next';
import { Form } from ':components/shadcn';
import { type ErrorOption, type FieldPath, useForm } from 'react-hook-form';
import { SpinnerButton } from ':frontend/components/common';
import { RHFErrorMessage } from ':frontend/components/forms/ErrorMessage';
import PasswordInput from ':frontend/components/forms/PasswordInput';
import { EMAIL_SOFT_VALIDATION_PATTERN, PASSWORD_MINIMAL_LENGTH, canonizeEmail } from ':utils/forms';

export type UserFormData = {
    email: string;
    password: string;
};

export type RegisterError = {
    name: FieldPath<UserFormData>;
    error: ErrorOption;
    options?: {
        shouldFocus: boolean;
    };
};

type RegisterUserFormProps = Readonly<{
    defaultValues?: Partial<UserFormData>;
    onSubmit: (output: UserFormData) => Promise<RegisterError[]>;
    isFetching: boolean;
}>;

export default function RegisterUserForm({ defaultValues, onSubmit, isFetching }: RegisterUserFormProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'register.userForm' });
    const { t: tf } = useTranslation('common', { keyPrefix: 'form' });

    const { register, handleSubmit, setError, formState: { errors } } = useForm<UserFormData>({ defaultValues });

    async function handleValidSubmit(data: UserFormData) {
        const validationErrors = await onSubmit({ ...data, email: canonizeEmail(data.email) });

        validationErrors.forEach(error => setError(error.name, error.error, error.options));
    }

    return (
        <Form.Root onSubmit={handleSubmit(handleValidSubmit)} className='w-full'>
            <div className='fl-form-label-inside'>
                <Form.Input
                    label={t('email-label')}
                    type='email'
                    {...register('email', {
                        required: tf('email-required'),
                        pattern: {
                            value: EMAIL_SOFT_VALIDATION_PATTERN,
                            message: tf('email-invalid'),
                        },
                    })}
                />
                <RHFErrorMessage errors={errors} name='email' />
            </div>
            <div className='fl-form-label-inside mt-4'>
                <PasswordInput
                    label={t('password-label')}
                    register={register('password', {
                        required: tf('password-required'),
                        minLength: { value: PASSWORD_MINIMAL_LENGTH, message: t('password-too-short-error', { minimalLength: PASSWORD_MINIMAL_LENGTH }) },
                    })}
                />
                <RHFErrorMessage errors={errors} name='password' />
            </div>
            <SpinnerButton
                type='submit'
                className='w-full mt-4'
                isFetching={isFetching}
            >
                {t('continue-button')}
            </SpinnerButton>
        </Form.Root>
    );
}

import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import { cn } from './utils';
import { forwardRef, type ComponentPropsWithoutRef, type ElementRef } from 'react';
import { Check1Icon, ChevronRightIcon } from ':components/icons/basic';

const Root = DropdownMenuPrimitive.Root;

const Trigger = DropdownMenuPrimitive.Trigger;

const Group = DropdownMenuPrimitive.Group;

const Portal = DropdownMenuPrimitive.Portal;

const Sub = DropdownMenuPrimitive.Sub;

const RadioGroup = DropdownMenuPrimitive.RadioGroup;

type SubTriggerProps = ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.SubTrigger> & {
    className?: string;
    inset?: boolean;
};

const SubTrigger = forwardRef<ElementRef<typeof DropdownMenuPrimitive.SubTrigger>, SubTriggerProps>(({ className, inset, children, ...props }, ref) => (
    <DropdownMenuPrimitive.SubTrigger
        ref={ref}
        className={cn(
            'flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none focus:bg-secondary-50 data-[state=open]:bg-secondary-50',
            inset && 'pl-8',
            className,
        )}
        {...props}
    >
        {children}
        <ChevronRightIcon size={10} className='ml-auto' />
    </DropdownMenuPrimitive.SubTrigger>
));
SubTrigger.displayName = DropdownMenuPrimitive.SubTrigger.displayName;

type SubContentProps = ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.SubContent> & {
    className?: string;
};

const SubContent = forwardRef<ElementRef<typeof DropdownMenuPrimitive.SubContent>, SubContentProps>(({ className, ...props }, ref) => (
    <DropdownMenuPrimitive.SubContent
        ref={ref}
        className={cn(
            'z-50 min-w-[8rem] overflow-hidden rounded-md border bg-white p-1 text-body shadow-lg data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
            className,
        )}
        {...props}
    />
));
SubContent.displayName = DropdownMenuPrimitive.SubContent.displayName;

type ContentProps = ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Content> & {
    className?: string;
    sideOffset?: number;
};

const Content = forwardRef<ElementRef<typeof DropdownMenuPrimitive.Content>, ContentProps>(({ className, sideOffset = 4, ...props }, ref) => (
    <DropdownMenuPrimitive.Portal>
        <DropdownMenuPrimitive.Content
            ref={ref}
            sideOffset={sideOffset}
            className={cn(
                'z-50 min-w-[8rem] overflow-hidden rounded-md border bg-white p-1 text-body shadow-md data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
                className,
            )}
            {...props}
        />
    </DropdownMenuPrimitive.Portal>
));
Content.displayName = DropdownMenuPrimitive.Content.displayName;

type ItemProps = ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Item> & {
    className?: string;
    inset?: boolean;
};

const Item = forwardRef<ElementRef<typeof DropdownMenuPrimitive.Item>, ItemProps>(({ className, inset, ...props }, ref) => (
    <DropdownMenuPrimitive.Item
        ref={ref}
        className={cn(
            'relative flex cursor-default select-none items-center gap-2 rounded-sm px-2 py-1.5 text-sm outline-none transition-colors focus:bg-secondary-50 focus:text-body data-[disabled]:pointer-events-none data-[disabled]:opacity-50 [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0',
            inset && 'pl-8',
            className,
        )}
        {...props}
    />
));
Item.displayName = DropdownMenuPrimitive.Item.displayName;

type CheckboxItemProps = ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.CheckboxItem> & {
    className?: string;
    checked?: boolean;
};

const CheckboxItem = forwardRef<ElementRef<typeof DropdownMenuPrimitive.CheckboxItem>, CheckboxItemProps>(({ className, checked, children, ...props }, ref) => (
    <DropdownMenuPrimitive.CheckboxItem
        ref={ref}
        className={cn(
            'relative flex cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none transition-colors focus:bg-secondary-50 focus:text-body data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
            className,
        )}
        checked={checked}
        {...props}
    >
        <span className='absolute left-2 flex h-3.5 w-3.5 items-center justify-center'>
            <DropdownMenuPrimitive.ItemIndicator>
                <Check1Icon size='xs' />
            </DropdownMenuPrimitive.ItemIndicator>
        </span>
        {children}
    </DropdownMenuPrimitive.CheckboxItem>
));
CheckboxItem.displayName = DropdownMenuPrimitive.CheckboxItem.displayName;

type RadioItemProps = ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.RadioItem> & {
    className?: string;
};

const RadioItem = forwardRef<ElementRef<typeof DropdownMenuPrimitive.RadioItem>, RadioItemProps>(({ className, children, ...props }, ref) => (
    <DropdownMenuPrimitive.RadioItem
        ref={ref}
        className={cn(
            'relative flex cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none transition-colors focus:bg-secondary-50 focus:text-body data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
            className,
        )}
        {...props}
    >
        <span className='absolute left-2 flex h-3.5 w-3.5 items-center justify-center'>
            <DropdownMenuPrimitive.ItemIndicator>
                <div className='h-2 w-2 rounded-full bg-body' />
            </DropdownMenuPrimitive.ItemIndicator>
        </span>
        {children}
    </DropdownMenuPrimitive.RadioItem>
));
RadioItem.displayName = DropdownMenuPrimitive.RadioItem.displayName;

type LabelProps = ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Label> & {
    className?: string;
    inset?: boolean;
};

const Label = forwardRef<ElementRef<typeof DropdownMenuPrimitive.Label>, LabelProps>(({ className, inset, ...props }, ref) => (
    <DropdownMenuPrimitive.Label
        ref={ref}
        className={cn(
            'px-2 py-1.5 text-sm',
            inset && 'pl-8',
            className,
        )}
        {...props}
    />
));
Label.displayName = DropdownMenuPrimitive.Label.displayName;

type SeparatorProps = ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Separator> & {
    className?: string;
};

const Separator = forwardRef<ElementRef<typeof DropdownMenuPrimitive.Separator>, SeparatorProps>(({ className, ...props }, ref) => (
    <DropdownMenuPrimitive.Separator
        ref={ref}
        className={cn('-mx-1 my-1 h-px bg-secondary-100', className)}
        {...props}
    />
));
Separator.displayName = DropdownMenuPrimitive.Separator.displayName;

type ShortcutProps = ComponentPropsWithoutRef<'span'> & {
    className?: string;
};

function Shortcut({ className, ...props }: ShortcutProps) {
    return (
        <span
            className={cn('ml-auto text-xs tracking-widest opacity-60', className)}
            {...props}
        />
    );
}
Shortcut.displayName = 'DropdownMenuShortcut';

export const DropdownMenu = {
    Root,
    Trigger,
    Content,
    Item,
    CheckboxItem,
    RadioItem,
    Label,
    Separator,
    Shortcut,
    Group,
    Portal,
    Sub,
    SubContent,
    SubTrigger,
    RadioGroup,
};
